import styled from 'styled-components';
import { bee4Theme } from './../../../../../styles/theme';
import { Table as TableComponent } from '../../../../../components/TradingTable/table';

export const Download = styled.a`
    transition: all 0.5s ease-in-out;

    svg {
        transition: all 0.5s ease-in-out;
    }
    &:hover {
        svg {
            fill: ${bee4Theme.colors.blue[100]};
        }
        color: ${bee4Theme.colors.blue[100]};
    }
`;

export const Table = styled(TableComponent)`
    position: relative !important;

    tr {
        padding: 0 !important;
        th,
        td {
            width: 200px;
        }
    }
    tbody {
        height: 290px;
        td {
            padding: 10px 24px !important;
        }
        th {
            font-weight: 700 !important;
        }
    }
`;
