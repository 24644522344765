import { createIcon } from '@chakra-ui/icons';

export const IconQuestion = createIcon({
    displayName: 'IconQuestion',
    viewBox: '0 0 22 22',
    path: (
        <path
            d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 17.0938C10.482 17.0938 10.0625 16.6742 10.0625 16.1562C10.0625 15.6383 10.482 15.2188 11 15.2188C11.518 15.2188 11.9375 15.6383 11.9375 16.1562C11.9375 16.6742 11.518 17.0938 11 17.0938ZM12.4742 11.9492C12.262 12.0312 12.0793 12.1752 11.9501 12.3625C11.821 12.5498 11.7512 12.7717 11.75 12.9992V13.5312C11.75 13.6344 11.6656 13.7188 11.5625 13.7188H10.4375C10.3344 13.7188 10.25 13.6344 10.25 13.5312V13.0273C10.25 12.4859 10.407 11.9516 10.7164 11.5062C11.0187 11.0703 11.4406 10.7375 11.9375 10.5477C12.7344 10.2406 13.25 9.57266 13.25 8.84375C13.25 7.81016 12.2398 6.96875 11 6.96875C9.76016 6.96875 8.75 7.81016 8.75 8.84375V9.02188C8.75 9.125 8.66563 9.20938 8.5625 9.20938H7.4375C7.33437 9.20938 7.25 9.125 7.25 9.02188V8.84375C7.25 7.92266 7.65312 7.0625 8.38437 6.42266C9.0875 5.80625 10.0156 5.46875 11 5.46875C11.9844 5.46875 12.9125 5.80859 13.6156 6.42266C14.3469 7.0625 14.75 7.92266 14.75 8.84375C14.75 10.1984 13.857 11.4172 12.4742 11.9492Z"
            fill="currentColor"
        />
    ),
});
