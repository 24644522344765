import appConfig from '../../config/appConfig';

import { TGetOrdersHistoryInitialState } from './types';

export const initialState: TGetOrdersHistoryInitialState = {
    error: false,
    loading: true,
    orders: [],
    pagination: { offset: 0, limit: appConfig.REGISTER_PER_PAGE },
};
