import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial';

export const getOpenOrdersSlice = createSlice({
    name: 'GetOpenOrders',
    initialState,
    reducers: {
        getOpenOrders: (state): void => {
            state.error = false;
            state.loading = true;
            state.orders = initialState.orders;
        },
        getOpenOrdersSuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;

            if (!!action.payload?.length && action.payload[0].OrderState !== 'FullyExecuted') {
                state.orders = [...state.orders, ...action.payload];
            }
            state.loading = false;
        },
        getOpenOrdersFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
        },
        removeItemOpenOrders: (state, action): void => {
            state.orders = state.orders.filter(
                (item) => item.OrderId !== action.payload.OrderId && action.payload[0]?.OrderState !== 'FullyExecuted'
            );
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },
        updateItemOpenOrders: (state, action): void => {
            state.orders = state.orders.filter((item) => item.OrderId !== action.payload.orderIdToReplace);
            state.error = false;
            state.loading = false;
        },
        resetOpenOrders: (state): void => {
            state.orders = [];
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },
    },
});

export const getOpenOrdersActions = getOpenOrdersSlice.actions;
export default getOpenOrdersSlice.reducer;
