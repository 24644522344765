import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb as BreadcrumbChakra, BreadcrumbItem, useColorMode } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { bee4Theme } from '../../styles/theme';

export const Breadcrumb = () => {
    const location = useLocation();
    const { colorMode } = useColorMode();

    let pathName = location.pathname.split(/[/]/);
    pathName = pathName.slice(0, pathName.length - 1);

    const formatName = (name: string) => {
        return name ? name.replace('-', ' ') : 'Início';
    };

    const redirectPath = (path: string) => {
        return path ? `/${path}` : '/';
    };

    const lastItemColor = colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.lead[500];
    const ItemsColor = colorMode === 'dark' ? bee4Theme.colors.gray[300] : bee4Theme.colors.gray[600];

    return (
        <>
            <BreadcrumbChakra
                spacing="6px"
                margin="30px 0"
                separator={<ChevronRightIcon color={bee4Theme.colors.gray[300]} fontSize={30} fontWeight="400" />}
            >
                {pathName.map((path, index) => (
                    <BreadcrumbItem
                        key={index}
                        color={index < pathName.length - 1 ? ItemsColor : lastItemColor}
                        fontSize="0.8rem"
                    >
                        <Link to={redirectPath(path)} style={{ textTransform: 'uppercase' }}>
                            {formatName(path)}
                        </Link>
                    </BreadcrumbItem>
                ))}
            </BreadcrumbChakra>
        </>
    );
};
