import styled from 'styled-components';
import { Container as ContainerChakra } from '@chakra-ui/react';

export const Form = styled.form`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
`;

export const WrapperBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 25px auto;

    @media (max-width: 1335px) {
        margin: 20px auto;
    }
`;

export const WrapperImage = styled.div`
    @media (max-width: 1335px) {
        width: 160px;
        height: 51px;
    }
`;

export const Container = styled(ContainerChakra)`
    height: calc(90vh - 45px);

    @media (max-width: 550px) {
        height: 100vh;
        margin-top: -5vh;
    }
`;

export const MessageWrapper = styled.div`
    margin: 40px 0 0;

    .breakLine {
        white-space: 'pre-wrap';
    }
`;
