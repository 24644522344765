import { TSubscribeTradesItem } from '../store/subscribeTrades/types';
import { date } from './date';

export const getTradePriceFromLastDealing = (
    trades: Record<TSubscribeTradesItem['TradeId'], TSubscribeTradesItem>
): number => {
    const _trades = Object.values(trades);
    const dates = [];

    _trades.map((item) => {
        const _date = date(item.Tradetime, 'x').startOf('day').format('DD/MM/YYYY');
        !dates.includes(_date) && dates.push(_date);
    });

    const index = dates.length > 1 ? 1 : 0;
    const lastDealingDate = date(dates[index], 'DD/MM/YYYY');
    const found = _trades.find((item) => date(item.Tradetime, 'x').startOf('day').isSame(lastDealingDate));

    return !!found ? found.Price : 0;
};
