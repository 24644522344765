import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';

export const Checkmark = styled.span`
    width: 14px;
    height: 14px;

    display: block;
    position: absolute;
    top: 50%;
    left: 0;

    transform: translateY(-50%);
    background: ${bee4Theme.colors.white};

    &::before {
        content: '';

        position: absolute;
        border-radius: 2px;
        z-index: 1;

        inset: -1px;
        padding: 1px;

        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
        -webkit-mask-composite: xor;

        mask-composite: exclude;
        transition: all 0.5s ease-in-out;
    }
    &::after {
        content: '';
        display: block;

        left: 4px;
        top: 0;

        z-index: 2;

        width: 7px;
        height: 10px;

        border: solid ${bee4Theme.colors.white};
        border-width: 0 2px 2px 0;

        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);

        opacity: 0;
        transition: all 0.3s ease-in-out;

        position: absolute;
        visibility: hidden;
    }
`;

export const Input = styled.input`
    width: 0;
    height: 0;

    position: absolute;
    opacity: 0;

    cursor: pointer;
    transition: all 0.5s ease-in-out;

    &:checked ~ ${Checkmark} {
        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        &:after {
            visibility: visible;
            opacity: 1;
        }
    }
`;

export const Container = styled.label`
    width: 100%;

    display: flex;
    direction: row;

    align-items: center;
    justify-content: start;

    position: relative;
    padding-left: 20px;

    cursor: pointer;

    font-weight: 400;
    font-size: 0.8rem;
    user-select: none;

    transition: all 0.5s ease-in-out;
`;
