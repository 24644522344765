import { Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { mergeMap, throttleTime, map, catchError, bufferTime, take, mergeAll, delay } from 'rxjs/operators';

import { getOrdersHistoryActions, getOrdersHistorySlice } from '.';
import { TOutputFrame } from '../../services/alphapoint/types';

const mapHistory = (defaultBody, Alphapoint, defaultBufferTime, state$) => {
    const bodiesPayload = [
        { ...defaultBody, InstrumentId: state$.value.companies.current.assets.instrumentBuyId },
        { ...defaultBody, InstrumentId: state$.value.companies.current.assets.instrumentSellId },
    ];

    return bodiesPayload.map((item) =>
        Alphapoint.subscribe({ action: getOrdersHistorySlice.name, ...item }).pipe(
            bufferTime(defaultBufferTime),
            map((data: TOutputFrame[]) => {
                const flattenResponse = data.reduce((acc, cur) => [...(cur.data as Array<number>), ...acc], []);
                return getOrdersHistoryActions.getOrdersHistorySuccess({
                    orders: flattenResponse,
                    trades: state$.value.tradesHistory,
                });
            }),
            catchError(() => of(getOrdersHistoryActions.getOrdersHistoryFailed())),
            take(1)
        )
    );
};

export const getOrdersHistoryEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime, defaultBufferTime }) =>
    action$.pipe(
        filter(getOrdersHistoryActions.getOrdersHistory.match),
        filter(() => !!state$.value.companies.current?.assets?.defaultInstrumentId),
        throttleTime(defaultThrottleTime),
        delay(1000),
        mergeMap(() => {
            const defaultBody = {
                omsId: state$.value.companies.current.assets.defaultOMSId,
                accountId: state$.value.user.userSession.alphapoint.accountId,
                instrumentId: state$.value.companies.current.assets.defaultInstrumentId,
                depth: state$.value.ordersHistory.pagination.limit,
                startIndex: 0,
            };
            return mapHistory(defaultBody, Alphapoint, defaultBufferTime, state$);
        }),
        mergeAll()
    );

export const getAllOrdersHistoryEpic: Epic = (
    action$,
    state$,
    { Alphapoint, defaultThrottleTime, defaultBufferTime }
) =>
    action$.pipe(
        filter(getOrdersHistoryActions.getAllOrdersHistory.match),
        throttleTime(defaultThrottleTime),
        delay(1000),
        mergeMap(() => {
            const defaultBody = {
                omsId: state$.value.companies.current.assets.defaultOMSId,
                accountId: state$.value.user.userSession.alphapoint.accountId,
                depth: state$.value.ordersHistory.pagination.limit,
                startIndex: 0,
            };
            return mapHistory(defaultBody, Alphapoint, defaultBufferTime, state$);
        }),
        mergeAll()
    );

export const paginateOrdersHistoryEpic: Epic = (
    action$,
    state$,
    { Alphapoint, defaultThrottleTime, defaultBufferTime }
) =>
    action$.pipe(
        filter(getOrdersHistoryActions.paginateOrdersHistory.match),
        throttleTime(defaultThrottleTime),
        mergeMap(() => {
            const defaultBody = {
                omsId: state$.value.companies.current.assets.defaultOMSId,
                accountId: state$.value.user.userSession.alphapoint.accountId,
                instrumentId: state$.value.companies.current.assets.defaultInstrumentId,
                depth: state$.value.ordersHistory.pagination.limit,
                startIndex: state$.value.ordersHistory.pagination.offset,
            };
            return mapHistory(defaultBody, Alphapoint, defaultBufferTime, state$);
        }),
        mergeAll()
    );
