import { Container } from '@chakra-ui/react';

import { MetaTags } from '../../components/MetaTags';
import { PageDescript } from '../../components/PageDescription';
import { PageTitle } from '../../components/PageTitle';

export const NotFound = () => {
    return (
        <main>
            <MetaTags>
                <title>Página não encontrada - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <PageTitle>Página não encontrada!</PageTitle>
                <PageDescript>
                    Infelizmente não encontramos essa página. Revise o endereço digitado, caso tenha chegado aqui por um
                    link, nos avise para ajustarmos o mais rápido possível.
                </PageDescript>
            </Container>
        </main>
    );
};
