import { TSubscribeLevel2InitialState, TSubscribeLevel2Item } from './types';

const subscribeLevel2Map = new Map();

subscribeLevel2Map.set(0, 'MDUpdateId');
subscribeLevel2Map.set(1, 'NumberOfAccounts');
subscribeLevel2Map.set(2, 'ActionDateTime');
subscribeLevel2Map.set(3, 'ActionType');
subscribeLevel2Map.set(4, 'LastTradePrice');
subscribeLevel2Map.set(5, 'NumberOfOrders');
subscribeLevel2Map.set(6, 'Price');
subscribeLevel2Map.set(7, 'ProductPairCode');
subscribeLevel2Map.set(8, 'Quantity');
subscribeLevel2Map.set(9, 'Side');

export { subscribeLevel2Map };

export const initialState: TSubscribeLevel2InitialState = {
    error: false,
    loading: true,
    orders: {},
};

export const parseSubscribeLevel2Payload = (
    data: Array<Array<string | number>>
): Record<TSubscribeLevel2Item['Price'], TSubscribeLevel2Item> => {
    const parsedPayload: Record<TSubscribeLevel2Item['Price'], TSubscribeLevel2Item> = {};

    data.forEach((orders) => {
        let key = undefined;
        const obj = {};

        orders.forEach((i, idx) => {
            if (subscribeLevel2Map.get(idx) === 'Price') {
                key = `${i}${orders[9]}`;
            }
            obj[subscribeLevel2Map.get(idx)] = i;
        });

        parsedPayload[key] = obj;
    });

    return parsedPayload;
};
