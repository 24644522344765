import appConfig from '../config/appConfig';

export const getToken = () => {
    const _storage = checkHasLocalStorageCredentials() ? localStorage : sessionStorage;
    const lastUserAuth = _storage.getItem(
        `CognitoIdentityServiceProvider.${appConfig.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
    );
    return _storage.getItem(
        `CognitoIdentityServiceProvider.${appConfig.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID}.${lastUserAuth}.idToken`
    );
};

export const checkHasLocalStorageCredentials = () => {
    const lastUserAuth = localStorage.getItem(
        `CognitoIdentityServiceProvider.${appConfig.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
    );
    return !!localStorage.getItem(
        `CognitoIdentityServiceProvider.${appConfig.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID}.${lastUserAuth}.idToken`
    );
};
