import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';

interface H1Props {
    fontSize?: string | number;
    width?: string;
}

export const Title = styled.h1<H1Props>`
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-bottom: 10px;

    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1.6rem')};
    text-align: left;

    line-height: 1.6;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    position: relative;

    &::after {
        content: '';

        width: 50px;
        height: 5px;

        position: absolute;
        bottom: -10px;
        left: 0;

        border-radius: 90px;
        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});
    }
`;
