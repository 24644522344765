import { BoxProps, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Card } from './styles';

interface ColorfulBorderProps extends BoxProps {
    children: ReactNode;
    background?: string;
    disabled?: boolean;
}

export const ColorfulBorder = ({ children, background, ...rest }: ColorfulBorderProps) => {
    const { colorMode } = useColorMode();

    return (
        <Card $colorMode={colorMode} $background={background} {...rest}>
            {children}
        </Card>
    );
};
