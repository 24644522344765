import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faYoutube,
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedinIn,
    faTiktok,
    faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import { SocialLink } from './styles';
import { useColorMode } from '@chakra-ui/react';

interface SocialIconProps {
    media: string;
    url: string;
    width?: string;
    height?: string;
}
export const SocialIcon = ({ media, url, width, height }: SocialIconProps) => {
    const { colorMode } = useColorMode();
    const medias = {
        facebook: faFacebookF,
        instagram: faInstagram,
        twitter: faTwitter,
        youtube: faYoutube,
        linkedin: faLinkedinIn,
        tiktok: faTiktok,
        pinterest: faPinterest,
    };
    return (
        <SocialLink href={url} target="_blank" $colorMode={colorMode} width={width} height={height}>
            <FontAwesomeIcon icon={medias[media]} />
        </SocialLink>
    );
};
