import { bee4Theme } from './../../../../../styles/theme';
import styled, { createGlobalStyle } from 'styled-components';
import { devices } from '../../../../../styles/devices';

export const Form = styled.form`
    width: 100%;
    padding: 10px 0;

    display: flex;
    flex-flow: row wrap;

    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${bee4Theme.colors.lead[200]};

    @media ${devices.maxTablet} {
        flex-direction: column;
        margin: 15px auto;
    }
    @media ${devices.minLaptop} {
        flex-direction: row;
        margin: 0 auto;
    }
`;

export const DateRangePickerWrapper = styled.div`
    margin: 0 10px;

    .rs-picker {
        width: 100%;
    }
    .rs-picker-toggle {
        background-color: ${bee4Theme.colors.gray[100]} !important;

        .rs-picker-toggle-placeholder {
            color: ${bee4Theme.colors.lead[700]} !important;
        }
    }
    input {
        width: 100%;
        background-color: ${bee4Theme.colors.gray[100]};
    }

    @media ${devices.maxTablet} {
        width: 100%;
        margin: 15px 0 0;
    }
    @media ${devices.minLaptop} {
        width: 65%;
    }
`;

/**
 * Artifício técnico para não ter que incorporar o RSuit
 * integralmente ao projeto adicionar mais bibliotecas que
 * o necessário, além do suporte à LESS que não faria sentido
 * somente para usar um componetne.
 */

type TDateRangePickerProps = {
    $colorMode?: string;
};

export const DateRangePickerProvider = createGlobalStyle<TDateRangePickerProps>`
        .rs-picker-daterange-menu {
            z-index: 100000 !important;
        }
        .rs-picker-daterange-content,
        .rs-picker-toolbar {
            background-color: ${({ $colorMode }) =>
                $colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.silver[100]} !important;

            .rs-btn-link {
                color: ${bee4Theme.colors.blue[200]} !important;
            }
        }
        .rs-calendar-table-cell-is-today {
            background-color: ${bee4Theme.colors.lead[100]} !important;
            color: ${bee4Theme.colors.white} !important;
            border-radius: 6px;
        }
        .rs-picker-toggle-value {
            color: ${bee4Theme.colors.lead[100]} !important;
        }
        .rs-picker-toolbar-right {
            .rs-btn {
                background-color: ${bee4Theme.colors.green[300]};
            }
        }

        // Header
        .rs-calendar-table-header-row {
            .rs-calendar-table-cell {
                span {
                    color: ${bee4Theme.colors.yellow[300]} !important;
                    background-color: transparent !important;

                    &:hover {
                        color: ${bee4Theme.colors.yellow[300]} !important;
                        border-color: ${bee4Theme.colors.blue[200]} !important;
                        background-color: transparent !important;
                    }
                }
            }
        }

        // Content
        .rs-calendar-table-row {
            .rs-calendar-table-cell {
                &:hover {
                    .rs-calendar-table-cell-content {
                        color: unset !important;
                        border: 1px solid ${bee4Theme.colors.blue[200]} !important;
                        background-color: transparent !important;
                    }
                }
            }
            .rs-calendar-table-cell-in-range {
                &::before {
                    background-color: ${({ $colorMode }) =>
                        $colorMode === 'dark' ? bee4Theme.colors.gray[400] : bee4Theme.colors.gray[100]};
                }
            }
        }
        .rs-calendar-table-cell-selected {
            .rs-calendar-table-cell-content {
                border: 1px solid ${bee4Theme.colors.blue[200]} !important;
                background-color: transparent !important;
                .rs-calendar-table-cell-day {
                    color: ${({ $colorMode }) =>
                        $colorMode === 'dark' ? bee4Theme.colors.gray[100] : bee4Theme.colors.lead[500]} !important;
                }
            }
        }
        .rs-calendar-table-cell-un-same-month {
            &.rs-calendar-table-cell-is-today {
                background-color: ${bee4Theme.colors.lead[100]} !important;
                color: ${({ $colorMode }) =>
                    $colorMode === 'dark' ? bee4Theme.colors.black : bee4Theme.colors.white} !important;
                border-radius: 6px;
            }
        }
        .rs-picker-daterange-calendar-group {
            @media ${devices.maxTablet} {
                height: auto;
                display: flex;
                min-width: 309px;
                flex-direction: column;

                .rs-calendar {
                    width: 100%;
                    min-width: unset;
                    border-right: 0 !important;
                }
            }
        }
    `;
