import { createSlice } from '@reduxjs/toolkit';
import { date } from '../../utils/date';
import { initialState, parseSubscribeTradesPayload } from './initial';
import appConfig from '../../config/appConfig';
import { TSubscribeTradesItem } from './types';

const sort = (_arr: Record<number, TSubscribeTradesItem>) => {
    return !!Object.values(_arr) && !!Object.values(_arr).length
        ? Object.values(_arr)
              .slice()
              .sort((a, b) => b.TradeId - a.TradeId)
        : _arr;
};

const duplicateFilters = (list: Record<number, TSubscribeTradesItem>) => {
    return Object.values(list).filter(
        (value, index, self) =>
            index ===
            self.findIndex(
                (t) => t.Tradetime === value.Tradetime && t.TradeId === value.TradeId && t.Price === value.Price
            )
    );
};

export const subscribeTradesSlice = createSlice({
    name: 'SubscribeTrades',
    initialState,
    reducers: {
        subscribeTrades: (state): void => {
            state.error = false;
            state.loading = true;
            state.trades = initialState.trades;
        },
        subscribeTradesSuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;

            let _trades = { ...parseSubscribeTradesPayload(action.payload), ...state.trades };
            if (!!appConfig.DATE_START_LIST_ORDERS) {
                const _start = date(appConfig.DATE_START_LIST_ORDERS, 'YYYY-MM-DD HH:mm:ss');

                // Limit from date
                _trades = Object.fromEntries(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    Object.entries(_trades).filter(([key, item]) => {
                        const tradeTime = date(item.Tradetime, 'x');
                        return tradeTime.isSameOrAfter(_start);
                    })
                );
            }

            const trades = duplicateFilters(sort(_trades));
            const times = [1674742543961, 1674742543960, 1674742543962, 1674742544005, 1674742543964];

            state.trades = trades.filter((item) => !times.includes(item.Tradetime));
            state.loading = false;
        },
        subscribeTradesFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
        resetTrades: (state): void => {
            state.trades = initialState.trades;
            state.updated = Date.now();
            state.error = false;
            state.loading = false;
        },
    },
});

export const subscribeTradesActions = subscribeTradesSlice.actions;
export default subscribeTradesSlice.reducer;
