import { TGetTradesHistoryInitialState } from './types';

export const initialState: TGetTradesHistoryInitialState = {
    error: false,
    trades: [],
    pagination: {
        offset: 0,
        limit: 0,
    },
};
