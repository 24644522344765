import { useNavigate } from 'react-router-dom';
import { Container, Flex, Text, Image, Box } from '@chakra-ui/react';

import { CardBox } from '../../../components/CardBox';
import { GradientButton } from '../../../components/GradientButton';
import { MetaTags } from '../../../components/MetaTags';

import Logo from '../../../assets/logos/bee4-white-logo.svg';
import IconSuccess from '../../../assets/icons/icon-success.svg';

import { useAppDispatch } from '../../../hooks/redux';
import { userActions } from '../../../store/user';
import { Main } from './styles';

export const PasswordSuccess = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleReturn = () => {
        dispatch(userActions.signOut());
        navigate('/');
    };

    return (
        <Main>
            <MetaTags>
                <title>Senha definida com sucesso - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <Flex width="100%" height="100%" flexFlow="column" alignItems="center" justifyContent="center">
                    <Image
                        boxSize="180px"
                        height="60px"
                        margin="0 auto 10px"
                        objectFit="contain"
                        src={Logo}
                        alt="Bee4"
                    />

                    <Flex width="90%" margin="15px auto" wrap="wrap" alignItems="center" justifyContent="center">
                        <Image
                            boxSize="49px"
                            height="49px"
                            margin="0 10px"
                            objectFit="contain"
                            src={IconSuccess}
                            alt="Sucesso"
                        />
                        <Text fontWeight="700" fontSize="1.8rem" textAlign="center">
                            Senha definida com sucesso!
                        </Text>
                    </Flex>

                    <CardBox width="100%" maxWidth="500px" height="auto" $topLine={true} padding="20px 0">
                        <Text fontSize=".9rem" color="white" textAlign="center">
                            Você já pode voltar a acessar nosso ambiente de negociação.
                        </Text>
                    </CardBox>

                    <Box margin="30px auto">
                        <GradientButton
                            width="320px"
                            id="sinin"
                            data-testid="sinin"
                            label="Acessar ambiente de negociação"
                            type="button"
                            variant="colorful"
                            onClick={handleReturn}
                        />
                    </Box>
                </Flex>
            </Container>
        </Main>
    );
};
