import { getToken } from './../utils/token';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import appConfig from '../config/appConfig';

enum StatusCode {
    NetworkError = 0,
    Unauthorized = 401,
    Forbidden = 403,
    InternalServerError = 500,
}

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
        const token = getToken();
        if (!!token) config.headers.Authorization = token;

        return config;
    } catch (error) {
        throw new Error(error);
    }
};

class Http {
    private instance: AxiosInstance | null = null;

    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp();
    }

    initHttp() {
        const http = axios.create({
            baseURL: appConfig.API_URL,
        });

        http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
        http.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const { response } = error;
                return this.handleError(response);
            }
        );

        this.instance = http;
        return http;
    }

    request<T = never, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config);
    }

    get<T = never, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config);
    }

    post<T = never, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig<T>): Promise<R> {
        return this.http.post<T, R>(url, data, config);
    }

    put<T = never, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig<T>): Promise<R> {
        return this.http.put<T, R>(url, data, config);
    }

    delete<T = never, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(url, config);
    }

    private handleError(error) {
        const { status } = error;
        switch (status) {
            case StatusCode.InternalServerError: {
                // Handle InternalServerError
                break;
            }
            case StatusCode.Forbidden: {
                // Handle Forbidden
                break;
            }
            case StatusCode.Unauthorized: {
                // Handle Unauthorized
                location.reload();
                break;
            }
        }

        return Promise.reject(error);
    }
}

export const http = new Http();
