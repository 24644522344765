import { HStack, VStack, Text, useColorModeValue } from '@chakra-ui/react';

import { Icon, IconProps } from '../../../../components/Icon';

interface IconLineProps {
    icon: IconProps['type'];
    title: string;
    text?: string | number;
    $hasBorder?: boolean;
    height?: string;
    width?: string;
    centered?: boolean;
}

export const IconLine = ({
    icon,
    title,
    text,
    $hasBorder = true,
    height = '20px',
    width = '20px',
    centered,
}: IconLineProps) => {
    const publishDateColor = useColorModeValue('lead.200', 'gray.200');

    return (
        <HStack
            borderBottom={$hasBorder && '2px solid'}
            borderColor="lead.100"
            alignItems={centered ? 'center' : 'left'}
            mb={$hasBorder ? 4 : 0}
            pb={$hasBorder ? 4 : 0}
        >
            {icon && <Icon type={icon} height={height} width={width} />}

            <VStack alignItems="left" spacing={0}>
                <Text fontWeight="700" fontSize="0.9rem">
                    {title}
                </Text>
                {text && (
                    <Text fontWeight="400" fontSize="0.8rem" color={publishDateColor} m={0}>
                        {text}
                    </Text>
                )}
            </VStack>
        </HStack>
    );
};
