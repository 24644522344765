import { useEffect, useState } from 'react';
import { useColorMode, Text } from '@chakra-ui/react';

import { useAppSelector } from '../../../../hooks/redux';
import { TBlockedResponse, useRoles } from '../../../../hooks/useRules';
import { Cancel } from './styles';

type TEditButton = {
    type: string;
    action: () => void;
};

export const CancelButton = ({ action, type }: TEditButton) => {
    const { colorMode } = useColorMode();
    const shouldIBlocked = useRoles();

    const { openOrders, timeline, cancelOrder } = useAppSelector((state) => state);
    const [role, setRole] = useState<TBlockedResponse>({} as TBlockedResponse);

    useEffect(() => {
        setRole(shouldIBlocked(type, 'cancel'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cancelButtonElement = () => (
        <Text display="block" fontWeight="700" fontSize="0.75rem" color="gray.700">
            Cancelar
        </Text>
    );

    if (role.blocked) {
        return cancelButtonElement();
    } else {
        if (
            !['irrevocable', 'unstable', 'closed', 'maintenace'].includes(timeline.currentStep.type) &&
            !cancelOrder.busy &&
            !openOrders.loading
        ) {
            return (
                <Cancel $colorMode={colorMode} onClick={action}>
                    Cancelar
                </Cancel>
            );
        }

        return cancelButtonElement();
    }
};
