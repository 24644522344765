import { App } from './App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import appConfig from './config/appConfig';

/**
 * Como o app está hospedado no S3, usar .env fica complicado
 * Então estou colocando hard code aqui para resolver o problema
 * das variáveis de ambiente.
 */

/** GTM **/
import TagManager from 'react-gtm-module';
const gtmId = appConfig.GTM_ID;
TagManager.initialize({ gtmId });

/** CONFIG INICIAL LOG - SENTRY **/
import { configInitialLog } from './config/logManager';
configInitialLog();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
