import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';
import { Link as LinkComponent } from '../../components/Link';
import { devices } from '../../styles/devices';

type TDateLabelProps = {
    $colorMode: string;
    width?: string;
    height?: string;
};

export const DateLabel = styled.tr<TDateLabelProps>`
    width: 100%;
    background: ${(props) =>
        props.$colorMode === 'dark'
            ? bee4Theme.colors.lead[300]
            : `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`} !important;

    label {
        background: ${bee4Theme.colors.lead[200]};
        color: ${bee4Theme.colors.white};
        padding: 5px 10px;
        border-radius: 25px;

        font-weight: 500;
        font-size: 0.75rem;

        text-align: left;
        float: left;
    }
`;

export const Link = styled(LinkComponent)`
    font-size: 0.7rem;
    font-weight: 700;

    @media ${devices.maxTablet} {
        position: relative;
    }
    @media ${devices.minLaptop} {
        position: absolute;
        top: 32px;
        right: 20px;
    }
`;
