import styled from 'styled-components';
import { bee4Theme } from '../../../../styles/theme';

type ButtonProps = {
    className?: string;
};

type TButtonsBox = {
    background: string;
};

type TCell = {
    width?: string | number;
};

type TLabel = { $colorMode?: string };
type TBoxLoadingProps = { $colorMode?: string };

export const CompanyBox = styled.div`
    width: 100%;
    padding: 10px 0 0;

    overflow: hidden;
    position: relative;
    border-radius: 0 10px 10px 10px;

    --transition: all 0.5s ease-in-out;
`;

export const CompanyData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 0;
`;

export const CompanyLastDealing = styled.div`
    padding: 0 10px 10px;
    position: relative;

    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: start;
`;

export const CompanyExcerpt = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4;
    padding: 0 10px 10px;
`;

export const Cell = styled.div<TCell>`
    width: ${({ width }) => width};
    font-weight: 700;
    font-size: 0.85rem;
    padding: 5px 1px;
`;

export const Label = styled.span<TLabel>`
    font-weight: 400;
    font-size: 0.85rem;
    color: ${({ $colorMode }) => ($colorMode === 'light' ? bee4Theme.colors.gray[700] : bee4Theme.colors.gray[300])};
    line-height: 1.4;
`;

export const Line = styled.hr`
    width: 100%;
    height: 2px;
    margin: 0 0 5px;

    background: transparent;
    border-top: 2px solid ${bee4Theme.colors.lead[200]};
`;

export const ButtonsBox = styled.div<TButtonsBox>`
    padding: 15px;
    background: ${(props) => props.background};

    display: flex;
    direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 0 0 10px 10px;
    overflow: hidden;
`;

export const Button = styled.button<ButtonProps>`
    padding: 2px 15px;
    margin: 0 3px;

    border: 0;
    border-radius: 50px;

    background: ${bee4Theme.colors.gray[500]};
    transition: all 0.3s ease-in-out;

    font-weight: 400;
    font-size: 0.7rem;

    color: ${bee4Theme.colors.white};

    cursor: unset;

    /* &:hover {
        background: ${bee4Theme.colors.blue[200]};
    } */

    &.active {
        background: ${bee4Theme.colors.blue[200]};
        font-weight: 700;
    }
`;

export const Logo = styled.figure`
    width: 64px;
    height: 64px;

    padding: 2px;
    overflow: hidden;
    position: relative;

    border-radius: 8px;
    background-color: ${bee4Theme.colors.white};

    img {
        width: 85%;
        height: 85%;

        position: absolute;
        object-fit: contain;
        object-position: center;

        z-index: 0;
        display: block;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }
`;

export const BoxLoading = styled.span<TBoxLoadingProps>`
    width: 95%;
    height: 100%;

    position: absolute;
    display: inline-block;

    z-index: 4;
    background: ${({ $colorMode }) =>
        $colorMode === 'dark'
            ? 'linear-gradient(#14161da3 50%, #14161da3 50%)'
            : 'linear-gradient(90deg,#F9F9F982,#DDE5FCA1)'};
`;
