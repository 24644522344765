import { configureStore } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { createEpicMiddleware } from 'redux-observable';

import config from '../config/appConfig';

import Alphapoint from '../services/alphapoint';
import { combinedEpics } from './epics';
import { rootReducers } from './reducers';

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        Alphapoint,
        Auth,
        defaultBufferTime: config._DEFAULT_BUFFER_TIME_,
        defaultThrottleTime: config._DEFAULT_THROTTLE_TIME_,
    },
});

const store = configureStore({
    reducer: rootReducers,
    middleware: [epicMiddleware],
});

epicMiddleware.run(combinedEpics);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
