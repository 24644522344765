import { Routes, Route } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { RescuePasswordProvider } from '../context/rescuePassword';

import { RescuePassword } from '../pages/RescuePassword';
import { SignIn } from '../pages/SignIn';

export function AuthRoutes(): JSX.Element {
    return (
        <>
            <Routes>
                <Route
                    path="/esqueci-minha-senha"
                    element={
                        <RescuePasswordProvider>
                            <RescuePassword />
                        </RescuePasswordProvider>
                    }
                />
                <Route path="/" element={<SignIn />} />
                <Route path="*" element={<SignIn />} />
            </Routes>
            <Footer />
        </>
    );
}
