import { useColorMode } from '@chakra-ui/react';
import { useAppSelector } from './redux';

export type TDataLayerProps = {
    event?: string;
    userId?: number | string;
    activeUser?: boolean;
    userType?: string;
    theme?: string;

    title: string;
    page: string;
};

function HookGTMDataLayer() {
    const { user } = useAppSelector((state) => state);
    const { colorMode } = useColorMode();

    const sendEvent = ({ event, page, title, userId, userType, activeUser, theme }: TDataLayerProps) => {
        window.dataLayer.push({
            event: event || 'pageView',
            page: page,
            pageTitle: title,
            userId: userId || user.userSession?.cognito?.userId || null,
            userType: userType || 'Investidor',
            activeUser: activeUser || true,
            theme: theme || colorMode,
        });
    };
    return sendEvent;
}

export const useGTMDataLayer = HookGTMDataLayer;
