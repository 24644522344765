import { AlertStatus, createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import { bee4Theme } from '../../styles/theme';

export interface ToastProps extends UseToastOptions {
    id: string;
    title: string;
    description: string;
    status: AlertStatus;
    date?: string;
}

export const toast = createStandaloneToast({ theme: bee4Theme });
export const Toast = (props: ToastProps) => {
    const interval = setInterval(() => {
        if (!!props.id && !toast.isActive(props.id)) {
            toast({
                duration: 7000,
                isClosable: true,
                position: 'top-right',
                variant: 'bee4',
                ...props,
            });
        }
        clearInterval(interval);
    }, 0);
};
