import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { switchMap, throttleTime, map, catchError, filter, concatMap } from 'rxjs/operators';

import { checkHasLocalStorageCredentials, getToken } from './../../utils/token';
import awsConfig from '../../config/appConfig';

import { userActions } from '.';
import { TResponseAlphapoint } from './types';
import appConfig from '../../config/appConfig';

const configStorage = (remember: boolean, Auth: any) => {
    const storage = remember ? localStorage : sessionStorage;
    Auth.configure({ ...awsConfig, storage });
};

export const userSignInEpic = (action$, state$, { Auth, defaultThrottleTime }) =>
    action$.pipe(
        filter(userActions.signIn.match),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            configStorage(payload.remember, Auth);
            return Auth.signIn(payload.email, payload.password)
                .then((response) => {
                    const _data = {
                        username: response.signInUserSession?.idToken?.payload?.finchainUsername,
                        password: response.signInUserSession?.idToken?.payload?.finchainPassword,
                        cognito: response, //Para a mudança de senha no primeiro acesso ele precisa carregar o objeto por completo,
                    };
                    return response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED'
                        ? userActions.createSession(_data)
                        : userActions.loginAlphapoint(_data);
                })
                .catch((error) => userActions.signinFailed(error));
        })
    );

export const signinAlphapointEpic = (action$, state$, dependencies) =>
    action$.pipe(
        filter(userActions.loginAlphapoint.match),
        throttleTime(dependencies.defaultThrottleTime),
        switchMap(({ payload }) => {
            const body = {
                UserName: payload.username, //'demo1@flowbtc.com.br',
                Password: payload.password, //'Teste4321!',
            };
            return dependencies.Alphapoint.subscribe({ action: 'AuthenticateUser', ...body }).pipe(
                map((response: TResponseAlphapoint) =>
                    response?.data?.Authenticated
                        ? userActions.createSession({ response, ...payload })
                        : userActions.signinFailed({})
                ),
                catchError(async (error) => userActions.signinFailed(error))
            );
        })
    );

export const userSignOutEpic = (action$, state$, { Auth, defaultThrottleTime }) =>
    action$.pipe(
        filter(userActions.signOut.match),
        throttleTime(defaultThrottleTime),
        switchMap(() =>
            Auth.signOut({ global: true })
                .then(() => userActions.logoutAlphapoint())
                .catch((error) => console.log(error))
        )
    );

export const signOutAlhapointEpic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(userActions.logoutAlphapoint.match),
        throttleTime(defaultThrottleTime),
        switchMap(() =>
            Alphapoint.subscribe({ action: 'LogOut' }).pipe(
                concatMap(() => of(userActions.removeSession(), userActions.resetUserData()))
            )
        )
    );

export const checkSessionAuthEpic = (action$, state$, { Auth, defaultThrottleTime }) =>
    action$.pipe(
        filter(userActions.checkSession.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            configStorage(checkHasLocalStorageCredentials(), Auth);
            return Auth.currentSession()
                .then((response) => {
                    const _data = {
                        username: response.idToken.payload.finchainUsername,
                        password: response.idToken.payload.finchainPassword,
                        cognito: response,
                    };
                    return userActions.loginAlphapoint(_data);
                })
                .catch((error) => {
                    console.log({ error });
                    return userActions.resetUserData();
                });
        })
    );

export const createUserDataSessionEpic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(userActions.createSession.match),
        filter(() => !state$.value.user?.userSession?.cognito?.firstAccess),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            const accessData = {
                intermediaryId:
                    payload.cognito.idToken?.payload.intermediaryId ||
                    payload.cognito.signInUserSession?.idToken.payload.intermediaryId,
                taxId:
                    payload.cognito.idToken?.payload['cognito:username'] ||
                    payload.cognito.signInUserSession?.idToken.payload['cognito:username'],
            };
            if (!accessData.intermediaryId || !accessData.taxId) {
                return of(userActions.resetUserData());
            }

            const person = accessData?.taxId.length > 11 ? 'legal-person' : 'natural-person';
            return ajax({
                url: `${appConfig.API_URL}register/user/investor/${person}/${accessData.intermediaryId}/${accessData.taxId}`,
                crossDomain: true,
                method: 'GET',
                headers: { Authorization: getToken() },
            }).pipe(
                map(({ response }) => {
                    return userActions.setUserDataSession(response);
                }),
                catchError((error) => {
                    console.log({ error });
                    return of(userActions.resetUserData());
                })
            );
        })
    );
