import { createIcon } from '@chakra-ui/icons';

export const IconDownload = createIcon({
    displayName: 'IconDownload',
    viewBox: '0 0 30 18',
    path: (
        <>
            <path
                d="M18.6156 18.2861H16.2237V10.4512C16.2237 10.3089 16.1075 10.1925 15.9654 10.1925H14.0286C13.8866 10.1925 13.7704 10.3089 13.7704 10.4512V18.2861H11.3849C11.1686 18.2861 11.0492 18.5351 11.1815 18.7032L14.7969 23.2852C14.821 23.3161 14.8519 23.3411 14.8871 23.3583C14.9224 23.3755 14.9611 23.3845 15.0003 23.3845C15.0394 23.3845 15.0781 23.3755 15.1134 23.3583C15.1486 23.3411 15.1795 23.3161 15.2036 23.2852L18.819 18.7032C18.9514 18.5351 18.8319 18.2861 18.6156 18.2861Z"
                fill="currentColor"
            />
            <path
                d="M24.6648 7.29902C23.1864 3.39287 19.4193 0.615234 15.0066 0.615234C10.5939 0.615234 6.82676 3.38964 5.34833 7.29579C2.58191 8.02334 0.538574 10.5488 0.538574 13.5495C0.538574 17.1226 3.42765 20.0166 6.99139 20.0166H8.28583C8.42786 20.0166 8.54407 19.9002 8.54407 19.758V17.8178C8.54407 17.6755 8.42786 17.5591 8.28583 17.5591H6.99139C5.90355 17.5591 4.88026 17.1258 4.11845 16.3401C3.35987 15.5576 2.95636 14.5034 2.99187 13.4105C3.02092 12.5568 3.31144 11.7549 3.83761 11.0791C4.37669 10.3903 5.13205 9.88911 5.97134 9.66599L7.19476 9.34587L7.64345 8.16238C7.92106 7.42513 8.30842 6.73638 8.79585 6.1123C9.27706 5.49374 9.84707 4.94999 10.4873 4.49875C11.8141 3.56425 13.3764 3.06951 15.0066 3.06951C16.6367 3.06951 18.1991 3.56425 19.5258 4.49875C20.1682 4.95145 20.7363 5.49469 21.2173 6.1123C21.7047 6.73638 22.0921 7.42836 22.3697 8.16238L22.8152 9.34264L24.0353 9.66599C25.7849 10.1381 27.0084 11.7322 27.0084 13.5495C27.0084 14.6198 26.5919 15.6287 25.8366 16.3853C25.4661 16.7586 25.0255 17.0545 24.5401 17.256C24.0547 17.4575 23.5342 17.5605 23.0088 17.5591H21.7144C21.5724 17.5591 21.4562 17.6755 21.4562 17.8178V19.758C21.4562 19.9002 21.5724 20.0166 21.7144 20.0166H23.0088C26.5726 20.0166 29.4617 17.1226 29.4617 13.5495C29.4617 10.552 27.4248 8.02981 24.6648 7.29902Z"
                fill="currentColor"
            />
        </>
    ),
});
