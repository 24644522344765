import { Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, throttleTime, filter, catchError, concatMap } from 'rxjs/operators';
import { cancelOrderActions } from '.';
import { cancelReplaceOrderActions } from '../cancelReplaceOrder';
import { getOpenOrdersActions } from '../getOpenOrders';
import { getOrdersHistoryActions } from '../getOrdersHistory';

import blockedUserRules from '../../data/BlockedUserRules/rules.json';

export const cancelOrderEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(cancelOrderActions.sendCancelOrder.match),
        filter(
            () => !['unstable', 'closed', 'maintenace', 'irrevocable'].includes(state$.value.timeline.currentStep?.type)
        ),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            /**
             * Valida se o usuário pode realizar a ação
             ***/
            if (
                state$.value.user?.userSession?.status.blocked &&
                blockedUserRules.blockedActions[payload.Side.toLowerCase()].cancel.some((item) =>
                    state$.value.user?.userSession?.status.type.includes(item)
                )
            ) {
                return of(cancelReplaceOrderActions.editFailed({}));
            }

            return Alphapoint.subscribe({ action: 'CancelOrder', ...payload }).pipe(
                concatMap(({ data }) => {
                    return data.errorCode === 104
                        ? of(cancelOrderActions.cancelFailed(data))
                        : merge(
                              of(cancelReplaceOrderActions.resetEditOrder()),
                              of(cancelOrderActions.cancelOrder()),
                              of(getOpenOrdersActions.removeItemOpenOrders(payload)),
                              of(getOrdersHistoryActions.getOrdersHistory()),
                              of(getOrdersHistoryActions.updateItemOrdersHistory(payload))
                          );
                }),
                catchError((error) => of(cancelOrderActions.cancelFailed(error)))
            );
        })
    );
