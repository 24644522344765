import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Flex,
    VStack,
    Text,
    Input,
    FormControl,
    useColorMode,
    FormErrorMessage,
    HStack,
    Image,
    useColorModeValue,
} from '@chakra-ui/react';

import { Button } from '../../../../../components/Button';
import { Link } from '../../../../../components/Link';
import CurrencyInput from '../../../../../components/CurrencyInput';
import iconAlert from '../../../../../assets/icons/icon-alert.svg';

import { convertStringCurrencyToNumber, currency } from '../../../../../utils/numbers';
import { dateComplete } from '../../../../../utils/date';

import { Form, Label } from './styles';
import { TGetOpenOrdersItem } from '../../../../../store/getOpenOrders/types';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { cancelReplaceOrderActions } from '../../../../../store/cancelReplaceOrder';

interface OrderEditlProps {
    data: TGetOpenOrdersItem;
    onClose: () => void;
}

export const OrderEdit = ({ data, onClose }: OrderEditlProps) => {
    const status = {
        'fully executed': 'Executada',
        canceled: 'Cancelada',
        rejected: 'Rejeitada',
        expired: 'Expirada',
        unknown: 'Desconhecida',
        working: 'Em negociação',
    };

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    const label = { buy: 'Compra', sell: 'Venda' };
    const { colorMode } = useColorMode();
    const { user, subscribeLevel1, timeline } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setValue('quantity', data?.OrigQuantity);
    }, [data, setValue]);

    useEffect(() => {
        if (timeline.currentStep?.type === 'dealing') {
            setMax(subscribeLevel1.tunnel.range.max);
            setMin(subscribeLevel1.tunnel.range.min);
        } else {
            setMax(subscribeLevel1.tunnel.max);
            setMin(subscribeLevel1.tunnel.min);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeLevel1]);

    const handleEditOrder = (formData) => {
        if (
            convertStringCurrencyToNumber(formData.price) !== data.Price ||
            Number(formData?.quantity) !== data.OrigQuantity
        ) {
            const orderToReplace = {
                omsId: data?.OMSId,
                orderIdToReplace: data?.OrderId,
                clientOrdId: 0,
                orderType: data?.OrderType,
                side: data?.Side === 'Buy' ? 0 : 1,
                accountId: user.userSession.alphapoint.accountId,
                instrumentId: data?.Instrument,
                useDisplayQuantity: false,
                limitPrice: convertStringCurrencyToNumber(formData.price),
                stopPrice: convertStringCurrencyToNumber(formData.price),
                quantity: Number(formData?.quantity),
                TimeInForce: 1,
            };
            dispatch(cancelReplaceOrderActions.sendEditOrder(orderToReplace));
        }
        onClose();
    };

    const validateQuantity = (value) => {
        value = parseInt(value);
        if (value <= 0) {
            return false;
        }

        if (timeline.currentStep?.type === 'irrevocable') {
            return value >= data.OrigQuantity;
        }
        return true;
    };

    const validatePrice = (value) => {
        value = convertStringCurrencyToNumber(value);
        if (value <= 0) {
            return false;
        }

        if (timeline.currentStep?.type === 'irrevocable') {
            return data.Side === 'Buy'
                ? value >= data?.Price && value <= subscribeLevel1.tunnel.max
                : value <= data?.Price && value >= subscribeLevel1.tunnel.min;
        }

        if (timeline.currentStep?.type === 'dealing' && data.Side === 'Buy') {
            return value <= subscribeLevel1.tunnel.range.max && value >= subscribeLevel1.tunnel.range.min;
        }

        return value <= subscribeLevel1.tunnel.max && value >= subscribeLevel1.tunnel.min;
    };

    const validatePriceMessage = () => {
        if (timeline.currentStep?.type === 'irrevocable') {
            if (data?.Side === 'Buy') {
                return data?.Price === max
                    ? `Não é possível reduzir o preço, apenas aumentá-lo até ${currency(max)}`
                    : `O Valor precisa ser maior que ${currency(data?.Price)} e menor que ${currency(max)}`;
            }
            if (data?.Side === 'Sell') {
                return data?.Price === min
                    ? `Não é possível aumentar o preço, apenas reduzi-lo até ${currency(min)}`
                    : `O Valor precisa ser menor que ${currency(data?.Price)} e maior que ${currency(min)}`;
            }
        }

        return `O valor precisa ser maior que ${currency(min)} e menor que ${currency(max)}`;
    };

    const errorPriceMessage = () => {
        if (errors.price?.type === 'validate') {
            return validatePriceMessage();
        }
        return errors.price?.message;
    };

    const errorQuantityMessage = () => {
        if (errors.quantity.type === 'validate') {
            if (timeline.currentStep?.type === 'irrevocable') {
                return `O Valor precisa ser maior que ${data?.OrigQuantity.toFixed(0)}`;
            }
            return `O valor precisa ser maior que 0`;
        }
        return errors.quantity?.message;
    };

    return (
        <Form noValidate onSubmit={handleSubmit(handleEditOrder)}>
            <VStack width="100%" spacing={2} alignItems="start">
                <Label>Data</Label>
                <Text fontWeight="700" fontSize="1rem" lineHeight="1" textTransform="capitalize">
                    {dateComplete(data?.ReceiveTime)}
                </Text>
            </VStack>

            <Flex width="100%" marginTop="45px" flexFlow="row wrap" alignItems="start" justifyContent="space-between">
                <VStack width="50%" spacing={2} alignItems="start">
                    <Label>Operação</Label>
                    <Text fontWeight="700" fontSize="1rem" lineHeight="1">
                        {label[data?.Side.toLowerCase()]}
                    </Text>
                </VStack>
                <VStack width="50%" spacing={2} alignItems="start">
                    <Label>Status</Label>
                    <Text fontWeight="700" fontSize="1rem" lineHeight="1">
                        {status[data?.OrderState.toLocaleLowerCase()]}
                    </Text>
                </VStack>
            </Flex>

            <VStack width="100%" marginTop="30px" spacing={2} alignItems="start">
                <FormControl isInvalid={errors.quantity} marginTop="15px">
                    <Label htmlFor="quantity">Quantidade Original</Label>
                    <Input
                        data-testid="quantity"
                        type="number"
                        autoComplete="off"
                        autoCorrect="off"
                        min={data?.OrigQuantity}
                        onKeyDown={(event) => event.key === 'e' && event.preventDefault()}
                        {...register('quantity', {
                            required: 'Campo obrigatório',
                            validate: validateQuantity,
                            onChange: (event) => {
                                const _qty =
                                    event.target.value.length > 10
                                        ? event.target.value.slice(0, 10)
                                        : event.target.value;
                                setValue('quantity', `${Math.floor(_qty)}`);
                            },
                        })}
                    />
                    {!!errors.quantity && <FormErrorMessage>{errorQuantityMessage()}</FormErrorMessage>}
                </FormControl>
                <Text fontWeight="700" fontSize=".8rem" lineHeight="1" marginTop="15px !important">
                    {timeline.currentStep?.type === 'irrevocable' &&
                        `Informe uma quantidade acima de ${data?.OrigQuantity.toFixed(0)}`}
                </Text>
            </VStack>

            <VStack width="100%" marginTop="30px" spacing={2} alignItems="start">
                <Label>Quantidade executada</Label>
                <Text fontWeight="700" fontSize="1rem" lineHeight="1">
                    {data?.QuantityExecuted.toFixed(0).replace('.', ',')}
                </Text>
            </VStack>

            <VStack width="100%" marginTop="30px" spacing={2} alignItems="start">
                <FormControl isInvalid={errors.price} marginTop="15px">
                    <Label htmlFor="price">Valor Original</Label>
                    <CurrencyInput
                        id="price"
                        ref={useRef()}
                        placeholder="R$ 0,00"
                        $colorMode={colorMode}
                        defaultValue={data?.Price}
                        aria-invalid={!!errors.price}
                        {...register('price', {
                            required: 'Campo obrigatório',
                            validate: validatePrice,
                        })}
                    />
                    {!!errors.price && <FormErrorMessage>{errorPriceMessage()}</FormErrorMessage>}
                </FormControl>
                <Text fontWeight="700" fontSize=".85rem" lineHeight="1" marginTop="15px !important">
                    {validatePriceMessage()}
                </Text>
            </VStack>

            <HStack spacing={5} alignItems="center" marginTop="30px">
                <Image src={iconAlert} width="24px" filter={useColorModeValue('invert(0)', 'invert(100%)')} />
                <Text fontWeight="400" fontSize=".8rem">
                    Ao editar sua oferta, ela será automaticamente cancelada e uma nova oferta será criada, em última
                    posição na fila de negociação.
                </Text>
            </HStack>

            <VStack spacing={5} marginTop="35px">
                <Button width="200px" type="submit" colorScheme="green">
                    Salvar alteração
                </Button>
                <Link onClick={onClose} fontWeight="700" fontSize=".7rem">
                    Cancelar
                </Link>
            </VStack>

            <Text fontWeight="400" fontSize=".8rem" lineHeight="1" marginTop="25px !important">
                Você pode editar ou alterar esta oferta entre 10h e 12h
            </Text>
        </Form>
    );
};
