import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';
import { devices } from '../../styles/devices';

interface ContainerProps {
    $colorMode: string;
}

export const FooterContainer = styled.footer<ContainerProps>`
    @media ${devices.maxMobileL} {
        height: auto;
    }
    @media ${devices.minTablet} {
        height: 130px;
        padding: 15px;
    }
    position: relative;
    bottom: 0;
    background: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[200])};
    font-weight: 400;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.4;
    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.gray[300] : bee4Theme.colors.lead[300])};
`;
