import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import notificationBell from './notificationBell';
import subscribeLevel1 from './subscribeLevel1';
import subscribeLevel2 from './subscribeLevel2';
import subscribeTrades from './subscribeTrades';
import getOpenOrders from './getOpenOrders';
import getOrdersHistory from './getOrdersHistory';
import sendOrder from './sendOrder';
import getAccountPositions from './getAccountPositions';
import subscribeAccountEvents from './subscribeAccountEvents';
import cancelOrder from './cancelOrder';
import cancelReplaceOrder from './cancelReplaceOrder';

import companies from './companies';
import clock from './clock';
import withdraw from './withdraw';
import wallet from './wallet';
import timeline from './timeline';
import user from './user';
import getTradesHistory from './getTradesHistory';

export const rootReducers = combineReducers({
    timeline,
    clock,
    companies,
    user,
    router: routerReducer,
    subscribeLevel1,
    subscribeLevel2,
    subscribeTrades,
    openOrders: getOpenOrders,
    ordersHistory: getOrdersHistory,
    tradesHistory: getTradesHistory,
    sendOrder,
    cancelOrder,
    cancelReplaceOrder,
    accountPositions: getAccountPositions,
    accountEvents: subscribeAccountEvents,
    notificationBell,
    withdraw,
    wallet,
});
