import { Routes, Route } from 'react-router-dom';
import { Header } from '../components/Header';

import { Dashboard } from '../pages/Dashboard';
import { Profile } from '../pages/Profile';
import { Wallet } from '../pages/Wallet';
import { Withdraw } from '../pages/Withdraw';
import { Deposit } from '../pages/Deposit';
import { NotFound } from '../pages/NotFound';

import { FirstAccess } from '../pages/FirstAccess';

import { Companies } from '../pages/Companies';
import { CompanySingle } from '../pages/CompanySingle';

import { Trading } from '../pages/Trading';
import { Footer } from '../components/Footer';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { companiesActions } from '../store/companies';
import { useEffect } from 'react';
import { MyOrders } from '../pages/MyOrders';

export function AppRoutes() {
    const user = useAppSelector(({ user }) => user);
    const dispatch = useAppDispatch();

    useEffect(() => {
        /** Get all companies from API to platform **/
        !user.userSession?.cognito?.firstAccess && dispatch(companiesActions.getCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {user.userSession.cognito.firstAccess ? (
                <Routes>
                    <Route path="/" element={<FirstAccess />} />
                    <Route path="/primeiro-acesso" element={<FirstAccess />} />
                </Routes>
            ) : (
                <>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/meu-perfil" element={<Profile />} />

                        <Route path="/minha-carteira" element={<Wallet />} />
                        <Route path="/minha-carteira/retirada" element={<Withdraw />} />
                        <Route path="/minha-carteira/depositos" element={<Deposit />} />
                        <Route path="/minhas-ofertas" element={<MyOrders />} />

                        <Route path="/nao-encontrado" element={<NotFound />} />

                        <Route path="/empresas/:slug" element={<CompanySingle />} />
                        <Route path="/empresas" element={<Companies />} />

                        <Route path="/negociacoes/:slug" element={<Trading />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </>
            )}
            <Footer />
        </>
    );
}
