import { ReactNode, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useAppSelector } from '../../hooks/redux';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';

interface CustomHelmetType {
    children: ReactNode;
}

export const MetaTags = ({ children }: CustomHelmetType) => {
    const sendGTM = useGTMDataLayer();
    const { timeline } = useAppSelector((state) => state);
    const [sended, setSended] = useState(false);

    useEffect(() => setSended(false), [timeline.currentStep.type]);

    const firePageViewEvent = () => {
        if (!sended && !document.title.includes('undefined')) {
            const timetout = setTimeout(() => {
                const title =
                    location.pathname.includes('negociacoes') &&
                    (timeline.currentStep.type === 'closed' || timeline.currentStep.type === 'maintenance')
                        ? `Negociação indisponível - ${document.title.split('Negociação indisponível - ').join('')}`
                        : document.title;

                sendGTM({
                    title,
                    page: `${location.pathname}`,
                });

                setSended(!document.title.includes('undefined'));
                clearTimeout(timetout);
            }, 600);
        }
    };

    return (
        <Helmet defer={false} onChangeClientState={firePageViewEvent}>
            {children}
        </Helmet>
    );
};
