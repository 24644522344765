import { ReactNode } from 'react';
import { BoxProps, useColorMode } from '@chakra-ui/react';

import { GradientLine } from '../components/GradientLine';
import { Card } from './styles';

interface CardBoxProps extends BoxProps {
    children: ReactNode;
    $topLine?: boolean;
    disabled?: boolean;
    background?: string;
}

export const Default = (props: CardBoxProps) => {
    const { $topLine, children, background } = props;
    const { colorMode } = useColorMode();

    return (
        <Card {...props} background={background} $topLine={$topLine} $colorMode={colorMode}>
            {$topLine ? <GradientLine /> : null}
            {children}
        </Card>
    );
};
