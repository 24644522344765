/** Types **/
type TResolutionSize = {
    width: number | string;
    height: number | string;
};

type TWindowSize = {
    width: number | string;
    height: number | string;
};

/** Functions **/
export const getResolution = (): TResolutionSize => {
    const ratio = window.devicePixelRatio || 1;
    const width = screen.width * ratio;
    const height = screen.height * ratio;
    return { width, height };
};

export const getWindowSize = (): TWindowSize => {
    return { width: window.innerWidth, height: window.innerHeight };
};

const checkIsMobile = () => {
    const { width } = getWindowSize();
    return width <= 414 ? true : false;
};

export const isMobile = () => {
    return checkIsMobile();
};
