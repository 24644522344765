import { Flex, useColorMode, Image, Text, Box, VStack } from '@chakra-ui/react';
import { Link } from '../Link';
import { FooterContainer } from './styles';

import CVMLogo from '../../assets/logos/cvm-logo.png';
import { memo } from 'react';

const FooterComponent = () => {
    const { colorMode } = useColorMode();
    return (
        <FooterContainer $colorMode={colorMode}>
            <Flex
                width="100%"
                maxWidth="1280px"
                margin="0 auto"
                direction={['column', 'column', 'column', 'row']}
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex
                    direction={['column', 'column', 'column', 'row']}
                    alignItems="center"
                    width="100%"
                    padding={['10px', '10px', '10px', '0']}
                >
                    <Image src={CVMLogo} width="80px" marginRight="10px" marginBottom={['15px', 0, 0, 0]} />
                    <Text
                        padding="5px"
                        fontWeight="400"
                        fontSize=".55rem"
                        textAlign={['center', 'center', 'center', 'left']}
                    >
                        A BEE4 é um ambiente de negociação de tokens representativos de valores mobiliários, autorizada
                        como mercado de balcão organizado pela Comissão de Valores Mobiliários no âmbito de seu sandbox
                        regulatório, nos termos da{' '}
                        <Link
                            fontSize=".5rem"
                            to="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol029.html"
                            target="_blank"
                        >
                            Resolução CVM n° 29, de 11 de maio de 2021
                        </Link>
                        , e{' '}
                        <Link
                            fontSize=".55rem"
                            to="https://conteudo.cvm.gov.br/legislacao/deliberacoes/deli0800/deli874.html"
                            target="_blank"
                        >
                            Deliberação CVM n° 874, de 30 de setembro de 2021
                        </Link>
                        , cuja vigência vai do dia 07 de junho de 2022 a 06 de junho de 2024. As atividades
                        disponibilizadas neste site são realizadas em caráter experimental, mediante autorização
                        temporária para desenvolvimento de atividade regulamentada no mercado de valores mobiliários
                        brasileiro. O tipo de investimento disponibilizado neste site pode não ser adequado a todos os
                        investidores, recomendamos que o investidor conheça as características e riscos de cada
                        investimento antes de investir. Recomendamos também o preenchimento do seu perfil de investidor
                        antes da realização de investimentos, bem como que entre em contato com o seu intermediário BEE4
                        para orientação com base em suas características e objetivos pessoais. Investimentos no mercado
                        de capitais estão sujeitos a risco de perda superior ao valor total do capital investido. As
                        informações disponíveis neste site tem caráter meramente informativo e não é uma recomendação
                        para a realização de investimento. Em caso de dúvida entre em contato nos canais de atendimento
                        dos intermediários BEE4.
                    </Text>
                </Flex>
                <VStack width={['100%', '100%', '100%', '30%']} alignItems={['center', 'center', 'center', 'flex-end']}>
                    <Box textAlign={['center', 'center', 'center', 'right']} padding={['10px', '10px', '10px', '0']}>
                        © BEE4 2023. Todos os direitos reservados.
                        <br />
                        <span style={{ marginRight: '3px' }}>Conheça nossa</span>
                        <Link
                            to="https://bee4.com.br/politica-de-privacidade"
                            target="_blank"
                            fontSize="0.7rem"
                            fontWeight="700"
                        >
                            Política de Privacidade
                        </Link>
                        .
                    </Box>
                    <Text fontSize=".55rem" padding="15px 0">
                        Build: {document.querySelector('meta[name="build-version"]')['content']}
                    </Text>
                </VStack>
            </Flex>
        </FooterContainer>
    );
};

export const Footer = memo(FooterComponent);
