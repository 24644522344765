import { useEffect } from 'react';
import { Toast } from '../Toast';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { subscribeAccountEventsActions } from '../../store/subscribeAccountEvents';
import { notificationBellActions } from '../../store/notificationBell';
import { date } from '../../utils/date';

export const NotificationListener = () => {
    const dispatch = useAppDispatch();
    const label = { Buy: 'compra', Sell: 'venda' };
    const { user, accountEvents, timeline, cancelReplaceOrder, companies } = useAppSelector((state) => state);

    const eventOptions = {
        NewOrderRejectEvent() {
            const { data } = accountEvents.event;
            let description = `Oferta rejeitada.`;

            if (data['Status'] === 'Rejected') {
                switch (data['RejectReason']) {
                    case 'Not_Enough_Funds':
                        description = 'Oferta rejeitada por falta de fundos.';
                        break;
                    case 'Invalid_Limit_Price_Below_Floor':
                        description = 'Oferta rejeitada por estar abaixo do valor mínimo.';
                        break;
                    case 'Invalid_Limit_Price_Above_Ceiling':
                        description = 'Oferta rejeitada por estar acima do valor máximo.';
                        break;
                    case 'Exceeds_Daily_Limit':
                        description = 'Oferta foi rejeitada por exceder o limite diário.';
                        break;
                    case 'Exceeds_Monthly_Limit':
                        description = 'Oferta foi rejeitada por exceder o limite mensal.';
                        break;
                }
            }

            return {
                id: accountEvents.event?.method,
                title: `${companies.current.assets.ticker} - Oferta rejeitada`,
                description,
                status: 'error',
                date: date().format('DD/MM/YYYY HH:mm:ss'),
            };
        },
        OrderStateEvent() {
            const { data } = accountEvents.event;
            if (data['OrderState'] === 'Canceled' && !cancelReplaceOrder.edit) {
                return {
                    id: `${accountEvents.event?.method}-canceled`,
                    title: `${companies.current.assets.ticker} - Oferta cancelada`,
                    description: `Oferta de ${label[data['Side']]} foi cancelada com sucesso.`,
                    status: 'success',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                };
            }

            if (
                data['OrderState'] !== 'Rejected' &&
                data['OrderState'] !== 'Canceled' &&
                data['ChangeReason'] === 'Trade'
            ) {
                return {
                    id: accountEvents.event?.method,
                    title: `${companies.current.assets.ticker} - Oferta executada`,
                    description: `Oferta de ${label[data['Side']]} foi executada com sucesso.`,
                    status: 'success',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                };
            }

            if (data['OrderState'] !== 'Rejected' && data['OrderState'] !== 'Canceled' && !cancelReplaceOrder.edit) {
                return {
                    id: accountEvents.event?.method,
                    title: `${companies.current.assets.ticker} - Oferta realizada`,
                    description: `Oferta de ${label[data['Side']]} foi realizada com sucesso.`,
                    status: 'success',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                };
            }

            if (data['OrderState'] === 'Rejected' && data['RejectReason'] === 'SelfTrading_Prevention') {
                return {
                    id: accountEvents.event?.method,
                    title: `${companies.current.assets.ticker} - Oferta rejeitada`,
                    description: `Oferta de ${label[data['Side']]} foi rejeitada pois a Oferta de ${
                        data['Side'] === 'Buy' ? 'venda' : 'compra'
                    } é sua própria.`,
                    status: 'error',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                };
            }

            if (cancelReplaceOrder.edit && data['OrderState'] === 'Canceled') {
                return {
                    id: `${accountEvents.event?.method}-edited`,
                    title: `${companies.current.assets.ticker} - Oferta editada`,
                    description: `Oferta de ${label[data['Side']]} foi editada com sucesso.`,
                    status: 'success',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                };
            }
        },
        CancelAllOrdersRejectEvent() {
            return {
                id: accountEvents.event?.method,
                title: `${companies.current.assets.ticker} - Cancelamento de oferta rejeitada`,
                description: `Instrumento não encontrado`,
                status: 'success',
                date: date().format('DD/MM/YYYY HH:mm:ss'),
            };
        },
        CancelOrderRejectEvent() {
            return {
                id: accountEvents.event?.method,
                title: `${companies.current.assets.ticker} - Cancelamento de oferta rejeitada`,
                description: `Oferta não encontrada`,
                status: 'success',
                date: date().format('DD/MM/YYYY HH:mm:ss'),
            };
        },
        CancelReplaceOrderRejectEvent() {
            return {
                id: accountEvents.event?.method,
                title: `${companies.current.assets.ticker} - Edição de oferta rejeitada`,
                description: `Oferta não encontrada`,
                status: 'error',
                date: date().format('DD/MM/YYYY HH:mm:ss'),
            };
        },
    };

    useEffect(() => {
        if (user.userSession.alphapoint.token === undefined || timeline.currentStep?.type === undefined) {
            return;
        }

        dispatch(subscribeAccountEventsActions.subscribeAccountEvents());
    }, [dispatch, user.userSession.alphapoint.token, timeline.currentStep?.type]);

    useEffect(() => {
        if (timeline.currentStep.type === 'processing' || accountEvents.updated === undefined) {
            return;
        }

        // eslint-disable-next-line no-prototype-builtins
        const _message = eventOptions.hasOwnProperty(accountEvents.event.method)
            ? eventOptions[accountEvents.event.method]()
            : null;

        if (!!_message) {
            Toast(_message);
            dispatch(notificationBellActions.pushNotificationBellList(_message));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountEvents.updated]);

    return <></>;
};
