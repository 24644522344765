import { InputHTMLAttributes } from 'react';
import { Checkmark, Container, Input } from './styles';

interface TCheckout extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    value?: any;
}

export const Checkbox = (props: TCheckout) => {
    return (
        <Container>
            <Input type="checkbox" value={props.value} {...props} />
            <Checkmark />
            {props.label}
        </Container>
    );
};
