import { createIcon } from '@chakra-ui/icons';

export const IconInfo = createIcon({
    displayName: 'IconInfo',
    viewBox: '0 0 22 22',
    path: (
        <>
            <path
                d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 6.18594 6.18594 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 15.8141 15.8141 19.7188 11 19.7188Z"
                fill="currentColor"
            />
            <path
                d="M9.87463 6.875C9.87463 7.17337 9.99316 7.45952 10.2041 7.6705C10.4151 7.88147 10.7013 8 10.9996 8C11.298 8 11.5841 7.88147 11.7951 7.6705C12.0061 7.45952 12.1246 7.17337 12.1246 6.875C12.1246 6.57663 12.0061 6.29048 11.7951 6.0795C11.5841 5.86853 11.298 5.75 10.9996 5.75C10.7013 5.75 10.4151 5.86853 10.2041 6.0795C9.99316 6.29048 9.87463 6.57663 9.87463 6.875ZM11.5621 9.5H10.4371C10.334 9.5 10.2496 9.58438 10.2496 9.6875V16.0625C10.2496 16.1656 10.334 16.25 10.4371 16.25H11.5621C11.6653 16.25 11.7496 16.1656 11.7496 16.0625V9.6875C11.7496 9.58438 11.6653 9.5 11.5621 9.5Z"
                fill="currentColor"
            />
        </>
    ),
});
