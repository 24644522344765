import { Box, Container, Flex, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react';

import { CardBox } from '../../components/CardBox';
import { PageTitle } from '../../components/PageTitle';
import { PageDescript } from '../../components/PageDescription';
import { Link } from '../../components/Link';
import { MetaTags } from '../../components/MetaTags';

import { hideString } from '../../utils/strings';
import { Avatar } from './styles';

import { useAppSelector } from '../../hooks/redux';
import { Information } from '../../components/Information';
import IconInformation from '../../assets/icons/icon-information.svg';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';

// import faqContent from '../../data/Faq/profileFaq.json';
// import { Accordion } from '../../components/Accordion';
// import { GradientButton } from '../../components/GradientButton';
// import { getInstituteName } from '../../utils/finalcialInstitution';

export const Profile = () => {
    const { user } = useAppSelector((state) => state);

    return (
        <main>
            <MetaTags>
                <title>Meu Perfil - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%" paddingBottom="30px">
                <PageTitle>Meu Perfil</PageTitle>
                <PageDescript>Aqui você encontra suas informações pessoais.</PageDescript>

                <UserBlockedDisclaimer />

                <Flex
                    width="100%"
                    maxWidth="630px"
                    flexFlow="row wrap"
                    position="relative"
                    margin="50px 0 0"
                    alignItems="start"
                >
                    <CardBox width="100%" height="100%" maxWidth="621px" $topLine={true} margin="0 0 0" padding="25px">
                        <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="flex-start"
                            direction={['column', 'column', 'row']}
                        >
                            <VStack width="180px">
                                <Avatar email={user.userData.email} size={110} rating="g" default="mp" />
                                <Information
                                    label="Sua imagem de perfil é gerada com uma conta Gravatar utilizando o mesmo e-mail cadastrado na plataforma"
                                    type="question"
                                />
                            </VStack>

                            <VStack alignItems="start">
                                <Text fontWeight="600" fontSize="1.4rem">
                                    {user.userData.name}
                                </Text>
                                {/* <Text fontWeight="400" fontSize="0.85rem">
                                    <strong>Nome Social: </strong>
                                    {userData.personal.social_name}
                                </Text> */}
                                <Text fontWeight="400" fontSize="0.85rem">
                                    <strong>E-mail: </strong>
                                    {hideString(user.userData.email)}
                                </Text>
                                <Text fontWeight="400" fontSize="0.85rem">
                                    <strong>Tel.: </strong>
                                    {hideString(
                                        `(${user.userData.phoneContacts[0]?.ddd}) ${user.userData.phoneContacts[0]?.phoneNumber}`
                                    )}
                                </Text>
                            </VStack>

                            {/* <RescuePasswordWrap>
                                <GradientButton
                                    label="Mudar Senha"
                                    action={() => alert('Página não implementada ainda, conteúdo de marcação')}
                                    type="button"
                                    padding="10px 25px"
                                    fontSize=".75rem"
                                />
                            </RescuePasswordWrap> */}
                        </Flex>
                    </CardBox>

                    {/* <CardBox
                        width={['100%', '100%', '100%', '70%']}
                        height="180px"
                        $topLine={true}
                        padding="35px 25px"
                        marginRight={[0, 0, 15]}
                    >
                        <Text fontWeight="500" fontSize="1rem" marginBottom="16px">
                            Dados Bancários
                        </Text>
                        <VStack spacing={2} alignItems="start">
                            <Text fontSize="0.85rem">
                                <strong>Banco: </strong>
                                {`${user.userData.paymentAccountBankInfo?.bankCode} - ${getInstituteName(
                                    user.userData.paymentAccountBankInfo?.bankCode
                                )}`}
                            </Text>
                            <Text fontSize="0.85rem">
                                <strong>Agência: </strong>
                                {user.userData.paymentAccountBankInfo?.bankBranch}
                            </Text>
                            <Text fontSize="0.85rem">
                                <strong>Conta corrente: </strong>
                                {user.userData.paymentAccountBankInfo?.bankAccount}-
                                {user.userData.paymentAccountBankInfo?.bankAccountDigit}
                            </Text>
                        </VStack>
                    </CardBox> */}

                    <Box
                        border="1px solid"
                        borderColor="lead.200"
                        borderRadius="10px"
                        background="transparent"
                        width={['100%', '100%', '100%', '70%']}
                        padding="25px"
                        margin="35px 0 0"
                    >
                        <Text fontWeight="700" fontSize=".9rem">
                            Intermediário:
                        </Text>
                        <Text fontWeight="400" fontSize=".85rem" lineHeight="1.8" textTransform="uppercase">
                            BEEGIN SOLUÇÕES EM CROWDFUNDING LTDA.
                        </Text>
                    </Box>
                </Flex>

                {/* <Flex direction="column" margin="30px 0 0">
                    <PageTitle>Perguntas Frequentes (FAQ)</PageTitle>

                    <Accordion data={faqContent} />

                    <Box width="100%" textAlign="right">
                        <Link
                            to="#"
                            fontWeight="700"
                            fontSize="0.75rem"
                            onClick={() => alert('Página não implementada ainda, conteúdo de marcação')}
                        >
                            Ver todas as perguntas
                        </Link>
                    </Box>
                </Flex> */}

                <Flex width="100%" position="relative" margin="0 0 30px" alignItems="center">
                    <Image
                        src={IconInformation}
                        width="32px"
                        margin="10px"
                        filter={useColorModeValue('invert(100%)', 'invert(0)')}
                    />
                    <VStack spacing="1" align="stretch" width="100%" maxWidth="645px" marginTop="20px">
                        <Text fontWeight="700" fontSize=".8rem" lineHeight="1.8">
                            Exercício dos direitos de utilizador
                        </Text>
                        <Text
                            fontWeight="400"
                            fontSize=".8rem"
                            color={useColorModeValue('', 'gray.100')}
                            lineHeight="1.4"
                            padding="5px 0"
                        >
                            Você pode exercer o direito de revogar a autorização, eliminar a conta, limpar e exportar
                            dados e fazer consultas sobre armazenamento. Consulte nossa{' '}
                            <Link
                                to="https://bee4.com.br/politica-de-privacidade"
                                target="_blank"
                                fontWeight="700"
                                fontSize=".8rem"
                            >
                                Política de Privacidade
                            </Link>{' '}
                            ou entre em contato diretamente pelo seguinte e-mail:{' '}
                            <Link to="privacidade@bee4.com.br" fontWeight="700" fontSize=".8rem">
                                privacidade@bee4.com.br
                            </Link>
                        </Text>
                        <Text
                            fontWeight="400"
                            fontSize=".8rem"
                            color={useColorModeValue('lead.500', 'gray.100')}
                            lineHeight="1.4"
                            padding="5px 0"
                        >
                            Para sua segurança esses dados só podem ser alterados através de nossa{' '}
                            <Link to="atendimento@bee4.com.br" fontWeight="700" fontSize=".8rem">
                                Central de Atendimento
                            </Link>
                        </Text>
                    </VStack>
                </Flex>
            </Container>
        </main>
    );
};
