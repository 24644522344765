import { VStack, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { devices } from '../../../../styles/devices';

export const ContainerPriceRange = styled(VStack)`
    min-width: 370px;
    position: relative;

    @media ${devices.maxTablet} {
        min-width: 90vw;
    }

    @media ${devices.minTablet} {
        margin-right: 35px;
    }
`;

export const CaptalizeSentence = styled(Text)`
    text-transform: lowercase;

    &:first-letter {
        text-transform: capitalize;
    }
`;
