import { createIcon } from '@chakra-ui/icons';

export const IconTag = createIcon({
    displayName: 'IconTag',
    viewBox: '0 0 22 24',
    path: (
        <path
            d="M10.2283 19.4587L20.3587 9.32568C20.4042 9.28014 20.4257 9.21853 20.4203 9.15693L19.7373 1.08371C19.7185 0.874781 19.5551 0.711388 19.3462 0.692638L11.273 0.0122809C11.2114 0.00692374 11.1471 0.0283522 11.1042 0.0738879L0.973871 10.2042C0.933992 10.2445 0.911621 10.2989 0.911621 10.3556C0.911621 10.4123 0.933992 10.4667 0.973871 10.5069L9.92566 19.4587C10.0087 19.5444 10.1453 19.5444 10.2283 19.4587ZM11.9051 2.00246L17.9212 2.51139L18.4301 8.52746L10.0757 16.8792L3.55066 10.3569L11.9051 2.00246ZM13.5166 6.99076C13.636 7.11015 13.7777 7.20485 13.9337 7.26945C14.0897 7.33406 14.2569 7.3673 14.4258 7.36729C14.5946 7.36728 14.7618 7.33401 14.9178 7.26938C15.0738 7.20475 15.2155 7.11003 15.3349 6.99063C15.4543 6.87123 15.549 6.72948 15.6136 6.57348C15.6782 6.41748 15.7114 6.25028 15.7114 6.08143C15.7114 5.91258 15.6781 5.74539 15.6135 5.58939C15.5489 5.4334 15.4542 5.29167 15.3348 5.17228C15.2154 5.0529 15.0736 4.9582 14.9176 4.89359C14.7616 4.82899 14.5944 4.79574 14.4256 4.79576C14.2567 4.79577 14.0895 4.82904 13.9335 4.89367C13.7775 4.95829 13.6358 5.05301 13.5164 5.17242C13.397 5.29182 13.3023 5.43357 13.2377 5.58957C13.1731 5.74557 13.1399 5.91277 13.1399 6.08162C13.1399 6.25047 13.1732 6.41766 13.2378 6.57365C13.3024 6.72964 13.3972 6.87138 13.5166 6.99076ZM21.1167 12.7489L20.056 11.6909C20.0157 11.651 19.9614 11.6286 19.9047 11.6286C19.848 11.6286 19.7936 11.651 19.7533 11.6909L10.0569 21.3685L3.69262 15.0203C3.65235 14.9804 3.59796 14.9581 3.54128 14.9581C3.4846 14.9581 3.43022 14.9804 3.38994 15.0203L2.32923 16.0784C2.28935 16.1186 2.26698 16.173 2.26698 16.2297C2.26698 16.2864 2.28935 16.3408 2.32923 16.381L8.84352 22.8846L9.90423 23.9426C9.98727 24.0257 10.1239 24.0257 10.2069 23.9426L21.1167 13.0516C21.1998 12.9685 21.1998 12.8319 21.1167 12.7489Z"
            fill="currentColor"
        />
    ),
});
