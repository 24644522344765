import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial';

export const subscribeAccountEventsSlice = createSlice({
    name: 'SubscribeAccountEvents',
    initialState,
    reducers: {
        subscribeAccountEvents: (state): void => {
            state.error = false;
        },
        subscribeAccountEventsSuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;
            state.event = action.payload;
        },
        subscribeAccountEventsFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
        },
    },
});

export const subscribeAccountEventsActions = subscribeAccountEventsSlice.actions;
export default subscribeAccountEventsSlice.reducer;
