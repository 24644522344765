import { bee4Theme } from './../../../../../../styles/theme';
import styled from 'styled-components';
import { devices } from '../../../../../../styles/devices';

type TStepProps = {
    numItems: number;
    active: boolean;
    halfOrFull?: 'half' | 'full';
    $colorMode?: string;
};
type TLabelProps = {
    active: boolean;
    off: boolean;
    $colorMode?: string;
};
type TDayBoxProps = {
    overlay: boolean;
};

export const Wrapper = styled.section`
    display: flex;
    flex-flow: row;

    align-items: start;
    justify-content: space-between;

    width: 100%;
    margin: 30px 0 50px;

    position: relative;

    @media ${devices.maxMobileL} {
        overflow-x: auto;
        overflow-y: hidden;
    }
`;

export const DayBox = styled.div<TDayBoxProps>`
    max-width: 360px;
    height: 86px;

    margin: 0;
    padding: 0;

    border-left: 3px solid ${bee4Theme.colors.lead[100]};
    opacity: ${({ overlay }) => (overlay ? 0.2 : 1)};

    @media ${devices.maxMobileL} {
        flex: 1 0 auto;
    }
    @media ${devices.minTablet} {
        flex: 1 1 auto;
    }

    &:nth-of-type(1) {
        label {
            &::before {
                display: block;
            }
        }
        z-index: 7;
    }
    &:nth-of-type(2) {
        z-index: 6;
    }
    &:nth-of-type(3) {
        z-index: 5;
    }
    &:nth-of-type(4) {
        z-index: 4;
    }
    &:nth-of-type(5) {
        z-index: 3;
    }
    &:nth-of-type(6) {
        z-index: 2;
    }
    &:nth-of-type(7) {
        z-index: 1;
        label {
            &::after {
                display: none;
            }
        }
    }
`;

export const Label = styled.label<TLabelProps>`
    width: 100%;
    padding: 10px;

    font-weight: 700;
    font-size: 0.8rem;

    text-align: left;
    position: relative;

    background-color: transparent;
    border-bottom: 7px solid transparent;

    display: block;
    color: ${bee4Theme.colors.white};

    &::before {
        content: '';

        display: none;
        padding: 5px;

        width: 12px;
        height: 12px;

        background-color: ${({ active, $colorMode }) => {
            if ($colorMode === 'dark') return active ? bee4Theme.colors.green[100] : bee4Theme.colors.lead[100];
            else return active ? bee4Theme.colors.green[100] : bee4Theme.colors.gray[100];
        }};

        border: 5px solid ${bee4Theme.colors.lead[200]};
        border-radius: 100%;

        position: absolute;
        bottom: -13px;
        left: -11px;

        z-index: 4;
    }
    &::after {
        content: '';
        padding: 5px;

        width: 12px;
        height: 12px;

        background-color: ${({ active, off, $colorMode }) => {
            if ($colorMode === 'dark') return active && !off ? bee4Theme.colors.green[100] : bee4Theme.colors.lead[100];
            else return active && !off ? bee4Theme.colors.green[100] : bee4Theme.colors.gray[600];
        }};

        border: 5px solid ${bee4Theme.colors.lead[200]};
        border-radius: 100%;

        position: absolute;
        bottom: -13px;
        right: -11px;

        z-index: 10;
    }
`;

export const Step = styled.div<TStepProps>`
    min-width: ${({ numItems }) => `calc(100% / ${numItems})`};
    width: ${({ numItems }) => (numItems > 1 ? '120px' : '100%')};
    height: 48px;

    cursor: pointer;
    padding: 7px 10px;

    font-weight: 400;
    font-size: 0.71rem;

    text-align: ${({ numItems }) => (numItems > 1 ? 'center' : 'left')};
    position: relative;

    // Bar
    &::before {
        content: '';

        width: 100%; //${({ halfOrFull }) => (halfOrFull === 'full' ? '100%' : '50%')};
        height: 46px;

        background: ${({ active, halfOrFull, $colorMode }) => {
            if ($colorMode === 'dark') {
                return !active
                    ? bee4Theme.colors.lead[200]
                    : halfOrFull === 'full'
                    ? bee4Theme.colors.blue[300]
                    : `linear-gradient(to right, ${bee4Theme.colors.blue[300]} 50%, ${bee4Theme.colors.lead[200]} 50%)`;
            } else {
                return !active
                    ? bee4Theme.colors.gray[600]
                    : halfOrFull === 'full'
                    ? bee4Theme.colors.blue[300]
                    : `linear-gradient(to right, ${bee4Theme.colors.blue[300]} 50%, ${bee4Theme.colors.gray[600]} 50%)`;
            }
        }};

        border: 5px solid;
        border-image-slice: 1;
        border-width: 5px;

        border-left: 0;
        border-right: 0;
        border-top: 0;

        border-image-source: ${({ active, halfOrFull }) =>
            !active
                ? `linear-gradient(to right, ${bee4Theme.colors.lead[100]}, ${bee4Theme.colors.lead[100]})`
                : halfOrFull === 'full'
                ? `linear-gradient(to right, ${bee4Theme.colors.blue[400]}, ${bee4Theme.colors.blue[400]})`
                : `linear-gradient(to right, ${bee4Theme.colors.blue[400]} 50%, ${bee4Theme.colors.lead[100]} 50%)`};

        position: absolute;
        top: -46px;
        left: 0;

        z-index: -1;
    }

    // Dots
    &::after {
        content: '';
        padding: 4px;

        width: 10px;
        height: 10px;

        background-color: ${({ active }) => (active ? bee4Theme.colors.green[100] : bee4Theme.colors.blue[400])};
        border: ${({ active }) =>
            active ? `3px solid ${bee4Theme.colors.lead[200]}` : `3px solid ${bee4Theme.colors.blue[300]}`};

        border-radius: 100%;
        z-index: 6;

        position: absolute;
        top: -10px;
        left: 50%;

        transform: translate(-50%);
        display: ${({ numItems }) => (numItems > 1 ? 'block' : 'none')};
    }
`;

export const Descript = styled.div`
    width: 100%;
    background-color: transparent;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;
`;
