import { Epic } from 'redux-observable';
import { EMPTY, filter, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { switchMap, throttleTime, catchError, concatMap, delay, map, tap } from 'rxjs/operators';

import { walletActions } from '.';
import appConfig from '../../config/appConfig';
import { date } from '../../utils/date';
import { getToken } from '../../utils/token';

export const getPositionsToWalletEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(walletActions.getWalletData.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
            };
            return Alphapoint.subscribe({ action: 'GetAccountPositions', ...body }).pipe(
                concatMap(({ data }) => {
                    const products = state$.value.companies.list.map((company) => company.assets.productSellId);
                    const positionsBuy = data.filter((item) => item.ProductId === appConfig._DEFAULT_FIAT_ID);
                    const positionsSell = data.filter((item) => products.includes(item.ProductId));
                    return of(walletActions.setWalletData({ buy: positionsBuy[0], sell: positionsSell }));
                }),
                catchError((error) => {
                    if (error.status === 401) location.reload();
                    return of(walletActions.getWalletFailed());
                })
            );
        })
    );

export const getTradingInvoicesEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(walletActions.getTradingInvoices.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            const pageSize = appConfig.INVOICES_PER_PAGE;
            const page = state$.value.wallet.invoices.pagination.currentPage;

            return ajax({
                url: `${appConfig.API_URL}trading-invoices/app/invoices/?page=${page}&pageSize=${pageSize}`,
                crossDomain: true,
                method: 'GET',
                headers: { Authorization: getToken() },
            }).pipe(
                map(({ response }) => {
                    return walletActions.setTradingInvoices({
                        currentPage: response['currentPage'],
                        data: response['data'],
                        totalItems: parseInt(response['totalQuantity']),
                    });
                }),
                catchError((error) => {
                    console.log({ error });
                    return of(EMPTY);
                })
            );
        })
    );

export const filterTradingInvoicesEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(walletActions.filterTradingInvoices.match),
        delay(800),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            return ajaxRequest({}, state$);
        })
    );

export const filterPaginatedTradingInvoicesEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(walletActions.changePage.match),
        delay(800),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            return ajaxRequest(payload, state$);
        })
    );

export const resetFilterTradingInvoicesEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(walletActions.reloadTradingInvoicesFilter.match),
        tap(console.log),
        delay(800),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            return ajaxRequest({}, state$);
        })
    );

const ajaxRequest = (payload, state$) => {
    let dateRange = '';
    const page = !!payload.page ? payload.page : 1;

    if (!!state$.value.wallet.invoices.dateRange?.length) {
        const initial = date(state$.value.wallet.invoices.dateRange[0]).format('YYYY-MM-DD');
        const final = date(state$.value.wallet.invoices.dateRange[1]).format('YYYY-MM-DD');
        dateRange = `&initialDate=${initial}&finalDate=${final}`;
    }
    const pageSize = payload.pageSize ? payload.pageSize : appConfig.INVOICES_PER_PAGE;

    return ajax({
        url: `${appConfig.API_URL}trading-invoices/app/invoices/?page=${page}&pageSize=${pageSize}${dateRange}`,
        crossDomain: true,
        method: 'GET',
        headers: { Authorization: getToken() },
    }).pipe(
        map(({ response }) => {
            return walletActions.setTradingInvoicesFiltered({
                data: response['data'],
                totalItems: response['totalQuantity'],
                currentPage: response['currentPage'],
            });
        }),
        catchError((error) => {
            console.log({ error });
            return of(walletActions.resetTradingInvoices());
        })
    );
};
