import { useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';

import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { Toast } from '../components/Toast';
import { getLS } from '../utils/storage';

import { userActions } from '../store/user';

const Routes = () => {
    const { user } = useAppSelector((state) => state);
    const { logged } = user.userSession;

    const { setColorMode } = useColorMode();
    const dispatch = useAppDispatch();

    /** Comportaments validate of login **/
    useEffect(() => {
        if (!!user.error) {
            Toast({
                id: 'SignInReturn',
                title: `Falha no login`,
                description: 'Usuário ou senha inválidos, tente novamente.',
                status: 'error',
            });
            return;
        }

        if (!!user.userSession?.cognito?.firstAccess) {
            const history = createBrowserHistory();
            history.push('/primeiro-acesso');
            return;
        }

        if (!!logged) {
            const userPreferences = !!getLS('@bee4:userPreferences') && JSON.parse(getLS('@bee4:userPreferences'));
            setColorMode(userPreferences?.theme || 'dark');
            return;
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.updated]);

    /** Check session and Reconnect **/
    useEffect(() => {
        dispatch(userActions.checkSession());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Router>{user.userSession.logged ? <AppRoutes /> : <AuthRoutes />}</Router>;
};

export default Routes;
