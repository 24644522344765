import { Tooltip, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Icon } from '../Icon';
import { ActionButton, BlockedButton } from './styles';

interface ButtonProps {
    colorScheme: 'green' | 'red';
    children: string | ReactNode;
    width?: string;
    padding?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    isBlocked?: boolean;
    tip?: string;
    onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
    const { colorMode } = useColorMode();

    return props.isBlocked ? (
        <Tooltip
            hasArrow
            maxWidth="300px"
            background="lead.100"
            placement="top"
            borderRadius="5px"
            color="white"
            fontSize=".7rem"
            textAlign="center"
            padding="10px"
            whiteSpace="pre-wrap"
            label={props.tip}
        >
            <BlockedButton $colorMode={colorMode} type="button" {...props}>
                <Icon type="question" height={4} width={4} />
                <span style={{ marginLeft: 5 }}>{props.children}</span>
            </BlockedButton>
        </Tooltip>
    ) : (
        <ActionButton {...props} type={props.type || 'submit'}>
            {props.children}
        </ActionButton>
    );
};
