import { Fragment, useEffect, useState } from 'react';
import { Container, Flex, HStack } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

import { PageDescript } from '../../components/PageDescription';
import { PageTitle } from '../../components/PageTitle';

import { MetaTags } from '../../components/MetaTags';
import { NotificationListener } from '../../components/NotificationListener';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { StatusLabel } from './styles';
import { CompanyInformation } from './Components/CompanyInformation';
import { companiesActions } from '../../store/companies';
import { SelectCompanyTicker } from './Components/SelectCompanyTicker';
import { validateOneDayStorage } from '../../utils/storage';
import { MessageBox } from '../../components/MessageBox';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';
import { MaintenanceMessage } from './Components/MaintenanceMessage';
import { ProcessingMessage } from './Components/ProcessingMessage';
import { Trade } from './Components/Trade';
import { WeeklySchedule } from './Components/TimeLine';
import { TimelineBar } from './Components/TimeLine/Components/Bars';

interface PageContent {
    title: string;
    descript: string;
}

export const Trading = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const timeline = useAppSelector((state) => state.timeline, shallowEqual);
    const { current, list, loading } = useAppSelector((state) => state.companies);

    const [pageContent, setPageContent] = useState({} as PageContent);
    const [title, setTitle] = useState('');

    useEffect(() => {
        // Ajusta o posicionamento do Scroll
        !location?.state && window.scrollTo(0, 0);

        // Pega a situação da exibição da mensagem
        validateOneDayStorage('warningClosed');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPageContent(() => ({ title: timeline.currentStep?.title, descript: timeline.currentStep?.descript }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    useEffect(() => {
        /** Get Data from company by Slug if not company in Redux **/
        if (!current.assets.ticker) {
            const _selected = list.filter((company) => company.slug === params.slug);
            !!_selected.length && dispatch(companiesActions.setCompany(_selected[0]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        setTitle(
            timeline.currentStep.type === 'closed' || timeline.currentStep.type === 'maintenance'
                ? `Negociação indisponível - ${current.name} - BEE4`
                : `Negociação - ${current.name} - BEE4`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    const mountContent = () => {
        if (timeline.currentStep?.type === 'maintenance') {
            return <MaintenanceMessage />;
        } else {
            return (
                <Fragment>
                    <Flex
                        direction={['column', 'column', 'column', 'row']}
                        alignItems="end"
                        justifyContent="space-between"
                    >
                        <CompanyInformation company={current} />
                        <TimelineBar />
                    </Flex>

                    <WeeklySchedule />

                    <Fragment>
                        {timeline.currentStep?.type === 'processing' || timeline.lockBox ? (
                            <ProcessingMessage />
                        ) : (
                            <Trade />
                        )}
                    </Fragment>
                </Fragment>
            );
        }
    };

    return (
        <main>
            <MetaTags>
                <title>{title}</title>
            </MetaTags>

            {!!current.name ? (
                <Container maxWidth="container.xl" width="100%" height="100%" padding="0 15px 40px">
                    <HStack spacing={5}>
                        <PageTitle width="fit-content">{pageContent.title || 'Ofertas para leilão'}</PageTitle>
                        {timeline.currentStep?.type === 'dealing' && (
                            <StatusLabel type="success">Em andamento</StatusLabel>
                        )}
                        {timeline.currentStep?.type === 'processing' && (
                            <StatusLabel type="warning">Em processamento</StatusLabel>
                        )}
                        {timeline.currentStep?.type === 'maintenance' && (
                            <StatusLabel type="error">Em manutenção</StatusLabel>
                        )}
                    </HStack>

                    <Flex
                        width="100%"
                        direction={['column', 'column', 'column', 'row']}
                        alignItems="end"
                        justifyContent="space-between"
                    >
                        <PageDescript>{pageContent.descript}</PageDescript>
                        {/* <Link to="#faq" fontSize=".80rem">
                                <HStack spacing={1}>
                                    <Image src={iconQuestion} width="14px" />
                                    <Text fontWeight={700}>Sobre leilão e pregão</Text>
                                </HStack>
                            </Link> */}
                    </Flex>

                    <UserBlockedDisclaimer />
                    <MessageBox messages={timeline?.eventsMessages} theme="warning" />
                    <SelectCompanyTicker />

                    {mountContent()}
                </Container>
            ) : (
                !loading && (
                    <Container maxW="container.xl" width="100%" height="100%" pb="40px">
                        <PageTitle>Ativo não encontrado</PageTitle>
                        <PageDescript>
                            Não conseguios encontrar esse ativo, por gentileza revise o endereço digitado.
                        </PageDescript>
                    </Container>
                )
            )}

            <NotificationListener />
        </main>
    );
};
