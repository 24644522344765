import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../config/appConfig';
import { date } from '../../utils/date';
import { getLS, setLS } from '../../utils/storage';
import { initialState } from './initial';
import { TNotificationMessage } from './types';

export * from './types';

const sort = (_arr: TNotificationMessage[]) => {
    if (!!_arr && !!_arr.length) {
        const dateconvert = (_date: string) => parseInt(date(_date, 'DD/MM/YYYY HH:mm:ss').format('X'));
        return _arr.slice().sort((a, b) => dateconvert(b.date) - dateconvert(a.date));
    }

    return _arr;
};

const NotificationBell = createSlice({
    name: 'notificationBell',
    initialState,
    reducers: {
        pushNotificationBellList: (state, action): void => {
            if (!!Object.keys(action.payload).length) {
                const _notification = action.payload;
                _notification['read'] = false;

                if (!state.notifications.some((item) => item.id === _notification.id)) {
                    const _notifications = state.notifications;
                    const _concat = _notifications.concat(action.payload);
                    const _filtered = _concat.filter((item, pos) => _concat.indexOf(item) === pos);

                    state.notifications = sort(_filtered);
                    state.updated = Date.now();
                }
            }
        },
        getNotificationBellFromStorageLocation: (state, action): void => {
            const _data = JSON.parse(getLS(`@bee4:notifications:${action.payload}`));
            state.notifications = !!_data?.length ? sort(_data.slice(0, appConfig.NOTIFICATION_BELL_LIMIT)) : [];
            state.updated = Date.now();
        },
        setNotificationBellToStorageLocation: (state, action): void => {
            setLS(`@bee4:notifications:${action.payload}`, JSON.stringify(sort(state.notifications)));
        },
        setMessageRead: (state, action): void => {
            const _notifications = state.notifications.slice();
            _notifications.map((message) => {
                if (JSON.stringify(message) === JSON.stringify(action.payload)) {
                    message.read = true;
                }
            });

            state.notifications = _notifications;
            state.updated = Date.now();
        },
        setAllMessageRead: (state): void => {
            const _notifications = state.notifications.slice();
            _notifications.map((message) => {
                if (!message.read) {
                    message.read = true;
                }
            });

            state.notifications = _notifications;
            state.updated = Date.now();
        },
    },
});

export const notificationBellActions = NotificationBell.actions;
export default NotificationBell.reducer;
