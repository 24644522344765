import { Icon as IconChakra, IconProps as IconChackraProps } from '@chakra-ui/react';

import { __ICONS_TYPES } from './constants';

export interface IconProps extends IconChackraProps {
    hidden?: boolean;
    type:
        | 'arrow'
        | 'check'
        | 'close'
        | 'warning'
        | 'play'
        | 'info'
        | 'comment'
        | 'tag'
        | 'cash'
        | 'twitter'
        | 'facebook'
        | 'instagram'
        | 'linkedin'
        | 'dashedLine'
        | 'filter'
        | 'question'
        | 'download';
}

export const Icon = ({ type, hidden, ...props }: IconProps) => {
    return <IconChakra as={__ICONS_TYPES[type]} {...props} display={hidden && 'none'} />;
};
