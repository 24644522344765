import { Epic } from 'redux-observable';
import { filter, of, delay } from 'rxjs';
import { switchMap, throttleTime, map, catchError, bufferTime, take } from 'rxjs/operators';

import { getOpenOrdersActions, getOpenOrdersSlice } from '.';
import { TOutputFrame } from '../../services/alphapoint/types';
import { cancelReplaceOrderActions } from '../cancelReplaceOrder';

export const getOpenOrdersEpic: Epic = (action$, state$, { Alphapoint, defaultBufferTime, defaultThrottleTime }) =>
    action$.pipe(
        filter(getOpenOrdersActions.getOpenOrders.match),
        throttleTime(defaultThrottleTime),
        delay(800),
        switchMap(() => {
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
            };

            return Alphapoint.subscribe({ action: getOpenOrdersSlice.name, ...body }).pipe(
                bufferTime(defaultBufferTime),
                map((data: TOutputFrame[]) => {
                    cancelReplaceOrderActions.resetEditOrder();
                    const flattenResponse = data.reduce((acc, cur) => [...(cur.data as Array<number>), ...acc], []);
                    const instruments = [
                        state$.value.companies.current.assets.instrumentBuyId,
                        state$.value.companies.current.assets.instrumentSellId,
                    ];
                    const orders = flattenResponse.filter((item) => instruments.includes(item.Instrument));
                    return getOpenOrdersActions.getOpenOrdersSuccess(orders);
                }),
                catchError(() => of(getOpenOrdersActions.getOpenOrdersFailed())),
                take(1)
            );
        })
    );
