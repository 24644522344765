import styled from 'styled-components';

import { devices } from '../../styles/devices';

import { Flex, Image } from '@chakra-ui/react';

export const FlexStyled = styled(Flex)`
    @media ${devices.maxMobileL} {
        flex-direction: column;
        backgournd-color: red;
    }
`;

export const ImageStyled = styled(Image)`
    @media ${devices.maxMobileL} {
        transform: rotate(90deg);
        margin: 20px auto;
    }
`;
