import { memo, useEffect, useState } from 'react';
import { useColorMode, useColorModeValue, Text, VStack, Box, HStack, Flex, Image } from '@chakra-ui/react';

import { PageTitle } from '../PageTitle';
import { Link } from '../Link';

import IconBellMessages from '../../assets/icons/icon-bell-message.svg';

import { HowMuchTime } from '../../utils/date';

import { BellContainer, MessagesContainer, Dot, Message, List, Button, HasMessage } from './styles';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { notificationBellActions, TNotificationMessage } from '../../store/notificationBell';
import { shallowEqual } from 'react-redux';
import { Icon } from '../Icon';

const NotificationBellComponent = () => {
    const { colorMode } = useColorMode();
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user, shallowEqual);
    const notificationBell = useAppSelector((state) => state.notificationBell, shallowEqual);

    const [messageList, setMessageList] = useState([]);
    const [buttonActive, setButtonActive] = useState('all');
    const [hasMessage, setHasMessage] = useState(false);

    const timeColor = useColorModeValue('gray.500', 'gray.200');

    const handleMessageRead = (item: TNotificationMessage) => {
        dispatch(notificationBellActions.setMessageRead(item));
    };

    const handleAllMessageRead = () => {
        dispatch(notificationBellActions.setAllMessageRead());
    };

    const handleFilterMessages = (event) => {
        const button = event.target?.id;
        setButtonActive(button);
        switch (button) {
            case 'not-read': {
                getMessagesNotRead();
                break;
            }
            case 'all':
                getAllMessages();
                break;
            default:
                break;
        }
    };

    const getAllMessages = () => {
        dispatch(notificationBellActions.getNotificationBellFromStorageLocation(user.userSession.cognito.userId));
    };

    const getMessagesNotRead = () => {
        setMessageList(notificationBell.notifications.filter((message) => !message.read) || []);
    };

    useEffect(() => {
        getAllMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setHasMessage(messageList.some((message) => !message.read));
        if (notificationBell.notifications.every((message) => !!message.read)) {
            setButtonActive('all');
            setMessageList(() => notificationBell.notifications);
        } else {
            buttonActive === 'not-read' ? getMessagesNotRead() : setMessageList(() => notificationBell.notifications);
        }
        dispatch(notificationBellActions.setNotificationBellToStorageLocation(user.userSession.cognito.userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationBell.updated]);

    useEffect(() => setHasMessage(() => messageList.some((message) => !message.read)), [messageList]);

    return (
        <BellContainer>
            <HStack position="relative" cursor="pointer">
                <Image
                    src={IconBellMessages}
                    width="24px"
                    height="24px"
                    filter={useColorModeValue('invert(1)', 'invert(0)')}
                />
                {hasMessage && <HasMessage />}
            </HStack>

            <MessagesContainer $colorMode={colorMode}>
                <Box padding="0 20px">
                    <PageTitle $fontSize="1.2rem">Notificações</PageTitle>
                </Box>

                <Flex alignItems="flex-end" justifyContent="space-between" margin="20px">
                    <HStack spacing="4">
                        <Button id="all" className={buttonActive === 'all' && 'active'} onClick={handleFilterMessages}>
                            Todas
                        </Button>
                        <Button
                            id="not-read"
                            className={buttonActive === 'not-read' && 'active'}
                            onClick={handleFilterMessages}
                        >
                            Não Lidas
                        </Button>
                    </HStack>

                    {hasMessage && (
                        <Link fontSize=".75rem" fontWeight="700" onClick={handleAllMessageRead}>
                            <Flex alignItems="center">
                                <Icon type="check" marginRight={1} />
                                Marcar todas como lidas
                            </Flex>
                        </Link>
                    )}
                    {/* <Link
                        to="#"
                        fontSize=".7rem"
                        fontWeight="700"
                        onClick={() => alert('Ação não implementada, somente marcação')}
                    >
                        Ver todas notificações
                    </Link> */}
                </Flex>

                <List>
                    {!!messageList.length ? (
                        messageList.map((message: TNotificationMessage) => (
                            <Message
                                key={`message-item-${Math.random()}`}
                                onClick={() => handleMessageRead(message)}
                                title={!message.read ? 'Clique para marcar como lida' : ''}
                            >
                                <Dot status={message.read} />
                                <VStack align="start">
                                    <Text fontWeight="700" fontSize="0.8rem" margin="0" lineHeight="1">
                                        {message.title}
                                    </Text>
                                    <Text fontWeight="400" fontSize="0.75rem" margin="0" lineHeight="1">
                                        {message.description}
                                    </Text>
                                    <Text fontWeight="700" fontSize="0.75rem" color={timeColor}>
                                        {HowMuchTime(message.date)}
                                    </Text>
                                </VStack>
                            </Message>
                        ))
                    ) : (
                        <Flex width="100%" height="90%" direction="column" alignItems="center" justifyContent="center">
                            <Text fontSize=".8rem" fontWeight="400" textAlign="center">
                                Não há notificações no momento
                            </Text>
                        </Flex>
                    )}
                </List>
            </MessagesContainer>
        </BellContainer>
    );
};

export const NotificationBell = memo(NotificationBellComponent);
