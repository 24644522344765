import { createIcon } from '@chakra-ui/icons';

export const IconFacebook = createIcon({
    displayName: 'IconFacebook',
    viewBox: '0 0 26 26',
    path: (
        <path
            d="M24.6061 0.384521H1.39375C0.835521 0.384521 0.384521 0.835521 0.384521 1.39375V24.6061C0.384521 25.1643 0.835521 25.6153 1.39375 25.6153H24.6061C25.1643 25.6153 25.6153 25.1643 25.6153 24.6061V1.39375C25.6153 0.835521 25.1643 0.384521 24.6061 0.384521ZM21.6919 7.74875H19.6766C18.0965 7.74875 17.7906 8.49937 17.7906 9.60321V12.0348H21.5626L21.0706 15.8415H17.7906V25.6153H13.8578V15.8447H10.5683V12.0348H13.8578V9.22791C13.8578 5.96998 15.8478 4.19437 18.7557 4.19437C20.1497 4.19437 21.345 4.29844 21.6951 4.34575V7.74875H21.6919Z"
            fill="currentColor"
        />
    ),
});
