import { timer, fromEvent, Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { map, retryWhen } from 'rxjs/operators';
import { Frame } from './props';
import { IAlphapointConnector, TInputFrame, TDefaultFilter, TOutputFrame, TMountFrameParams } from './types';
import appConfig from '../../config/appConfig';

class AlphapointConnector implements IAlphapointConnector {
    api: string;

    connection: WebSocketSubject<TInputFrame>;

    constructor() {
        this.api = appConfig.ALPHAPOINT_URI;
        this.connection = webSocket(this.api);
    }

    /**
     * Avoid any duplicances on response by checking method + ID or any custom method
     * @param msg Response from channel
     * @param frame Mounted Frame from source Request
     * @param filter Use this param as a middleware to check Socket Response before return it
     * @returns Boolean
     */
    defaultFilter(msg: TInputFrame, frame: TInputFrame, filter: TDefaultFilter = () => false): boolean {
        const isSameMethod = frame.n === msg.n;
        const isSameIdentifier = frame.i === msg.i;
        return (isSameMethod && isSameIdentifier) || filter(msg, frame);
    }

    /**
     * @param rawFrame A Frame format object for request
     * @returns A frame format object for response
     */
    mountFrame(rawFrame: TMountFrameParams): TInputFrame | never {
        if (!rawFrame) {
            return;
        }

        const { action: method, ...params } = rawFrame;
        return Frame.mount(method, params);
    }

    /**
     * When the magic happens. It adds the requested frame to the pool
     * and start to listen multiple requests
     * @param next A Frame format object for request
     * @param unsub A Frame format object for request (but this time for unsubscribe)
     * @param filter Use this param as a middleware to check Socket Response before return it
     * @returns An Observable. To be used as subscribe function on consumers
     */
    stream$(
        next: TMountFrameParams,
        unsub?: TMountFrameParams,
        filter?: TDefaultFilter
    ): Observable<TInputFrame> | never {
        const frame = this.mountFrame(next);

        if (!frame) {
            return;
        }

        return this.connection.multiplex(
            () => frame,
            () => this.mountFrame(unsub) || JSON.stringify({ unsubscribe: `${next.method}` }),
            (msg) => this.defaultFilter(msg, frame, filter)
        );
    }

    /**
     * This method is used as Middleware in order to pase every response from AP
     * @param next A Frame format object for request
     * @param unsub A Frame format object for request (but this time for unsubscribe)
     * @param filter Use this param as a middleware to check Socket Response before return it
     * @returns An Observable. To be used as subscribe function on consumers
     */
    subscribe(
        next: TMountFrameParams,
        unsub: TMountFrameParams = undefined,
        filter: TDefaultFilter = undefined
    ): Observable<TOutputFrame> | never {
        return this.stream$(next, unsub, filter).pipe(
            retryWhen(() => (window.navigator.onLine ? timer(1000) : fromEvent(window, 'online'))),
            map((res: TInputFrame) => Frame.parse(res))
        );
    }
}

export default new AlphapointConnector();
