import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { v4 as uuid } from 'uuid';

import {
    Container,
    Flex,
    HStack,
    VStack,
    Text,
    useColorMode,
    Image,
    Grid,
    useColorModeValue,
    Box,
    GridItem,
} from '@chakra-ui/react';
// import { SwiperSlide } from 'swiper/react';

import { PageTitle } from '../../components/PageTitle';
import { PageDescript } from '../../components/PageDescription';
import { Link } from '../../components/Link';
import { CardBox } from '../../components/CardBox';
import { GradientButton } from '../../components/GradientButton';
import { SocialIcon } from '../../components/SocialIcon';
import { MetaTags } from '../../components/MetaTags';
import { IconLine } from './components/IconLine';
// import { FeedbackCarousel } from '../../components/FeedbackCarousel';
// import { FeedbackSlider } from '../../components/FeedbackCarousel/components/FeedbackSlider';
// import { CompanyTable } from '../../components/CompanyTable';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import { Icon } from '../../components/Icon';

import { onlyDateDBToBR, onlyYear } from '../../utils/date';
import IconLocation from '../../assets/icons/icon-location.svg';

import { BoxDownloads, DonwloadItem, Logo, VideoContainer } from './styles';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { companiesActions } from '../../store/companies';
import { useState } from 'react';
import { formatNumberWithoutFraction } from '../../utils/numbers';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';

export const CompanySingle = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const params = useParams();
    const filterIcon = useColorModeValue('invert(100%)', 'invert(0)');

    const publishDateColor = useColorModeValue('lead.200', 'gray.200');
    const { list, current, loading } = useAppSelector((state) => state.companies);
    const { colorMode } = useColorMode();

    const [governanceFiles, setGovernanceFiles] = useState([]);
    const [financialFiles, setFinancialFiles] = useState([]);
    const [facts, setFacts] = useState([]);

    useEffect(() => {
        dispatch(companiesActions.resetCompanies());
        !list.length && dispatch(companiesActions.getCompanies());
        checkTicker();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkTicker();
        setGovernanceFiles(current.files?.filter((_company) => _company.type === 'governance'));
        setFinancialFiles(current.files?.filter((_company) => _company.type === 'financial'));
        setFacts(current.facts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, current]);

    const checkTicker = () => {
        if (!current.assets.ticker) {
            const _selected = list.filter((company) => company.slug === params.slug);
            !!_selected.length && dispatch(companiesActions.setCompany(_selected[0]));
        }
    };

    return (
        <main>
            <MetaTags>
                <title>{`Empresas Listadas - ${current.name} - BEE4`}</title>
            </MetaTags>
            {!!current.name ? (
                <Container maxW="container.xl" width="100%" height="100%" pb="40px">
                    <PageTitle>Sobre a empresa</PageTitle>
                    <PageDescript>Conheça informações mais detalhadas sobre a {current.name}.</PageDescript>

                    <UserBlockedDisclaimer />

                    <Flex flexFlow={['column', 'column', 'row']} alignItems="center" justifyContent="space-between">
                        <Flex
                            direction={['column', 'row']}
                            alignItems={['center', 'baseline']}
                            justifyContent="flex-start"
                            position="relative"
                        >
                            <Flex
                                width={['auto', 'auto', 'auto', '720px']}
                                alignItems="center"
                                direction={['column', 'row']}
                                margin={['30px auto', '30px 0', '40px 0']}
                            >
                                <Logo>
                                    <Image src={current.fullLogo} />
                                </Logo>

                                <VStack
                                    spacing={[2, 0]}
                                    margin={['15px 0', '15px 20px']}
                                    alignItems={['cetnter', 'start']}
                                >
                                    <Text
                                        fontWeight="600"
                                        fontSize="1.4rem"
                                        textTransform="uppercase"
                                        textAlign={['center', 'left']}
                                    >
                                        {current.assets?.ticker}
                                    </Text>
                                    <Text
                                        fontWeight="600"
                                        fontSize="1.4rem"
                                        textTransform="uppercase"
                                        textAlign={['center', 'left']}
                                    >
                                        {current.socialName}
                                    </Text>
                                    <Text fontWeight="500" fontSize="1rem" textAlign={['center', 'left']}>
                                        Fundada em {onlyYear(current.since)}
                                    </Text>
                                    <HStack spacing={2} justifyContent={['center', 'start']}>
                                        <Image src={IconLocation} width="20px" filter={filterIcon} />
                                        <Text fontWeight="500" fontSize="1rem">
                                            {current.location?.city}/{current.location?.state} -{' '}
                                            {current.location?.country}
                                        </Text>
                                    </HStack>
                                    <Link onClick={() => window.open(current.site, '_blank')}>
                                        <Text fontWeight="500" fontSize="1rem" textAlign={['center', 'left']}>
                                            {current.site?.replace(/^https?:\/\//, '')}
                                        </Text>
                                    </Link>
                                    <HStack
                                        spacing={5}
                                        padding={['15px 0', '15px 0', '15px 0', '4px 0 0']}
                                        justifyContent={['center', 'center', 'center', 'flex-start']}
                                    >
                                        {!!current.social?.length &&
                                            current.social.map((item, index) => (
                                                <SocialIcon
                                                    url={item.url}
                                                    media={item.media}
                                                    key={`company-social-${index}`}
                                                    width="25px"
                                                    height="25px"
                                                />
                                            ))}
                                    </HStack>
                                </VStack>

                                <Box
                                    position={['unset', 'unset', 'unset', 'absolute']}
                                    bottom={['unset', 'unset', 'unset', '53px']}
                                    right={['unset', 'unset', 'unset', '70px']}
                                >
                                    <GradientButton
                                        label="Negociar"
                                        type="button"
                                        width="150px"
                                        margin="0"
                                        variant="colorful"
                                        onClick={() => navigate(`/negociacoes/${current.slug}`)}
                                    />
                                </Box>
                            </Flex>
                        </Flex>

                        <VideoContainer flex={1} justifyContent="flex-end">
                            <YoutubeEmbed url={current.video} align="right" />
                        </VideoContainer>
                    </Flex>

                    <CardBox $topLine={true} padding={['25px', '50px']} mb={9}>
                        <Text fontWeight="600" fontSize="1rem" marginLeft="-20px">
                            Sobre
                        </Text>

                        <Text width="100%" fontWeight="400" fontSize="0.8rem" lineHeight="1.6" margin="1rem 0 2rem">
                            {current.description}
                        </Text>

                        {/* <VStack alignItems="start" spacing={0} marginLeft="-20px">
                            <Text fontWeight="600" fontSize="1rem">
                                Informações gerenciais da empresa
                            </Text>
                            <Text fontWeight="400" fontSize=".75rem">
                                R$ (em milhares de reais)
                            </Text>
                        </VStack>

                        <Text fontWeight="600" fontSize=".9rem" mt="1rem" lineHeight={2}>
                            Referência: {current.financial?.reference}
                        </Text> */}

                        {/* <CompanyTable data={current.financial?.table} /> */}
                    </CardBox>

                    <Grid
                        templateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}
                        templateRows="2fr"
                        columnGap={5}
                        rowGap={30}
                        pb="40px"
                    >
                        <VStack spacing={10}>
                            <GridItem width="100%">
                                <CardBox $topLine={true} padding={['25px', '50px']}>
                                    {/* <IconLine
                                        icon="info"
                                        height="21px"
                                        width="21px"
                                        title="Explicação dos ajustados:"
                                        text={current.explanation}
                                    />

                                    <IconLine
                                        icon="comment"
                                        height="24px"
                                        width="22px"
                                        title="Comentários"
                                        text={current.comments}
                                    /> */}

                                    <IconLine
                                        icon="tag"
                                        height="22px"
                                        width="24px"
                                        title="Total de ações da empresa"
                                        text={formatNumberWithoutFraction(current.stockTotal)}
                                        centered
                                    />

                                    <IconLine
                                        icon="tag"
                                        height="22px"
                                        width="24px"
                                        title="Total de ações em circulação"
                                        text={formatNumberWithoutFraction(current.stock)}
                                        $hasBorder={false}
                                        centered
                                    />
                                </CardBox>
                            </GridItem>

                            <GridItem>
                                <CardBox $topLine={true} padding="25px">
                                    <Text fontWeight="700" fontSize="1rem" margin="30px 0 0">
                                        Principais executivos e conselho
                                    </Text>

                                    <Flex
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="space-evenly"
                                        padding={['0 5px', '0 15px']}
                                    >
                                        {!!current.executives?.length &&
                                            current.executives.map((executive, index) => (
                                                <VStack
                                                    key={`executive-${index}`}
                                                    alignItems="start"
                                                    width="100%"
                                                    borderBottom={
                                                        index === current.executives.length - 1 ? 0 : '2px solid'
                                                    }
                                                    borderColor="lead.100"
                                                    padding="15px 0"
                                                    spacing={0}
                                                >
                                                    <Text fontWeight="500" fontSize="1rem">
                                                        {executive.name}
                                                    </Text>
                                                    <Text fontWeight="400" fontSize=".8rem" textTransform="uppercase">
                                                        {executive.office}
                                                    </Text>
                                                    <Text
                                                        fontWeight="400"
                                                        fontSize=".8rem"
                                                        padding="15px 0"
                                                        lineHeight="1.4"
                                                    >
                                                        {executive.excerpt}
                                                    </Text>

                                                    {!!executive.social?.length &&
                                                        executive.social.map((social, index) => (
                                                            <SocialIcon
                                                                key={`executive-social-${index}`}
                                                                media={social.media}
                                                                url={social.url}
                                                                width="25px"
                                                                height="25px"
                                                            />
                                                        ))}
                                                </VStack>
                                            ))}
                                    </Flex>
                                </CardBox>
                            </GridItem>
                        </VStack>

                        <VStack spacing={10}>
                            {/* <GridItem width="100%">
                                <CardBox $topLine={true} padding={['25px', '30px']}>
                                    <Text fontWeight="600" fontSize="1rem">
                                        Informações adicionais
                                    </Text>

                                    <Flex justifyContent="center">
                                        {!!current.additionalInformation?.length && (
                                            <FeedbackCarousel spaceBetween={50} slidesPerView={1} loop>
                                                {current.additionalInformation.map((information) => (
                                                    <SwiperSlide key={`recent-orders-${uuid()}`}>
                                                        <FeedbackSlider
                                                            text={information.text}
                                                            subtext={`Data da publicação: ${onlyDateDBToBR(
                                                                information.datePublished
                                                            )}`}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </FeedbackCarousel>
                                        )}
                                    </Flex>
                                </CardBox>
                            </GridItem> */}

                            <GridItem width="100%">
                                <CardBox $topLine={true} padding={8}>
                                    <Text fontWeight="600" fontSize="1.1rem" marginLeft={-4} mb={2}>
                                        Documentos
                                    </Text>
                                    {!!financialFiles?.length && (
                                        <>
                                            <Text fontWeight="600" fontSize="1rem" marginLeft={-4}>
                                                Financeiros
                                            </Text>
                                            <BoxDownloads $colorMode={colorMode}>
                                                {financialFiles.map((_file, index) => (
                                                    <DonwloadItem
                                                        key={`file-${index}`}
                                                        href={_file.file}
                                                        target="_blank"
                                                    >
                                                        <Text fontWeight="700" fontSize="0.9rem">
                                                            {_file.title}
                                                        </Text>
                                                        <Text
                                                            fontWeight="400"
                                                            fontSize="0.8rem"
                                                            color={publishDateColor}
                                                        >
                                                            Data de publicação: {onlyDateDBToBR(_file.date)}
                                                        </Text>
                                                        <Icon type="download" height={7} width={7} />
                                                    </DonwloadItem>
                                                ))}
                                            </BoxDownloads>
                                        </>
                                    )}

                                    {!!governanceFiles?.length && (
                                        <>
                                            <Text fontWeight="600" fontSize="1rem" ml={-4} mt={6}>
                                                Governança
                                            </Text>
                                            <BoxDownloads $colorMode={colorMode}>
                                                {!!governanceFiles?.length &&
                                                    governanceFiles.map((_file, index) => (
                                                        <DonwloadItem
                                                            key={`file-${index}`}
                                                            href={_file.file}
                                                            target="_blank"
                                                        >
                                                            <Text fontWeight="700" fontSize="0.9rem">
                                                                {_file.title}
                                                            </Text>
                                                            <Text
                                                                fontWeight="400"
                                                                fontSize="0.8rem"
                                                                color={publishDateColor}
                                                            >
                                                                Data de publicação: {onlyDateDBToBR(_file.date)}
                                                            </Text>
                                                            <Icon type="download" height={7} width={7} />
                                                        </DonwloadItem>
                                                    ))}
                                            </BoxDownloads>
                                        </>
                                    )}
                                </CardBox>
                            </GridItem>

                            <GridItem width="100%">
                                <CardBox $topLine={true} padding={8}>
                                    <Text fontWeight="600" fontSize="1.1rem" marginLeft={-4} mb={2}>
                                        Fatos relevantes
                                    </Text>

                                    {/**
                                     * Precisamos entender o formato para esses fatos.
                                     */}
                                    <Text fontWeight="400" fontSize=".875rem" lineHeight="1.4">
                                        Aqui serão exibidos comunicados e fatos relevantes sobre a empresa.
                                    </Text>

                                    {!!facts?.length && (
                                        <>
                                            <BoxDownloads $colorMode={colorMode}>
                                                {!!facts?.length &&
                                                    facts.map((_file, index) => (
                                                        <DonwloadItem
                                                            key={`file-${index}`}
                                                            href={_file.file}
                                                            target="_blank"
                                                        >
                                                            <Text fontWeight="700" fontSize="0.9rem">
                                                                {_file.title}
                                                            </Text>
                                                            <Text
                                                                fontWeight="400"
                                                                fontSize="0.8rem"
                                                                color={publishDateColor}
                                                            >
                                                                Data de publicação: {onlyDateDBToBR(_file.date)}
                                                            </Text>
                                                            <Icon type="download" height={7} width={7} />
                                                        </DonwloadItem>
                                                    ))}
                                            </BoxDownloads>
                                        </>
                                    )}
                                </CardBox>
                            </GridItem>
                        </VStack>
                    </Grid>
                </Container>
            ) : (
                !loading && (
                    <Container maxW="container.xl" width="100%" height="100%" pb="40px">
                        <Flex width="100%" height="70Vh" alignItems="center" justifyContent="center">
                            <PageDescript>
                                Não conseguimos encontrar essa empresa, por gentileza revise o endereço digitado.
                            </PageDescript>
                        </Flex>
                    </Container>
                )
            )}
        </main>
    );
};
