import styled, { css } from 'styled-components';
import { bee4Theme } from '../../../styles/theme';

interface ColorfulProps {
    width?: string;
    height?: string;
    $colorMode?: string;
    disabled?: boolean;
    padding?: string;
    margin?: string;
    fontSize?: string | number;
    fontWeight?: string | number;
    maxWidth?: string | number;
}

const ColorfulStyle = css<ColorfulProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'auto'};
    max-width: ${(props) => props.maxWidth || 'unset'};

    padding: ${(props) => (props.padding ? props.padding : '12px 0')};
    margin: ${(props) => (props.margin ? props.margin : 0)};

    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    border: 1px solid transparent;
    border-radius: 4px;

    font-weight: 500;
    color: ${bee4Theme.colors.white};

    text-transform: uppercase;
    transition: all 0.5s ease-in-out;

    font-family: 'Georama', sans-serif;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};

    &:focus,
    &:active,
    &:visited,
    &:hover {
        color: ${bee4Theme.colors.black} !important;
        &::before {
            background-position: -1px 100%; //-1px fix bug Chrome
        }
    }
    &:disabled {
        opacity: 0.4;
    }
    &::before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        z-index: -1;

        transition: all 0.5s ease-in-out;
        border-radius: 4px;

        background-size: 250% 101%;
        background-position: 100%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[200]} 3%,
                        ${bee4Theme.colors.blue[200]} 45%,
                        ${bee4Theme.colors.blue[100]} 50%,
                        ${bee4Theme.colors.blue[400]} 110%
                    )`
                : `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[200]} 3%,
                        ${bee4Theme.colors.blue[200]} 35%,
                        ${bee4Theme.colors.blue[100]} 50%,
                        ${bee4Theme.colors.blue[400]} 110%
                    )`};
    }
`;

export const ButtonHover = styled.button`
    ${ColorfulStyle}
`;

export const LinkHover = styled.a`
    ${ColorfulStyle}
`;
