import { memo, useMemo, useRef, useState } from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
    Container,
    Image,
    Flex,
    useColorMode,
    HStack,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useColorModeValue,
    Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { NotificationBell } from '../NotificationBell';

import Bee4LightLogo from '../../assets/logos/bee4-white-logo.svg';
import Bee4DarkLogo from '../../assets/logos/bee4-black-logo.svg';
import { Modal } from '../../components/Modal';

import { HeaderContainer, MobileMenu, NavigationMenu } from './styles';
import { UserMenu } from '../UserMenu';
import MenuItems from './menu.json';
import { Link } from '../Link';
import { Button } from '../Button';

const HeaderComponent = () => {
    const { colorMode } = useColorMode();
    const HamburgerRef = useRef();

    const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
    const [IPOModal, setIPOModal] = useState(false);

    const logo = colorMode === 'dark' ? Bee4LightLogo : Bee4DarkLogo;
    const Logo = useMemo(() => <Image width="78px" height="23px" src={logo} alt="Bee4" />, [logo]);

    const mountMenu = () => {
        return MenuItems.map((item) => (
            <NavLink key={item.label} onClick={() => setDrawerMenuOpen(false)} to={item.route}>
                {item.label}
            </NavLink>
        ));
    };

    return (
        <HeaderContainer $colorMode={colorMode}>
            <Container maxW="container.xl" width="100%" height="100%">
                <Flex
                    width="100%"
                    flexFlow="row"
                    position="relative"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <NavLink to="/">{Logo}</NavLink>

                    <HamburgerIcon onClick={() => setDrawerMenuOpen(true)} display={['block', 'block', 'none']} />

                    <NavigationMenu $colorMode={colorMode}>
                        {mountMenu()}
                        <a onClick={() => setIPOModal(true)} style={{ cursor: 'pointer' }}>
                            {"IPO's em andamento"}
                        </a>
                        <Modal
                            isOpen={IPOModal}
                            onClose={() => setIPOModal(false)}
                            size="md"
                            topLine={true}
                            motionPreset={'slideInBottom'}
                        >
                            <Text fontSize=".9rem" padding="40px 0 10px">
                                {
                                    "Você será direcionado para a BEE4 IPO, onde ocorrem os IPO's das empresas que serão listadas na BEE4."
                                }
                            </Text>
                            <Text fontWeight="700" fontSize=".9rem">
                                Deseja continuar?
                            </Text>
                            <Flex alignItems="center" justify="flex-end">
                                <Link onClick={() => setIPOModal(false)}>
                                    <Text
                                        fontWeight="700"
                                        fontSize=".8rem"
                                        textTransform="uppercase"
                                        marginRight="20px"
                                    >
                                        Fechar
                                    </Text>
                                </Link>
                                <Button
                                    width="140px"
                                    colorScheme="green"
                                    onClick={() => {
                                        window.open('https://bee4.com.br/bee4-ipo', '_blank');
                                        setIPOModal(false);
                                    }}
                                >
                                    Continuar
                                </Button>
                            </Flex>
                        </Modal>
                    </NavigationMenu>

                    <HStack spacing="15px">
                        <NotificationBell />
                        <UserMenu />
                    </HStack>

                    <Drawer
                        isOpen={drawerMenuOpen}
                        onClose={() => setDrawerMenuOpen(false)}
                        placement="left"
                        finalFocusRef={HamburgerRef}
                        variant="bee4"
                    >
                        <DrawerOverlay />
                        <DrawerContent background={useColorModeValue('gray.200', 'lead.400')}>
                            <DrawerCloseButton />
                            <DrawerHeader>
                                <NavLink to="/painel">
                                    <Image width="78px" height="23px" src={logo} alt="Bee4" />
                                </NavLink>
                            </DrawerHeader>

                            <DrawerBody>
                                <MobileMenu $colorMode={colorMode}>{mountMenu()}</MobileMenu>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Flex>
            </Container>
        </HeaderContainer>
    );
};

export const Header = memo(HeaderComponent);
