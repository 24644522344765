import { Flex, Text } from '@chakra-ui/react';
import CheckIcon from '../../assets/icons/icon-check.svg';
import ErrorIcon from '../../assets/icons/icon-error.svg';

interface FeedbackPasswordProps {
    status: boolean;
    value: string;
    message: string;
}

export const FeedbackPassword = ({ status, value, message }: FeedbackPasswordProps) => {
    if (status && value) {
        return (
            <Flex alignItems="center">
                <img src={CheckIcon} />
                <Text fontSize=".9rem" color="gray.500" paddingLeft="2px">
                    {message}
                </Text>
            </Flex>
        );
    }
    if (!status && value) {
        return (
            <Flex alignItems="center">
                <img src={ErrorIcon} />
                <Text fontSize=".9rem" color="red.300" paddingLeft="2px">
                    {message}
                </Text>
            </Flex>
        );
    }
    return (
        <Flex alignItems="center">
            <Text fontSize=".9rem" color="gray.500" paddingLeft="2px">
                {message}
            </Text>
        </Flex>
    );
};
