import { TCompanyPayload } from './types';
import { Epic } from 'redux-observable';
import { mergeMap, mergeAll, filter, throttleTime, merge, of } from 'rxjs';

import { companiesActions } from '.';
import { ajax } from 'rxjs/ajax';
import appConfig from '../../config/appConfig';
import { getToken } from '../../utils/token';

export const getCompaniesEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(companiesActions.getCompanies.match),
        throttleTime(defaultThrottleTime),
        mergeMap(async () => {
            return ajax({
                url: `${appConfig.API_URL}business/companies`,
                crossDomain: true,
                method: 'GET',
                headers: {
                    Authorization: getToken(),
                },
            }).pipe(
                mergeMap((payload) => {
                    const { response } = payload as TCompanyPayload;
                    const data = response.map((item) => item.config);
                    return merge(of(companiesActions.setCompanies(data)), of(companiesActions.setPlatformAssets(data)));
                })
            );
        }),
        mergeAll()
    );
