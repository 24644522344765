import { Flex, Text, Image, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { currency } from '../../utils/numbers';

import IconArrowUp from '../../assets/icons/icon-arrow-up.svg';

interface LabelHighLightProps {
    value: number;
    type: 'currency' | 'percent';
    small?: boolean;
}
export const LabelHighLigh = ({ value, type, small }: LabelHighLightProps) => {
    const [milhar, setMilhar] = useState('');
    const [cents, setCents] = useState('');

    useEffect(() => {
        if (type === 'currency') {
            const string = currency(value);
            const money = string.split('$');
            const splited = money[1].split(',');

            setMilhar(splited[0]);
            setCents(splited[1]);
        } else {
            const splited = value.toString().split('.');
            setMilhar(splited[0]);
            setCents(splited[1]);
        }
    }, [value, type]);

    return type === 'currency' ? (
        <Flex alignItems="flex-end" justifyContent="center">
            <Text fontWeight="600" fontSize={small ? '.9rem' : '1.2rem'} lineHeight="1.2">
                R$
            </Text>
            <Text fontWeight="600" fontSize={small ? '1.6rem' : '2rem'} lineHeight="1">
                {milhar}
            </Text>
            <Text fontWeight="600" fontSize={small ? '.9rem' : '1.2rem'} lineHeight="1.4">
                ,{cents}
            </Text>
        </Flex>
    ) : (
        <Flex
            alignItems="flex-end"
            justifyContent="center"
            color={value === 0 ? '' : value > 0 ? 'green.100' : 'red.100'}
        >
            <HStack spacing="5px">
                {value !== 0 && (
                    <Image
                        width="15px"
                        height="15px"
                        src={IconArrowUp}
                        transform={value < 0 ? 'rotate(180deg)' : 'rotate(0deg)'}
                        filter={
                            value < 0
                                ? 'brightness(0) saturate(100%) invert(67%) sepia(51%) saturate(3986%) hue-rotate(320deg) brightness(101%) contrast(105%)'
                                : 'brightness(0) saturate(100%) invert(54%) sepia(27%) saturate(975%) hue-rotate(81deg) brightness(95%) contrast(98%)'
                        }
                    />
                )}
                <Text fontWeight="600" fontSize={small ? '1.2rem' : '2rem'} lineHeight="1">
                    {milhar}
                </Text>
            </HStack>
            {cents && (
                <Text fontWeight="600" fontSize={small ? '2rem' : '2rem'} lineHeight="1">
                    ,{cents}
                </Text>
            )}
            <Text fontWeight="600" fontSize={small ? '2rem' : '2rem'} lineHeight="1">
                %
            </Text>
        </Flex>
    );
};
