import { Epic } from 'redux-observable';
import { v4 as uuid } from 'uuid';
import { of } from 'rxjs';
import { switchMap, throttleTime, filter, catchError, concatMap } from 'rxjs/operators';

import { cancelReplaceOrderActions } from '.';
import { Toast } from '../../components/Toast';
import { getOpenOrdersActions } from '../getOpenOrders';
import { getOrdersHistoryActions } from '../getOrdersHistory';
import { notificationBellActions } from '../notificationBell';
import { date } from '../../utils/date';

import blockedUserRules from '../../data/BlockedUserRules/rules.json';

export const cancelReplaceOrderEpics: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(cancelReplaceOrderActions.sendEditOrder.match),
        filter(() => !['unstable', 'closed', 'maintenace'].includes(state$.value.timeline.currentStep?.type)),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            /**
             * Valida se o usuário pode realizar a ação
             ***/
            const side = { 0: 'buy', 1: 'sell' };
            if (
                state$.value.user?.userSession?.status.blocked &&
                blockedUserRules.blockedActions[side[payload.side]].edit.some((item) =>
                    state$.value.user?.userSession?.status.type.includes(item)
                )
            ) {
                return of(cancelReplaceOrderActions.editFailed({}));
            }

            return Alphapoint.subscribe({ action: 'CancelReplaceOrder', ...payload }).pipe(
                concatMap(({ data }) => {
                    if (data.errorcode === 101) {
                        const message = {
                            id: `${data.errormsg}-${uuid()}`,
                            title: 'Ordem rejeitada',
                            description: 'Ordem rejeitada por falta de fundos.',
                            status: 'error' as const,
                            date: date().format('DD/MM/YYYY HH:mm:ss'),
                        };

                        notificationBellActions.pushNotificationBellList(message);
                        Toast(message);

                        return of(cancelReplaceOrderActions.editFailed(data));
                    }

                    return of(
                        cancelReplaceOrderActions.editOrder(),
                        getOpenOrdersActions.updateItemOpenOrders(payload),
                        getOpenOrdersActions.getOpenOrders(),
                        getOrdersHistoryActions.getOrdersHistory()
                    );
                }),
                catchError((error) => of(cancelReplaceOrderActions.editFailed(error)))
            );
        })
    );
