import { createSlice } from '@reduxjs/toolkit';
import { initialState, parseSubscribeLevel2Payload } from './initial';

export const subscribeLevel2Slice = createSlice({
    name: 'SubscribeLevel2',
    initialState,
    reducers: {
        subscribeLevel2: (state): void => {
            state.error = false;
            state.loading = true;
            state.orders = initialState.orders;
        },
        unsubscribeLevel2: (state): void => {
            state.error = false;
            state.loading = false;
            state.orders = initialState.orders;
            state.updated = Date.now();
        },
        subscribeLevel2Success: (state, action): void => {
            if (!!action.payload.length) {
                state.error = false;

                /**
                 * All orders uses 'Price' as key. So we just need to check 'ActionType === 2 (delete)'
                 * to remove from object. 'ActionType === 1 (update)' it updates automatically because
                 * we are using 'Price' as key.
                 */
                const mergedOrders = { ...state.orders, ...parseSubscribeLevel2Payload(action.payload) };
                const processedOrders = {};
                let deleteIndex = null;

                Object.entries(mergedOrders).forEach(([key, value]) => {
                    if (value.ActionType === 2) {
                        deleteIndex = key;
                    } else {
                        deleteIndex = null;
                        processedOrders[key] = value;
                    }
                });

                state.orders = processedOrders;
                !!deleteIndex && delete state.orders[parseInt(deleteIndex)];

                state.updated = Date.now();
            }
            state.loading = false;
        },
        subscribeLevel2Failed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
    },
});

export const subscribeLevel2Actions = subscribeLevel2Slice.actions;
export default subscribeLevel2Slice.reducer;
