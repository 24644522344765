import { devices } from './../../styles/devices';
import styled from 'styled-components';

import { bee4Theme } from '../../styles/theme';
import background from '../../assets/backgrounds/background-button.svg';

interface ButtonType {
    $colorMode: string;
    $highlight?: boolean;
}
export const Button = styled.div<ButtonType>`
    width: 100%;
    height: 90px;

    padding: 30px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    border: 1px solid;
    border-radius: 10px;
    border-color: ${({ $colorMode }) =>
        $colorMode === 'dark' ? bee4Theme.colors.lead[100] : bee4Theme.colors.gray[200]};

    background: ${({ $colorMode, $highlight }) => {
        if ($highlight) {
            return $colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[400];
        }
        return $colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.silver[200];
    }};

    color: ${({ $highlight, $colorMode }) => {
        if ($highlight) return bee4Theme.colors.white;
        return $colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.black;
    }};

    position: relative;
    overflow: hidden;

    cursor: pointer;
    transition: all 0.5s ease-in-out;

    @media ${devices.maxTablet} {
        margin: 5px 0;
    }
    @media ${devices.minLaptop} {
        max-width: 300px;
    }

    figure {
        width: 90px;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out;

        background: ${({ $colorMode, $highlight }) => {
            if ($highlight) {
                return bee4Theme.colors.lead[100];
            }
            return $colorMode === 'dark' ? bee4Theme.colors.lead[300] : bee4Theme.colors.gray[500];
        }};

        img {
            width: 35px;
            display: block;
        }
    }
    span {
        display: block;
        width: calc(100% - 90px) !important;

        font-weight: 400;
        font-size: 0.87rem;

        text-align: center;
        text-transform: uppercase;

        letter-spacing: 0.05em;
    }

    &:hover {
        background: ${bee4Theme.colors.blue[300]};
        color: ${bee4Theme.colors.white};

        figure {
            background: ${bee4Theme.colors.blue[400]};
        }
    }
    &::after {
        content: '';

        width: 30%;
        height: 100%;

        position: absolute;
        bottom: 0;
        right: 0;

        background-image: url(${background});
        background-size: 100% 100%;

        background-repeat: no-repeat;
        background-position: center;
    }
`;
