import { createContext, ReactNode, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';

/** Types **/
interface RescuePasswordProviderProps {
    children: ReactNode;
}
interface userData {
    userName: string;
    password?: string;
    code?: string;
}
interface RescuePasswordContextData {
    step: number;
    userData: userData;
    setStep: (state: number) => void;
    setUserData: (state: userData) => void;
    requestCode: (userName: string) => Promise<boolean>;
    updatePassword: (userName: string, code: string, password: string) => Promise<{ success: boolean; error?: string }>;
}

/** Context **/
const RescuePasswordContext = createContext<RescuePasswordContextData>({} as RescuePasswordContextData);

export const RescuePasswordProvider = ({ children }: RescuePasswordProviderProps) => {
    const [step, setStep] = useState(1);
    const [userData, setUserData] = useState({} as userData);

    const requestCode = async (userName: string) => {
        try {
            await Auth.forgotPassword(userName);
            return true;
        } catch (error) {
            console.log({ error });
            return false;
        }
    };

    const updatePassword = async (userName: string, code: string, password: string) => {
        try {
            await Auth.forgotPasswordSubmit(userName, code, password);
            return { success: true };
        } catch (error) {
            return { success: false, error };
        }
    };

    return (
        <RescuePasswordContext.Provider value={{ step, setStep, userData, setUserData, requestCode, updatePassword }}>
            {children}
        </RescuePasswordContext.Provider>
    );
};

/** Hook **/
export function useChangePass() {
    return useContext(RescuePasswordContext);
}
