import { createGlobalStyle } from 'styled-components';
import backgroundDots from '../assets/backgrounds/background-dots.png';
import { devices } from './devices';

export const GlobalStyles = createGlobalStyle`
    :root {
        font-size: 16px;

        -webkit-font-smoothing: subpixel-antialiased;

        /** Variables **/
        ---fontFamily: 'Georama', sans-serif;
        ---secondaryFont: 'Inter', sans-serif;
    }
    * {
        margin: 0;
        padding: 0;
        outline: 0;

        box-sizing: border-box;
        font-family: var(---fontFamily);
    }
    html {
        width: 100%;
        height: 100%;

        padding: 0;
        margin:  0;

        scroll-behavior: smooth;

        /** Adjust font size to small devices **/
        @media (max-width: 1080px) { font-size: 93.75%; }
        @media (max-width: 720px)  { font-size:  87.5%; }

        body {
            width: 100%;
            height: 100%;

            padding: 0;
            margin:  0;

            transition: all .3s ease-in-out;

            main {
                width: 100%;
                min-height: 100%;

                padding-top: 100px;
                padding-bottom: 100px;
                position: relative;

                @media ${devices.maxMobileL} {
                    margin-bottom: -64px;
                }
                @media ${devices.minTablet} {
                    margin-bottom: -130px;
                }

                &::before {
                    content: "";

                    background-image: url(${backgroundDots});
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: 0 40vh;
                    background-attachment:  fixed;

                    width: 100%;
                    height: 100%;

                    position: absolute;
                    top: 0; left: 0;

                    z-index: -1;
                    opacity: 0.05;
                }
                &::after {
                    content: "";
                    display: block;

                    @media ${devices.maxMobileL} {
                        height: -64px;
                    }
                    @media ${devices.minTablet} {
                        height: -46px;
                    }
                }
            }
            footer {
                @media ${devices.maxMobileL} {
                    height: -64px;
                }
                @media ${devices.minTablet} {
                    height: -46px;
                }
            }
            a { text-decoration: none !important; }
            p { letter-spacing: 0.05em; }
            button { cursor: pointer; }
            button, input, textarea { font-family: var(---fontFamily) !important; }

            [disabled] {
                opacity: .6;
                cursor:  not-allowed;
            }
            #root {
                margin:  0;
                padding: 0;

                width: 100%;
                height: 100%;

                position: relative;
                z-index: 1;
            }
        }
    }
`;
