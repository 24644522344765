import { TMessages, IFrame, TInputFrame, TOutputFrame } from './types';

export const DMessages: TMessages = {
    0: 'request',
    1: 'reply',
    2: 'subscribe to event',
    3: 'event',
    4: 'unsubscribe to event',
    5: 'error',
};

class CFrame implements IFrame {
    i: IFrame['i'];

    constructor() {
        this.i = 0;
    }

    /**
     * It should be a private method by the way (ECMA Script 2015)
     * @returns A number multiple of two. Following Alphapoint directives;
     */
    private iterate(): number {
        this.i += 2;
        return this.i;
    }

    /**
     * It should be a private method by the way (ECMA Script 2015)
     * Check if there are necessary parameters to avoid unnecessary requests;
     * @returns boolean
     */
    private validate(action: string, payload: Record<string, unknown>): boolean {
        if (!action || action === '' || !payload || payload === {}) {
            return false;
        }

        return true;
    }

    /**
     * @param action Alphapoint method
     * @param payload Request body with params
     * @returns Formatted Frame following Alphapoint specifications;
     */
    mount(action: string, payload: Record<string, unknown>): TInputFrame {
        if (!this.validate(action, payload)) {
            return;
        }

        const ID = this.iterate();

        return {
            m: 0,
            i: ID,
            n: action,
            o: JSON.stringify(payload),
        };
    }

    /**
     *
     * @param msg Alphapoint response. A stringfied object
     * @returns A parsed object where 'data' is the server response;
     */
    parse(msg: TInputFrame): TOutputFrame {
        const { m, i, n, o } = msg;
        let data = undefined;

        try {
            data = JSON.parse(o);
        } catch (e: ReturnType<Error>) {
            data = { result: false, error: 0 };
        }

        return {
            i,
            msg: DMessages[m],
            method: n,
            data,
        };
    }
}

export const Frame = new CFrame();
