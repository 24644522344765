import { ChakraProvider, localStorageManager } from '@chakra-ui/react';
import Routes from './routes';

import { GlobalStyles } from './styles/globals';
import { bee4Theme } from './styles/theme';

/** Amplify **/
import Amplify from 'aws-amplify';
import amplifyConfig from './config/amplify';

Amplify.configure(amplifyConfig);

export function App() {
    return (
        <ChakraProvider theme={bee4Theme} colorModeManager={localStorageManager}>
            <GlobalStyles />
            <Routes />
        </ChakraProvider>
    );
}
