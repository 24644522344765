import { Toast } from './../components/Toast/index';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/pt-br';

/** Define moment to use Brasília Hour */
const _moment = moment;
export const timezone = 'America/Sao_Paulo';
export const weekdays = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
];
export const monthsShort = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
export const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

const PT_BR = {
    weekdays,
    monthsShort,
    months,
    relativeTime: {
        future: 'há %s',
        past: 'há %s',
        s: 'alguns segundos',
        ss: '%d segundos',
        m: '1 minuto',
        mm: '%d minutos',
        h: '1 hora',
        hh: '%dh',
        d: '1 dia',
        dd: '%d dias',
        M: '1 mês',
        MM: '%d meses',
        y: '1 ano',
        yy: '%d anos',
    },
};

_moment.tz.setDefault(timezone);
_moment.updateLocale('pt-br', PT_BR);
_moment.locale('pt-br');

/** Instancias **/
export type DateType = moment.Moment;
export const date = (date?: string | number | Moment, format?: string) => _moment(date, format).tz(timezone);
export const dateObj = _moment;

/** Helpers **/
export const checkClockCorrectly = (date): boolean => {
    /** Fusos em relação ao horário de Brasília **/
    const convert = { '-02:00': -1, '-03:00': 0, '-04:00': 1, '-05:00': 2 };

    const utc = moment().format('Z');
    const currentTime = moment();
    const federalDistrictTime = moment.utc(date);

    if (!federalDistrictTime.isSame(currentTime.add(convert[utc], 'hour'), 'minutes')) {
        Toast({
            id: 'ClockCheck',
            title: `Erro de relógio`,
            description: 'Seu relógio não está com o horário correto, ajuste por gentileza.',
            status: 'error',
        });
        return false;
    }

    return true;
};

export const getTodayShort = () => {
    const _date = _moment().tz(timezone);
    _date.locale('en');
    return _date.format('ddd');
};

export const getDayShort = (date: number | string) => {
    const _date = _moment(date).tz(timezone);
    _date.locale('en');
    return _date.format('ddd');
};

export const now = () => {
    const _date = _moment().tz(timezone);
    return _date.format('dddd, D [de] MMMM');
};

export const lastWeekDay = (days: number) => {
    const endOfWeek = date().endOf('week');
    return endOfWeek.subtract(days, 'days').format('DD/MM');
};

export const dateDBToBR = (date: string | number) => {
    const _date = _moment(date, 'YYYY-MM-DD HH:mm:ss');
    return _date.format('DD/MM/YYYY HH:mm:ss');
};

export const onlyDateDBToBR = (date: string | number) => {
    const _date = _moment(date);
    return _date.format('DD/MM/YYYY');
};

export const onlyYear = (date: string | number) => {
    const _date = _moment(date, 'YYYY-MM-DD HH:mm:ss');
    return _date.format('YYYY');
};

export const onlyTime = (date: string | number, format?: string) => {
    const _date = _moment(date, format);
    return _date.format('HH:mm:ss');
};

export const HowMuchTime = (date: string | number) => {
    const _date = _moment(date, 'DD/MM/YYYY HH:mm:ss');
    return _date.fromNow();
};

export const dateShort = (date: string | number, format?: string) => {
    const _date = _moment(date, format);
    return _date.format('DD/MMM HH:mm:ss');
};

export const dateComplete = (date: string | number) => {
    const _date = _moment(date);
    return _date.format('dddd, DD/MMM YYYY HH:mm:ss');
};

export const last24hours = () => {
    return _moment().tz(timezone).subtract(24, 'hour').valueOf();
};

export const dateToTicks = (date) => {
    const epochOffset = 621355968000000000;
    const ticksPerMillisecond = 10000;
    const ticks = date.valueOf() * ticksPerMillisecond + epochOffset;
    return ticks;
};

export const ticksToDate = (ticks) => {
    return Number.isInteger(ticks) ? new Date(ticks / 1e4 + new Date('0001-01-01T00:00:00Z').getTime()) : null;
};
