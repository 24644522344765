import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
    mergeMap,
    delay,
    mergeAll,
    throttleTime,
    map,
    catchError,
    bufferTime,
    switchMap,
    takeUntil,
    filter,
} from 'rxjs/operators';

import { subscribeLevel2Slice, subscribeLevel2Actions } from '.';
import { TOutputFrame } from '../../services/alphapoint/types';

export const subscribeLevel2Epic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime, defaultBufferTime }) =>
    action$.pipe(
        filter(subscribeLevel2Actions.subscribeLevel2.match),
        throttleTime(defaultThrottleTime),
        delay(500),
        mergeMap(() => {
            const bodiesPayload = [];
            const defaultPayload = {
                OMSId: state$.value.companies.current.assets.defaultOMSId,
                Depth: 300,
            };

            if (state$.value.timeline.currentStep?.type === 'dealing') {
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.defaultInstrumentId,
                });
            } else {
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.instrumentBuyId,
                });
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.instrumentSellId,
                });
            }

            /**
             * Essa parte está rerenderizando a tela de negociação,
             * precisamos ver o que está rolando...
             **/
            return bodiesPayload.map((item) =>
                Alphapoint.subscribe(
                    { action: subscribeLevel2Slice.name, ...item },
                    { action: 'UnsubscribeLevel2', ...item },
                    (msg) => msg.n === 'Level2UpdateEvent'
                ).pipe(
                    bufferTime(defaultBufferTime),
                    map((data: TOutputFrame[]) => {
                        const flattenResponse = data.reduce((acc, cur) => [...(cur.data as Array<number>), ...acc], []);
                        return subscribeLevel2Actions.subscribeLevel2Success(flattenResponse);
                    }),
                    catchError(() => of(subscribeLevel2Actions.subscribeLevel2Failed())),
                    takeUntil(action$.pipe(filter(subscribeLevel2Actions.unsubscribeLevel2.match)))
                )
            );
        }),
        mergeAll()
    );

export const unsubscribeLevel2Epic: Epic = (action$, state$, { Alphapoint }) =>
    action$.pipe(
        filter(subscribeLevel2Actions.unsubscribeLevel2.match),
        switchMap(() => {
            const bodiesPayload = [];
            const defaultPayload = {
                OMSId: state$.value.companies.current.assets.defaultOMSId,
            };

            if (state$.value.timeline.currentStep?.type === 'dealing') {
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.defaultInstrumentId,
                });
            } else {
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.instrumentBuyId,
                });
                bodiesPayload.push({
                    ...defaultPayload,
                    InstrumentId: state$.value.companies.current.assets.instrumentSellId,
                });
            }

            return bodiesPayload.map((item) =>
                Alphapoint.subscribe({ action: 'UnsubscribeLevel2', ...item }, {}).pipe(
                    map(() => subscribeLevel2Actions.unsubscribeLevel2())
                )
            );
        })
    );
