import styled from 'styled-components';
import Gravatar from 'react-gravatar';

import { devices } from '../../styles/devices';

export const Avatar = styled(Gravatar)`
    border-radius: 100%;
    margin-right: 10px;
    overflow: hidden;
`;

export const RescuePasswordWrap = styled.span`
    @media ${devices.maxTablet} {
        position: relative;
        max-width: 90%;
        margin: 25px 0 0;
    }
    @media ${devices.minLaptop} {
        position: absolute;
        bottom: 10px;
        right: 10px;
        margin: 15px;
    }
`;
