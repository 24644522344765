import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBlockDisclaimer } from '../../hooks/useRules';
import { MessageBox } from '../MessageBox';

export const UserBlockedDisclaimer = () => {
    const location = useLocation();

    const { blocked, message, type } = useBlockDisclaimer();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const regex = new RegExp('negociacoes');
        setShow(
            (type.includes('cvm88') && regex.test(location.pathname)) || !type.includes('cvm88') || type.length > 1
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return blocked && show ? (
        <MessageBox messageId="userBlocked" messages={[{ message }]} theme="error" close={false} />
    ) : (
        <Fragment></Fragment>
    );
};
