import {
    IconArrow,
    IconCheck,
    IconClose,
    IconWarning,
    IconPlay,
    IconInfo,
    IconComment,
    IconTag,
    IconCash,
    IconTwitter,
    IconFacebook,
    IconInstagram,
    IconLinkedin,
    IconDashedLine,
    IconQuestion,
    IconFilter,
    IconDownload,
} from './createIcons';

export const __ICONS_TYPES = {
    arrow: IconArrow,
    check: IconCheck,
    close: IconClose,
    warning: IconWarning,
    info: IconInfo,
    comment: IconComment,
    tag: IconTag,
    cash: IconCash,
    play: IconPlay,
    twitter: IconTwitter,
    facebook: IconFacebook,
    instagram: IconInstagram,
    linkedin: IconLinkedin,
    dashedLine: IconDashedLine,
    question: IconQuestion,
    filter: IconFilter,
    download: IconDownload,
};
