export const setLS = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
};

export const getLS = (key: string) => {
    return window.localStorage.getItem(key);
};

export const removeLS = (key: string) => {
    window.localStorage.removeItem(key);
};

export const setLSOneDay = (key) => {
    const date = new Date();
    date.setHours(24, 0, 0, 0);

    const item = {
        value: true,
        expiry: date.getTime(),
    };

    localStorage.setItem(key, JSON.stringify(item));
};

export const validateOneDayStorage = (key) => {
    const storaged = localStorage.getItem(key);

    if (storaged) {
        const item = JSON.parse(storaged);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
        }
    }
};
