import { Flex } from '@chakra-ui/react';
import { Icon } from '../../../../../components/Icon';

interface DashedLineProps {
    centered?: boolean;
}

export const DashedLine = ({ centered }: DashedLineProps) => {
    return (
        <Flex justifyContent={centered ? 'center' : 'flex-end'}>
            <Icon
                position="absolute"
                type="dashedLine"
                height="70px"
                width="30px"
                mt="-40px"
                right={!centered && 0}
                mr="-29px"
            />
        </Flex>
    );
};
