import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../config/appConfig';
import { initialState } from './initial';

export const getAccountPositionsSlice = createSlice({
    name: 'GetAccountPositions',
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAccountPositions: (state, action): void => {
            state.error = false;
            state.loading = true;
        },
        getAccountPositionsSuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;
            const { company, data } = action.payload;

            if (
                !!Object.keys(company).length &&
                data.filter((item) => item.ProductId === appConfig._DEFAULT_FIAT_ID).length > 0
            ) {
                const buy = data.find((item) => item.ProductId === appConfig._DEFAULT_FIAT_ID);
                state.positions.buy = {
                    amount: buy.Amount || 0,
                    hold: buy.Hold || 0,
                    operatingLimit: buy.Amount || 0,
                    blockedOperatingLimit: buy.Hold || 0,
                    pendingDeposits: buy.PendingDeposits,
                };
            }

            if (
                !!Object.keys(company).length &&
                data.filter((item) => item.ProductId === company.assets.productSellId).length > 0
            ) {
                const sell = data.find((item) => item.ProductId === company.assets.productSellId);
                state.positions.sell = {
                    tokens: parseInt(sell.Amount) || 0,
                    hold: parseInt(sell.Hold) || 0,
                };
            }
            state.loading = false;
        },
        getAccountPositionsFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
        resetAccountPositions: (state): void => {
            state.positions = initialState.positions;
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAccountValues: (state, action): void => {
            state.error = false;
            state.loading = true;
        },
    },
});

export const getAccountPositionsActions = getAccountPositionsSlice.actions;
export default getAccountPositionsSlice.reducer;
