import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, throttleTime, concatMap, catchError, filter } from 'rxjs/operators';

import { sendOrderSlice, sendOrderActions } from '.';
import { cancelReplaceOrderActions } from '../cancelReplaceOrder';
import { getOrdersHistoryActions } from '../getOrdersHistory';

import blockedUserRules from '../../data/BlockedUserRules/rules.json';

export const sendOrderEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) => {
    return action$.pipe(
        filter(sendOrderActions.sendOrder.match),
        filter(() => {
            return (
                state$.value.user?.userSession?.alphapoint?.accountId &&
                !['unstable', 'closed', 'maintenace'].includes(state$.value.timeline.currentStep?.type)
            );
        }),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
                ...payload,
            };

            /**
             * Valida se o usuário pode realizar a ação
             ***/
            const side = { 0: 'buy', 1: 'sell' };
            if (
                state$.value.user?.userSession?.status.blocked &&
                blockedUserRules.blockedActions[side[payload.Side]].insert.some((item) =>
                    state$.value.user?.userSession?.status.type.includes(item)
                )
            ) {
                return of(sendOrderActions.sendOrderFailed());
            }

            return Alphapoint.subscribe({ action: sendOrderSlice.name, ...body }).pipe(
                concatMap(({ data }) =>
                    of(
                        cancelReplaceOrderActions.resetEditOrder(),
                        sendOrderActions.sendOrderSuccess(data),
                        getOrdersHistoryActions.getOrdersHistory()
                    )
                ),
                catchError(() => of(sendOrderActions.sendOrderFailed()))
            );
        })
    );
};
