import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

import { bee4Theme } from '../../styles/theme';
import { Icon } from '../Icon';

type TMessageCardProps = {
    $theme?: 'warning' | 'none' | 'info' | 'success' | 'error';
};

const themeColor = {
    warning: 'yellow',
    info: 'gray',
    success: 'green',
    error: 'red',
};

export const MessageContainer = styled(Flex)`
    max-height: ${(props) => (props.hidden ? 0 : 600)}px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const MessageCard = styled(Flex)<TMessageCardProps>`
    margin: 15px 0;
    max-width: 656px;
    align-items: center;
    background-image: repeating-linear-gradient(
        45deg,
        ${({ $theme }) => bee4Theme.functions.opacity(bee4Theme.colors[themeColor[$theme]][300], 0.2)},
        ${({ $theme }) => bee4Theme.functions.opacity(bee4Theme.colors[themeColor[$theme]][300], 0.2)} 40px,
        ${({ $theme }) => bee4Theme.functions.opacity(bee4Theme.colors[themeColor[$theme]][100], 0.2)} 40px,
        ${({ $theme }) => bee4Theme.functions.opacity(bee4Theme.colors[themeColor[$theme]][100], 0.2)} 80px
    );
    border-radius: 4px;
    border: 2px solid ${({ $theme }) => bee4Theme.colors[themeColor[$theme]][100]};
    font-size: 0.875rem;
    padding: 15px 30px;

    a {
        &:hover {
            color: ${bee4Theme.colors.white};
            text-decoration: underline !important;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    cursor: pointer;
`;
