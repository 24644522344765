import { Text as TextChakra } from '@chakra-ui/react';
import styled from 'styled-components';
import { devices } from '../../../../../styles/devices';
import { CardBox as CardBoxBee4 } from '../../../../../components/CardBox';

export const Title = styled(TextChakra)`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;

    @media ${devices.maxTablet} {
        font-size: 0.85rem;
    }
`;

export const Text = styled(TextChakra)`
    font-size: 0.88rem;
    font-weight: light;
    margin-top: 0.1rem;

    @media ${devices.maxTablet} {
        font-size: 0.8rem;
    }
`;

export const CardBox = styled(CardBoxBee4)`
    height: 100%;
    display: flex;
    flexdirection: column;
    justifycontent: space-between;
    padding: 16px;
    margin: 10px 0;
`;
