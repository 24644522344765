export type TGetOrdersHistoryPayload = {
    /**
     * Integer. The ID of the Order Management System on which the orders
     * took place. Required. If no other values are specified, the call
     * returns the orders associated with the default account for the
     * logged-in user on this Order Management System.
     */
    omsId?: number;

    /**
     * Integer. The account ID that made the trades. A user with Trading
     * permission must be associated with this account, although other
     * users also can be associated with the account. If no account ID is
     * supplied, the system assumes the default account for the logged-in user.
     */
    AccountId?: number;

    /**
     * long integer. A user-assigned ID for the order (like a purchase-order
     * number assigned by a company). clientOrderId defaults to 0.
     */
    ClientOrderId?: number;

    /**
     * long integer. The original ID of the order. If specified, the call
     * returns changed orders associated with this order ID.
     */
    originalOrderId?: number;

    /**
     * long integer. If the order has been changed, shows the original
     * client order ID, a value that the client can create (much like a purchase order).
     */
    OriginalClientOrderId?: number;

    /**
     * integer. The ID of the user whose account orders will be returned.
     * If not specified, the call returns the orders of the logged-in user.
     */
    UserId?: number;

    /**
     * 	long integer. The ID of the instrument named in the order. If not specified,
     * the call returns orders for all instruments traded by this account.
     */
    InstrumentId?: number;

    /**
     * long integer. Date and time at which to begin the orders history, in POSIX format.
     */
    StartTimestamp?: number;

    /**
     * long integer. Date and time at which to end the orders report, in POSIX format.
     */
    EndTimestamp?: number;

    /**
     * integer. In this case, the count of orders to return, counting from the StartIndex.
     * If not specified, returns all orders between BeginTimeStamp and EndTimeStamp,
     * beginning at StartIndex and working backwards.
     */
    Depth?: number;

    /**
     * integer. The starting index into the order history, from 0 (the most recent trade)
     * and moving backwards in time. If not specified, defaults to 0.
     */
    StartIndex?: number;
};

export type TGetOrdersHistoryItem = {
    /**
     * string. The side of a trade. One of:
     *  0 Buy
     *  1 Sell
     *  2 Short
     *  3 Unknown (an error condition)
     */
    Side: string;

    /**
     * long integer. The ID of the open order.
     * The OrderID is unique in each Order Management System.
     */
    OrderId: number;

    /**
     * real. The price at which the buy or sell has been ordered.
     */
    Price: number;

    /**
     * real. The quantity of the product to be bought or sold.
     */
    Quantity: number;

    /**
     * real. The quantity available to buy or sell that is publicly
     * displayed to the market. To display a displayQuantity value,
     * an order must be a Limit order with a reserve.
     */
    DisplayQuantity?: number;

    /**
     * integer. ID of the instrument being traded. The call
     * GetInstruments can supply the instrument IDs that are available.
     */
    Instrument: number;

    /**
     * 	string. Describes the type of order this is. One of:
     *  0 Unknown (an error condition)
     *  1 Market order
     *  2 Limit
     *  3 StopMarket
     *  4 StopLimit
     *  5 TrailingStopMarket
     *  6 TrailingStopLimit
     *  7 BlockTrade
     */
    Account: number;

    /**
     * integer. An ID supplied by the client to identify the order
     * (like a purchase order number). The ClientOrderId defaults to 0 if not supplied.
     */
    OrderType: string;

    /**
     * integer. An ID supplied by the client to identify the order
     * (like a purchase order number). The ClientOrderId defaults to 0 if not supplied.
     */
    ClientOrderId: number;

    /**
     * string. The current state of the order. One of:
     *  0 Unknown
     *  1 Working
     *  2 Rejected
     *  3 Canceled
     *  4 Expired
     *  5 Fully Executed.
     */
    OrderState: string;

    /**
     * long integer. Time stamp of the order in POSIX format x 1000
     * (milliseconds since 1/1/1970 in UTC time zone).
     */
    ReceiveTime: number;

    /**
     * long integer. Time stamp of the order Microsoft Ticks format and
     * UTC time zone. Note: Microsoft Ticks format is usually provided as a string.
     * Here it is provided as a long integer.
     */
    ReceiveTimeTicks?: number;

    /**
     * real. If the open order has been changed or partially filled,
     * this value shows the original quantity of the order.
     */
    OrigQuantity: number;

    /**
     * real. If the open order has been at least partially executed,
     * this value shows the amount that has been executed.
     */
    QuantityExecuted: number;

    /**
     * 	real. The average executed price for the instrument in the order.
     */
    AvgPrice?: number;

    /**
     * integer. The ID of the other party in an off-market trade.
     */
    CounterPartyId: number;

    /**
     * string. If the order has been changed, this string value holds the reason. One of:
     *  0 Unknown
     *  1 NewInputAccepted
     *  2 NewInputRejected
     *  3 OtherRejected
     *  4 Expired
     *  5 Trade
     *  6 SystemCanceled_NoMoreMarket
     *  7 SystemCanceled_BelowMinimum
     *  8 SystemCanceled_PriceCollar
     *  9 SystemCanceled_MarginFailed
     *  100 UserModified
     */
    ChangeReason?: string;

    /**
     * integer. If the order has been changed, this is the ID of the original order.
     */
    OrigOrderId: number;

    /**
     * integer. If the order has been changed, this is the ID of the original client order ID.
     */
    OrigClOrdId?: number;

    /**
     * integer. The user ID of the person who entered the order.
     */
    EnteredBy?: number;

    /**
     * Boolean. If this order is a quote, the value for IsQuote is true, else false.
     */
    IsQuote?: boolean;

    /**
     * real. If this order is a quote, this value is the Inside Ask price.
     */
    InsideAsk: number;

    /**
     * real. If this order is a quote, this value is the quantity of the Inside Ask quote.
     */
    InsideAskSize: number;

    /**
     * real. If this order is a quote, this value is the Inside Bid price.
     */
    InsideBid: number;

    /**
     * real. If this order is a quote, this value is the quantity of the Inside Bid quote.
     */
    InsideBidSize: number;

    /**
     * real. The last price that this instrument traded at.
     */
    LastTradePrice?: number;

    /**
     * string. If this open order has been rejected, this string holds the reason
     * for the rejection.
     */
    RejectReason?: string;

    /**
     * Boolean. For a block trade, if both parties to the block trade agree that one of
     * the parties will report the trade for both sides, this value is true. Othersise, false.
     */
    IsLockedIn?: boolean;

    /**
     * string. If this order has been canceled, this string holds the cancelation reason.
     */
    CancelReason?: string;

    /**
     * integer. The ID of the Order Management System on which the order took place.
     */
    OMSId: number;
};

export type TGetOrdersHistoryInitialState = {
    updated?: number;
    error: boolean;
    loading: boolean;
    orders: TGetOrdersHistoryItem[];
    pagination: { offset: number; limit?: number };
};

export const statusLabel = {
    FullyExecuted: 'Executada',
    Canceled: 'Cancelada',
    Working: 'Em negociação',
    Expired: 'Expirada',
    Rejected: 'Rejeitada',
    ExecutedAuction: 'Exec. em Leilão',
    CanceledAuction: 'Cancel. em Leilão',
};
