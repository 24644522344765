import { useAppSelector } from './redux';
import blockedUserRules from '../data/BlockedUserRules/rules.json';

type TSideProps = 'buy' | 'sell' | 'withdraw' | string;
type TActionProps = 'insert' | 'edit' | 'cancel';

export type TBlockedResponse = {
    blocked: boolean;
    messages: {
        offer?: string;
        edit?: string;
        withdraw?: string;
    };
};

function HookRules() {
    const { user } = useAppSelector((state) => state);

    const shouldIBlock = (type: TSideProps, action?: TActionProps) => {
        if (!user.userSession.status.blocked) {
            return {
                blocked: user.userSession.status.blocked,
                messages: {
                    offer: '',
                    edit: '',
                    withdraw: '',
                },
            };
        }

        const _type = user.userSession.status.type;
        const _blockedAction = !!action
            ? blockedUserRules.blockedActions[type][action]
            : blockedUserRules.blockedActions[type];

        return {
            blocked: user.userSession.status.blocked && _blockedAction.some((item) => _type.includes(item)),
            messages:
                _type.length > 1
                    ? blockedUserRules?.messages?.general?.tooltip
                    : blockedUserRules?.messages[_type[0]]?.tooltip,
        };
    };

    return shouldIBlock;
}

function HookDisclaimer() {
    const { user } = useAppSelector((state) => state);

    return user.userSession.status.blocked
        ? {
              type: user.userSession.status.type,
              blocked: user.userSession.status.blocked,
              message:
                  user?.userSession?.status?.type.length > 1
                      ? blockedUserRules?.messages?.general?.disclaimer
                      : blockedUserRules?.messages[user.userSession?.status?.type[0]]?.disclaimer,
          }
        : {
              type: [],
              blocked: false,
              message: '',
          };
}

export const useRoles = HookRules;
export const useBlockDisclaimer = HookDisclaimer;
