import appConfig from './appConfig';

/**
 * Como o app está hospedado no S3, usar .env fica complicado
 * Então estou colocando hard code aqui para resolver o problema
 * das variáveis de ambiente.
 */
const amplifyConfig = {
    aws_cognito_region: appConfig.AWS_COGNITO_REGION, //process.env.REACT_APP_BASE_URL,
    aws_user_pools_id: appConfig.AWS_COGNITO_USER_POOL_ID, //process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: appConfig.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID, //process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    aws_cognito_identity_pool_id: appConfig.AWS_COGNITO_USER_IDENTITY_POOL_ID, // process.env.REACT_APP_AWS_COGNITO_USER_IDENTITY_POOL_ID,
};

export default amplifyConfig;
