import { Text, useColorMode, useColorModeValue } from '@chakra-ui/react';

import { Table } from './styles';
import { CardBox } from '../../../../components/CardBox';
import { TWalletSellItem } from '../../../../store/wallet/types';
import { bee4Theme } from '../../../../styles/theme';
import { formatNumberWithoutFraction } from '../../../../utils/numbers';
import { Loading } from '../../../../components/Loading';

type TTokensBalances = {
    tokens: TWalletSellItem[];
    show: boolean;
    loading: boolean;
    title: string;
    width: string | number | string[] | number[];
};

export const TokensBalance = ({ width, tokens, show, title, loading }: TTokensBalances) => {
    const { colorMode } = useColorMode();
    return (
        <CardBox
            $topLine={true}
            width={width}
            padding="30px 0 25px"
            minHeight="216px"
            background={useColorModeValue(
                `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`,
                'lead.200'
            ).toString()}
        >
            <Text fontWeight="700" fontSize=".9rem" padding="0 20px">
                {title}
            </Text>
            <Table $colorMode={colorMode} height={tokens.length > 3 ? '150px' : 'auto'}>
                <thead>
                    <tr>
                        <th>Ativo</th>
                        <th>Total</th>
                        <th>Livre</th>
                        <th>Bloqueado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="mobile-header">
                        <th>Ativo</th>
                        <th>Total</th>
                        <th>Livre</th>
                        <th>Bloqueado</th>
                    </tr>
                    {tokens.map((item) => (
                        <tr key={item.coin}>
                            <td>{item.coin}</td>
                            <td>{!show ? '**********' : formatNumberWithoutFraction(item.tokens)}</td>
                            <td>{!show ? '**********' : formatNumberWithoutFraction(item.tokens - item.hold)}</td>
                            <td>{!show ? '**********' : formatNumberWithoutFraction(item.hold)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Loading active={loading} />
        </CardBox>
    );
};
