import { Container, HStack, Text } from '@chakra-ui/react';

import { Fragment, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useColorMode } from '@chakra-ui/react';

import { MetaTags } from '../../components/MetaTags';
import { PageDescript } from '../../components/PageDescription';
import { PageTitle } from '../../components/PageTitle';
import { ColorfulLabel } from '../../components/ColorfulLabel';
import { Loading } from '../../components/Loading';

import { statusLabel, TGetOrdersHistoryItem } from '../../store/getOrdersHistory/types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { currency } from '../../utils/numbers';
import { date, dateShort, onlyDateDBToBR } from '../../utils/date';
import { DateLabel } from '../../components/TradingTable/styles';
import { getOrdersHistoryActions } from '../../store/getOrdersHistory';
import { getTradesHistoryActions } from '../../store/getTradesHistory';
import { Information } from '../../components/Information';
import { companiesActions } from '../../store/companies';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';
import { Table } from '../../components/TradingTable/table';
import { CardBox } from '../../components/CardBox';

export const MyOrders = () => {
    const infiniteScroll = useRef(null);

    const { colorMode } = useColorMode();
    const dispatch = useAppDispatch();
    const { ordersHistory, companies } = useAppSelector((state) => state);

    let currentDate = '';
    const label = { Buy: 'Compra', Sell: 'Venda' };
    const message =
        'O Preço da oferta corresponde ao valor com o qual a oferta foi registrada. A execução de uma oferta pode ocorrer por um preço igual ou melhor que o definido no momento do envio. Para saber o preço executado, acesse a nota de negociação enviada por e-mail.';

    useEffect(() => {
        dispatch(companiesActions.resetCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getOrdersHistoryActions.getAllOrdersHistory());
        dispatch(getTradesHistoryActions.getTradesHistory());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies.current]);

    useEffect(() => {
        const div = infiniteScroll.current;
        div.addEventListener('scroll', () => {
            if (div.scrollTop + div.clientHeight + 1 >= div.scrollHeight) {
                dispatch(getOrdersHistoryActions.paginateOrdersHistory());
                dispatch(getTradesHistoryActions.getTradesHistory());
            }
        });

        return () => {
            dispatch(getOrdersHistoryActions.resetOrdersHistory());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const status = (order) => {
        const { OrderState, OrigQuantity, QuantityExecuted } = order;

        if (OrderState === 'Working' && !!QuantityExecuted && OrigQuantity > QuantityExecuted) {
            return 'Exe. parcialmente';
        }

        return statusLabel[OrderState];
    };

    const getTickerName = (id: number): string => {
        return companies.assets[id];
    };

    return (
        <main>
            <MetaTags>
                <title>Minhas Ofertas - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <PageTitle>Minhas Ofertas</PageTitle>
                <PageDescript>Acompanhe todas as ofertas de compras e vendas realizadas</PageDescript>

                <UserBlockedDisclaimer />

                <CardBox $topLine={true} width="100%" margin="50px 0" padding="30px 0 0">
                    <HStack spacing={1}>
                        <Text fontWeight="500" fontSize=".9rem" paddingLeft="20px">
                            Minhas ofertas
                        </Text>
                    </HStack>
                    <Table $colorMode={colorMode} width="100%" height="40vh">
                        <thead>
                            <tr className="seven-columns">
                                <th>Operação</th>
                                <th>Ticker</th>
                                <th>Qtd original</th>
                                <th>Qtd executada</th>
                                <th>
                                    <HStack spacing={1} width="130px">
                                        <Text fontSize="0.9rem">Preço da oferta</Text>
                                        <Information label={message} />
                                    </HStack>
                                </th>
                                <th>Status</th>
                                <th>Data/Hora</th>
                            </tr>
                        </thead>
                        <tbody ref={infiniteScroll} style={{ minHeight: '100%' }}>
                            <tr id="mobile-header" className="seven-columns">
                                <th>Operação</th>
                                <th>Ticker</th>
                                <th>Qtd original</th>
                                <th>Qtd executada</th>
                                <th>
                                    <HStack spacing={1} width="130px">
                                        <Text fontSize="0.9rem">Preço da oferta</Text>
                                        <Information label={message} />
                                    </HStack>
                                </th>
                                <th>Status</th>
                                <th>Data/Hora</th>
                            </tr>
                            {!!ordersHistory.orders.length ? (
                                ordersHistory.orders.map((order: TGetOrdersHistoryItem) => {
                                    let showDate = false;

                                    if (
                                        date(new Date(order.ReceiveTime).toISOString()).format('YYYYMMDD') !==
                                        currentDate
                                    ) {
                                        currentDate = date(new Date(order.ReceiveTime).toISOString()).format(
                                            'YYYYMMDD'
                                        );
                                        showDate = true;
                                    }

                                    return (
                                        <Fragment key={`recent-orders-${uuid()}`}>
                                            {showDate && (
                                                <DateLabel $colorMode={colorMode}>
                                                    <td>
                                                        <label>
                                                            {date(order.ReceiveTime, 'x').isSame(new Date(), 'day')
                                                                ? 'Hoje'
                                                                : onlyDateDBToBR(
                                                                      new Date(order.ReceiveTime).toISOString()
                                                                  )}
                                                        </label>
                                                    </td>
                                                </DateLabel>
                                            )}
                                            <tr className="large-row seven-columns">
                                                <td>
                                                    <ColorfulLabel type={order.Side.toLocaleLowerCase()}>
                                                        {label[order.Side]}
                                                    </ColorfulLabel>
                                                </td>
                                                <td>{getTickerName(order.Instrument)}</td>
                                                <td>{order.OrigQuantity}</td>
                                                <td>{order.QuantityExecuted}</td>
                                                <td>{currency(order.Price)}</td>
                                                <td>{status(order)}</td>
                                                <td>{dateShort(order.ReceiveTime, 'x')}</td>
                                            </tr>
                                        </Fragment>
                                    );
                                })
                            ) : ordersHistory.loading ? (
                                <></>
                            ) : (
                                <tr>
                                    <td
                                        colSpan={10}
                                        style={{ maxWidth: 'unset', display: 'table-cell', width: '100%' }}
                                    >
                                        <Text fontSize=".9rem" textAlign="center" textStyle="italic">
                                            Você não possui ofertas
                                        </Text>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr style={{ position: 'absolute', bottom: 0 }}>
                                <td>
                                    <Loading active={ordersHistory.loading} />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </CardBox>
            </Container>
        </main>
    );
};
