import { Tooltip, useColorMode } from '@chakra-ui/react';
import { GradientButtonProps } from '..';
import { Icon } from '../../Icon';

import { BlockedButtonStyled, BlockedLinkStyled } from './styles';

export const BlockedButton = (props: GradientButtonProps) => {
    const { colorMode } = useColorMode();

    return (
        <Tooltip
            hasArrow
            maxWidth="300px"
            background="lead.100"
            placement="top"
            borderRadius="5px"
            color="white"
            fontSize=".7rem"
            textAlign="center"
            padding="10px"
            whiteSpace="pre-wrap"
            label={props.tip}
        >
            {!!props.link ? (
                <BlockedLinkStyled {...props} data-testid={props.id} $colorMode={colorMode}>
                    <Icon type="question" height={4} width={4} />
                    <span style={{ marginLeft: 5 }}>{props.label}</span>
                </BlockedLinkStyled>
            ) : (
                <BlockedButtonStyled {...props} type="button" data-testid={props.id} $colorMode={colorMode}>
                    <Icon type="question" height={4} width={4} />
                    <span style={{ marginLeft: 5 }}>{props.label}</span>
                </BlockedButtonStyled>
            )}
        </Tooltip>
    );
};
