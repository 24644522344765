import { TUserInitialState } from './types';

export const userSession: TUserInitialState = {
    error: false,
    loading: false,
    userData: {
        name: '',
        companyName: '',
        email: '',
        phoneContacts: [],
        accountBankInfos: [],
    },
    userSession: {
        logged: false,
        status: {
            blocked: false,
            type: [],
        },
    },
};
