import { createIcon } from '@chakra-ui/icons';

export const IconPlay = createIcon({
    displayName: 'IconPlay',
    viewBox: '0 0 80 81',
    path: (
        <>
            <path
                d="M40 5.95605C20.6719 5.95605 5 21.619 5 40.9362C5 60.2533 20.6719 75.9162 40 75.9162C59.3281 75.9162 75 60.2533 75 40.9362C75 21.619 59.3281 5.95605 40 5.95605ZM40 69.9821C23.9531 69.9821 10.9375 56.9739 10.9375 40.9362C10.9375 24.8984 23.9531 11.8902 40 11.8902C56.0469 11.8902 69.0625 24.8984 69.0625 40.9362C69.0625 56.9739 56.0469 69.9821 40 69.9821Z"
                fill="currentColor"
            />
            <path
                d="M56.2012 39.9272L33.0684 23.1399C32.8828 23.0039 32.6632 22.9222 32.4339 22.9037C32.2046 22.8852 31.9747 22.9307 31.7697 23.0351C31.5648 23.1396 31.3929 23.2988 31.2732 23.4952C31.1535 23.6915 31.0907 23.9172 31.0918 24.1471V57.7217C31.0918 58.7446 32.2481 59.3224 33.0684 58.729L56.2012 41.9417C56.3609 41.8266 56.491 41.6753 56.5807 41.5002C56.6704 41.3251 56.7172 41.1312 56.7172 40.9344C56.7172 40.7377 56.6704 40.5437 56.5807 40.3686C56.491 40.1935 56.3609 40.0422 56.2012 39.9272V39.9272ZM36.0762 50.39V31.4789L49.0996 40.9344L36.0762 50.39V50.39Z"
                fill="currentColor"
            />
        </>
    ),
});
