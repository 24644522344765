import {
    Container,
    Flex,
    Text,
    Image,
    useColorModeValue,
    FormControl,
    FormErrorMessage,
    useColorMode,
    VStack,
    HStack,
} from '@chakra-ui/react';

import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

import { PageTitle } from '../../components/PageTitle';
import { CardBox } from '../../components/CardBox';
import { PageDescript } from '../../components/PageDescription';
import { GradientButton } from '../../components/GradientButton';
import CurrencyInput from '../../components/CurrencyInput';

import IconInformation from '../../assets/icons/icon-alert.svg';

import { convertStringCurrencyToNumber, currency } from '../../utils/numbers';

import { Form } from './styles';
import { Toast } from '../../components/Toast';
import { MetaTags } from '../../components/MetaTags';
import { Breadcrumb } from '../../components/Breadcrumb';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { getInstituteName } from '../../utils/finalcialInstitution';
import { getAccountPositionsActions } from '../../store/getAccountPositions';

import { requestWithdraw } from '../../services/balance.api';
import { ModalConfirm } from './Components/ModalConfirm';
import { subscribeAccountEventsActions } from '../../store/subscribeAccountEvents';
import { walletActions } from '../../store/wallet';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';
import { TBlockedResponse, useRoles } from '../../hooks/useRules';

export const Withdraw = () => {
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const dispatch = useAppDispatch();
    const shouldIBlocked = useRoles();

    const { colorMode } = useColorMode();
    const { user, wallet } = useAppSelector((state) => state);

    const inportantColor = useColorModeValue('lead.500', 'gray.200');
    const minWithdraw = 0;

    const [roles, setRoles] = useState<TBlockedResponse>({} as TBlockedResponse);
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [showBalance, setShowBalance] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [formData, setFormData] = useState(null);
    const [doRequire, setDoRequire] = useState(null);

    useEffect(() => {
        if (user.userSession.alphapoint.token === null) return;

        dispatch(walletActions.getWalletData());
        dispatch(subscribeAccountEventsActions.subscribeAccountEvents());

        return () => {
            dispatch(getAccountPositionsActions.resetAccountPositions());
            setRoles({} as TBlockedResponse);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRoles(shouldIBlocked('withdraw'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userSession]);

    useEffect(() => {
        const { buy } = wallet.positions;
        setBalance(() => buy.amount - buy.hold);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet]);

    useEffect(() => {
        doRequire && handleRequireWithdraw(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doRequire]);

    const handleShowBalance = () => {
        setShowBalance(() => !showBalance);
    };

    const handleRequireWithdraw = async (value) => {
        setIsSubmiting(true);

        if (roles.blocked) {
            Toast({
                id: 'RequestFailed',
                title: `Falha na solicitação!`,
                description: roles.messages.withdraw,
                status: 'error',
            });
            setIsSubmiting(false);
            return;
        }

        /** Convert Currency String to Decimal */
        value.amount = Number(value.amount.replace(/[^\d,]/gi, '').replace(/[,]/gi, '.'));
        const response = await requestWithdraw({
            taxId: user.userData.taxId,
            value: value.amount,
        });

        if (!!response && /\b(?:4[0-9]{2}|5[0-4][0-9])\b/gm.test(response['statusCode'])) {
            Toast({
                id: 'RequestFailed',
                title: `Falha na solicitação!`,
                description:
                    'Infelizmente não conseguimos realizar a solicitação de retirada, tente novamente mais tarde.',
                status: 'error',
            });
            setIsSubmiting(false);
            return;
        }

        Toast({
            id: 'RequestSuccess',
            title: `Sucesso!`,
            description: 'Sua solicitação foi enviada com sucesso!',
            status: 'success',
        });

        reset();
        // Artifício técnico para o bug do reset React Currency Field
        setAmount('');
        reset({ amount: null });

        setDoRequire(false);
        setConfirm(false);
        setFormData(null);
        setIsSubmiting(false);
        return;
    };

    const checkValue = (amount: string) => {
        return convertStringCurrencyToNumber(amount) > minWithdraw && convertStringCurrencyToNumber(amount) <= balance;
    };

    const confirmWithdraw = (value) => {
        setConfirm(!roles.blocked);
        setFormData(value);
    };

    return (
        <main>
            <MetaTags>
                <title>Solicitação de Retirada - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%" paddingBottom="40px">
                <ModalConfirm
                    isOpen={confirm}
                    onClose={() => setConfirm(false)}
                    action={() => setDoRequire(true)}
                    isSubmiting={isSubmiting}
                />

                <Breadcrumb />
                <PageTitle>Retirada</PageTitle>
                <PageDescript>Defina o valor que deseja transferir para sua conta bancária.</PageDescript>

                <UserBlockedDisclaimer />

                <Flex width="100%" flexFlow="row wrap" position="relative" margin="50px 0 0" alignItems="start">
                    <CardBox width="100%" height="100%" maxWidth="410px" margin="15px 0" $topLine={true} padding="20px">
                        <Text fontWeight="600" fontSize="1.5rem">
                            Saldo disponível para retirada
                        </Text>
                        <Flex
                            width="190px"
                            alignItems="center"
                            justifyContent="space-between"
                            background="green.100"
                            padding="10px 15px"
                            margin="15px 25px"
                            borderRadius="4px"
                            userSelect="none"
                            color={useColorModeValue('white', 'white')}
                        >
                            {showBalance ? (
                                <>
                                    {roles.blocked
                                        ? currency(0)
                                        : currency(wallet.positions.buy.amount - wallet.positions.buy.hold)}
                                    <ViewOffIcon cursor="pointer" onClick={handleShowBalance} />
                                </>
                            ) : (
                                <>
                                    R$ *************
                                    <ViewIcon cursor="pointer" onClick={handleShowBalance} />
                                </>
                            )}
                        </Flex>
                    </CardBox>
                </Flex>

                <PageTitle>Solicitar retirada</PageTitle>
                <Form $colorMode={colorMode} noValidate onSubmit={handleSubmit(confirmWithdraw)}>
                    <Text fontWeight="500" fontSize="1rem">
                        Dados Bancários
                    </Text>

                    <VStack spacing={1} alignItems="start" margin="25px 0">
                        <Text fontSize="0.85rem">
                            <strong>Banco: </strong>
                            {`${user.userData.accountBankInfos[0]?.bankNumber || ''} - ${getInstituteName(
                                user.userData.accountBankInfos[0]?.bankNumber || ''
                            )}`}
                        </Text>
                        <Text fontSize="0.85rem">
                            <strong>Agência: </strong>
                            {user.userData.accountBankInfos[0]?.branch}
                        </Text>
                        <Text fontSize="0.85rem">
                            <strong>Conta corrente: </strong>
                            {`${user.userData.accountBankInfos[0]?.checkingAccount}-${user.userData.accountBankInfos[0]?.accountDigit}`}
                        </Text>
                    </VStack>

                    <FormControl isInvalid={errors.amount}>
                        <CurrencyInput
                            id="amount"
                            ref={useRef()}
                            placeholder="Valor"
                            value={amount}
                            $colorMode={colorMode}
                            onValueChange={(_value: string) => setAmount(_value)}
                            aria-invalid={!!errors.amount}
                            {...register('amount', {
                                required: true,
                                validate: checkValue,
                            })}
                        />
                        {errors.amount?.type === 'required' ? (
                            <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                        ) : errors.amount?.type === 'validate' && balance > 0 ? (
                            <FormErrorMessage>
                                O Valor precisa ser maior que {currency(minWithdraw)} e menor que {currency(balance)}.
                            </FormErrorMessage>
                        ) : (
                            <FormErrorMessage>
                                Você não possui saldo para retiradas ({currency(balance)}).
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <Flex alignItems="center" justifyContent="center" direction="column" margin="15px 0">
                        <GradientButton
                            width="80%"
                            maxWidth="320px"
                            id="requestWithdraw"
                            data-testid="requestWithdraw"
                            label={isSubmiting ? 'Aguarde...' : 'Solicitar retirada'}
                            type="submit"
                            isBlocked={roles.blocked}
                            tip={roles.blocked && roles.messages.withdraw}
                            variant="colorful"
                        />
                    </Flex>
                </Form>

                <Flex width="100%" maxWidth="500px" position="relative" margin="10px 0 30px" direction="column">
                    <Text fontWeight="700" fontSize=".9rem" paddingLeft="50px">
                        IMPORTANTE
                    </Text>
                    <HStack spacing={0} margin="10px 0">
                        <Image
                            src={IconInformation}
                            width="80px"
                            margin="10px"
                            filter={useColorModeValue('invert(0%)', 'invert(100%)')}
                        />
                        <Text fontFamily="Inter, sans-serif" fontWeight="400" fontSize=".85rem" color={inportantColor}>
                            Ao solicitar uma retirada o valor solicitado será debitado de seu{' '}
                            <strong>&#34;Saldo livre&#34;</strong> e transferido para{' '}
                            <strong>&#34;Saldo bloqueado&#34;</strong>. O mesmo estará{' '}
                            <strong>disponível em sua conta em até 2 dias úteis</strong>.
                        </Text>
                    </HStack>
                </Flex>
            </Container>
        </main>
    );
};
