import { useEffect, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';

import { date } from '../../../../../utils/date';
import { Icon } from '../../../../../components/Icon';

import { Table, Download } from './style';
import { Loading } from '../../../../../components/Loading';
import { TTradingInvoices } from '../../../../../store/wallet/types';
import { getInvoiceFile } from '../../../../../services/invoice-pdf.api';
import { Toast } from '../../../../../components/Toast';

type TTradingInvoicesTable = {
    data: TTradingInvoices[];
    loading?: boolean;
    emptyMessage?: string;
    limit?: number;
};

export const TradingInvoicesTable = ({ data, loading, emptyMessage, limit }: TTradingInvoicesTable) => {
    const { colorMode } = useColorMode();
    const [invoices, setInvoices] = useState<TTradingInvoices[]>([]);
    const [charging, setCharging] = useState(loading);

    useEffect(() => {
        const _data = !!limit ? data.filter((_, index) => index < limit) : data;
        setInvoices(() => _data);
        return () => {
            setInvoices(() => []);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => setCharging(() => loading), [loading]);

    const downloadFile = async (id: string) => {
        setCharging(true);
        const data = await getInvoiceFile(id);

        if (data?.save) {
            const buffer = new Uint8Array(data['file']['data']);
            const file = new Blob([buffer], { type: 'application/pdf' });
            const _url = URL.createObjectURL(file);
            window.open(_url, 'target="_blank"');
            setCharging(false);
        } else {
            Toast({
                id: 'downloadFail',
                title: `Falha ao acessar o arquivo`,
                description: 'Houve um erro ao tentar baixar o arquivo.',
                status: 'error',
            });
            setCharging(false);
        }
    };

    return (
        <Table $colorMode={colorMode}>
            <thead>
                <tr>
                    <th>Nº da nota</th>
                    <th>Data do pregão</th>
                    <th>Download</th>
                </tr>
            </thead>
            <tbody>
                <tr id="mobile-header">
                    <th>Nº da nota</th>
                    <th>Data do pregão</th>
                    <th>Download</th>
                </tr>
                {invoices.length ? (
                    invoices.map((item) => (
                        <tr key={item.id}>
                            <th>{item.id}</th>
                            <td>{date(item.trading_session_date).utc().format('DD/MM/YYYY')}</td>
                            <td>
                                <Download onClick={() => downloadFile(item.id)} style={{ cursor: 'pointer' }}>
                                    <Icon type="download" height={7} width={7} />
                                </Download>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={3}
                            style={{ width: '100%', maxWidth: 'none', textAlign: 'center', fontSize: '.9rem' }}
                        >
                            {!charging && (emptyMessage || 'Não há notas de negociação.')}
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr style={{ position: 'absolute', bottom: 0 }}>
                    <td>
                        <Loading active={charging} />
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
};
