import { Tooltip, useColorModeValue, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

import IconInformation from '../../assets/icons/icon-information-filled.svg';
import IconQuestion from '../../assets/icons/icon-question-dark.svg';

type TTolltipProps = {
    label: string;
    type?: 'information' | 'question';
    children?: ReactNode;
};

export const Information = ({ label, type, children }: TTolltipProps) => {
    const imageFilter = useColorModeValue('invert(75%)', 'invert(0)');

    return (
        <Tooltip
            hasArrow
            maxWidth="200px"
            background="lead.100"
            placement="top"
            borderRadius="5px"
            color="white"
            fontSize=".7rem"
            textAlign="center"
            padding="10px"
            whiteSpace="pre-wrap"
            label={label}
        >
            {!!children ? (
                children
            ) : (
                <Image
                    src={type === 'question' ? IconQuestion : IconInformation}
                    width="16px"
                    height="16px"
                    filter={imageFilter}
                />
            )}
        </Tooltip>
    );
};
