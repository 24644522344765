import { ajax } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { takeUntil, delay, throttleTime, filter, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { EMPTY, interval, merge, of } from 'rxjs';

import { timelineActions } from './index';
import { clockActions } from '../clock';
import { getToken } from '../../utils/token';
import appConfig from '../../config/appConfig';
import { defaultResponse } from './initial';
import { date } from '../../utils/date';
import { subscribeLevel1Actions } from '../subscribeLevel1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import debugTimelineDynamo from '../../data/Timeline/timeline-dynamo.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TTimelineAPIPayload, WeekDayType } from './types';

// Ajax Request
const requestTimelineData = (state$) => {
    return ajax({
        url: `${appConfig.API_URL}config/platform/step/${state$.value.companies.current.assets.ticker}`,
        crossDomain: true,
        method: 'GET',
        headers: { Authorization: getToken() },
    }).pipe(
        mergeMap(({ response }) => {
            const _resp = JSON.parse(response['body']) as TTimelineAPIPayload;

            /** Test/Debug **
            const _day = '2023-03-15';
            const _time = '23:59:03';
            // const _time = date().format('HH:mm:ss');
            const _step = 0;
            const _closed = true;
            const _weekDay = 'wed';
            const _date = date(`${_day} ${_time}`, 'YYYY-MM-DD HH:mm:ss');
            // _resp.allWeekEvents = [
            //     {
            //         category: 'national',
            //         date: '01/03',
            //         title: 'Ano novo',
            //     },
            // ];
            _resp.currentStep =
                _date.isSameOrBefore(date(`${_day} 20:00:00`, 'YYYY-MM-DD HH:mm:ss')) && !_closed
                    ? (debugTimelineDynamo.schedules[_weekDay][_step] as WeekDayType)
                    : (debugTimelineDynamo.closed as WeekDayType);
            _resp.time = _date.valueOf();
            /***********/

            if (/\b(?:4[0-9]{2}|5[0-4][0-9])\b/gm.test(response['statusCode'].toString())) {
                return merge(
                    of(timelineActions.failedGetTimeline({ error: _resp['message'] })),
                    of(timelineActions.setTimelineData(defaultResponse)),
                    of(clockActions.setClock(date().valueOf()))
                );
            }
            const action$ =
                JSON.stringify(state$.value.timeline.currentStep) !== JSON.stringify(_resp.currentStep)
                    ? of(timelineActions.setTimelineData(_resp))
                    : merge(
                          of(clockActions.setClock({ time: _resp.time, date: _resp.date })),
                          of(timelineActions.setLoadingFalse())
                      );

            const level1$ =
                state$.value.subscribeLevel1.tunnel.reference !== _resp.currentStep.referencePrice
                    ? of(subscribeLevel1Actions.subscribeLevel1TunnelLimiters(_resp))
                    : EMPTY;

            return merge(action$, level1$);
        }),
        catchError((error) => {
            if (error.status === 401) {
                location.reload();
                return merge(
                    of(timelineActions.setTimelineData(defaultResponse)),
                    of(clockActions.setClock(date().valueOf()))
                );
            }

            return merge(
                of(timelineActions.failedGetTimeline(error)),
                of(timelineActions.setTimelineData(defaultResponse)),
                of(clockActions.setClock(date().valueOf()))
            );
        })
    );
};

// Polling da timeline
export const subscribeTimelineEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(timelineActions.getTimelineData.match),
        throttleTime(defaultThrottleTime),
        delay(1000),
        switchMap(() =>
            interval(1300).pipe(
                switchMap(() => requestTimelineData(state$)),
                takeUntil(action$.pipe(filter(timelineActions.resetTimeline.match)))
            )
        )
    );

// Pega realiza somente 1 requisição para os dados da timeline
export const getNowTimelineEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(timelineActions.getNowTimelineData.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => requestTimelineData(state$))
    );
