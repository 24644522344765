import { Text } from '@chakra-ui/react';

interface PageDescriptProps {
    children: unknown;
}

export const PageDescript = ({ children }: PageDescriptProps): JSX.Element => {
    return (
        <Text fontSize="1.5rem" fontWeight="300" marginTop={13} marginBottom={15}>
            {children}
        </Text>
    );
};
