import styled from 'styled-components';

import { bee4Theme } from './../../styles/theme';
import { devices } from '../../styles/devices';
import { Flex, HStack } from '@chakra-ui/react';
import { __VIDEO_WIDTH } from '../../components/YoutubeEmbed/constants';

interface BoxDownloadsProps {
    $colorMode?: string;
}

export const Logo = styled.figure`
    margin: 0 0 10px 0;

    overflow: hidden;
    position: relative;

    border-radius: 8px;
    background-color: ${bee4Theme.colors.white};

    img {
        width: 85%;
        height: 85%;

        position: absolute;
        object-fit: contain;
        object-position: center;

        z-index: 0;
        display: block;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    @media ${devices.maxTablet} {
        width: 260px;
        height: 260px;
    }
    @media ${devices.minLaptop} {
        width: 180px;
        height: 180px;
    }

    &::before {
        content: '';

        position: absolute;
        border-radius: 8px;
        z-index: 2;

        inset: -1px;
        padding: 4px;

        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

export const BoxDownloads = styled.div<BoxDownloadsProps>`
    width: 100%;

    display: flex;
    overflow-y: auto;
    flex-direction: column;

    p {
        margin: 0 !important;
    }
    &::-webkit-scrollbar {
        width: 5px;
        height: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: ${(props) =>
            props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
    }

    scrollbar-color: ${(props) => (props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)')}
        transparent;
    scrollbar-width: thin;
`;

export const DonwloadItem = styled.a`
    width: 100%;
    border-bottom: 2px solid ${bee4Theme.colors.lead[100]};

    padding: 15px 0;
    position: relative;

    cursor: pointer;
    transition: all 0.5s ease-in-out;

    & > svg {
        position: absolute;
        top: 50%;
        right: 20px;

        transform: translateY(-50%);

        transition: all 0.5s ease-in-out;
    }
    &:hover {
        color: inherit !important;
        & > svg {
            color: ${bee4Theme.colors.blue[200]} !important;
        }
    }
`;

export const HStackStyled = styled(HStack)`
    @media ${devices.maxLaptop} {
        flex-direction: column;
    }
`;

// important para sobreescrever spacing do tema no mobile
export const VideoContainer = styled(Flex)`
    @media ${devices.maxLaptop} {
        width: ${__VIDEO_WIDTH}px;
        margin: 20px 0 !important;
        justify-content: center;
    }

    @media ${devices.maxMobileL} {
        max-width: 100%;
        margin: 20px 0 !important;
        min-height: calc(100% - 22px);
    }
`;
