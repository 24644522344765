import { createIcon } from '@chakra-ui/icons';

export const IconDashedLine = createIcon({
    displayName: 'IconDashedLine',
    viewBox: '0 0 24 24',
    path: (
        <line
            x1="0.5"
            y1="0.5"
            x2="0.500002"
            y2="39.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeDasharray="2 2"
        />
    ),
});
