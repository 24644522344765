import { createIcon } from '@chakra-ui/icons';

export const IconComment = createIcon({
    displayName: 'IconComment',
    viewBox: '0 0 24 23',
    path: (
        <>
            <path
                d="M13.3405 9.56244C12.7351 9.56244 12.2659 10.0419 12.2659 10.6339C12.2659 11.2258 12.7351 11.7053 13.3405 11.7053C13.8935 11.7053 14.3626 11.2258 14.3626 10.6339C14.3626 10.0419 13.8935 9.56244 13.3405 9.56244ZM6.00212 9.56244C5.39671 9.56244 4.92757 10.0419 4.92757 10.6339C4.92757 11.2258 5.39671 11.7053 6.00212 11.7053C6.55512 11.7053 7.02425 11.2258 7.02425 10.6339C7.02425 10.0419 6.55512 9.56244 6.00212 9.56244Z"
                fill="currentColor"
            />
            <path
                d="M21.7527 7.52464C20.5401 5.81877 18.7916 4.58964 16.7993 4.04249V4.04517C16.3511 3.53624 15.8453 3.06749 15.2792 2.64964C10.9888 -0.537864 4.96615 0.434457 1.83424 4.81928C-0.689632 8.38178 -0.582177 13.1737 1.99149 16.5782L2.01246 20.13C2.01246 20.2157 2.02556 20.3014 2.05177 20.3818C2.08474 20.4891 2.13809 20.5888 2.20876 20.675C2.27944 20.7612 2.36607 20.8324 2.46368 20.8844C2.56129 20.9364 2.66798 20.9682 2.77763 20.978C2.88729 20.9878 2.99776 20.9755 3.10273 20.9416L6.42072 19.8729C7.2987 20.1916 8.20551 20.3737 9.10708 20.4246L9.09398 20.4354C11.4292 22.1737 14.4903 22.6961 17.2972 21.7479L20.6283 22.8568C20.7122 22.8836 20.7987 22.8996 20.8878 22.8996C21.3517 22.8996 21.7264 22.5166 21.7264 22.0425V18.4532C24.0354 15.2496 24.0957 10.822 21.7527 7.52464ZM6.78764 17.9711L6.47313 17.8371L3.8785 18.6675L3.85229 15.8818L3.64262 15.6407C1.42539 12.8764 1.27862 8.89339 3.35433 5.97106C5.88082 2.42999 10.7241 1.64785 14.1784 4.20321C17.6432 6.77731 18.4111 11.7193 15.9082 15.2389C13.8089 18.1827 10.0506 19.2702 6.78764 17.9711ZM20.0229 17.5157L19.8132 17.7836L19.8394 20.5693L17.271 19.6854L16.9565 19.8193C15.4888 20.3764 13.9242 20.422 12.4749 20.0068L12.4696 20.0041C14.4615 19.3798 16.2015 18.1119 17.4283 16.3907C19.4306 13.5702 19.7556 10.0264 18.5919 7.00499L18.6076 7.01571C19.2104 7.45767 19.7634 8.00946 20.2326 8.67642C22.1353 11.3443 22.0278 14.9764 20.0229 17.5157Z"
                fill="currentColor"
            />
            <path
                d="M9.6713 9.56244C9.06589 9.56244 8.59676 10.0419 8.59676 10.6339C8.59676 11.2258 9.06589 11.7053 9.6713 11.7053C10.2243 11.7053 10.6934 11.2258 10.6934 10.6339C10.6934 10.0419 10.2243 9.56244 9.6713 9.56244Z"
                fill="currentColor"
            />
        </>
    ),
});
