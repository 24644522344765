import { Text, useColorMode, HStack } from '@chakra-ui/react';
import { CustomProvider, DateRangePicker } from 'rsuite';

import { Button } from '../../../../../components/Button';
import { Icon } from '../../../../../components/Icon';

import 'rsuite/dist/rsuite.min.css';
import ptBR from 'rsuite/locales/pt_BR';

import { Form, DateRangePickerWrapper, DateRangePickerProvider } from './styles';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { walletActions } from '../../../../../store/wallet';
import { useEffect, useState } from 'react';

type TTradingInvoicesFilterProps = {
    id: string;
    target?: 'modal' | 'self';
    openModal?: (param: any) => void;
    action?: (param: any) => void;
};

export const TradingInvoicesFilter = (props: TTradingInvoicesFilterProps) => {
    const { id, target = 'self' } = props;

    const { colorMode } = useColorMode();
    const { wallet } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const [dateRange, setDateRange] = useState<DateRange>(wallet.invoices.dateRange);

    useEffect(() => {
        !!dateRange && dispatch(walletActions.setTradingInvoicesFilter(dateRange));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    const handleFilter = (event) => {
        event.preventDefault();
        target === 'modal' && dispatch(walletActions.openModal(true));
        !!dateRange?.length && dispatch(walletActions.filterTradingInvoices({}));
    };

    return (
        <Form id={`form-${id}`} noValidate onSubmit={handleFilter}>
            <HStack spacing={1}>
                <Icon type={'filter'} width="19px" height="19px" />
                <Text fontWeight={500} fontSize="1rem">
                    Filtro por período:
                </Text>
            </HStack>

            <DateRangePickerWrapper>
                <DateRangePickerProvider $colorMode={colorMode} />
                <CustomProvider locale={ptBR}>
                    <DateRangePicker
                        id={id}
                        cleanable={true}
                        appearance="default"
                        size="md"
                        character="-"
                        placeholder="Início - Fim"
                        format="dd/MM/yyyy"
                        value={wallet.invoices.dateRange}
                        defaultValue={wallet.invoices.dateRange}
                        onChange={(value) => setDateRange(() => value)}
                        onClean={() => {
                            setDateRange(null);
                            dispatch(walletActions.reloadTradingInvoicesFilter());
                        }}
                    />
                </CustomProvider>
            </DateRangePickerWrapper>

            <Button colorScheme={'green'} type={'submit'} width="100px" padding="10px">
                <Text fontWeight={500} textTransform="uppercase">
                    Filtrar
                </Text>
            </Button>
        </Form>
    );
};
