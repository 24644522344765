import { HStack, Text } from '@chakra-ui/react';

import { Modal } from '../../../../../components/Modal';
import { TradingInvoicesFilter } from '../Filter';
import { TradingInvoicesTable } from '../Table';

import { Pagination } from '../../../../../components/Pagination';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { walletActions } from '../../../../../store/wallet';
import appConfig from '../../../../../config/appConfig';

export const TradingInvoicesModal = () => {
    const dispatch = useAppDispatch();
    const { wallet } = useAppSelector((state) => state);

    useEffect(() => {
        !wallet.invoices?.dateRange?.length &&
            wallet.invoices.modal.open &&
            dispatch(walletActions.getTradingInvoices());

        return () => {
            dispatch(walletActions.resetTradingInvoices());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet.invoices.modal.open]);

    return (
        <Modal
            isOpen={wallet.invoices.modal.open}
            onClose={() => {
                dispatch(walletActions.resetTradingInvoicesFilter());
                dispatch(walletActions.openModal(false));
            }}
            motionPreset="slideInBottom"
            size="4xl"
            modalTitle="Notas Listadas"
            topLine={true}
        >
            <Text fontWeight={700} fontSize="1rem">
                Confira todas as notas de negociação ou filtre por período
            </Text>
            <HStack spacing={0} width="100%" margin="0px 0 5px">
                <Text fontWeight={500} fontSize=".7rem" padding="0" color="gray.200">
                    Algumas notas de negociação requerem senha. Para abri-las, utilize os 5 primeiros dígitos do seu CPF
                    como senha.
                </Text>
            </HStack>

            <TradingInvoicesFilter id="modal-filter" />
            <TradingInvoicesTable
                data={wallet.invoices.data}
                loading={wallet.invoices.modal.loading}
                emptyMessage={
                    !!wallet.invoices?.dateRange?.length
                        ? 'Não há notas de negociação para o período especificado'
                        : 'Não há notas de negociação'
                }
            />
            <Pagination
                currentPage={wallet.invoices.pagination.currentPage}
                totalItems={wallet.invoices.pagination.totalItems}
                pageSize={appConfig.INVOICES_PER_PAGE}
                action={(page) => dispatch(walletActions.changePage({ page }))}
            />
        </Modal>
    );
};
