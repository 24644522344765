import { bee4Theme } from '../../styles/theme';
import { Box, HStack } from '@chakra-ui/react';
import styled from 'styled-components';
import { devices } from '../../styles/devices';

interface MessagesContainerProps {
    $colorMode: string;
}
interface DotProps {
    status: boolean;
}
interface ButtonProps {
    className?: string;
}
type TElement = {
    title?: string;
};

export const MessagesContainer = styled(Box)<MessagesContainerProps>`
    padding: 20px 0;

    background: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300])};
    visibility: hidden;

    transition: all 0.5s ease-in-out;
    position: absolute;

    opacity: 0;
    transform: translateX(-50%);

    border-radius: 1px 8px 8px 8px;
    box-sizing: border-box;

    @media ${devices.maxTablet} {
        width: 350px;
        height: 450px;

        top: 50px;
        left: -70px;
    }
    @media ${devices.minLaptop} {
        width: 400px;
        height: 450px;

        top: 50px;
        left: -30px;
    }

    &::before {
        content: '';
        position: absolute;

        top: -4px;
        left: 0;

        width: 45%;
        height: 4px;

        border-radius: 10px;
        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});
    }
    &::after {
        content: '';

        width: 0;
        height: 0;

        position: absolute;

        border-style: solid;
        border-width: 0 13px 15px 13px;
        border-color: transparent transparent
            ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300])}
            transparent;

        @media ${devices.maxTablet} {
            top: -15px;
            right: 78px;
        }
        @media ${devices.minLaptop} {
            top: -15px;
            right: 145px;
        }
    }
`;

export const Message = styled(HStack)<TElement>`
    width: 100%;
    height: auto;

    padding: 15px 10px;

    border-bottom: 1px solid ${bee4Theme.colors.lead[100]};

    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
        background: ${(props) =>
            props.$colorMode === 'dark' ? bee4Theme.colors.lead[100] : bee4Theme.colors.gray[600]};
        color: ${bee4Theme.colors.white};
    }
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    max-height: 320px;

    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        height: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 15px;
    }

    scrollbar-color: rgba(255, 255, 255, 0.4) transparent;
    scrollbar-width: thin;
`;

export const BellContainer = styled.span`
    position: relative;

    &:hover ${MessagesContainer} {
        visibility: visible;
        opacity: 1;
    }
`;

export const Dot = styled.span<DotProps>`
    width: 8px;
    height: 8px;

    border-radius: 100%;
    border: 0;

    background: ${(props) => (props.status ? bee4Theme.colors.gray[500] : bee4Theme.colors.blue[100])};
`;

export const Button = styled.button<ButtonProps>`
    padding: 2px 15px;

    border: 0;
    border-radius: 50px;

    background: ${bee4Theme.colors.gray[500]};
    transition: all 0.3s ease-in-out;

    font-weight: 400;
    font-size: 0.7rem;

    text-transform: uppercase;
    color: ${bee4Theme.colors.white};

    &:hover {
        background: ${bee4Theme.colors.blue[200]};
    }

    &.active {
        background: ${bee4Theme.colors.blue[200]};
        font-weight: 700;
    }
`;

export const HasMessage = styled.span`
    width: 10px;
    height: 10px;

    background: ${bee4Theme.colors.red[100]};
    border-radius: 100%;
    overflow: hidden;

    position: absolute;
    top: 0;
    right: 0;
`;
