import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial';
import { date } from '../../utils/date';
import appConfig from '../../config/appConfig';
import { TGetOrdersHistoryItem } from './types';
import { TGetTradesHistoryInitialState } from '../getTradesHistory/types';

const sort = (_arr) => {
    if (!!_arr && !!_arr.length) {
        const dateconvert = (_date: string) => parseInt(date(_date).format('YYYYMMDDHHmmss'));
        return _arr.slice().sort((a, b) => dateconvert(b.ReceiveTime) - dateconvert(a.ReceiveTime));
    }

    return _arr;
};

const auctionOrderState = (orders: TGetOrdersHistoryItem[], tradesHistory: TGetTradesHistoryInitialState) => {
    const _ordersByTrades = [];

    tradesHistory.trades.map((_order) => {
        _order.ClientOrderId.toString().charAt(0) === '9' &&
            _ordersByTrades.push({
                Price: _order.Price,
                OrigQuantity: _order.Quantity,
                OrigOrderId: _order.OrderId,

                Side: _order.Side,
                OrderId: _order.OrderId,
                Quantity: _order.Quantity,
                Instrument: _order.InstrumentId,
                Account: _order.AccountId,
                OrderType: _order.OrderType,
                ClientOrderId: _order.ClientOrderId,
                QuantityExecuted: _order.Quantity,
                InsideAsk: _order.InsideAsk,
                InsideAskSize: _order.InsideAskSize,
                InsideBid: _order.InsideBid,
                InsideBidSize: _order.InsideBidSize,
                OMSId: _order.OMSId,
                ReceiveTime: _order.TradeTimeMS,

                ExecutionId: _order.ExecutionId,
                TradeId: _order.TradeId,

                CounterPartyId: parseInt(_order.CounterParty),
                OrderState: 'ExecutedAuction',
            });
    });

    // Ordena por data (ReceiveTime)
    const _sorted = sort([...orders, ..._ordersByTrades]);

    // Remove duplicidades de ordens com base no ExecutionId, OrderID, Quantity e Price.
    return _sorted.filter(
        (value, index, self) =>
            index ===
            self.findIndex(
                (t) =>
                    t.ReceiveTime === value.ReceiveTime &&
                    t.OrderId === value.OrderId &&
                    t.Quantity === value.Quantity &&
                    t.ExecutionId === value.ExecutionId
            )
    );
};

export const getOrdersHistorySlice = createSlice({
    name: 'GetOrdersHistory',
    initialState,
    reducers: {
        getOrdersHistory: (state): void => {
            state.error = false;
            state.loading = true;
            state.orders = initialState.orders;
        },
        getAllOrdersHistory: (state): void => {
            state.error = false;
            state.loading = true;
            state.orders = initialState.orders;
        },
        getOrdersHistorySuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;

            // Concat and remove duplicate Orders by ReceiveTime, OrderId and Quantity.
            state.orders = [...action.payload.orders, ...state.orders].filter(
                (value, index, self) =>
                    index ===
                    self.findIndex(
                        (t) =>
                            t.ReceiveTime === value.ReceiveTime &&
                            t.OrderId === value.OrderId &&
                            t.Quantity === value.Quantity
                    )
            );

            const _orders = auctionOrderState(state.orders, action.payload.trades);

            if (!!appConfig.DATE_START_LIST_ORDERS) {
                const _start = date(appConfig.DATE_START_LIST_ORDERS, 'YYYY-MM-DD HH:mm:ss');
                const _filtered = _orders.filter((item) => date(item.ReceiveTime, 'x').isSameOrAfter(_start));
                state.orders = _filtered;
            } else {
                state.orders = _orders;
            }

            state.loading = false;
        },
        updateItemOrdersHistory: (state, action): void => {
            state.orders = state.orders.filter((item) => item.OrderId !== action.payload.OrderId);
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },
        getOrdersHistoryFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
        paginateOrdersHistory: (state): void => {
            state.error = false;
            state.loading = true;
            state.pagination = {
                ...state.pagination,
                offset: state.pagination.offset + appConfig.REGISTER_PER_PAGE + 1,
            };
        },
        resetOrdersHistory: (state): void => {
            state.error = false;
            state.loading = true;
            state.pagination = initialState.pagination;
            state.orders = initialState.orders;
        },
    },
});

export const getOrdersHistoryActions = getOrdersHistorySlice.actions;
export default getOrdersHistorySlice.reducer;
