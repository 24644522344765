import { useState } from 'react';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';

import { Box, HStack, Text, useColorMode } from '@chakra-ui/react';
import { formatNumber } from '../../../../utils/numbers';
import type { TSubscribeLevel2Item } from '../../../../store/subscribeLevel2/types';

import { subscribeLevel2Actions } from '../../../../store/subscribeLevel2';

import { Loading } from '../../../../components/Loading';
import { CardBox } from '../../../../components/CardBox';
import { Information } from '../../../../components/Information';

import { TableOrders, LineWithBar, BoxContainer } from './styles';

interface TableOffersProps {
    title: string;
    type: string;
    tablePosition: 'left' | 'right';
}

const message =
    'Representa o total financeiro de todas as ofertas até aquele determinado preço começando pelo topo do livro. É uma medida de profundidade do mercado.';

export const TableOffers = ({ title, type }: TableOffersProps) => {
    let partialAmount = 0;
    const { colorMode } = useColorMode();
    const { subscribeLevel2, timeline, companies } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (timeline.currentStep?.type === undefined) {
            return;
        }

        dispatch(subscribeLevel2Actions.subscribeLevel2());

        return () => {
            dispatch(subscribeLevel2Actions.unsubscribeLevel2());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    useEffect(() => {
        const orderTypeEnum = type === 'buy' ? 0 : 1;
        const _data = Object.values(subscribeLevel2.orders).filter((item) => item.Side === orderTypeEnum);
        const _sorted = _data.sort((a, b) => (type === 'buy' ? b.Price - a.Price : a.Price - b.Price));
        let _orders = [];

        /**
         * Alphapoint API Doc: integer. ProductPairCode is the same value and used for the same
         * purpose as InstrumentID. The two are completely equivalent.
         * InstrumentId 47 = ProductPairCode 47.
         */
        if (type === 'buy') {
            _orders =
                timeline.currentStep?.type === 'dealing'
                    ? _sorted.filter((item) => item.ProductPairCode === companies.current.assets.defaultInstrumentId)
                    : _sorted.filter((item) => item.ProductPairCode === companies.current.assets.instrumentBuyId);
        } else {
            _orders = _sorted;
        }
        setOrders(() => [..._orders]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeLevel2.updated]);

    const Line = (item: TSubscribeLevel2Item) => {
        /** Calc Total Price Volume **/
        const totalAmount = orders.reduce(
            (acc: number, cur: TSubscribeLevel2Item) => acc + cur.Price * cur.Quantity,
            0
        );
        partialAmount += item.Price * item.Quantity;
        const sizeBar = `${Math.floor(partialAmount * 100) / totalAmount}%`;

        return (
            <LineWithBar key={`offer-${type}-${uuid()}`} size={sizeBar} type={type}>
                <td>
                    {/* <Information label={message}>{formatNumber(partialAmount)}</Information> */}
                    {formatNumber(partialAmount)}
                </td>
                <td>{item.Quantity.toFixed(0)}</td>
                <td>{formatNumber(item.Price)}</td>
            </LineWithBar>
        );
    };

    return (
        <BoxContainer>
            <CardBox $hasBorder={true} $topLine={true} width="100%" height="500px" padding="30px 0 0">
                <Text fontWeight="700" fontSize="0.9rem" letterSpacing="0.05em" lineHeight="1.4" textAlign="center">
                    {title}
                </Text>
                <Box overflow="hidden" borderRadius="8px">
                    <TableOrders $colorMode={colorMode} variant="bee4Trading">
                        <thead>
                            <tr style={{ flexDirection: type === 'sell' ? 'row-reverse' : 'unset' }}>
                                <th>
                                    <HStack spacing={1}>
                                        <Text>Total</Text>
                                        <Information label={message} />
                                    </HStack>
                                </th>
                                <th>Qtd</th>
                                <th>Preço</th>
                            </tr>
                        </thead>
                        <tbody>{!!orders.length && orders.map((item: TSubscribeLevel2Item) => Line(item))}</tbody>
                    </TableOrders>
                    <Loading active={subscribeLevel2.loading} />
                </Box>
            </CardBox>
        </BoxContainer>
    );
};
