import { OrderCancel } from './OrderCancel';
import { OrderEdit } from './OrderEdit';

import { Modal } from '../../../../components/Modal';
import { TGetOpenOrdersItem } from '../../../../store/getOpenOrders/types';

interface ModalProps {
    data?: TGetOpenOrdersItem;
    isOpen?: boolean;
    scrollPosition?: number;
    which: 'edit' | 'cancel';
    onClose: () => void;
}

export const ModalTrading = ({ which, isOpen, onClose, data }: ModalProps) => {
    const label = { buy: 'Compra', sell: 'Venda' };
    const modalControl =
        which === 'edit'
            ? {
                  title: `Editar Oferta de ${label[data?.Side.toLowerCase()]}`,
                  size: 'xl',
              }
            : {
                  title: `Cancelar Oferta de ${label[data?.Side.toLowerCase()]}`,
                  size: 'md',
              };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            size={modalControl.size}
            modalTitle={modalControl.title}
            topLine={true}
            isCentered={which === 'cancel'}
        >
            {which === 'edit' ? (
                <OrderEdit data={data} onClose={onClose} />
            ) : (
                <OrderCancel data={data} onClose={onClose} />
            )}
        </Modal>
    );
};
