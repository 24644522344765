import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useColorMode } from '@chakra-ui/react';

import { usePagination, DOTS } from '../../hooks/usePagination';

import { Page, Pages } from './styles';

type TPaginationProps = {
    pageSize?: number;
    totalItems?: number;
    currentPage?: number;
    siblingCount?: number;
    action?: (value: number) => void;
};

export const Pagination = ({ pageSize, currentPage, siblingCount = 1, totalItems, action }: TPaginationProps) => {
    const { colorMode } = useColorMode();
    const paginationRange = usePagination({
        currentPage,
        totalItems,
        siblingCount,
        pageSize,
    });

    if (!currentPage || paginationRange.length < 2) {
        return null;
    }

    const lastPage = paginationRange[paginationRange.length - 1];

    const onNext = () => action(currentPage + 1);
    const onPrevious = () => action(currentPage - 1);

    return (
        <Pages>
            {currentPage !== 1 && (
                <Page key="prev-button" onClick={onPrevious} colorMode={colorMode}>
                    <ChevronLeftIcon fontSize="1rem" fontWeight="400" />
                </Page>
            )}
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return (
                        <Page key={`dots-${index}`} dots={true} colorMode={colorMode}>
                            ...
                        </Page>
                    );
                }

                return (
                    <Page
                        key={`page-${pageNumber}`}
                        onClick={() => action(pageNumber)}
                        className={pageNumber === currentPage && 'active'}
                        colorMode={colorMode}
                    >
                        {pageNumber}
                    </Page>
                );
            })}
            {lastPage !== currentPage && (
                <Page key="next-button" onClick={onNext} colorMode={colorMode}>
                    <ChevronRightIcon fontSize="1rem" fontWeight="400" />
                </Page>
            )}
        </Pages>
    );
};
