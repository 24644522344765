import styled from 'styled-components';
import { devices } from '../../../../styles/devices';
import { bee4Theme } from '../../../../styles/theme';

type TTableProps = {
    $colorMode?: string;
    whidth?: string;
    height?: string;
};

export const Table = styled.table<TTableProps>`
    --paddingCell: 10px 15px;
    --paddingFirstCell: 40px;
    --fontSize: 0.89rem;

    width: 100%;
    margin: 10px 0 0 0;

    table-layout: fixed;
    border-collapse: collapse;

    overflow-y: hidden;
    overflow-x: auto;

    background: ${(props) =>
        props.$colorMode === 'light'
            ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`
            : bee4Theme.colors.lead[200]};

    thead,
    tbody {
        display: inline-block;
        padding: 0;
        margin: 0;

        tr {
            width: 100%;
            min-width: max-content;
            display: inline-flex;

            align-items: center;
            justify-content: space-between;

            &#mobile-header {
                background: transparent !important;

                @media ${devices.maxTablet} {
                    display: inline-flex !important;
                }
                @media ${devices.minLaptop} {
                    display: none !important;
                }
            }
            &:nth-of-type(odd) {
                padding: 0;
                background-color: ${({ $colorMode }) =>
                    $colorMode === 'dark'
                        ? bee4Theme.colors.lead[200]
                        : `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`};
            }
            &:nth-of-type(even) {
                background-color: ${({ $colorMode }) =>
                    $colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[100]};
            }
            th,
            td {
                width: 100%;
                padding: 16px 24px;

                border: 0;
                text-align: center;
                text-transform: none;

                @media ${devices.maxTablet} {
                    min-width: 200px;
                }
                @media ${devices.minLaptop} {
                    max-width: 200px;
                }
            }
            th {
                font-weight: 400;
                font-size: 0.9rem;

                color: ${(props) =>
                    props.$colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.lead[500]};
            }
            td {
                font-weight: 400;
                font-size: 0.8rem;
            }
        }
    }

    thead {
        width: 100%;
        font-weight: 400;
        font-size: var(--fontSize);
        th {
            @media ${devices.maxTablet} {
                display: none;
            }
            @media ${devices.minLaptop} {
                display: block;
            }
        }
    }
    tbody {
        width: 100%;
        position: relative;

        @media ${devices.maxTablet} {
            height: auto;
            max-height: 250px;
            overflow: auto;
        }
        @media ${devices.minLaptop} {
            height: ${(props) => props.height};
            overflow-x: visible;
            overflow-y: auto;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 2px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: ${(props) =>
                props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
        }

        scrollbar-color: ${(props) => (props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)')}
            transparent;
        scrollbar-width: thin;

        tr {
            border-top: 1px solid ${bee4Theme.colors.lead[100]};

            width: 100%;
            min-width: max-content;
            display: inline-flex;

            align-items: center;
            justify-content: space-between;

            &:last-of-type {
                border-bottom: 1px solid ${bee4Theme.colors.lead[100]};
            }
            &:nth-of-type(odd) {
                padding: 0;
            }
            &:nth-of-type(even) {
                background-color: ${({ $colorMode }) =>
                    $colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[200]};
            }
        }
    }
`;
