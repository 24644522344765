import { TimelineConfig, WeekDayType, EventsMessages, WeekEvents } from './types';

export const initialState: TimelineConfig = {
    schedule: [] as WeekDayType[],
    currentStep: {} as WeekDayType,
    eventsMessages: [] as EventsMessages[],
    allWeekEvents: [] as WeekEvents[],
    loading: false,
    error: false,
    percentFunds: 10,
};

export const closed = {
    title: 'Fechada para negócios',
    descript: 'No momento não é possível operar o sistema.',
    start: '00:00:00',
    finish: '23:59:59',
    label: 'BEE4 fechada para negócios',
    tip: 'A BEE4 fecha às 20h! Horário de funcionamento da BEE4 é de 10h às 20h nos dias úteis. Portanto, fora desse período não é possível realizar Ofertas na plataforma.',
    type: 'closed' as const,
    empty: false,
    informationBox: {
        lastTrade: 'Último negócio',
        theoreticalVariation: 'Variação teórica',
        theoreticalPrice: 'Preço teórico',
    },
};

export const schedule = [
    {
        start: '00:00:00',
        finish: '23:59:59',
    },
];

export const defaultResponse = {
    currentStep: { ...closed, type: 'closed', empty: false },
    schedule: schedule,
    percentFunds: 10,
};
