import { createIcon } from '@chakra-ui/icons';

export const IconInstagram = createIcon({
    displayName: 'IconInstagram',
    viewBox: '0 0 26 26',
    path: (
        <path
            d="M12.9965 8.79299C10.6801 8.79299 8.78967 10.6834 8.78967 12.9999C8.78967 15.3164 10.6801 17.2068 12.9965 17.2068C15.313 17.2068 17.2034 15.3164 17.2034 12.9999C17.2034 10.6834 15.313 8.79299 12.9965 8.79299ZM25.6139 12.9999C25.6139 11.2578 25.6297 9.53149 25.5318 7.79254C25.434 5.77272 24.9732 3.98012 23.4963 2.50312C22.0161 1.02297 20.2267 0.565351 18.2069 0.467516C16.4649 0.36968 14.7386 0.38546 12.9997 0.38546C11.2576 0.38546 9.53132 0.36968 7.7924 0.467516C5.77262 0.565351 3.98005 1.02612 2.50308 2.50312C1.02296 3.98328 0.565348 5.77272 0.467514 7.79254C0.369681 9.53464 0.38546 11.261 0.38546 12.9999C0.38546 14.7389 0.369681 16.4683 0.467514 18.2073C0.565348 20.2271 1.02611 22.0197 2.50308 23.4967C3.98321 24.9768 5.77262 25.4345 7.7924 25.5323C9.53447 25.6301 11.2608 25.6144 12.9997 25.6144C14.7417 25.6144 16.468 25.6301 18.2069 25.5323C20.2267 25.4345 22.0193 24.9737 23.4963 23.4967C24.9764 22.0165 25.434 20.2271 25.5318 18.2073C25.6328 16.4683 25.6139 14.742 25.6139 12.9999V12.9999ZM12.9965 19.4728C9.41455 19.4728 6.52372 16.5819 6.52372 12.9999C6.52372 9.41787 9.41455 6.52699 12.9965 6.52699C16.5785 6.52699 19.4693 9.41787 19.4693 12.9999C19.4693 16.5819 16.5785 19.4728 12.9965 19.4728ZM19.7344 7.77361C18.8981 7.77361 18.2227 7.09823 18.2227 6.26189C18.2227 5.42556 18.8981 4.75018 19.7344 4.75018C20.5707 4.75018 21.2461 5.42556 21.2461 6.26189C21.2463 6.46048 21.2074 6.65717 21.1315 6.84069C21.0556 7.02422 20.9443 7.19097 20.8039 7.33139C20.6635 7.47182 20.4967 7.58316 20.3132 7.65904C20.1297 7.73492 19.933 7.77386 19.7344 7.77361V7.77361Z"
            fill="currentColor"
        />
    ),
});
