import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial';

export * from './types';

const cancelReplaceOrder = createSlice({
    name: 'CancelReplaceOrder',
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sendEditOrder: (state, action) => {
            state.error = false;
            state.edit = false;
        },
        editOrder: (state) => {
            state.updated = Date.now();
            state.error = false;
            state.edit = true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        editFailed: (state, action): void => {
            state.error = true;
            state.edit = false;
            state.updated = Date.now();
        },
        resetEditOrder: (state): void => {
            state.edit = false;
            state.error = false;
            state.updated = Date.now();
        },
        clockFailed: (state): void => {
            state.error = false;
            state.edit = false;
        },
    },
});

export const cancelReplaceOrderActions = cancelReplaceOrder.actions;
export default cancelReplaceOrder.reducer;
