import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../config/appConfig';
import { initialState } from './initial';

export const getTradesHistorySlice = createSlice({
    name: 'GetTradesHistory',
    initialState,
    reducers: {
        getTradesHistory: (state): void => {
            state.error = false;
            state.trades = initialState.trades;
        },
        getTradesHistorySuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;
            state.trades = [...action.payload, ...state.trades];

            const ignore = [192677, 192678, 192679, 192680];
            state.trades = state.trades.filter((item) => !ignore.includes(item.TradeId));
        },
        getTradesHistoryfailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
        },
        paginateTradesHistory: (state): void => {
            state.error = false;
            state.pagination = {
                ...state.pagination,
                offset: state.pagination.offset + appConfig.REGISTER_PER_PAGE + 1,
            };
        },
    },
});

export const getTradesHistoryActions = getTradesHistorySlice.actions;
export default getTradesHistorySlice.reducer;
