import {
    Text,
    Container,
    Image,
    Flex,
    InputRightElement,
    InputGroup,
    FormControl,
    Input,
    FormErrorMessage,
    PinInput,
    PinInputField,
    VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { CardBox } from '../../../components/CardBox';
import { GradientButton } from '../../../components/GradientButton';

import Logo from '../../../assets/logos/bee4-white-logo.svg';
import IconSecurity from '../../../assets/icons/icon-security.svg';

import { Form, Main } from './styles';
import { FeedbackPassword } from '../../../components/FeedbackPassword';
import { useChangePass } from '../../../context/rescuePassword';
import { PageTitle } from '../../../components/PageTitle';
import { Toast } from '../../../components/Toast';
import { MetaTags } from '../../../components/MetaTags';
import { Link } from '../../../components/Link';

export const SetNewPassword = () => {
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Campo obrigatório'),
        confirm: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { updatePassword, requestCode } = useChangePass();
    const [code, setCode] = useState<string>();

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm(formOptions);

    const initialValidation = {
        special: false,
        number: false,
        uppercase: false,
        lowercase: false,
        length: false,
    };

    const { setStep, userData } = useChangePass();
    const [showPass, setShowPass] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [validation, setValidation] = useState(initialValidation);
    const [password, setPassword] = useState('');

    const handleValidate = (event) => {
        const _password = event.target.value;

        const regexSpecialChars = `\`!@#$%^&*()_+-=[]{};':"\\|,.<>/?~`;
        const _validation = initialValidation;

        /** Special Character Check **/
        regexSpecialChars.split('').some((specialChar) => {
            if (_password.includes(specialChar)) {
                _validation.special = true;
            }
        });

        /** Number Check **/
        const decimal = /\d/;
        _validation.number = decimal.test(_password);

        /** Uppercase Check **/
        const uppercase = /[A-Z]/;
        _validation.uppercase = uppercase.test(_password);

        /** Lowercase Check **/
        const lowercase = /[a-z]/;
        _validation.lowercase = lowercase.test(_password);

        /** Length String **/
        _validation.length = _password.length >= 8;

        setValidation(_validation);
        setPassword(() => _password);
    };

    const handleSubmitNewPassword = async (data) => {
        const response = await updatePassword(userData.userName, code, data.password);
        if (response.success) {
            return setStep(4);
        }

        if (response.error?.toString().match(/(CodeMismatchException)/i)) {
            return Toast({
                id: 'ChangePassReturn',
                title: `Falha ao mudar sua senha`,
                description: 'O código de verificação não é válido. Revise por gentileza.',
                status: 'error',
            });
        }

        return Toast({
            id: 'passFeedback',
            title: `Falha ao alterar senha`,
            description: 'Houve um erro ao atualizar sua senha, por gentileza tente novamente.',
            status: 'error',
        });
    };

    const interceptSubmit = (event) => {
        event.preventDefault();

        if (!code) {
            setError('code', {
                type: 'manual',
                message: 'Campo obrigatório',
            });
            return false;
        }

        if (Object.values(validation).some((value) => value === false)) {
            Toast({
                id: 'passFeedback',
                title: `Falha de validação`,
                description: 'A senha não corresponde aos critérios.',
                status: 'error',
            });
            return false;
        }

        return handleSubmit(handleSubmitNewPassword)(event);
    };

    const handleCode = (data) => {
        setCode(data);
        clearErrors('code');
    };

    const handleSendCode = async () => {
        setCode('');

        if (await requestCode(userData.userName)) {
            return Toast({
                id: 'CodenSended',
                title: `Sucesso`,
                description: 'Enviado com sucesso...',
                status: 'success',
            });
        }

        return Toast({
            id: 'CodenSended',
            title: `Erro ao enviar o código`,
            description: 'Falha ao enviar o código, tente novamente mais tarde.',
            status: 'error',
        });
    };

    return (
        <Main>
            <MetaTags>
                <title>Insira o código de verificação e sua nova senha - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <Image width="100px" height="50px" objectFit="contain" margin="10px 0" src={Logo} alt="Bee4" />
                <PageTitle>Redefinição de senha</PageTitle>

                <VStack marginTop="80px" alignItems="center" justifyContent="center" width="100%">
                    <CardBox width="100%" maxWidth="620px" height="auto" padding="20px" $topLine={true}>
                        <Text fontSize=".9rem" color="white" textAlign="center">
                            Acesse seu e-mail, enviamos um código de acesso para redefinição de sua senha
                        </Text>
                    </CardBox>

                    <Flex
                        width="100%"
                        height="80%"
                        flexFlow="column"
                        padding="0"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CardBox
                            width="95%"
                            maxWidth="360px"
                            height="auto"
                            padding="25px 15px"
                            margin="25px auto 40px"
                            variant="back-light"
                        >
                            <Flex width="100%" height="100%" direction="column" alignItems="center">
                                <Flex width="90%" margin="10px 0" alignItems="center">
                                    <Image src={IconSecurity} width="40px" />
                                    <Text fontSize="0.8rem" fontWeight="400">
                                        Ambiente Seguro
                                    </Text>
                                </Flex>

                                <Form noValidate onSubmit={interceptSubmit}>
                                    <FormControl isInvalid={errors.code}>
                                        <Text fontSize=".8rem" color="white" padding="10px 0">
                                            Digite o código de acesso recebido por e-mail
                                        </Text>

                                        <Flex alignItems="center" justifyContent="space-around">
                                            <PinInput
                                                value={code}
                                                autoFocus={true}
                                                variant="outline"
                                                onChange={handleCode}
                                            >
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                                <PinInputField
                                                    backgroundColor="gray.000"
                                                    color="gray.700"
                                                    fontSize="1.4rem"
                                                />
                                            </PinInput>
                                        </Flex>

                                        {errors.code && <FormErrorMessage>Campo obrigatório</FormErrorMessage>}

                                        <Flex wrap="wrap" alignItems="center" padding="5px 0 0">
                                            <Text fontSize=".75rem" fontWeight="300" paddingEnd={1}>
                                                Ainda não recebeu o código?
                                            </Text>
                                            <Link to="#" onClick={handleSendCode} fontSize=".75rem" fontWeight="300">
                                                clique aqui
                                            </Link>
                                        </Flex>
                                    </FormControl>

                                    <FormControl isInvalid={errors.password} marginTop="15px">
                                        <Text fontSize=".8rem" color="white" padding="10px 0">
                                            Insira sua nova senha
                                        </Text>
                                        <InputGroup>
                                            <Input
                                                data-testid="password"
                                                type={showPass ? 'text' : 'password'}
                                                placeholder="Senha..."
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                {...register('password')}
                                                onChange={handleValidate}
                                            />
                                            <InputRightElement
                                                onClick={() => setShowPass(!showPass)}
                                                style={{ cursor: 'pointer' }}
                                                color="gray.700"
                                            >
                                                {showPass ? <ViewOffIcon /> : <ViewIcon />}
                                            </InputRightElement>
                                        </InputGroup>
                                        {errors.password && (
                                            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                                        )}
                                    </FormControl>

                                    <FormControl isInvalid={errors.confirm} marginTop="15px">
                                        <InputGroup>
                                            <Input
                                                data-testid="confirm"
                                                type={showConfirm ? 'text' : 'password'}
                                                placeholder="Confirmar senha..."
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                {...register('confirm')}
                                            />
                                            <InputRightElement
                                                onClick={() => setShowConfirm(!showConfirm)}
                                                style={{ cursor: 'pointer' }}
                                                color="gray.700"
                                            >
                                                {showConfirm ? <ViewOffIcon /> : <ViewIcon />}
                                            </InputRightElement>
                                        </InputGroup>
                                        {errors.confirm && (
                                            <FormErrorMessage>{errors.confirm?.message}</FormErrorMessage>
                                        )}
                                    </FormControl>

                                    <Flex direction="column" padding="15px 0 0">
                                        <FeedbackPassword
                                            status={validation.length}
                                            value={password}
                                            message="No mínimo 8 caracteres"
                                        />
                                        <FeedbackPassword
                                            status={validation.special}
                                            value={password}
                                            message="Caractere especial (ex: #*@)"
                                        />
                                        <FeedbackPassword
                                            status={validation.number}
                                            value={password}
                                            message="Pelo menos um número"
                                        />
                                        <FeedbackPassword
                                            status={validation.uppercase}
                                            value={password}
                                            message="Letra maiúscula"
                                        />
                                        <FeedbackPassword
                                            status={validation.lowercase}
                                            value={password}
                                            message="Letra minúscula"
                                        />
                                    </Flex>

                                    <FormControl marginTop="25px">
                                        <GradientButton
                                            id="signin"
                                            label="Redefinir Senha"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </FormControl>
                                </Form>
                            </Flex>
                        </CardBox>
                    </Flex>
                </VStack>
            </Container>
        </Main>
    );
};
