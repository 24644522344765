import { BoxProps, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { GradientLine } from '../components/GradientLine';
import { Card } from './styles';

interface BlueBoxProps extends BoxProps {
    children: ReactNode;
    topLine?: boolean;
    background?: string;
}

export const BlueBox = ({ children, topLine, background, ...rest }: BlueBoxProps) => {
    const { colorMode } = useColorMode();

    return (
        <Card $colorMode={colorMode} $background={background} {...rest}>
            {topLine ? <GradientLine /> : null}
            {children}
        </Card>
    );
};
