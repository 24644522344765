import { HStack } from '@chakra-ui/react';
import { TGetOpenOrdersItem } from '../../../../store/getOpenOrders/types';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';

import { EditButton } from './editButton';
import { CancelButton } from './cancelButton';

type TActionButtonsProps = {
    order: TGetOpenOrdersItem;
    select: (data: TGetOpenOrdersItem) => void;
    openEdit: (data: boolean) => void;
    openCancel: (data: boolean) => void;
};

export const ActionButtons = ({ order, select, openEdit, openCancel }: TActionButtonsProps) => {
    const sendGTM = useGTMDataLayer();

    const handleOpenEditModal = (_data: TGetOpenOrdersItem) => {
        sendGTM({
            title: `Negociação - Editar Ordem - BEE4`,
            page: `${location.pathname}/editar-ordem`,
        });
        select(_data);
        openEdit(true);
    };

    const handleOpenCancelModal = (_data: TGetOpenOrdersItem) => {
        sendGTM({
            title: 'Negociação - Cancelar Ordem - BEE4',
            page: `${location.pathname}/cancelar-ordem`,
        });
        select(_data);
        openCancel(true);
    };

    return (
        <HStack spacing={15} justifyContent="end">
            <EditButton action={() => handleOpenEditModal(order)} type={order.Side.toLocaleLowerCase()} />
            <CancelButton action={() => handleOpenCancelModal(order)} type={order.Side.toLocaleLowerCase()} />
        </HStack>
    );
};
