export const taxId = (value: string) => {
    return value.length === 14
        ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        : value.length === 11
        ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        : '';
};

export const hideString = (value: string): string => {
    const length = value.length;
    let result = '';

    const validations = {
        email: {
            regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            action: () => {
                const start = value.substring(0, 3);
                const split = value.split('@');
                const final = `@${split[1]}`;
                return `${start}${'*'.repeat(10)}${final}`;
            },
        },
        phone: {
            regex: /^((\+\d{2}\s)?\(\d{2}\)\s?\d{4}\d?-\d{4})?$/g,
            action: () => {
                const start = value.substring(0, 7);
                const final = value.substring(length - 2);
                return `${start}${'*'.repeat(3)}-${'*'.repeat(2)}${final}`;
            },
        },
        phoneWithoutHifen: {
            regex: /^((\+\d{2}\s)?\(\d{2}\)\s?\d{4}\d?\d{4})?$/g,
            action: () => {
                const start = value.substring(0, 7);
                const final = value.substring(length - 2);
                return `${start}${'*'.repeat(3)}-${'*'.repeat(2)}${final}`;
            },
        },
        cpf: {
            regex: /^\d{3}.\d{3}.\d{3}-\d{2}$/g,
            action: () => {
                const start = value.substring(0, 3);
                const final = value.substring(length - 2);
                return `${start}.${'*'.repeat(3)}.${'*'.repeat(3)}-${final}`;
            },
        },
        cnpj: {
            regex: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/g,
            action: () => {
                const start = value.substring(0, 2);
                const final = value.substring(length - 2);
                return `${start}.${'*'.repeat(3)}.${'*'.repeat(3)}/${'*'.repeat(3)}-${final}`;
            },
        },
        name: {
            regex: /^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/g,
            action: () => {
                const split = value.split(' ');
                const start = Array.isArray(split) ? split[0] : value.substring(0, length / 3);
                const final = value.substring(length - 3);
                return `${start} ${'*'.repeat(15)} ${final}`;
            },
        },
    };

    Object.keys(validations).map((index) => {
        if (!!value.match(validations[index].regex)) result = validations[index].action();
    });

    return result;
};

export const hideName = (value) => {
    const split = value.split(' ');
    const start = split[0];

    const surnames = split.map((item: any, index: any) => {
        if (index === 0) return;

        if (index === split.length - 1) {
            const lastLetter = item.substring(item.length - 3, item.length);
            const hidden = '*'.repeat(item.length < 2 ? 0 : item.length - 2);
            return `${hidden}${lastLetter}`;
        }

        return '*'.repeat(item.length);
    });

    return `${start} ${surnames.join(' ')}`;
};

export const stripTags = (str) => {
    const _str = str.toString();
    return !!_str ? str.replace(/(<([^>]+)>)/gi, '') : '';
};

export const slugify = (value: string): string => {
    return value
        .toString()
        .toLowerCase()
        .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a') // Special Characters #1
        .replace(/[èÈéÉêÊëË]+/g, 'e') // Special Characters #2
        .replace(/[ìÌíÍîÎïÏ]+/g, 'i') // Special Characters #3
        .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o') // Special Characters #4
        .replace(/[ùÙúÚûÛüÜ]+/g, 'u') // Special Characters #5
        .replace(/[ýÝÿŸ]+/g, 'y') // Special Characters #6
        .replace(/[ñÑ]+/g, 'n') // Special Characters #7
        .replace(/[çÇ]+/g, 'c') // Special Characters #8
        .replace(/[ß]+/g, 'ss') // Special Characters #9
        .replace(/[Ææ]+/g, 'ae') // Special Characters #10
        .replace(/[Øøœ]+/g, 'oe') // Special Characters #11
        .replace(/[%]+/g, 'pct') // Special Characters #12
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
