import { TGetAccountPositionsInitialState } from './types';

export const initialState: TGetAccountPositionsInitialState = {
    error: false,
    loading: true,
    positions: {
        buy: {
            amount: 0,
            hold: 0,
            operatingLimit: 0,
            blockedOperatingLimit: 0,
            pendingDeposits: 0,
        },
        sell: {
            tokens: 0,
            hold: 0,
        },
    },
};
