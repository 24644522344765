import styled from 'styled-components';
import { devices } from '../../styles/devices';
import { bee4Theme } from './../../styles/theme';

interface HeaderContainerProps {
    $colorMode: string;
}
interface NavigationMenuProps {
    $colorMode: string;
}
interface TMobileMenu {
    $colorMode: string;
}

export const HeaderContainer = styled.header<HeaderContainerProps>`
    width: 100%;
    height: 70px;

    padding: 16px 0;

    background: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[200])};
    position: fixed;
    top: 0;

    z-index: 100;
`;

export const NavigationMenu = styled.nav<NavigationMenuProps>`
    flex-flow: row;

    align-items: center;
    justify-content: space-between;

    @media ${devices.maxTablet} {
        display: none;
    }
    @media ${devices.minLaptop} {
        display: flex;
        width: auto;
    }
    a {
        padding: 8px 20px;
        border: 0px;
        border-radius: 4px;

        font-weight: 500;
        font-size: 0.85rem;
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.black)};

        transition: all 0.5s ease-in-out;
        text-transform: uppercase;
        text-decoration: none !important;

        &:hover {
            background: ${(props) =>
                props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300]} !important;
            text-decoration: none !important;
        }
        &.active {
            background: ${(props) =>
                props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300]};
        }
    }
`;

export const MobileMenu = styled.span<TMobileMenu>`
    width: 100%;
    height: 50%;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-evenly;

    a {
        color: ${({ $colorMode }) =>
            $colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.black} !important;
        transition: all 0.5s ease-in-out;
        &:hover {
            color: ${({ $colorMode }) =>
                $colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300]} !important;
        }
    }
`;
