import { Flex, HStack } from '@chakra-ui/react';
import { GradientButton } from '../../../../components/GradientButton';

import { Modal } from '../../../../components/Modal';
import { MainBox } from './MainBox';
import IconPriceRange from '../../../../assets/icons/icon-price-range.svg';
import * as Bee4 from './styles';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ModalPriceRange = ({ isOpen, onClose }: ModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            size="5xl"
            modalTitle="ENTENDA A FAIXA DE PREÇO"
            topLine={true}
        >
            <Flex
                width="100%"
                maxWidth="1280px"
                margin="0 auto"
                direction={['column-reverse', 'column-reverse', 'column-reverse', 'row']}
                alignItems="center"
                justifyContent="space-between"
            >
                <HStack
                    flexDirection="column"
                    alignItems="flex-start"
                    flex={1}
                    width={['100%', '100%', '100%', '50%']}
                    padding={'10px'}
                    spacing={0}
                >
                    <Bee4.Text>
                        {' '}
                        A BEE4 tem um sistema exclusivo de amortecimento de volatilidade dos tokens, com faixa de preço
                        de negociação do ativo.
                    </Bee4.Text>
                    <Bee4.Text>Veja como o sistema funciona: </Bee4.Text>
                </HStack>
                <HStack
                    flex={[1, 1, 1, 0.6]}
                    alignItems="flex-start"
                    justifyContent={['center', 'center', 'center', 'center']}
                >
                    <Bee4.Image src={IconPriceRange} alt="Bee4" />
                </HStack>
            </Flex>
            <MainBox />

            <Flex justifyContent="center">
                <Bee4.TextFooter>
                    Você pode obter mais informações acessando nosso{' '}
                    <Bee4.Link to="https://bee4.com.br/manual-de-operacoes-e-liquidacao" target="_blank">
                        Manual de Operações e Liquidação
                    </Bee4.Link>
                    , disponível no site da BEE4.
                </Bee4.TextFooter>
            </Flex>
            <Flex
                width={['100%', '85%', '70%', '50%']}
                margin="0 auto"
                alignItems="center"
                direction={'column'}
                textAlign="center"
            >
                <GradientButton label="Fechar" type="button" variant="colorful" fontSize=".8rem" onClick={onClose} />
            </Flex>
        </Modal>
    );
};
