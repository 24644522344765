import { useCallback, useEffect, useState } from 'react';
import { Text, Image, HStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { currency } from '../../../../utils/numbers';

import { dateShort } from '../../../../utils/date';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';

import IconLock from '../../../../assets/icons/icon-lock.svg';
import IconUnLock from '../../../../assets/icons/icon-unlock.svg';

import { ModalTrading } from '../ModalTrading';
import { Loading } from '../../../../components/Loading';
import { bee4Theme } from '../../../../styles/theme';
import { TGetOpenOrdersItem } from '../../../../store/getOpenOrders/types';
import { getOpenOrdersActions } from '../../../../store/getOpenOrders';
import { ActionButtons } from '../ActionButtons';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { Toast } from '../../../../components/Toast';
import { shallowEqual } from 'react-redux';

export const OpenOrders = ({ companyName }: { companyName: string }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const sendGTM = useGTMDataLayer();

    const cancelOrder = useAppSelector((state) => state.cancelOrder, shallowEqual);
    const { openOrders, user, timeline } = useAppSelector((state) => state);
    const [orders, setOrders] = useState([]);

    const [orderSelected, setOrderSelected] = useState<TGetOpenOrdersItem>();
    const [openCancel, setOpenCancel] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const label = { buy: 'Compra', sell: 'Venda' };

    useEffect(() => {
        if (user.userSession.alphapoint.token === null || timeline.currentStep.type === undefined) {
            return;
        }

        dispatch(getOpenOrdersActions.getOpenOrders());
        return () => {
            dispatch(getOpenOrdersActions.resetOpenOrders());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    useEffect(() => {
        if (!openOrders.updated) {
            return;
        }

        const dict = {};
        openOrders.orders.forEach((item: TGetOpenOrdersItem) => {
            dict[item.OrderId] = item;
        });

        setOrders(() => Object.values(dict));
        setOrders((state) => state.sort((a, b) => Number(b.ReceiveTime) - Number(a.ReceiveTime)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openOrders.updated]);

    useEffect(() => {
        cancelOrder.error &&
            Toast({
                id: 'cancelOrderError',
                title: 'Oferta não cancelada',
                description: `Ocorreu um problema ao cancelar essa oferta`,
                status: 'error',
            });
    }, [cancelOrder.error]);

    const showPadlock = useCallback((side) => {
        const icon = timeline.currentStep?.type === 'revocable' ? IconUnLock : IconLock;

        return (
            <HStack spacing={2} justifyContent="center">
                <Image src={icon} width="14px" />
                <Text color={side === 'buy' ? bee4Theme.colors.green[100] : bee4Theme.colors.red[100]}>
                    {label[side]}
                </Text>
            </HStack>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseEditModal = () => {
        sendGTM({
            title: `Negociação - ${companyName} - BEE4`,
            page: `${location.pathname}/editar-ordem`,
        });
        setOrderSelected(null);
        setOpenEdit(false);
    };

    const handleCloseCancelModal = () => {
        sendGTM({
            title: `Negociação - ${companyName} - BEE4`,
            page: `${location.pathname}`,
        });
        setOrderSelected(null);
        setOpenCancel(false);
    };

    return (
        <>
            <thead>
                <tr className="six-columns">
                    <th>Operação</th>
                    <th>Qtd pendente</th>
                    <th>Qtd original</th>
                    <th>Preço</th>
                    <th>Data/Hora</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr id="mobile-header" className="six-columns">
                    <th>Operação</th>
                    <th>Qtd pendente</th>
                    <th>Qtd original</th>
                    <th>Preço</th>
                    <th>Data/Hora</th>
                    <th></th>
                </tr>
                {!!orders.length &&
                    orders.map((order: TGetOpenOrdersItem) => (
                        <tr key={`open-orders-${order.OrderId}`} className="six-columns">
                            <td color={order.Side === 'Buy' ? 'green.100' : 'red.100'}>
                                {showPadlock(order.Side.toLowerCase())}
                            </td>
                            <td>{order.DisplayQuantity}</td>
                            <td>{order.OrigQuantity}</td>
                            <td>{currency(order.Price)}</td>
                            <td>{dateShort(order.ReceiveTime, 'x')}</td>
                            <td>
                                <ActionButtons
                                    order={order}
                                    select={setOrderSelected}
                                    openEdit={setOpenEdit}
                                    openCancel={setOpenCancel}
                                />
                            </td>
                        </tr>
                    ))}
            </tbody>
            <tfoot>
                <tr style={{ position: 'absolute', bottom: 0 }}>
                    <td>
                        <Loading active={openOrders.loading} />
                        <ModalTrading
                            which="cancel"
                            isOpen={openCancel}
                            data={orderSelected}
                            onClose={handleCloseCancelModal}
                        />
                        <ModalTrading
                            which="edit"
                            isOpen={openEdit}
                            data={orderSelected}
                            onClose={handleCloseEditModal}
                        />
                    </td>
                </tr>
            </tfoot>
        </>
    );
};
