import styled from 'styled-components';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { bee4Theme } from '../../styles/theme';

interface CurrencyInputFieldProps extends CurrencyInputProps {
    $colorMode?: string;
    ref: React.Ref<HTMLInputElement>;
}

/**
 * Aqui não setei as cores do tema (deixando estáticas) pelo fato que
 * as cores são padrões do React-Hook-Form e como o campo CurrencyInputField
 * segue o padrão dos outros campos, ficou assim.
 */
export const CurrencyInputField = styled(CurrencyInput)<CurrencyInputFieldProps>`
    width: 100%;
    height: 45px;

    border-radius: 0.375rem;
    border: 1px solid ${(props) => (props.$colorMode === 'light' ? '#c3c6cf' : 'transparent')};

    background-color: ${bee4Theme.colors.gray['000']};
    color: ${bee4Theme.colors.gray[700]};

    padding: 15px;
    position: relative;

    outline: 2px solid transparent;
    outline-offset: 2px;

    &::placeholder {
        font-weight: 300;
        color: ${bee4Theme.colors.gray[700]};
    }
    &[aria-invalid='true'] {
        border-color: #f01c1c;
        box-shadow: 0 0 0 1px #f01c1c;
    }
    &:focus {
        z-index: 1;
        border: 1.5px solid;
        border-color: ${(props) => (props.$colorMode === 'light' ? '#060b7a' : '#0852DD')};
        box-shadow: 0 0 0 1px ${(props) => (props.$colorMode === 'light' ? '#060b7a' : '#0852DD')};
    }
`;
