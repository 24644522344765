import { useEffect, useState } from 'react';
import { Mask } from './styles';

interface ImageMaskProps {
    image: string;
    radius: number;
    sides: number;
    width?: string;
    height?: string;
    display?: string | string[];
}

export const ImageMask = ({ image, radius, sides, width, height, display }: ImageMaskProps) => {
    const [svg, setSvg] = useState(
        '<svg version="1.1" {width} {height} xmlns="http://www.w3.org/2000/svg" {enable-background} {viewBox} x="0px" y="0px"><polygon {points} /></svg>'
    );

    const range = (count) => Array.from(Array(count).keys());
    const degreesToRadians = (angleInDegrees) => (Math.PI * angleInDegrees) / 180;

    const points = () => {
        const angle = 360 / sides;
        const vertexIndices = range(sides);
        const offsetDeg = 90 - (180 - angle) / 2;
        const offset = degreesToRadians(offsetDeg);

        return vertexIndices.map((index) => {
            return {
                theta: offset + degreesToRadians(angle * index),
                r: radius,
            };
        });
    };

    const polygon = ([cx, cy]) => {
        return points()
            .map(({ r, theta }) => [cx + r * Math.cos(theta), cy + r * Math.sin(theta)])
            .join(' ');
    };

    useEffect(() => {
        const size = 2 * radius + 50;
        const points = polygon([size / 2, size / 2]);

        setSvg(() =>
            svg
                .replace('{width}', `width="${width}"`)
                .replace('{height}', `height="${height}"`)
                .replace('{viewBox}', `viewBox="0 0 ${size} ${size}"`)
                .replace('{points}', `points="${points}"`)
                .replace('{enable-background}', `enable-background="new 0 0 ${size} ${size}"`)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radius]);

    return (
        <Mask hexagon={window.btoa(svg)} width={width} height={height} display={display}>
            <img src={image} />
        </Mask>
    );
};
