import { Text, useColorMode, Image, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { companiesActions } from '../../../../store/companies';
import { TCompany } from '../../../../store/companies/types';
import { getOpenOrdersActions } from '../../../../store/getOpenOrders';
import { subscribeLevel2Actions } from '../../../../store/subscribeLevel2';
import { subscribeTradesActions } from '../../../../store/subscribeTrades';
import { CompaniesAssetsOption, IconChevron, Logo, SelectCompaniesAssets, SelectWrapper } from './styles';

export const SelectCompanyTicker = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selector = useRef(null);

    const { colorMode } = useColorMode();
    const { list, current } = useAppSelector((state) => state.companies);
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState<TCompany>({} as TCompany);

    useEffect(() => {
        setSelected(current);
    }, [current]);

    useEffect(() => {
        const handleClickOutside = () => {
            if (selector.current && !selector.current.contains(event.target)) {
                setOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selector]);

    const action = (company: TCompany) => {
        setOpened(false);
        setSelected({} as TCompany);

        // Reset Booking & OpenOrders
        dispatch(subscribeLevel2Actions.unsubscribeLevel2());
        dispatch(getOpenOrdersActions.resetOpenOrders());
        dispatch(subscribeTradesActions.resetTrades());

        const timeout = setTimeout(() => {
            setSelected(company);
            navigate(`/negociacoes/${company.slug}`);
            dispatch(companiesActions.setCompany(company));
            clearTimeout(timeout);
        }, 510);
    };

    const OptionEmpty = () => {
        return (
            <CompaniesAssetsOption $colorMode={colorMode}>
                <Text
                    width="100%"
                    fontWeight="500"
                    fontSize=".9em"
                    textTransform="uppercase"
                    padding="20px 10px"
                    onClick={() => setOpened(!opened)}
                >
                    Selecionar Ticker
                </Text>
            </CompaniesAssetsOption>
        );
    };

    const OptionTicker = (company: TCompany) => {
        return (
            <CompaniesAssetsOption
                key={company.assets?.ticker}
                $colorMode={colorMode}
                onClick={() => (opened ? action(company) : setOpened(true))}
            >
                <Logo>
                    <Image src={company.fullLogo} />
                </Logo>
                {company.assets?.ticker}
            </CompaniesAssetsOption>
        );
    };

    return (
        <Flex
            margin="40px 0"
            position="relative"
            width={['100%', '100%', '100%', '420px']}
            height={['125px', '125px', '125px', '50px']}
            direction={['column', 'column', 'column', 'row']}
            alignItems="center"
        >
            <Text fontSize="1rem" lineHeight="1.6" width="170px">
                Selecione a empresa que deseja negociar
            </Text>
            <SelectWrapper $colorMode={colorMode} ref={selector}>
                <SelectCompaniesAssets className={opened ? 'opened' : ''}>
                    {!selected.assets?.ticker || opened ? OptionEmpty() : OptionTicker(selected)}
                    {!!list.length && list.map((_company) => OptionTicker(_company))}
                </SelectCompaniesAssets>
                <IconChevron />
            </SelectWrapper>
        </Flex>
    );
};
