import { useState } from 'react';

import { SetPassword } from './SetPassword';
import { PasswordSuccess } from './PasswordSuccess';

export const FirstAccess = () => {
    const [step, setStep] = useState(1);

    switch (step) {
        case 2: // Success screen
            return <PasswordSuccess />;
        case 1:
        default:
            return <SetPassword setStep={setStep} />;
    }
};
