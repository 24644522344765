import appConfig from '../config/appConfig';
import { http } from './rest.api';

export const getInvoiceFile = async (invoiceId) => {
    if (!invoiceId) return null;

    try {
        const { data } = await http.get(`${appConfig.API_URL}trading-invoices/app/invoices/${invoiceId}`);
        return data;
    } catch (error) {
        console.log({ error });
        return { save: false };
    }
};
