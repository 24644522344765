import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';

import IconGraphic from '../../assets/icons/icon-graphic.svg';
import IconGraphicHover from '../../assets/icons/icon-graphic-hover.svg';
import { devices } from '../../styles/devices';
import { __STATUS } from './Components/constants';

interface StatusLabelProps {
    type: 'success' | 'error' | 'warning';
}

export const StatusLabel = styled.span<StatusLabelProps>`
    font-weight: 400;
    font-size: 0.8rem;

    border-radius: 80px;
    background: ${(props) => __STATUS[props.type]};

    color: ${bee4Theme.colors.white};
    text-transform: uppercase;

    padding: 4px 15px;
`;

export const GraphicButton = styled.span`
    display: block;
    cursor: pointer;

    width: 35px;
    height: 35px;

    background-image: url(${IconGraphic});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 0.3s ease-in-out;

    &:hover {
        width: 35px;
        height: 35px;
        background-image: url(${IconGraphicHover});
    }
`;

export const FlexContainer = styled(Flex)`
    margin-top: 25px;
    flex-flow: row;

    @media ${devices.maxLaptop} {
        flex-flow: wrap;
    }
`;

export const WarningCard = styled(Flex)`
    padding: 96px 124px;
    position: relative;
    max-width: 1030px;
    background-color: ${bee4Theme.colors.lead[100]};

    border: solid 2px transparent;
    border-radius: 24px;

    box-sizing: border-box;
    background-clip: padding-box;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to left, #060b7a, #48dddd);
    }

    @media ${devices.maxLaptop} {
        padding: 40px 60px;
    }

    @media ${devices.maxTablet} {
        padding: 20px 40px;
        flex-direction: column;
    }
`;
