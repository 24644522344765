import { Epic } from 'redux-observable';
import { of, EMPTY, switchMap, merge } from 'rxjs';
import { delay, mergeMap, mergeAll, throttleTime, catchError, filter, map } from 'rxjs/operators';

import { subscribeAccountEventsActions, subscribeAccountEventsSlice } from '.';
import { Frame } from '../../services/alphapoint/props';
import { TInputFrame, TOutputFrame } from '../../services/alphapoint/types';
import { eventMap } from './initial';

import { getOpenOrdersActions } from '../getOpenOrders';
import { getOrdersHistoryActions } from '../getOrdersHistory';
import { getAccountPositionsActions } from '../getAccountPositions';
import { walletActions } from '../wallet';

export const subscribeAccountEventsEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(subscribeAccountEventsActions.subscribeAccountEvents.match),
        filter(
            () =>
                state$.value.timeline.currentStep?.type !== 'closed' &&
                state$.value.timeline.currentStep?.type !== 'processing'
        ),
        throttleTime(defaultThrottleTime),
        delay(1000),
        mergeMap(() => {
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
            };

            return Alphapoint.stream$(
                {
                    action: subscribeAccountEventsSlice.name,
                    ...body,
                },
                {},
                (msg) => eventMap.some((event) => event === msg.n)
            ).pipe(
                map((msg: TInputFrame) => Frame.parse(msg)),
                filter((frame: TOutputFrame) => frame.msg === 'event'),
                map((frame: TOutputFrame) => {
                    let action$;
                    switch (frame.method) {
                        case 'OrderStateEvent':
                            action$ =
                                frame.data['OrderState'] !== 'Rejected' && frame.data['OrderState'] !== 'Canceled'
                                    ? of(getOpenOrdersActions.getOpenOrdersSuccess([frame.data]))
                                    : of(getOpenOrdersActions.getOpenOrdersSuccess([]));
                            break;
                        case 'AccountPositionEvent':
                            action$ = of(
                                walletActions.updatePositions([frame.data]),
                                getAccountPositionsActions.getAccountPositionsSuccess({
                                    company: state$.value.companies.current,
                                    data: [frame.data],
                                })
                            );
                            break;
                        default:
                            action$ = EMPTY;
                            break;
                    }
                    return merge(
                        action$,
                        of(subscribeAccountEventsActions.subscribeAccountEventsSuccess(frame)),
                        of(getOpenOrdersActions.resetOpenOrders()),
                        of(getOpenOrdersActions.getOpenOrders()),
                        of(getOrdersHistoryActions.getOrdersHistory())
                    );
                }),
                catchError(() => of(subscribeAccountEventsActions.subscribeAccountEventsFailed()))
            );
        }),
        mergeAll()
    );

export const subscribeAccountValueEventsEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(getAccountPositionsActions.getAccountValues.match),
        throttleTime(defaultThrottleTime),
        delay(1000),
        switchMap(() => {
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
            };

            return Alphapoint.stream$(
                {
                    action: subscribeAccountEventsSlice.name,
                    ...body,
                },
                {},
                (msg) => eventMap.some((event) => event === msg.n)
            ).pipe(
                map((msg: TInputFrame) => Frame.parse(msg)),
                filter((frame: TOutputFrame) => frame.msg === 'event'),
                map((frame: TOutputFrame) => {
                    if (frame.method === 'AccountPositionEvent')
                        return of(getAccountPositionsActions.getAccountPositionsSuccess([frame.data]));
                }),
                catchError(() => of(subscribeAccountEventsActions.subscribeAccountEventsFailed()))
            );
        })
    );
