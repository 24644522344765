import { bee4Theme } from './../../styles/theme';
import { ModalContent } from '@chakra-ui/react';
import styled from 'styled-components';

interface ModalBoxProps {
    $colorMode: string;
    $isSmall: boolean;
}

export const ModalBox = styled(ModalContent)<ModalBoxProps>`
    padding: 10px 15px 25px;
    background: ${(props) =>
        props.$colorMode === 'dark'
            ? `${bee4Theme.colors.lead[300]}`
            : `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`} !important;
    border-radius: 0 10px 10px 10px !important;
    align-self: ${({ $isSmall }) => (!$isSmall ? 'flex-start' : 'inherit')};
`;

export const TopLine = styled.span`
    position: absolute;

    top: -4px;
    left: 0;

    width: 55%;
    height: 4px;

    border-radius: 10px;
    background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});
`;
