import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

import BlueLightEffect from '../../../assets/backgrounds/detail-blue.png';
import { devices } from '../../../styles/devices';
import { bee4Theme } from '../../../styles/theme';

type TCustomBoxProps = {
    $lightEffect?: boolean; // '$' signals the transient prop
    $topLine?: boolean; // '$' signals the transient prop
    $colorMode?: string; // '$' signals the transient prop
    disabled?: boolean;
};

export const Card = styled(Box)<TCustomBoxProps>`
    background: ${(props) => {
        if (props.$topLine) {
            return props.$colorMode === 'light'
                ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`
                : bee4Theme.colors.lead[300];
        }

        return props.$colorMode === 'light'
            ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`
            : `linear-gradient(90deg, ${bee4Theme.colors.lead[400]}, ${bee4Theme.colors.lead[200]})`;
    }};

    ${({ disabled }) => disabled && `opacity: 0.4 !important; pointer-events:none;`}
    border-radius: ${({ $topLine }) => ($topLine ? '0 10px 10px 10px' : '0.7rem')};
    border: ${({ $topLine }) => ($topLine ? `1px solid ${bee4Theme.colors.lead[100]}` : 0)};
    position: relative;

    --width: 527px;
    --height: 100%;
    --HPosition: -235px;
    --zindex: -1;
    --opacity: 0.8;

    @media ${devices.maxMobileL} {
        --width: 0;
    }

    &::before {
        content: '';

        display: 'block';

        background-image: url(${BlueLightEffect});
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;

        position: absolute;
        left: var(--HPosition);
        top: 0;

        width: var(--width);
        height: var(--height);

        z-index: var(--zindex);
        opacity: var(--opacity);

        transform: scaleX(-1);
    }
    &::after {
        content: '';

        display: block;

        background-image: url(${BlueLightEffect});
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;

        position: absolute;
        right: var(--HPosition);
        top: 0;

        width: var(--width);
        height: var(--height);

        z-index: var(--zindex);
        opacity: var(--opacity);
    }
`;
