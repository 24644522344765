import { useEffect, useState } from 'react';
import { Flex, HStack, Text, useColorMode } from '@chakra-ui/react';
import { shallowEqual } from 'react-redux';

import { date } from '../../../../../../utils/date';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux';
import { timelineActions } from '../../../../../../store/timeline';
import { WeekDayType } from '../../../../../../store/timeline/types';
import { TClock } from '../../../../../../store/clock/types';
import { Loading } from '../../../../../../components/Loading';
import { Information } from '../../../../../../components/Information';

import { Bar, Label } from './styled';
import { Toast } from '../../../../../../components/Toast';

export const TimelineBar = () => {
    const dispatch = useAppDispatch();

    const { colorMode } = useColorMode();
    const timeline = useAppSelector((state) => state.timeline, shallowEqual);
    const clock: TClock = useAppSelector((state) => state.clock);

    const [empty, setEmpty] = useState(false);

    useEffect(() => {
        dispatch(timelineActions.getTimelineData());
        return () => {
            dispatch(timelineActions.resetTimeline());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log({ currentStep: timeline.currentStep.type });
    }, [timeline.currentStep]);

    useEffect(() => {
        !!timeline.error &&
            Toast({
                id: 'api_error',
                title: 'Instabilidade na aplicação',
                description: `Estamos passando por um momento de instabilidade, aguarde alguns instantes por gentileza...`,
                status: 'error',
                date: date().format('DD/MM/YYYY HH:mm:ss'),
            });
    }, [timeline.error]);

    const calcDateTime = (startDate: string, finishDate: string) => {
        const now = date(clock.time, 'x');
        const _start = date(`${now.format('YYYY-MM-DD')} ${startDate}`, 'YYYY-MM-DD HH:mm:ss');
        const _finish = date(`${now.format('YYYY-MM-DD')} ${finishDate}`, 'YYYY-MM-DD HH:mm:ss');

        const tomorrow = now.clone().add(1, 'days');
        const yesterday = now.clone().subtract(1, 'days');

        // Another Steps
        if (timeline.currentStep.type !== 'closed') {
            const start = now
                .clone()
                .set({ hour: _start.hour(), minutes: _start.minutes(), seconds: _start.seconds() });
            const finish = now
                .clone()
                .set({ hour: _finish.hour(), minutes: _finish.minutes(), seconds: _finish.seconds() });

            return { start, finish };
        }

        // Close withou schedule
        const { currentStep } = timeline;
        if (!!currentStep.type) {
            const nowString = now.format('YYYY-MM-DD');
            const csStart = date(`${nowString} ${currentStep.start}`, 'YYYY-MM-DD HH:mm:ss');
            const csFinish = date(`${nowString} ${currentStep.finish}`, 'YYYY-MM-DD HH:mm:ss');

            const isValid = now.isValid() && csStart.isValid() && csFinish.isValid();
            if (isValid && !now.isSameOrAfter(csStart) && !now.isSameOrBefore(csFinish)) {
                !empty && setEmpty(true);
            } else {
                !!empty && setEmpty(false);
            }
        }

        // Closed
        let day =
            now.isBefore(tomorrow.clone().startOf('day')) && now.isAfter(_start) ? now.clone() : yesterday.clone();
        const start = date(day).set({ hour: _start.hour(), minute: _start.minute(), second: _start.second() });

        if (start.clone().startOf('day').isSame(now.clone().startOf('day'))) {
            day =
                now.isBefore(yesterday.clone().endOf('day')) && now.isBefore(_finish) ? now.clone() : tomorrow.clone();
        } else {
            day = now.clone();
        }

        const finish = date(day).set({
            hour: _finish.hour(),
            minutes: _finish.minutes(),
            seconds: _finish.seconds(),
        });

        return { start, finish };
    };

    const filler = (startDate: string, finishDate: string) => {
        if (!startDate || !finishDate) {
            return null;
        }

        const _now = date(clock.time, 'x');
        const { start, finish } = calcDateTime(startDate, finishDate);

        if (empty) {
            return 0;
        }

        if (_now.isAfter(finish)) {
            return 100;
        }

        if (_now.isBetween(start, finish)) {
            return Math.ceil((_now.diff(start) / finish.diff(start)) * 100);
        }

        return 0;
    };

    const checkTime = (_date: string) => {
        const minute = parseInt(date(_date, 'HH:mm:ss').format('mm'));
        return date(_date, 'HH:mm:ss').format(minute > 0 ? 'HH[h]mm[min]' : 'HH[h]');
    };

    const mountTimeline = (item: WeekDayType, index: number) => {
        const start = item.start;
        const finish = item.finish;

        const fill = filler(start, finish);
        const length = 100;

        return (
            <Flex
                key={`timeline-bars-${index}`}
                width={['100%', '100%', '100%', `${length}%`]}
                margin="1px 0"
                padding="0 5px"
                flexFlow="row wrap"
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex width="100%" justifyContent="space-between">
                    <Label $colorMode={colorMode} status={fill}>
                        {checkTime(start)}
                    </Label>
                    <Label $colorMode={colorMode} status={fill}>
                        {date(finish, 'HH:mm:ss').format('HH[h]mm[min]')}
                    </Label>
                </Flex>

                <Bar fill={fill} />

                <HStack spacing={1} padding="5px 0">
                    {!!item.label && (
                        <>
                            <Text fontWeight="400" fontSize="0.75rem" lineHeight="17px" letterSpacing="0.05em">
                                {item.label}
                            </Text>
                            <Information label={item.tip} />
                        </>
                    )}
                </HStack>
            </Flex>
        );
    };

    if (['unstable', 'maintenance'].includes(timeline.currentStep.type)) {
        return <></>;
    }

    return (
        <Flex width="100%" flexFlow="row wrap" alignItems="center" justifyContent="space-between" position="relative">
            <Flex
                width="100%"
                margin="30px 0"
                flexFlow={['column', 'column', 'column', 'row']}
                alignItems="center"
                justifyContent="space-between"
            >
                {!timeline.loading && mountTimeline(timeline.currentStep, 0)}
            </Flex>

            <Loading active={timeline.loading} />
        </Flex>
    );
};
