import { useEffect } from 'react';
import { HStack, Text } from '@chakra-ui/react';

import { CardBox } from '../../../../components/CardBox';

import { Information } from '../../../../components/Information';
import { TradingInvoicesFilter } from './Filter';
import { TradingInvoicesTable } from './Table';
import { GradientButton } from '../../../../components/GradientButton';
import { TradingInvoicesModal } from './Modal';
import { walletActions } from '../../../../store/wallet';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

export const TradingIncoices = () => {
    const dispatch = useAppDispatch();
    const { wallet } = useAppSelector((state) => state);

    const labelInformation =
        'A Nota de negociação apresenta todos os registros de suas operações, assim como custos e tarifas envolvidos em seus investimentos. Este documento é disponibilizado uma vez por semana, após o encerramento do ciclo de pregão.';

    useEffect(() => {
        dispatch(walletActions.setInvoicesLoading(true));
        dispatch(walletActions.getTradingInvoices());

        return () => {
            dispatch(walletActions.resetTradingInvoices());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CardBox $topLine={true} width={['100%', '100%', '76%']} marginTop="20px" padding="30px 0 0">
            <HStack spacing={1}>
                <Text fontWeight="700" fontSize="1.2rem" paddingLeft="20px">
                    Minhas notas de negociação
                </Text>
                <Information label={labelInformation} />
            </HStack>

            <HStack spacing={0} width="100%" margin="0px 20px 5px">
                <Text fontWeight={500} fontSize=".7rem" padding="0" color="gray.200">
                    Algumas notas de negociação requerem senha. Para abri-las, utilize os 5 primeiros dígitos do seu CPF
                    como senha.
                </Text>
            </HStack>

            <TradingInvoicesFilter id="wallet-filter" target="modal" />
            <TradingInvoicesTable limit={5} data={wallet.invoices.lastInvoices} loading={wallet.invoices.loading} />

            <HStack padding="10px 0 30px" alignItems="center" justifyContent="center">
                <GradientButton
                    width="100%"
                    maxWidth="240px"
                    variant="colorful"
                    label="Exibir todas notas listadas"
                    onClick={() => dispatch(walletActions.openModal(true))}
                />
                <TradingInvoicesModal />
            </HStack>
        </CardBox>
    );
};
