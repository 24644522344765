import { memo, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, Input, Text, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react';

import CurrencyInput from '../../../../components/CurrencyInput';
import { Button } from '../../../../components/Button';

import { BoxContainer, Form, Label, ButtonGroup } from './styles';

import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { convertStringCurrencyToNumber, currency, formatNumberWithoutFraction } from '../../../../utils/numbers';

import { getAccountPositionsActions } from '../../../../store/getAccountPositions';
import { sendOrderActions } from '../../../../store/sendOrder';
import { Toast } from '../../../../components/Toast';
import { CardBox } from '../../../../components/CardBox';
import { date } from '../../../../utils/date';
import { notificationBellActions } from '../../../../store/notificationBell';
import { Loading } from '../../../../components/Loading';
import { ModalOrderConfirm } from '../ModalOrderConfirm';
import { validateOneDayStorage } from '../../../../utils/storage';
import { __KEY_MODAL_ORDER } from './constants';
import { useRoles } from '../../../../hooks/useRules';

interface FormTradingProps {
    modalId: string;
    type: 'buy' | 'sell';
    title: string;
}
type TDataFromType = {
    buy: {
        ammount: {
            label: string;
            value: string;
        };
        allocated: {
            label: string;
            value: string;
        };
    };
    sell: {
        ammount: {
            label: string;
            value: string | number;
        };
        allocated: {
            label: string;
            value: string | number;
        };
    };
};
type TValidateResponse = {
    id: string;
    title?: string;
    message?: string;
    approved: boolean;
};

const FormTradingComponent = ({ modalId, type, title }: FormTradingProps) => {
    const { colorMode } = useColorMode();
    const dispatch = useAppDispatch();
    const shouldIBlocked = useRoles();

    const { user, accountPositions, sendOrder, timeline, subscribeLevel1, companies } = useAppSelector(
        (state) => state
    );

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm();

    const dataFromTypeInitial = {
        buy: {
            ammount: { label: 'Saldo disponível para negociar', value: '0' },
            allocated: { label: 'Saldo alocado em ofertas, retiradas ou bloqueios', value: '0' },
        },
        sell: {
            ammount: { label: 'Tokens disponíveis para negociar', value: '0' },
            allocated: { label: 'Tokens comprometidos alocados em outras ofertas', value: '0' },
        },
    };

    const [total, setTotal] = useState(0);
    const [price, setPrice] = useState('');
    const [dataFromType, setDataFromType] = useState<TDataFromType>(dataFromTypeInitial);
    const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);

    const watchFields = watch(['price', 'quantity']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        validateOneDayStorage(__KEY_MODAL_ORDER);
    });

    useEffect(() => {
        validateOneDayStorage(__KEY_MODAL_ORDER);
    });

    useEffect(() => {
        setTotal(convertStringCurrencyToNumber(watchFields[0]) * watchFields[1] || 0);
    }, [watchFields]);

    useEffect(() => {
        if (user.userSession.alphapoint.token === null || timeline.currentStep?.type === undefined) {
            return;
        }

        dispatch(getAccountPositionsActions.getAccountPositions({ type }));
        return () => {
            dispatch(getAccountPositionsActions.resetAccountPositions());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep, companies.current.assets]);

    useEffect(() => {
        const _state = dataFromType;

        _state.buy.ammount.value = shouldIBlocked('withdraw').blocked
            ? currency(0)
            : currency(accountPositions.positions.buy.operatingLimit - accountPositions.positions.buy.hold || 0);

        _state.buy.allocated.value = shouldIBlocked('withdraw').blocked
            ? currency(accountPositions.positions.buy.operatingLimit)
            : currency(accountPositions.positions.buy.hold || 0);

        _state.sell.ammount.value = formatNumberWithoutFraction(
            accountPositions.positions.sell.tokens - accountPositions.positions.sell.hold || 0
        );

        _state.sell.allocated.value = formatNumberWithoutFraction(Math.abs(accountPositions.positions.sell.hold) || 0);

        setDataFromType(() => _state);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountPositions.positions]);

    useEffect(() => {
        setPrice('');
        reset({ quantity: null });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies.current.assets.defaultInstrumentId]);

    const validate = (quantity: number, price: number): TValidateResponse => {
        const defaultFailedReturn = {
            approved: false,
            title: `${companies.current.assets.ticker} - Falha ao realizar a oferta`,
        };

        const convertedPrice = currency(price);
        const formatedQuantity = formatNumberWithoutFraction(quantity);
        const side = {
            buy: 'compra',
            sell: 'venda',
        };

        // Check Price to Operating Limit
        if (type === 'buy' && price > accountPositions.positions.buy.operatingLimit)
            return {
                ...defaultFailedReturn,
                id: `responseBuyOperatingLimitFail`,
                message: `Seu saldo é insuficiente para essa oferta de ${side[type]} de ${formatedQuantity} token(s) ao preço de ${convertedPrice}`,
            };

        // Check Quantity to num Tokens
        if (type === 'sell' && quantity > accountPositions.positions.sell.tokens)
            return {
                ...defaultFailedReturn,
                id: `responseSellTokenFail`,
                message: `Seu saldo de tokens é insuficiente para essa oferta de ${side[type]} de ${formatedQuantity} token(s) ao preço de ${convertedPrice}`,
            };

        // Check prices with tunnel in dealing
        if (timeline.currentStep.type === 'dealing') {
            if (price > subscribeLevel1.tunnel.range.max) {
                return {
                    ...defaultFailedReturn,
                    id: `responseRangeDealingMaxFail`,
                    message: `O valor da oferta de ${side[type]} por ${formatedQuantity} token(s) ao preço de ${convertedPrice} é maior que o máximo do túnel de preço`,
                };
            }
            if (price < subscribeLevel1.tunnel.range.min) {
                return {
                    ...defaultFailedReturn,
                    id: `responseRangeDealingMinFail`,
                    message: `O valor da oferta de ${side[type]} por ${formatedQuantity} token(s) ao preço de ${convertedPrice} é menor que o mínimo do túnel de preço`,
                };
            }
            return { id: 'noneId', approved: true };
        }

        // Check prices with tunnel in auction
        if (price > subscribeLevel1.tunnel.max) {
            return {
                ...defaultFailedReturn,
                id: `responseRangeAuctionMaxFail`,
                message: `O valor da oferta de ${side[type]} por ${formatedQuantity} token(s) ao preço de ${convertedPrice} é maior que o máximo do túnel de preço`,
            };
        }
        if (price < subscribeLevel1.tunnel.min) {
            return {
                ...defaultFailedReturn,
                id: `responseRangeAuctionMinFail`,
                message: `O valor da oferta de ${side[type]} por ${formatedQuantity} token(s) ao preço de ${convertedPrice} é menor que o mínimo do túnel de preço`,
            };
        }

        return { id: 'noneId', approved: true };
    };

    const handleExecuteOrder = (values) => {
        if (timeline.currentStep.type === 'irrevocable' && !getValues('orderChecker')) {
            setOrderModalIsOpen(true);
            setValue('orderChecker', true);
        } else {
            setOrderModalIsOpen(false);
            executeOrder(values);
            setValue('orderChecker', false);
        }
    };

    const onCloseHandler = (isCheked) => {
        setOrderModalIsOpen(false);
        setValue('orderChecker', isCheked);
    };

    const executeOrder = (values) => {
        if (['unstable', 'closed', 'maintenace'].includes(timeline.currentStep?.type))
            return Toast({
                id: 'OfflineReturn',
                title: `Fechada para negócios.`,
                description: 'A BEE4 fecha às 20h! Horário de funcionamento da BEE4 é de 10h às 20h nos dias úteis.',
                status: 'error',
            });

        const Quantity = convertStringCurrencyToNumber(values.quantity);
        const Price = convertStringCurrencyToNumber(values.price);
        const response = validate(Quantity, Price);

        if (!response.approved) {
            dispatch(
                notificationBellActions.pushNotificationBellList({
                    id: response.id,
                    title: response.title,
                    description: response.message,
                    status: 'error',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                })
            );

            return Toast({
                id: response.id,
                title: response.title,
                description: response.message,
                status: 'error',
            });
        }

        dispatch(
            sendOrderActions.sendOrder({
                OrderType: 2,
                TimeInForce: 1,
                Side: type === 'buy' ? 0 : 1,
                InstrumentId:
                    timeline.currentStep?.type === 'dealing'
                        ? companies.current.assets.defaultInstrumentId
                        : type === 'buy'
                        ? companies.current.assets.instrumentBuyId
                        : companies.current.assets.instrumentSellId,
                quantity: Quantity,
                LimitPrice: Price,
            })
        );

        setPrice('');
        reset({ quantity: null });
    };

    const showTotalPrice = (total) => {
        return total.toFixed(2).length > 11 ? `${currency(total).substring(0, 14)}...` : currency(total);
    };

    return (
        <>
            <BoxContainer>
                <CardBox
                    $topLine={true}
                    width="100%"
                    height="500px"
                    padding="30px 0 0"
                    margin={['10px 0', '10px 0', '10px 0', 0]}
                    disabled={
                        ['unstable', 'closed', 'maintenace'].includes(timeline.currentStep?.type) ||
                        accountPositions.loading
                    }
                    $hasBorder={true}
                >
                    <Text
                        fontWeight="700"
                        fontSize="0.8rem"
                        letterSpacing="0.05em"
                        lineHeight="1.4"
                        textAlign="left"
                        padding="0 15px"
                    >
                        {title}
                    </Text>

                    <VStack spacing={2} padding="15px 0 0">
                        <VStack spacing={2} width="100%" padding="0 10px" alignItems="start">
                            <Text fontWeight="400" fontSize="0.75rem" color={useColorModeValue('gray.400', 'gray.300')}>
                                {dataFromType[type]['ammount']?.label}
                            </Text>
                            <Text fontFamily="inter" fontWeight="700" fontSize="1.4rem" lineHeight="1">
                                {dataFromType[type]['ammount']?.value.toString()}
                            </Text>
                        </VStack>

                        <VStack spacing={2} width="100%" padding="0 10px" alignItems="start">
                            <Text fontWeight="400" fontSize="0.75rem" color={useColorModeValue('gray.400', 'gray.300')}>
                                {dataFromType[type]['allocated']?.label}
                            </Text>
                            <Text fontFamily="inter" fontWeight="700" fontSize="1rem" lineHeight="1">
                                {dataFromType[type]['allocated']?.value.toString()}
                            </Text>
                        </VStack>
                    </VStack>

                    <Form type={type} noValidate onSubmit={handleSubmit(handleExecuteOrder)} id={modalId}>
                        <FormControl isInvalid={errors.quantity} marginTop="15px">
                            <Label htmlFor="quantity">Quantidade</Label>
                            <Input
                                data-testid="quantity"
                                type="number"
                                placeholder="Digite a quantidade"
                                autoComplete="off"
                                autoCorrect="off"
                                disabled={['unstable', 'closed', 'maintenace'].includes(timeline.currentStep?.type)}
                                min={0}
                                step="1"
                                onKeyDown={(event) => event.key === 'e' && event.preventDefault()}
                                {...register('quantity', {
                                    // required: true,
                                    min: 0,
                                    validate: (value) => parseInt(value) > 0,
                                    onChange: (event) => {
                                        const _qty =
                                            event.target.value.length > 10
                                                ? event.target.value.slice(0, 10)
                                                : event.target.value;
                                        setValue('quantity', `${Math.floor(_qty)}`);
                                    },
                                })}
                            />
                        </FormControl>

                        <FormControl isInvalid={errors.price}>
                            <Label htmlFor="price">Preço de {type === 'buy' ? 'compra' : 'venda '}</Label>
                            <CurrencyInput
                                id="price"
                                ref={useRef()}
                                placeholder="R$ 0,00"
                                $colorMode={colorMode}
                                value={price}
                                aria-invalid={!!errors.price}
                                disabled={['unstable', 'closed', 'maintenace'].includes(timeline.currentStep?.type)}
                                onValueChange={(_value: string) => setPrice(_value)}
                                {...register('price', {
                                    // required: true,
                                    validate: (value) => convertStringCurrencyToNumber(value) > 0,
                                })}
                            />
                        </FormControl>

                        <VStack spacing={2} width="100%" margin="10px 0 0" alignItems="start">
                            <Text fontWeight="400" fontSize=".8rem" lineHeight={2}>
                                Valor total da negociação
                            </Text>
                            <Text
                                fontFamily="inter"
                                fontWeight="700"
                                fontSize="1.5rem"
                                textAlign="left"
                                lineHeight={1}
                                title={`${currency(total)}`}
                            >
                                {showTotalPrice(total)}
                            </Text>
                        </VStack>

                        <ButtonGroup>
                            <Button
                                isBlocked={shouldIBlocked(type, 'insert').blocked}
                                tip={shouldIBlocked(type, 'insert').messages.offer}
                                colorScheme={type === 'buy' ? 'green' : 'red'}
                                disabled={
                                    ['unstable', 'closed', 'maintenace'].includes(timeline.currentStep?.type) ||
                                    isSubmitting ||
                                    (sendOrder.loading && type === sendOrder.side)
                                }
                            >
                                {isSubmitting || (sendOrder.loading && type === sendOrder.side)
                                    ? 'aguarde...'
                                    : type === 'buy'
                                    ? 'Comprar'
                                    : 'Vender'}
                            </Button>
                        </ButtonGroup>

                        <input type="hidden" {...register('orderChecker')} />
                    </Form>

                    <Loading active={accountPositions.loading} />
                </CardBox>
            </BoxContainer>

            <ModalOrderConfirm
                isOpen={orderModalIsOpen && !localStorage.getItem(__KEY_MODAL_ORDER)}
                onClose={onCloseHandler}
                id={modalId}
            />
        </>
    );
};

export const FormTrading = memo(FormTradingComponent);
