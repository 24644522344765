import { Flex, Image, Text, VStack, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { bee4Theme } from '../../../../styles/theme';

import { Link } from '../../../../components/Link';

import IconInformation from '../../../../assets/icons/icon-information.svg';
import { Logo, TitleLink } from './styles';
import { TCompany } from '../../../../store/companies/types';

interface CompanyInformationProps {
    company: TCompany;
}

export const CompanyInformation = ({ company }: CompanyInformationProps) => {
    const navigate = useNavigate();
    const { colorMode } = useColorMode();

    return (
        <Flex width="100%" margin="45px 0 25px" alignItems="start">
            <Logo onClick={() => navigate(`/empresas/${company.slug}`)}>
                <Image src={company.fullLogo} />
            </Logo>

            <VStack spacing={1} padding="0 15px" alignItems="left">
                <Text fontWeight="700" fontSize="1.6rem" letterSpacing="0.05em" lineHeight="1.2" textAlign="left">
                    {company.assets?.ticker}
                </Text>
                <TitleLink
                    fontWeight="600"
                    fontSize="1.4rem"
                    letterSpacing="0.05em"
                    lineHeight="1.2"
                    textAlign="left"
                    cursor="pointer"
                    $colorMode={colorMode}
                    onClick={() => navigate(`/empresas/${company.slug}`)}
                >
                    {company.socialName}
                </TitleLink>
                <Flex alignItems="center" paddingTop={2}>
                    <Image
                        src={IconInformation}
                        width="22px"
                        marginRight="5px"
                        filter={useColorModeValue('invert(50%)', 'invert(30%)')}
                    />
                    <Text
                        fontFamily="Inter, sans-serif"
                        marginRight="5px"
                        fontSize=".85rem"
                        color={useColorModeValue(bee4Theme.colors.gray[700], bee4Theme.colors.gray[300])}
                    >
                        Em caso de dúvidas envie e-mail para:{' '}
                        <Link to="atendimento@bee4.com.br" fontWeight="500" fontSize=".85rem">
                            atendimento@bee4.com.br
                        </Link>
                    </Text>
                </Flex>
            </VStack>
        </Flex>
    );
};
