import { ReactNode } from 'react';
import { Box, Text, useColorMode, Flex } from '@chakra-ui/react';
import { CardBox } from '../CardBox';
import { Link } from './styles';

import { useAppSelector } from '../../hooks/redux';
import { Information } from '../Information';
import { Table } from './table';

type TTradingTableProps = {
    title?: string;
    children: ReactNode;
    loading?: boolean;

    width?: string;
    height?: string;

    margin?: string;
    style?: any;

    information?: {
        time: 'irrevocable' | 'revocable' | 'dealing' | 'processing' | 'closed';
        message: string;
    };

    hasLink?: {
        label: string;
        link: string;
        target: '_self' | '_blank' | '_parent' | '_top' | string;
    };
};

export const TradingTable = (props: TTradingTableProps) => {
    const { colorMode } = useColorMode();
    const timeline = useAppSelector((state) => state.timeline);

    return (
        <CardBox
            width="100%"
            height={['500px', '500px', '500px', '100%']}
            $topLine={true}
            $hasBorder={true}
            padding="30px 0 0"
            margin="15px 0 0"
        >
            <Flex flexFlow="row wrap" alignItems="center" justifyContent="center">
                <Text fontWeight="700" fontSize=".9rem" textAlign="center">
                    {props.title}
                </Text>
                {!!props.information?.time && props.information?.time === timeline.currentStep?.type && (
                    <Information label={props.information.message} />
                )}
                {!!props.hasLink && (
                    <Link to={props.hasLink.link} target={props.hasLink.target}>
                        {props.hasLink.label}
                    </Link>
                )}
            </Flex>
            <Box overflow="hidden" borderRadius="8px">
                <Table
                    $colorMode={colorMode}
                    width={props.width}
                    height={props.height}
                    margin={props.margin}
                    style={props.style}
                >
                    {props.children}
                </Table>
            </Box>
        </CardBox>
    );
};
