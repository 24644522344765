import { ChevronDownIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { devices } from '../../../../styles/devices';
import { bee4Theme } from '../../../../styles/theme';

type TSelectCompaniesAssets = {
    $colorMode?: string;
    width?: string;
    onChange?: () => void;
    fontWeight?: string | number;
    fontSize?: string | number;
    $background?: string;
};

type TSelectWrapper = {
    $colorMode: string;
};

type TAssetsOption = {
    $colorMode: string;
};

const radius = '10px';

export const IconChevron = styled(ChevronDownIcon)`
    position: relative;
    margin-left: 5px;
    font-size: 1.4rem;

    position: absolute;
    top: 19px;
    right: 10px;

    transition: all 0.3s ease-in-out;
`;

export const SelectWrapper = styled.div<TSelectWrapper>`
    width: 250px;
    height: auto;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    padding: 0;
    border: 0;

    z-index: 10;

    border-radius: ${radius};
    background: ${({ $colorMode }) =>
        $colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.silver[100]};

    position: absolute;

    @media ${devices.maxLaptop} {
        left: 50%;
        top: 60px;
        transform: translateX(-50%);
    }
    @media ${devices.minTablet} {
        right: 0;
        top: 0;
    }

    &::after {
        content: '';

        position: absolute;
        --align: -2px;

        top: var(--align);
        bottom: var(--align);
        left: var(--align);
        right: var(--align);

        background-size: 250% 100%;
        background-position: 0%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.lead[400]} 50%,
                        ${bee4Theme.colors.lead[200]} 110%
                    )`
                : `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.silver[100]} 50%,
                        ${bee4Theme.colors.silver[200]} 110%
                    )`};

        z-index: -1;
        border-radius: ${radius};
    }
`;

export const SelectCompaniesAssets = styled.div<TSelectCompaniesAssets>`
    width: 100%;
    height: 100%;

    max-height: 58px;
    transition: all 0.8s ease-in;

    position: relative;
    overflow: hidden;

    text-transform: uppercase;
    transition: all 0.5s ease-in-out;

    font-family: 'Georama', sans-serif;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};

    position: relative;
    border-radius: ${radius};

    &.opened {
        max-height: 10000px;

        & ~ ${IconChevron} {
            transform: rotate(180deg);
        }
    }
`;

export const CompaniesAssetsOption = styled.div<TAssetsOption>`
    width: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    transition: all 0.3s ease-in-out;
    background: ${({ $colorMode }) =>
        $colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.silver[100]};

    border-bottom: 1px solid ${bee4Theme.colors.gray[200]};

    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background: ${({ $colorMode }) =>
            $colorMode === 'dark' ? bee4Theme.colors.lead[100] : bee4Theme.colors.gray[200]};
    }
`;

export const Logo = styled.figure`
    width: 40px;
    height: 40px;
    margin: 10px;

    overflow: hidden;
    position: relative;

    border-radius: 8px;
    background-color: ${bee4Theme.colors.white};

    cursor: pointer;

    img {
        width: 95%;
        height: 95%;

        position: absolute;
        object-fit: contain;
        object-position: center;

        z-index: 0;
        display: block;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }
`;
