import { useEffect, useMemo, useState } from 'react';
import { HStack, useColorMode, useColorModeValue } from '@chakra-ui/react';

import { GradientButton } from '../GradientButton';
import { getLS, setLS } from '../../utils/storage';

import {
    Avatar,
    UserMenuContainer,
    UserMenuContent,
    Link,
    SwitchColorMode,
    IconChevron,
    UserNameText,
    Wrapper,
} from './styles';
import { bee4Theme } from '../../styles/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { userActions } from '../../store/user';

export const UserMenu = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(({ user }) => user);
    const { colorMode, toggleColorMode } = useColorMode();
    const [username, setUsername] = useState('');
    const backgroundSignoutButton = useColorModeValue(bee4Theme.colors.gray[300], bee4Theme.colors.lead[300]);

    useEffect(() => {
        const userPreferences = !!getLS('@bee4:userPreferences') && JSON.parse(getLS('@bee4:userPreferences'));
        !!userPreferences.theme && colorMode !== userPreferences.theme && handleChangeColorMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!user.userData?.taxId) {
            const _fullname =
                user.userData.taxId.length === 14
                    ? user.userData.companyName
                    : user.userData.taxId.length === 11
                    ? user.userData.name
                    : '';
            const _splited = _fullname.split(' ');
            setUsername(`${_splited[0]} ${_splited.slice(-1)}`);
        }
    }, [user]);

    const handleChangeColorMode = () => {
        const userPreferences = !!getLS('@bee4:userPreferences')
            ? JSON.parse(getLS('@bee4:userPreferences'))
            : { theme: colorMode };
        userPreferences.theme = colorMode === 'light' ? 'dark' : 'light';
        setLS('@bee4:userPreferences', JSON.stringify(userPreferences));
        toggleColorMode();
    };

    const switchTheme = useMemo(() => {
        return <SwitchColorMode size="lg" $colorMode={colorMode} onChange={handleChangeColorMode} />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorMode]);

    return (
        <UserMenuContainer>
            <Wrapper>
                <Avatar email={user.userData.email} size={38} rating="g" default="mp" />
                <UserNameText display={['none', 'none', 'inline']}>{username}</UserNameText>
                <IconChevron />

                <UserMenuContent $colorMode={colorMode}>
                    <Link $colorMode={colorMode} to="/meu-perfil">
                        Perfil
                    </Link>
                    <Link $colorMode={colorMode} to="/minha-carteira">
                        Minha carteira
                    </Link>
                    <HStack spacing={5} padding="15px 25px">
                        {switchTheme}
                        <GradientButton
                            width="80px"
                            padding="5px 8px"
                            id="logout"
                            data-testid="logout"
                            label="Sair"
                            type="button"
                            background={backgroundSignoutButton}
                            onClick={() => dispatch(userActions.signOut())}
                        />
                    </HStack>
                </UserMenuContent>
            </Wrapper>
        </UserMenuContainer>
    );
};
