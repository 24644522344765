const size = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
};

export const devices = {
    minMobileS: `(min-width: ${size.mobileS}px)`,
    minMobileM: `(min-width: ${size.mobileM}px)`,
    minMobileL: `(min-width: ${size.mobileL}px)`,
    minTablet: `(min-width: ${size.tablet}px)`,
    minLaptop: `(min-width: ${size.laptop}px)`,
    minLaptopL: `(min-width: ${size.laptopL}px)`,
    minDesktop: `(min-width: ${size.desktop}px)`,
    minDesktopL: `(min-width: ${size.desktop}px)`,

    maxMobileS: `(max-width: ${size.mobileS - 1}px)`,
    maxMobileM: `(max-width: ${size.mobileM - 1}px)`,
    maxMobileL: `(max-width: ${size.mobileL - 1}px)`,
    maxTablet: `(max-width: ${size.tablet - 1}px)`,
    maxLaptop: `(max-width: ${size.laptop - 1}px)`,
    maxLaptopL: `(max-width: ${size.laptopL - 1}px)`,
    maxDesktop: `(max-width: ${size.desktop - 1}px)`,
    maxDesktopL: `(max-width: ${size.desktop - 1}px)`,
};
