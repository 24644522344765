import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial';

export const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        setWithdraw: (state, action): void => {
            state.closed = action.payload;
        },
    },
});

export const withdrawActions = withdrawSlice.actions;
export default withdrawSlice.reducer;
