import { devices } from './../../styles/devices';
import styled from 'styled-components';

export interface MaskType {
    width?: string;
    height?: string;
    hexagon?: string;
    display?: string | string[];
}

export const Mask = styled.span<MaskType>`
    mask-image: url('data:image/svg+xml;base64,${({ hexagon }) => hexagon}');
    -webkit-mask-image: url('data:image/svg+xml;base64,${({ hexagon }) => hexagon}');
    -moz-mask-image: url('data:image/svg+xml;base64,${({ hexagon }) => hexagon}');
    background-size: cover;

    width: ${({ width }) => width || '200px'};
    height: ${({ height }) => height || '200px'};

    margin: 0 auto;

    @media ${devices.maxTablet} {
        display: ${({ display }) => (Array.isArray(display) ? display[0] : display || 'block')};
    }
    @media ${devices.minLaptop} {
        display: ${({ display }) => (Array.isArray(display) ? display[1] : display || 'block')};
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;
