import { bee4Theme } from './../../../../styles/theme';
import { devices } from '../../../../styles/devices';
import { Text } from '@chakra-ui/react';

import styled from 'styled-components';

type TTitleLink = {
    $colorMode: string;
};

export const Logo = styled.figure`
    margin: 0 0 10px 0;

    overflow: hidden;
    position: relative;

    border-radius: 8px;
    background-color: ${bee4Theme.colors.white};

    cursor: pointer;

    img {
        width: 85%;
        height: 85%;

        position: absolute;
        object-fit: contain;
        object-position: center;

        z-index: 0;
        display: block;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    @media ${devices.maxTablet} {
        width: 260px;
        height: 100px;
    }
    @media ${devices.minLaptop} {
        width: 70px;
        height: 70px;
    }

    &::before {
        content: '';

        position: absolute;
        border-radius: 8px;
        z-index: 2;

        inset: -1px;
        padding: 4px;

        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

export const TitleLink = styled(Text)<TTitleLink>`
    transition: all 0.3s ease-in-out;
    &:hover {
        &:hover {
            color: ${({ $colorMode }) =>
                $colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300]};
        }
    }
`;
