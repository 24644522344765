import styled, { keyframes } from 'styled-components';
import LoadingWhite from './image/loading-white.svg';
import LoadingGradient from './image/loading-gradient.svg';

const Animation = keyframes`
    0% { background-image: url(${LoadingWhite}); }
    50% { background-image: url(${LoadingGradient}); }
    100% { background-image: url(${LoadingWhite}); }
`;

export const LoadingAnimation = styled.span`
    display: block;

    width: 32px;
    height: 32px;

    position: absolute;
    transform: translateX(-50%);

    left: 50%;
    bottom: 15px;
    z-index: 8;

    background-size: cover;
    background-repeat: no-repeat;

    animation-name: ${Animation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    z-index: 8;
`;
