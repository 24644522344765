import { ReactNode, useRef } from 'react';
import { Swiper } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Arrow, Box } from './styles';

import 'swiper/css';
import 'swiper/css/pagination';
import { useColorMode } from '@chakra-ui/react';
import { Icon } from '../Icon';
import { Loading } from '../Loading';

export interface BoxType {
    width?: string;
    height?: string;
    background?: string;
    children: ReactNode;
    spaceBetween: number;
    slidesPerView: number;
    loop?: boolean;
    autoplay?: { delay: number } | boolean;
    loading?: boolean;
}

export const BoxCarousel = ({
    loop,
    background,
    width,
    height,
    children,
    spaceBetween,
    slidesPerView,
    autoplay,
    loading,
}: BoxType) => {
    const { colorMode } = useColorMode();

    const prevSlide = useRef(null);
    const nextSlide = useRef(null);

    return (
        <Box background={background} width={width} height={height} $colorMode={colorMode}>
            <Swiper
                modules={[Pagination, Autoplay, Navigation]}
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                loop={loop}
                autoplay={autoplay}
                pagination={{
                    el: '.pagination-wrapper',
                    clickable: true,
                }}
                navigation={{
                    prevEl: !!prevSlide.current ? prevSlide.current : undefined,
                    nextEl: !!nextSlide.current ? nextSlide.current : undefined,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation['prevEl'] = prevSlide.current;
                    swiper.params.navigation['nextEl'] = nextSlide.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
            >
                {children}
            </Swiper>

            <Arrow side="left" ref={prevSlide}>
                <Icon type="arrow" />
            </Arrow>
            <Arrow side="right" ref={nextSlide}>
                <Icon type="arrow" />
            </Arrow>

            <Loading active={loading} />
            <div className="pagination-wrapper" />
        </Box>
    );
};
