import { Epic } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { throttleTime, switchMap, filter, catchError } from 'rxjs/operators';
import { getTradesHistoryActions } from '.';
import { getToken } from '../../utils/token';

import appConfig from '../../config/appConfig';
import { of } from 'rxjs/internal/observable/of';

export const getTradesHistoryEpics: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(getTradesHistoryActions.getTradesHistory.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            const base = `${appConfig.API_URL}balance/user-app/finchain/trades-history?accountId=${state$.value.user.userSession.alphapoint.accountId}`;
            const instrumnet = `&instrumentId=${state$.value.companies.current.assets.defaultInstrumentId}`;
            const url = !!state$.value.companies.current.assets.defaultInstrumentId
                ? `${base}${instrumnet}`
                : `${base}`;

            return ajax({
                url,
                crossDomain: true,
                method: 'GET',
                headers: { Authorization: getToken() },
            }).pipe(
                switchMap(({ response }) => {
                    return of(getTradesHistoryActions.getTradesHistorySuccess(response));
                }),
                catchError((error) => {
                    console.log({ error });
                    return of(getTradesHistoryActions.getTradesHistoryfailed());
                })
            );
        })
    );
