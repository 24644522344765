import { background, Box } from '@chakra-ui/react';
import styled from 'styled-components';

import { bee4Theme } from '../../../styles/theme';

interface CardProps {
    $topLine?: boolean; // '$' signals the transient prop
    $colorMode?: string; // '$' signals the transient prop
    $hasBorder?: boolean; // '$' signals the transient prop
    disabled?: boolean;
    style?: unknown;
}

export const Card = styled(Box)<CardProps>`
    background: ${(props) => {
        if (props.background) {
            return background;
        }
        if (props.$topLine) {
            return props.$colorMode === 'light'
                ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`
                : bee4Theme.colors.lead[300];
        }

        return props.$colorMode === 'light'
            ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`
            : `linear-gradient(90deg, ${bee4Theme.colors.lead[400]}, ${bee4Theme.colors.lead[200]})`;
    }};

    border-radius: ${(props) => {
        return props.$topLine ? '0 10px 10px 10px' : '0.7rem';
    }};
    border: ${(props) =>
        (props.$topLine && props.$colorMode === 'dark') || props.$hasBorder
            ? `1px solid ${bee4Theme.colors.lead[100]}`
            : 0};

    position: relative;
    ${({ disabled }) =>
        disabled && `opacity: 1 !important;pointer-events:none; div, input { opacity: 0.4 !important; } `}
`;
