import styled from 'styled-components';

import { devices } from '../../styles/devices';
import { bee4Theme } from '../../styles/theme';

import { Icon } from '../Icon';

import { __VIDEO_HEIGHT, __VIDEO_WIDTH } from './constants';

interface ResponsiveVideoContainerPorps {
    align?: string;
}

export const ResponsiveVideoContainer = styled.div<ResponsiveVideoContainerPorps>`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    min-width: ${__VIDEO_WIDTH + 8}px;

    @media ${devices.maxLaptop} {
        width: ${__VIDEO_WIDTH}px;
        margin: 20px 0 !important;
    }

    @media ${devices.maxMobileL} {
        width: ${__VIDEO_WIDTH}px;
        min-width: unset;
        margin: 20px 0 !important;
    }

    > iframe {
        top: 0;
        left: ${({ align }) => (align === 'left' ? '1px' : 'unset')};
        right: ${({ align }) => (align === 'right' ? '1px' : 'unset')};
        bottom: 0;
        margin: auto;
        width: ${__VIDEO_WIDTH}px;
        height: ${__VIDEO_HEIGHT}px;
        position: absolute;
        border-radius: 4px;
        padding: 1px;
        overflow: hidden;

        @media ${devices.maxMobileL} {
            width: ${__VIDEO_WIDTH}px;
            height: 96%;
            left: 0;
            right: 0;
        }

        &::before {
            content: '';

            position: absolute;
            border-radius: 4px;

            inset: -1px;
            padding: 1px;

            background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

            -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }
    }
`;

interface ThumbNailPorps {
    embedId: string;
    align?: string;
}

export const ThumbNail = styled.div<ThumbNailPorps>`
    background-image: ${({ embedId }) => `url(http://img.youtube.com/vi/${embedId}/maxresdefault.jpg)`};
    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;

    top: 0;
    left: ${({ align }) => (align === 'left' ? '1px' : 'unset')};
    right: ${({ align }) => (align === 'right' ? '1px' : 'unset')};
    bottom: 0;
    margin: auto;
    width: ${__VIDEO_WIDTH}px;
    height: ${__VIDEO_HEIGHT}px;
    position: absolute;

    @media ${devices.maxMobileL} {
        width: calc(100% - 8px);
        height: 96%;
        left: 0;
        right: 0;
    }
`;

interface BackgroundPorps {
    align?: string;
}

export const Background = styled.div<BackgroundPorps>`
    top: 0;
    left: ${({ align }) => (align === 'left' ? '1px' : 'unset')};
    right: ${({ align }) => (align === 'right' ? '1px' : 'unset')};
    bottom: 0;
    margin: auto;
    position: absolute;

    width: ${__VIDEO_WIDTH}px;
    height: ${__VIDEO_HEIGHT}px;
    background-color: #000000;

    @media ${devices.maxMobileL} {
        width: calc(100% - 8px);
        height: 96%;
        left: 0;
        right: 0;
    }

    &::before {
        content: '';

        position: absolute;
        border-radius: 4px;

        inset: -1px;
        padding: 1px;

        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

export const BackgroundTransparent = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;

    width: 100%;
    height: 100%;
    background-color: #000000b2;

    &::before {
        content: '';

        position: absolute;
        border-radius: 4px;

        inset: -1px;
        padding: 1px;

        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});

        -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

export const StyledPlayIcon = styled(Icon)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    border-color: White;
`;
