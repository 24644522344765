import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import appConfig from './appConfig';

interface UserProps {
    userName: string;
    email: string;
    userId: string;
}

interface LogProps {
    message: string;
    level: Sentry.Severity;
}

export const statusError = Sentry.Severity;

export const configInitialLog = () => {
    Sentry.init({
        dsn: appConfig.SENTRY_DNS,
        integrations: [new BrowserTracing()],
        // pegar de acordo com variavel de ambiente
        environment: appConfig.SENTRY_ENVIRONMENT,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
};

export const setUserLog = ({ userName, email, userId }: UserProps) => {
    Sentry.configureScope(function (scope) {
        // scope.setTag('my-tag', 'my value');
        scope.setUser({
            username: userName,
            id: userId,
            email,
        });
    });
};

export const captureLogError = ({ message, level }: LogProps) => {
    Sentry.withScope(function () {
        Sentry.captureMessage(message, {
            level,
        });
    });
};

export const clearUserLog = () => {
    Sentry.configureScope((scope) => scope.clear());
};

// Sentry.captureMessage(`Erro na Api - "mensagem retorno api" - "codigo erro api"`, { level: Sentry.Severity.Warning });
// Sentry.captureException('erro');
// Sentry.captureEvent({});
