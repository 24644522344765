import styled from 'styled-components';
import { Stack, HStack, Text } from '@chakra-ui/react';
import { bee4Theme } from '../../styles/theme';

import { CardBox as Bee4CardBox } from '../../components/CardBox';
import { devices } from '../../styles/devices';

interface CardBoxProps {
    $marginTop: number;
}

interface CardProps {
    border: 'false' | null;
}

interface SubtitleProps {
    color: string;
}

export const Wrapper = styled(Stack)`
    flex-direction: row !important;
    padding: 20px 0;

    @media ${devices.maxTablet} {
        flex-direction: column !important;
    }
`;

export const WrapperButtons = styled(Stack)`
    gap: 15px;

    @media ${devices.maxTablet} {
        flex-direction: row;
        padding: 0 15px;
    }

    button {
        margin-top: 0 !important;
    }
`;

export const CardBox = styled(Bee4CardBox)<CardBoxProps>`
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    margin-top: ${({ $marginTop }) => `${$marginTop}px`};
    height: 100%;
`;

export const MainValues = styled(Stack)`
    flex-direction: row !important;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;

    @media ${devices.maxMobileL} {
        flex-direction: column !important;
    }
`;

export const Card = styled(HStack)<CardProps>`
    position: relative;
    flex-direction: column !important;
    align-items: flex-start !important;
    width: calc(100% / 3);
    margin-top: 0px !important;
    padding: 0 10px;
    min-height: 90px;

    ${({ border }) =>
        border !== 'false' &&
        `
    &::after {
        content: '';

        width: 2px;
        height: 100%;

        position: absolute;

        background: ${bee4Theme.colors.lead[100]};

        right: 5px;
    }`};

    @media ${devices.maxMobileL} {
        border-right: none;
        width: 95%;
        padding: 10px 0;

        ${({ border }) =>
            border !== 'false' &&
            `
        border-bottom: 2px solid ${bee4Theme.colors.lead[100]};
        `};

        &::after {
            content: none;
        }
    }
`;

export const TitleCard = styled(Text)`
    font-size: 0.85rem;
    line-height: 1.2rem;
    font-size: 1rem;
`;

export const SubtitleCard = styled(Text)<SubtitleProps>`
    font-size: 0.65rem;
    line-height: 1rem;
    margin-top: 5px !important;
    color: ${({ color }) => color};
    font-size: 0.72rem;
`;

export const ValueCard = styled(Text)`
    font-size: 0.65rem;
    line-height: 1.3rem;
    font-size: 1rem;

    display: flex;
    height: 100%;
    align-items: flex-end;

    @media ${devices.maxTablet} {
        padding-top: 15px;
    }
`;
