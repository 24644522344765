import { bee4Theme } from './../../styles/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface NavigationLinkProps {
    to?: string;
    href?: string;
    fontSize?: string | number;
    fontWeight?: string | number;
    $colorMode: string;
    target?: string;
    $textTransform?: string;
    onClick?: () => void;
}

export const NavigationLink = styled(Link)<NavigationLinkProps>`
    font-size: ${(props) => props.fontSize || '1rem'};
    font-weight: ${(props) => props.fontWeight || 400};

    transition: all 0.3s ease-in-out;
    letter-spacing: 0.05em;
    text-transform: ${({ $textTransform }) => ($textTransform ? $textTransform : 'normal')};

    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[200] : bee4Theme.colors.blue[500])};

    &:hover {
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300])};
    }
`;

export const NavigationFakeLink = styled.span<NavigationLinkProps>`
    font-size: ${(props) => props.fontSize || '1rem'};
    font-weight: ${(props) => props.fontWeight || 400};

    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[200] : bee4Theme.colors.blue[500])};

    transition: all 0.3s ease-in-out;
    letter-spacing: 0.05em;
    text-transform: ${({ $textTransform }) => ($textTransform ? $textTransform : 'normal')};

    cursor: pointer;

    &:hover {
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300])};
    }
`;

export const NavigationExternalLink = styled.a<NavigationLinkProps>`
    font-size: ${(props) => props.fontSize || '1rem'};
    font-weight: ${(props) => props.fontWeight || 400};

    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[200] : bee4Theme.colors.blue[500])};

    transition: all 0.3s ease-in-out;
    letter-spacing: 0.05em;
    text-transform: ${({ $textTransform }) => ($textTransform ? $textTransform : 'normal')};

    cursor: pointer;

    &:hover {
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300])};
    }
`;
