import styled from 'styled-components';
import { bee4Theme } from '../../../../styles/theme';

type ButtonProps = {
    $colorMode?: string;
};

export const Edit = styled.span<ButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 0.75rem;

    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[200] : bee4Theme.colors.blue[500])};
    transition: all 0.3s ease-in-out;

    cursor: pointer;

    img {
        margin-right: 2px;
        transition: all 0.3s ease-in-out;

        ${(props) =>
            props.$colorMode === 'dark'
                ? `filter: brightness(0) saturate(100%) invert(71%) sepia(74%) saturate(2514%) hue-rotate(166deg) brightness(93%) contrast(109%);`
                : `filter: brightness(0) saturate(100%) invert(10%) sepia(72%) saturate(3765%) hue-rotate(221deg) brightness(98%) contrast(100%);`}
    }
    &:hover {
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.blue[100] : bee4Theme.colors.blue[300])};
        img {
            ${(props) =>
                props.$colorMode === 'dark'
                    ? `filter: brightness(0) saturate(100%) invert(86%) sepia(100%) saturate(5348%) hue-rotate(142deg) brightness(89%) contrast(93%);`
                    : `filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(6713%) hue-rotate(219deg) brightness(94%) contrast(94%);`}
        }
    }
`;

export const Cancel = styled.span<ButtonProps>`
    cursor: pointer;

    font-weight: 700;
    font-size: 0.75rem;

    color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.red[200] : bee4Theme.colors.red[200])};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.red[300] : bee4Theme.colors.red[300])};
    }
`;
