import { useEffect, useState } from 'react';
import { Flex, Tooltip, Image, useColorMode, Text } from '@chakra-ui/react';

import IconEdit from '../../../../assets/icons/icon-edit.svg';
import { useAppSelector } from '../../../../hooks/redux';
import { TBlockedResponse, useRoles } from '../../../../hooks/useRules';
import { Edit } from './styles';

type TEditButton = {
    type: string;
    action: () => void;
};

export const EditButton = ({ action, type }: TEditButton) => {
    const { colorMode } = useColorMode();
    const shouldIBlocked = useRoles();

    const { openOrders, timeline, cancelOrder } = useAppSelector((state) => state);
    const [role, setRole] = useState<TBlockedResponse>({} as TBlockedResponse);

    useEffect(() => {
        setRole(shouldIBlocked(type, 'edit'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editButtonElement = () => (
        <Flex alignItems="center" marginRight="5px" cursor="pointer">
            <Image
                src={IconEdit}
                width="16px"
                marginRight="2px"
                filter="brightness(0) saturate(100%) invert(33%) sepia(16%) saturate(654%) hue-rotate(189deg) brightness(94%) contrast(85%)"
            />
            <Text fontWeight="700" fontSize="0.75rem" color="gray.700">
                Editar
            </Text>
        </Flex>
    );

    if (role.blocked) {
        return (
            <Tooltip
                hasArrow
                maxWidth="300px"
                background="lead.100"
                placement="top"
                borderRadius="5px"
                color="white"
                fontSize=".7rem"
                textAlign="center"
                padding="10px"
                whiteSpace="pre-wrap"
                label={role.messages.edit}
            >
                {editButtonElement()}
            </Tooltip>
        );
    } else {
        if (
            !['unstable', 'closed', 'maintenace'].includes(timeline.currentStep.type) &&
            !cancelOrder.busy &&
            !openOrders.loading
        ) {
            return (
                <Edit $colorMode={colorMode} onClick={action}>
                    <Image src={IconEdit} width="16px" />
                    Editar
                </Edit>
            );
        }
        return editButtonElement();
    }
};
