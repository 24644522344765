import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../config/appConfig';
import { initialState } from './initial';

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        getCompanies: (state): void => {
            state.loading = true;
        },
        setCompanies: (state, action): void => {
            state.list = action.payload;
            state.loading = false;
        },
        setCompany: (state, action): void => {
            state.current = {
                ...action.payload,
                assets: {
                    ...action.payload.assets,
                    defaultFiatId: appConfig._DEFAULT_FIAT_ID, // 4 - BRL
                    defaultOMSId: appConfig._DEFAULT_OMS_ID, // 1 - Finchain
                },
            };
            state.loading = false;
        },
        setPlatformAssets: (state, action): void => {
            let assets = {};
            action.payload.map((company) => {
                assets = {
                    ...assets,
                    [company.assets.instrumentBuyId]: company.assets.ticker,
                    [company.assets.instrumentSellId]: company.assets.ticker,
                };
            });
            state.assets = assets;
        },
        resetCompanies: (state): void => {
            state.current = initialState.current;
            state.loading = false;
        },
        resetCompany: (state): void => {
            state.current = initialState.current;
        },
    },
});

export const companiesActions = companiesSlice.actions;
export default companiesSlice.reducer;
