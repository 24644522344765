import { Flex, Switch, Text } from '@chakra-ui/react';
import Gravatar from 'react-gravatar';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { bee4Theme } from './../../styles/theme';
import { devices } from '../../styles/devices';

import IconLight from '../../assets/icons/icon-switch-light.svg';
import IconDark from '../../assets/icons/icon-switch-dark.svg';

interface UserMenuContentProps {
    $colorMode: string;
}
interface LinkProps {
    $colorMode: string;
    to: string;
}
interface SwitchColorModeProps {
    size?: string;
    $colorMode?: string;
}

export const IconChevron = styled(ChevronDownIcon)`
    position: relative;
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
`;

export const Avatar = styled(Gravatar)`
    border-radius: 100%;
    margin-right: 10px;
    overflow: hidden;
`;

export const UserMenuContent = styled.div<UserMenuContentProps>`
    width: 200px;
    height: 180px;

    padding: 20px 0;

    background: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[300] : bee4Theme.colors.gray[300])};
    visibility: hidden;

    transition: all 0.5s ease-in-out;
    position: absolute;

    opacity: 0;

    border-radius: 0px 8px 8px 8px;
    box-sizing: border-box;

    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    @media ${devices.maxTablet} {
        top: 55px;
        right: 0;
    }
    @media ${devices.minLaptop} {
        top: 58px;
        right: 0;
    }

    &::before {
        content: '';
        position: absolute;

        top: -4px;
        left: 0;

        width: 45%;
        height: 4px;

        border-radius: 10px;
        background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});
    }
    &::after {
        content: '';

        width: 0;
        height: 0;

        position: absolute;

        border-style: solid;
        border-width: 0 13px 15px 13px;
        border-color: transparent transparent
            ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[300] : bee4Theme.colors.gray[300])}
            transparent;

        @media ${devices.maxTablet} {
            top: -15px;
            right: 30px;
        }
        @media ${devices.minLaptop} {
            top: -15px;
            right: 50px;
        }
    }
`;

export const UserMenuContainer = styled.span`
    position: relative;

    &:hover ${UserMenuContent} {
        visibility: visible;
        opacity: 1;
    }

    &:hover ${IconChevron} {
        transform: rotate(180deg);
    }
`;

export const Link = styled(NavLink)<LinkProps>`
    width: 100%;
    padding: 10px 15px;

    font-weight: 500;
    font-size: 0.8rem;

    text-transform: uppercase;
    transition: all 0.5s ease-in-out;

    &:hover {
        color: ${bee4Theme.colors.white};
        background: ${(props) =>
            props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[500]};
    }
`;

export const SwitchColorMode = styled(Switch)<SwitchColorModeProps>`
    .chakra-switch__track {
        position: relative;
        z-index: 0;

        background: ${({ $colorMode }) =>
            $colorMode === 'light' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[500]};
        &[data-checked] {
            background: ${({ $colorMode }) =>
                $colorMode === 'light' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[500]};
        }
        &::before {
            content: '';

            width: 14px;
            height: 14px;

            background-image: url(${IconLight});
            background-position: center;
            background-size: contain;

            position: absolute;
            top: 7px;
            left: 5px;

            z-index: 1;
        }
        &::after {
            content: '';

            width: 14px;
            height: 14px;

            background-image: url(${IconDark});
            background-position: center;
            background-size: contain;

            position: absolute;
            top: 7px;
            right: 5px;

            z-index: 2;
        }
    }
    .chakra-switch__thumb {
        background: ${bee4Theme.colors.blue[400]};
        position: relative;
        z-index: 4;
    }
`;

export const UserNameText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 400;
    font-size: 0.86em;
`;

export const Wrapper = styled(Flex)`
    width: 100%;
    max-width: 200px;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    @media (min-width: 768px) and (max-width: 870px) {
        max-width: 135px;
    }
`;
