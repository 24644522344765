import { ReactNode } from 'react';
import { BoxProps } from '@chakra-ui/react';

import { BackLight } from './BackLight';
import { BlueBox } from './BlueBox';
import { Default } from './Default';
import { ColorfulBorder } from './ColorfulBorder';

interface CardBoxProps extends BoxProps {
    children: ReactNode;
    lightEffect?: boolean;
    $topLine?: boolean;
    $hasBorder?: boolean;
    background?: string;
    style?: unknown;
    disabled?: boolean;
    variant?: 'blue-box' | 'back-light' | 'colorful-border';
}

export const CardBox = (props: CardBoxProps) => {
    const { variant, $topLine } = props;

    const groupBoxVariant = {
        'blue-box': <BlueBox background={props.background} {...props} />,
        'back-light': <BackLight {...props} />,
        'colorful-border': <ColorfulBorder {...props} />,
    };

    if (!variant) return <Default $topLine={$topLine} background={props.background} {...props} />;

    return groupBoxVariant[variant];
};
