import { createSlice } from '@reduxjs/toolkit';
import { userSession } from './initial';

export const userSlice = createSlice({
    name: 'user',
    initialState: userSession,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        signIn: (state, action): void => {
            state.error = false;
            state.loading = true;
        },

        signOut: (state): void => {
            state.error = false;
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        loginAlphapoint: (state, action): void => {
            state.error = false;
        },

        logoutAlphapoint: (state): void => {
            state.error = false;
        },

        setUserDataSession: (state, action): void => {
            const { payload } = action;

            state.userData = {
                ...payload,
            };

            if (!!payload?.blockedStatus?.type?.length) {
                const _type = payload?.blockedStatus?.type.map((item) => item.toLowerCase());
                state.userSession.status = {
                    blocked: payload.blockedStatus.blocked,
                    type: _type,
                };
            }

            /***
            state.userSession.status = {
                blocked: true,
                type: ['cvm88', 'register', 'bacen'],
            };
            /***/

            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },

        createSession: (state, action): void => {
            const { payload } = action;

            state.userSession.cognito = {
                token: payload.cognito.idToken?.jwtToken || payload.cognito.signInUserSession?.idToken.jwtToken,
                userId: payload.cognito.idToken?.payload.sub || payload.cognito.signInUserSession?.idToken.payload.sub,
                userName:
                    payload.cognito.idToken?.payload['cognito:username'] ||
                    payload.cognito.signInUserSession?.idToken.payload['cognito:username'],
                firstAccess: payload.cognito.challengeName === 'NEW_PASSWORD_REQUIRED',
                registerId:
                    payload.cognito.idToken?.payload.registerId ||
                    payload.cognito.signInUserSession?.idToken.payload.registerId,
                intermediaryId:
                    payload.cognito.idToken?.payload.intermediaryId ||
                    payload.cognito.signInUserSession?.idToken.payload.intermediaryId,
            };

            if (!!payload.response?.data) {
                state.userSession.alphapoint = {
                    token: payload.response.data.SessionToken,
                    userId: payload.response.data.User.UserId,
                    accountId: payload.response.data.User.AccountId,
                    omsId: payload.response.data.User.OMSId,
                };
            }

            if (payload.cognito.challengeName === 'NEW_PASSWORD_REQUIRED') {
                state.object = payload.cognito;
            }

            state.userSession.logged = true;

            state.updated = Date.now();
            state.error = false;
            state.loading = false;
        },
        removeSession: (state): void => {
            state.userData.name = null;
            state.userData.email = null;

            state.userSession.cognito = null;
            state.userSession.alphapoint = null;

            state.userSession.logged = false;
            state.updated = Date.now();
            state.error = false;
        },

        checkSession: (state): void => {
            state.error = false;
            state.loading = true;
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        signinFailed: (state, action): void => {
            state.userSession.logged = false;
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
        clockFail: (state): void => {
            state.userSession.logged = false;
            state.updated = Date.now();
            state.error = false;
            state.loading = false;
        },
        resetUserData: (state): void => {
            state.userData = userSession.userData;
            state.userSession = userSession.userSession;
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },
    },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
