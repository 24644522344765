import { createSlice } from '@reduxjs/toolkit';
import { date } from '../../utils/date';
import { initialState } from './initial';

export const clockSlice = createSlice({
    name: 'clock',
    initialState,
    reducers: {
        setClock: (state, action): void => {
            state.time = action.payload ? action.payload.time : date().valueOf();
        },
    },
});

export const clockActions = clockSlice.actions;
export default clockSlice.reducer;
