import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Container, Flex, HStack, Text, VStack, Image, Grid, useColorModeValue } from '@chakra-ui/react';

import { PageDescript } from '../../components/PageDescription';
import { PageTitle } from '../../components/PageTitle';
import { MetaTags } from '../../components/MetaTags';
import { ButtonNavigate } from '../../components/ButtonNavigate';
import { GradientButton } from '../../components/GradientButton';
import { ImageMask } from '../../components/ImageMask';

import iconDeposit from '../../assets/icons/icon-deposit.svg';
import iconWithdraw from '../../assets/icons/icon-withdraw.svg';
import iconWallet from '../../assets/icons/icon-wallet.svg';
import iconCompanies from '../../assets/icons/icon-companies.svg';
import BlueBoxBackground from '../../assets/backgrounds/background-blue-box.png';

import { BoxCarousel } from '../../components/BoxCarousel';
import { CardBox } from '../../components/CardBox';

import { bee4Theme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { shuffle } from '../../utils/array';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';
import { TCompany } from '../../store/companies/types';

export const Dashboard = () => {
    const buttonBackgroundColor = useColorModeValue(bee4Theme.colors.silver[100], bee4Theme.colors.lead[100]);

    const [loading, setLoading] = useState(true);
    const [highlights, setHighlights] = useState([]);

    const navigate = useNavigate();
    const { companies } = useAppSelector((state) => state);

    useEffect(() => {
        const filter = async () => {
            const promise = new Promise((resolve) =>
                resolve(shuffle(companies.list.filter((company) => !!company.highlight)))
            );
            promise.then((response: TCompany[]) => {
                setHighlights(() => response);
                setLoading(false);
            });
        };
        !companies.loading && filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies]);

    return (
        <main>
            <MetaTags>
                <title>Painel Administrativo - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%" marginBottom="60px">
                <PageTitle>Acesso rápido</PageTitle>
                <PageDescript>Aqui você encontra as principais funções de nosso ambiente de negociações</PageDescript>

                <UserBlockedDisclaimer />

                <Grid
                    width="100%"
                    margin="30px 0 30px"
                    templateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}
                    gap={5}
                >
                    <BoxCarousel
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{ delay: 3000 }}
                        loading={loading}
                    >
                        {!!highlights.length
                            ? highlights.map((company, index) => (
                                  <SwiperSlide key={`company-carousel-item${index}`}>
                                      <Flex
                                          width="100%"
                                          height="100%"
                                          minHeight="280px"
                                          flexFlow="row"
                                          alignItems="center"
                                          justifyContent="space-evenly"
                                      >
                                          <VStack
                                              width={['100%', '100%', '100%', '49%']}
                                              spacing={5}
                                              alignItems="start"
                                          >
                                              <HStack spacing={2}>
                                                  <Image src={company.smallLogo} width="245px" />
                                              </HStack>
                                              <Text
                                                  width="100%"
                                                  fontWeight="300"
                                                  fontSize="1rem"
                                                  lineHeight="1.6"
                                                  padding="20px 0"
                                              >
                                                  {company.excerpt}
                                              </Text>
                                              <GradientButton
                                                  width="230px"
                                                  label={'Conhecer empresa'}
                                                  type={'button'}
                                                  background={buttonBackgroundColor}
                                                  onClick={() => navigate(`/empresas/${company.slug}`)}
                                              />
                                          </VStack>
                                          <HStack width={['0%', '0%', '0%', '46%']} alignItems="center">
                                              <ImageMask
                                                  width="100%"
                                                  height="260px"
                                                  image={company.background}
                                                  radius={310}
                                                  sides={6}
                                                  display={['none', 'block']}
                                              />
                                          </HStack>
                                      </Flex>
                                  </SwiperSlide>
                              ))
                            : !companies.loading && (
                                  <Text fontWeight="400" fontSize=".8rem">
                                      Não há empresas em destaque
                                  </Text>
                              )}
                    </BoxCarousel>

                    <CardBox variant="blue-box" padding="30px 40px" background={BlueBoxBackground}>
                        <Flex
                            width="100%"
                            height="100%"
                            direction="column"
                            justifyContent="center"
                            zIndex={1}
                            position="relative"
                        >
                            <VStack width={['100%', '100%', '100%', '85%']} spacing={5} alignItems="start">
                                <Text fontWeight="300" fontSize="2rem">
                                    Saiba como negociar na BEE4
                                </Text>
                                <Text fontWeight="300" fontSize="1.2rem" lineHeight="1.6" margin="25px 0">
                                    Veja nosso tutorial e saiba como usar o ambiente de negociações da BEE4 para
                                    construir portfólios promissores e aproveitar as melhores oportunidades.
                                </Text>

                                <GradientButton
                                    maxWidth="150px"
                                    width="100%"
                                    variant="colorful-light"
                                    label={'Saiba mais'}
                                    link={true}
                                    href="https://ajuda.bee4.com.br/hc/pt-br/articles/10365799774093-Como-investir-na-BEE4"
                                    target="_blank"
                                />
                            </VStack>
                        </Flex>
                    </CardBox>
                </Grid>

                <Flex
                    width="100%"
                    flexFlow="row wrap"
                    position="relative"
                    margin="50px 0 30px"
                    alignItems="start"
                    justifyContent="space-between"
                >
                    <ButtonNavigate to="/empresas" icon={iconCompanies} label="Empresas Listadas" highlight={true} />
                    <ButtonNavigate to="/minha-carteira" icon={iconWallet} label="Minha Carteira" />
                    <ButtonNavigate to="/minha-carteira/depositos" icon={iconDeposit} label="Depósito" />
                    <ButtonNavigate to="/minha-carteira/retirada" icon={iconWithdraw} label="Retirada" />
                </Flex>
            </Container>
        </main>
    );
};
