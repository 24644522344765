import { Container, Grid, GridItem, Text } from '@chakra-ui/react';

import { useAppSelector } from '../../hooks/redux';
import { PageDescript } from '../../components/PageDescription';
import { PageTitle } from '../../components/PageTitle';
import { MetaTags } from '../../components/MetaTags';

import { CompanyCard } from './components/CompanyCard';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';

export const Companies = () => {
    const { list, loading } = useAppSelector((state) => state.companies);

    return (
        <main>
            <MetaTags>
                <title>Negociação / Empresas Listadas - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <PageTitle>Negociação</PageTitle>
                <PageDescript>Selecione a empresa que você quer negociar</PageDescript>

                <UserBlockedDisclaimer />

                <Grid
                    width="100%"
                    margin="30px 0 60px"
                    gap={6}
                    templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
                    templateRows="auto"
                >
                    {!!list.length
                        ? list.map((company) => <CompanyCard key={company.assets.ticker} data={company} />)
                        : !loading && (
                              <GridItem width="100%" colSpan={4}>
                                  <Text width="100%" fontWeight="400" fontSize=".9rem" textAlign="center">
                                      Não há empresas cadastradas.
                                  </Text>
                              </GridItem>
                          )}
                </Grid>
            </Container>
        </main>
    );
};
