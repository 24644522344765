import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { bee4Theme } from '../../../styles/theme';

import BlueLightEffect from '../../../assets/backgrounds/detail-horizontal-blue.png';
import { devices } from '../../../styles/devices';

interface CustomBoxProps {
    $lightEffect?: boolean; // '$' signals the transient prop
    $topLine?: boolean; // '$' signals the transient prop
    $colorMode?: string; // '$' signals the transient prop
    $background?: string; // '$' signals the transient prop
    disabled?: boolean;
}

export const Card = styled(Box)<CustomBoxProps>`
    background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]} 3%, ${bee4Theme.colors.blue[400]} 80%);

    position: relative;
    border-radius: 0.7rem;
    border: 0;

    color: ${bee4Theme.colors.white};

    ${({ disabled }) =>
        disabled && `opacity: 1 !important;pointer-events:none; div, input { opacity: 0.4 !important; } `}

    --width: 527px;
    --height: 100%;
    --HPosition: -235px;
    --zindex: -1;
    --opacity: 0.8;

    &::before {
        content: '';

        width: var(--height);
        height: 70px;

        display: block;

        background-image: url(${BlueLightEffect});
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;

        position: absolute;

        left: 0;
        top: -62px;

        z-index: var(--zindex);
        opacity: var(--opacity);

        @media ${devices.maxLaptopL} and (min-width: 1170px) {
            top: -45px;
        }

        @media (max-width: 1169px) and (min-width: 992px) {
            top: -47px;
        }

        @media ${devices.maxTablet} {
            top: -47px;
        }

        @media ${devices.maxMobileL} {
            top: -33px;
        }
    }
    &::after {
        content: '';

        background: url(${({ $background }) => $background});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        width: 100%;
        height: 200px;

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
`;
