import { Text as TextChakra, Image as ImageChakra } from '@chakra-ui/react';
import styled from 'styled-components';
import { devices } from '../../../../styles/devices';
import { Link as LinkBee4 } from '../../../../components/Link';

export const Text = styled(TextChakra)`
    font-weight: 400;
    font-size: 1.5rem;
    text-align: left;

    @media ${devices.maxLaptop} {
        font-size: 0.9rem;
        text-align: center;
        width: 100%;
    }
`;

export const Image = styled(ImageChakra)`
    width: 60%;
    margin: 0 0 20px 6px;
    objectfit: contain;

    @media ${devices.maxTablet} {
        margin: 2rem 0;
    }
`;

export const TextFooter = styled(TextChakra)`
    font-size: 0.85rem;
    margin: 1rem 0 1.4rem;
    @media ${devices.maxTablet} {
        font-size: 0.75rem;
        margin: 0.25rem 0 0.45rem;
    }
`;

export const Link = styled(LinkBee4)`
    font-size: 0.85rem;
    font-weight: 500;
    @media ${devices.maxTablet} {
        font-size: 0.75rem;
    }
`;
