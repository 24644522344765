import { useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Button } from './styles';

interface ButtonNavigateProps {
    to: string;
    icon: string;
    label: string;
    highlight?: boolean;
}

export const ButtonNavigate = ({ to, icon, label, highlight }: ButtonNavigateProps) => {
    const navigate = useNavigate();
    const { colorMode } = useColorMode();

    return (
        <Button $colorMode={colorMode} onClick={() => navigate(to)} $highlight={highlight}>
            <figure>
                <img src={icon} />
            </figure>
            <span>{label}</span>
        </Button>
    );
};
