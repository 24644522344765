import { ColorfulButton } from './Colorful';
import { ColorfulLightButton } from './ColorfulLight';
import { GrayButton } from './Gray';
import { OutlineButton } from './Outline';
import { BlockedButton } from './Blocked';

export interface GradientButtonProps {
    label: string;
    id?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    margin?: string;
    maxWidth?: string | number;
    type?: 'button' | 'submit' | 'reset';
    link?: boolean;
    href?: string;
    target?: '_self' | '_blank' | '_parent' | '_top' | string;
    variant?: 'colorful' | 'outline' | 'colorful-light' | 'gray';
    padding?: string;
    fontWeight?: string | number;
    fontSize?: string | number;
    color?: string;
    isBlocked?: boolean;
    tip?: string;
    background?: string;
    $colorMode?: string;
    onClick?: (e: unknown) => unknown | void | null;
}

export const GradientButton = (props: GradientButtonProps) => {
    const _buttons = {
        colorful: <ColorfulButton {...props} />,
        'colorful-light': <ColorfulLightButton {...props} />,
        gray: <GrayButton {...props} />,
        outline: <OutlineButton {...props} />,
        blocked: <BlockedButton {...props} />,
    };

    return props.isBlocked ? _buttons['blocked'] : _buttons[props.variant || 'outline'];
};
