import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, throttleTime, map, catchError, delay, filter } from 'rxjs/operators';

import { subscribeTradesActions, subscribeTradesSlice } from '.';

export const subscribeTradesEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(subscribeTradesActions.subscribeTrades.match),
        filter(() => !!state$.value.companies.current.assets.defaultInstrumentId),
        throttleTime(defaultThrottleTime),
        delay(600),
        switchMap(() => {
            const body = {
                omsId: state$.value.companies.current.assets.defaultOMSId,
                accountId: state$.value.user.userSession.alphapoint.accountId,
                instrumentId: state$.value.companies.current.assets.defaultInstrumentId,
                IncludeLastCount: 125,
            };
            return Alphapoint.subscribe(
                { action: subscribeTradesSlice.name, ...body },
                { action: 'UnsubscribeTrades', ...body },
                (msg) => msg.n === 'TradeDataUpdateEvent'
            ).pipe(
                delay(200),
                map(({ data }) => subscribeTradesActions.subscribeTradesSuccess(data)),
                catchError(() => of(subscribeTradesActions.subscribeTradesFailed()))
            );
        })
    );
