import { BoxProps, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { GradientLine } from '../components/GradientLine';
import { Card } from './styles';

interface BackLightProps extends BoxProps {
    children: ReactNode;
    lightEffect?: boolean;
    topLine?: boolean;
}

export const BackLight = ({ children, topLine, ...rest }: BackLightProps) => {
    const { colorMode } = useColorMode();

    return (
        <Card $colorMode={colorMode} $$topLine={topLine} {...rest}>
            {topLine ? <GradientLine /> : null}
            {children}
        </Card>
    );
};
