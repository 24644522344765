import { createIcon } from '@chakra-ui/icons';

export const IconCash = createIcon({
    displayName: 'IconCash',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12 0.923096C5.88299 0.923096 0.923096 5.88299 0.923096 12C0.923096 18.1171 5.88299 23.0769 12 23.0769C18.1171 23.0769 23.0769 18.1171 23.0769 12C23.0769 5.88299 18.1171 0.923096 12 0.923096ZM12 21.1978C6.92145 21.1978 2.80222 17.0786 2.80222 12C2.80222 6.92145 6.92145 2.80222 12 2.80222C17.0786 2.80222 21.1978 6.92145 21.1978 12C21.1978 17.0786 17.0786 21.1978 12 21.1978ZM13.1794 11.4264L12.5514 11.2805V7.95991C13.491 8.08848 14.072 8.67694 14.1709 9.39892C14.1833 9.49782 14.2673 9.56952 14.3662 9.56952H15.4764C15.5926 9.56952 15.6841 9.46815 15.6742 9.35194C15.5234 7.81156 14.255 6.82255 12.5613 6.65194V5.84343C12.5613 5.73463 12.4723 5.64562 12.3635 5.64562H11.6687C11.5599 5.64562 11.4709 5.73463 11.4709 5.84343V6.65936C9.72035 6.82996 8.35057 7.79672 8.35057 9.60167C8.35057 11.2731 9.58189 12.0791 10.875 12.3882L11.4857 12.544V16.0723C10.3929 15.9264 9.77969 15.3429 9.65359 14.5566C9.63876 14.4627 9.55469 14.3934 9.45826 14.3934H8.31595C8.19974 14.3934 8.10826 14.4923 8.11815 14.6085C8.22941 15.9684 9.26046 17.2195 11.461 17.3802V18.1566C11.461 18.2654 11.55 18.3544 11.6588 18.3544H12.361C12.4698 18.3544 12.5588 18.2654 12.5588 18.1541L12.5539 17.3703C14.4899 17.1997 15.8745 16.1638 15.8745 14.3044C15.872 12.5885 14.7816 11.822 13.1794 11.4264ZM11.4833 11.0258C11.3448 10.9863 11.2286 10.9492 11.1124 10.9022C10.2767 10.6006 9.88848 10.1135 9.88848 9.48546C9.88848 8.58793 10.5684 8.07612 11.4833 7.95991V11.0258ZM12.5514 16.0797V12.7739C12.628 12.7962 12.6973 12.8135 12.769 12.8283C13.9385 13.1844 14.3316 13.6789 14.3316 14.4379C14.3316 15.4047 13.6047 15.9857 12.5514 16.0797Z"
            fill="currentColor"
        />
    ),
});
