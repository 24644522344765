import { createSlice } from '@reduxjs/toolkit';
import { initialState, closed, schedule } from './initial';

const stateTypes = ['irrevocable', 'revocable', 'dealing', 'processing', 'closed', 'maintenance', 'unstable'];

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setTimelineData: (state, action): void => {
            if (!!action.payload.currentStep) {
                state.currentStep = !stateTypes.includes(action.payload.currentStep.type)
                    ? closed
                    : action.payload.currentStep;
            } else {
                state.currentStep = closed;
            }

            /****
            state.currentStep.type = 'closed';
            state.time = date(
                `${date('01/03/2023', 'DD/MM/YYYY').format('DD/MM/YYYY')} ${date().format('HH:mm:ss')}`,
                'DD/MM/YYYY HH:mm:ss'
            ).valueOf();
            /****/

            state.time = action.payload.time;
            state.allWeekEvents = action.payload.allWeekEvents;
            state.eventsMessages = action.payload.eventsMessages;
            state.schedule = action.payload.schedule.length ? action.payload.schedule : schedule;
            state.lastDealing = action.payload.lastDealing;
            state.lockBox = action.payload.currentStep?.lockBox;
            state.loading = false;
        },
        getTimelineData: (state): void => {
            state.error = false;
            state.loading = true;
        },
        getNowTimelineData: (state): void => {
            state.error = false;
            state.loading = false;
        },
        resetTimeline: (state): void => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        failedGetTimeline: (state, action): void => {
            state.error = true;
            state.loading = false;
        },
        setLoadingFalse: (state): void => {
            state.error = false;
            state.loading = false;
        },
    },
});

export const timelineActions = timelineSlice.actions;
export default timelineSlice.reducer;
