import React from 'react';
import { Flex, Divider, VStack } from '@chakra-ui/react';
import { bee4Theme } from '../../../../../styles/theme';

import * as Bee4 from './styles';

const dividerStyle = {
    borderWidth: 1,
    marginY: 3,
};

export const MainBox = () => {
    return (
        <Bee4.CardBox variant="colorful-border" background={bee4Theme.colors.lead[200]}>
            <Flex direction="column">
                <VStack alignItems="flex-start" spacing={0}>
                    <Bee4.Title>Preço de referência:</Bee4.Title>
                    <Bee4.Text>
                        É o preço calculado pela BEE4 com base no preço de referência da semana anterior e nos preços e
                        volumes negociados no pregão de quarta-feira. A plataforma divulga esse preço na quinta-feira,
                        dia seguinte ao do pregão, às 10h.
                    </Bee4.Text>
                </VStack>
                <Divider {...dividerStyle} />
                <VStack alignItems="flex-start" spacing={0}>
                    <Bee4.Title>Faixa de preço:</Bee4.Title>
                    <Bee4.Text>
                        Para evitar oscilações bruscas, a BEE4 estabelece uma faixa de preço para colocação de ofertas
                        de compra e de venda, com um valor mínimo que é 10% abaixo do preço de referência e um máximo de
                        10% acima do preço de referência. Novas ofertas só são aceitas se estiverem dentro dessa faixa
                        de preço.
                    </Bee4.Text>
                </VStack>
                <Divider {...dividerStyle} />
                <VStack alignItems="flex-start" spacing={0}>
                    <Bee4.Title>Preço de referência (ajustado):</Bee4.Title>
                    <Bee4.Text>
                        Às quartas-feiras, logo após o encerramento do leilão, às 12h, a BEE4 divulga o novo preço de
                        referência do ativo, ajustado de acordo com as ofertas executadas durante o leilão.
                    </Bee4.Text>
                </VStack>
                <Divider {...dividerStyle} />
                <VStack alignItems="flex-start" spacing={0}>
                    <Bee4.Title>Faixa de preço (ajustada):</Bee4.Title>
                    <Bee4.Text>
                        Após o encerramento do leilão, a BEE4 divulga também uma nova faixa de preço, mais estreita (2%
                        de oscilação para cima e para baixo do novo preço de referência). Durante o pregão, só são
                        aceitas ofertas que estiverem dentro dessa nova faixa ajustada.
                    </Bee4.Text>
                </VStack>
            </Flex>
        </Bee4.CardBox>
    );
};
