import styled from 'styled-components';
import { devices } from '../../styles/devices';
import { bee4Theme } from '../../styles/theme';

type TTableProps = {
    $colorMode: string;
    whidth?: string;
    height?: string;
    margin?: string;
};

export const Table = styled.table<TTableProps>`
    width: 100% !important;
    height: 100% !important;

    margin: ${(props) => (props.margin ? props.margin : '15px 0 0')};

    table-layout: fixed;
    border-collapse: collapse;

    overflow-y: hidden;
    overflow-x: auto;

    thead,
    tbody {
        display: inline-block;
        padding: 0;
        margin: 0;

        tr {
            width: 100%;
            min-width: max-content;
            display: inline-flex;

            align-items: center;
            justify-content: space-between;
            padding: 5px 0;

            &.six-columns {
                th,
                td {
                    @media ${devices.maxTablet} {
                        min-width: 120px;
                    }
                    @media ${devices.minLaptop} {
                        min-width: 16.6%;
                    }
                }
            }
            &.seven-columns {
                th,
                td {
                    @media ${devices.maxTablet} {
                        min-width: 116px;
                    }
                    @media ${devices.minLaptop} {
                        min-width: 14.65%;
                    }
                }
            }

            &#mobile-header {
                position: sticky;
                top: 0;
                background: ${bee4Theme.colors.lead[300]};

                @media ${devices.maxTablet} {
                    display: inline-flex;
                }
                @media ${devices.minLaptop} {
                    display: none;
                }
            }
            th,
            td {
                border: 0;
                text-align: center;
                text-transform: none;

                @media ${devices.maxTablet} {
                    max-width: 140px;
                    padding: 16px 15px;
                }
                @media ${devices.minLaptop} {
                    max-width: 200px;
                    padding: 16px 24px;
                }
            }
            th {
                font-weight: 400;
                font-size: 0.9rem;

                color: ${(props) =>
                    props.$colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.lead[500]};
            }
            td {
                font-weight: 400;
                font-size: 0.8rem;
            }
            &.small-row {
                th,
                td {
                    @media ${devices.minLaptop} {
                        min-width: unset;
                        max-width: 200px;
                        padding: 16px 24px;
                    }
                }
            }
            &.large-row {
                th,
                td {
                    @media ${devices.maxTablet} {
                        min-width: 120px;
                        max-width: 140px;
                        padding: 16px 0;
                    }
                    @media ${devices.minLaptop} {
                        min-width: 180px;
                        max-width: 200px;
                        padding: 16px 24px;
                    }
                }
            }
        }
    }
    thead {
        width: 100%;
        tr {
            padding-right: 10px;
            th {
                @media ${devices.maxTablet} {
                    display: none;
                }
                @media ${devices.minLaptop} {
                    display: block;
                }
            }
        }
    }
    tbody {
        width: ${(props) => (props.width ? props.width : '100%')};

        @media ${devices.maxTablet} {
            height: 400px;
        }
        @media ${devices.minLaptop} {
            height: ${(props) => (props.height ? props.height : '276px')};
        }

        overflow-x: visible;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
            width: 5px;
            height: 2px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: ${(props) =>
                props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
        }

        scrollbar-color: ${(props) => (props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)')}
            transparent;
        scrollbar-width: thin;

        tr {
            &:last-child {
                margin-bottom: 20px;
            }
            &:nth-of-type(odd) {
                background-color: ${({ $colorMode }) =>
                    $colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[200]};
            }
            &:nth-of-type(even) {
                background-color: ${({ $colorMode }) =>
                    $colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[100]};
            }
        }
    }
    tfoot {
        position: absolute;
        bottom: 0px;
        left: 50%;
    }

    &::after {
        content: '';

        position: absolute;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 43px;

        border-radius: 0 0 10px 10px;
        background: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(180deg, transparent 15%, ${bee4Theme.colors.lead[300]} 60%)`
                : `linear-gradient(180deg, transparent 15%, ${bee4Theme.colors.gray[200]} 60%)`};
    }
`;
