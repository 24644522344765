import { TWalletInitialState, TWalletSellItem } from './types';

export const initialState: TWalletInitialState = {
    error: false,
    loading: true,
    invoices: {
        loading: false,
        pagination: {
            currentPage: 1,
            totalItems: 0,
        },
        dateRange: null,
        modal: {
            loading: false,
            open: false,
        },
        data: [],
        lastInvoices: [],
    },
    positions: {
        buy: {
            amount: 0,
            hold: 0,
            operatingLimit: 0,
            blockedOperatingLimit: 0,
            pendingDeposits: 0,
        },
        sell: [] as TWalletSellItem[],
    },
};
