import styled, { css } from 'styled-components';
import { bee4Theme } from '../../../styles/theme';

interface OutlineProps {
    width?: string;
    height?: string;
    $colorMode?: string;
    disabled?: boolean;
    padding?: string;
    margin?: string;
    fontSize?: string | number;
    fontWeight?: string | number;
    color?: string;
    maxWidth?: string | number;
    background?: string;
}

const OutlineStyle = css<OutlineProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'auto'};

    max-width: ${(props) => props.maxWidth || 'unset'};

    padding: ${(props) => (props.padding ? props.padding : '12px 0')};
    margin: ${(props) => (props.margin ? props.margin : 0)};

    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    border: 1px solid transparent;
    border-radius: 4px;

    color: ${(props) => {
        if (!!props.color) return `${props.color} !important`;

        return props.$colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.black;
    }};

    text-transform: uppercase;
    transition: all 0.5s ease-in-out;

    font-family: 'Georama', sans-serif;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};

    &:focus,
    &:active,
    &:visited,
    &:hover {
        color: ${bee4Theme.colors.white} !important;
        &::before {
            background-position: -1px 100%; //-1px fix bug Chrome
        }
    }
    &:disabled {
        opacity: 0.4;
    }
    &::before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        z-index: -1;

        transition: all 0.5s ease-in-out;
        border-radius: 4px;

        background-size: 250% 105%;
        background-position: 100%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${
                            !!props.background
                                ? `${props.background} 50%, ${props.background} 110%`
                                : `${bee4Theme.colors.lead[400]} 50%, ${bee4Theme.colors.lead[200]} 110%`
                        }
                    )`
                : `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${
                            !!props.background
                                ? `${props.background} 50%, ${props.background} 110%`
                                : `${bee4Theme.colors.silver[100]} 50%, ${bee4Theme.colors.silver[200]} 110%`
                        }
                    )`};
    }
    &::after {
        content: '';

        position: absolute;

        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;

        background-size: 250% 100%;
        background-position: 0%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.lead[400]} 50%,
                        ${bee4Theme.colors.lead[200]} 110%
                    )`
                : `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.silver[100]} 50%,
                        ${bee4Theme.colors.silver[200]} 110%
                    )`};

        z-index: -2;
        border-radius: 4px;
    }
`;

export const ButtonHover = styled.button`
    ${OutlineStyle}
`;

export const LinkHover = styled.a`
    ${OutlineStyle}
`;
