import { useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { NavigationLink, NavigationExternalLink, NavigationFakeLink } from './styles';

interface LnkProps {
    to?: string;
    children: ReactNode;
    fontSize?: string | number;
    fontWeight?: string | number;
    target?: string;
    $textTransform?: string;
    onClick?: () => void;
}

export const Link = ({
    children,
    fontSize,
    fontWeight,
    to,
    target = '_self',
    onClick,
    $textTransform,
    ...rest
}: LnkProps) => {
    const { colorMode } = useColorMode();

    // External Link
    if (/(http(s?)):\/\//i.test(to) || /^#[^ !@#$%^&*(),.?":{}|<>]*$/gi.test(to)) {
        return (
            <NavigationExternalLink
                href={to}
                $colorMode={colorMode}
                fontSize={fontSize}
                fontWeight={fontWeight}
                onClick={onClick}
                target={target}
                $textTransform={$textTransform}
                {...rest}
            >
                {children}
            </NavigationExternalLink>
        );
    }

    // Email Link
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(to)) {
        return (
            <NavigationExternalLink
                href={`mailto:${to}`}
                $colorMode={colorMode}
                fontSize={fontSize}
                fontWeight={fontWeight}
                target={target}
                $textTransform={$textTransform}
                {...rest}
            >
                {children}
            </NavigationExternalLink>
        );
    }

    // Internal Link
    if (!!to) {
        return (
            <NavigationLink
                to={to}
                $colorMode={colorMode}
                fontSize={fontSize}
                fontWeight={fontWeight}
                onClick={onClick}
                target={target}
                $textTransform={$textTransform}
                {...rest}
            >
                {children}
            </NavigationLink>
        );
    }

    // Fake Link
    return (
        <NavigationFakeLink
            $colorMode={colorMode}
            fontSize={fontSize}
            fontWeight={fontWeight}
            onClick={onClick}
            $textTransform={$textTransform}
            {...rest}
        >
            {children}
        </NavigationFakeLink>
    );
};
