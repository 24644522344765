import { Table, Tr } from '@chakra-ui/react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { CardBox } from '../../../../components/CardBox';
import { devices } from '../../../../styles/devices';
import { bee4Theme } from '../../../../styles/theme';

interface TableProps {
    $colorMode?: string;
}
interface LineWithBarProps {
    size: string;
    type: string;
    children: ReactNode[];
}

export const TableOrders = styled(Table)<TableProps>`
    width: 100%;
    margin: 15px 0 0;

    table-layout: fixed;
    border-collapse: collapse;

    position: relative;
    overflow: hidden;

    thead,
    tbody {
        display: block;

        tr {
            width: 100%;
            display: flex;

            align-items: center;
            justify-content: space-between;

            padding: 0px 10px;

            th {
                width: 100%;
                max-width: 200px;

                border: 0;
                padding: 10px 7px;

                text-align: left;
                text-transform: none;

                font-weight: 400;
                font-size: 0.9rem;

                color: ${(props) =>
                    props.$colorMode === 'dark' ? bee4Theme.colors.white : bee4Theme.colors.lead[500]};
            }
            td {
                width: 100%;
                max-width: 200px;
                text-align: left;
                text-transform: none;

                font-weight: 400;
                font-size: 0.8rem;
            }
        }
    }
    tbody {
        width: 100%;
        height: ${(props) => (props.height ? props.height : '371px')};

        overflow-x: auto;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
            height: 2px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: ${(props) =>
                props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
        }

        scrollbar-color: ${(props) => (props.$colorMode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)')}
            transparent;
        scrollbar-width: thin;
    }
    tfoot {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 50%;
    }
`;

export const LineWithBar = styled(Tr)<LineWithBarProps>`
    width: 100%;

    position: relative;
    z-index: 1;

    ${({ type }) => type === 'sell' && 'flex-direction: row-reverse;'}

    td {
        padding: 7px;
        position: relative;
        z-index: 1;

        font-weight: 400 !important;
        font-size: 0.8rem !important;
    }
    &::after {
        content: '';

        width: ${(props) => props.size};
        height: 100%;

        position: absolute;
        top: 0;
        z-index: 0;

        ${(props) => (props.type === 'buy' ? 'right: 0' : 'left: 0')};

        background-color: ${(props) =>
            props.type === 'buy' ? bee4Theme.colors.green[100] : bee4Theme.colors.red[100]};
    }
`;

export const BoxContainer = styled(CardBox)`
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 350px;

    @media screen and (max-width: 1100px) {
        max-width: 300px;
    }

    @media ${devices.maxLaptop} {
        max-width: 49%;
    }

    @media ${devices.maxMobileL} {
        max-width: unset;
    }
`;
