import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial';

export const subscribeLevel1Slice = createSlice({
    name: 'SubscribeLevel1',
    initialState,
    reducers: {
        subscribeLevel1: (state): void => {
            state.auction = initialState.auction;
            state.tunnel = initialState.tunnel;

            state.error = false;
            state.loading = true;
        },
        unsubscribeLevel1: (state): void => {
            state.error = false;
        },
        subscribeLevel1Success: (state, action): void => {
            state.error = false;
            state = Object.assign(state, action.payload);
            state.updated = Date.now();
        },
        subscribeLevel1AuctionData: (state, action): void => {
            state.auction.price =
                action.payload?.data?.AuctionQuantity >= 0 ? action.payload?.data?.AuctionPrice : null; // null = N/A no Component
            state.auction.quantity = action.payload?.data?.AuctionQuantity || 0;
            state.error = false;
            state.updated = Date.now();
        },
        subscribeLevel1TunnelData: (state, action): void => {
            state.error = false;
            state.tunnel =
                action.payload.currentStep === 'dealing'
                    ? {
                          min: state.tunnel.min || 0,
                          max: state.tunnel.max || 0,
                          reference: state.tunnel.reference,
                          range: {
                              min: action.payload.response.PriceFloorLimit || 0,
                              max: action.payload.response.PriceCeilingLimit || 0,
                          },
                      }
                    : {
                          min: action.payload.response.PriceFloorLimit || 0,
                          max: action.payload.response.PriceCeilingLimit || 0,
                          reference: state.tunnel.reference || 0,
                      };
            state.loading = false;
            state.updated = Date.now();
        },
        subscribeLevel1TunnelLimiters: (state, action): void => {
            if (action.payload.currentStep.type === 'dealing') {
                state.tunnel = {
                    min: action.payload.tunnel.min || 0,
                    max: action.payload.tunnel.max || 0,
                    reference: action.payload.currentStep.referencePrice || 0,
                    range: state.tunnel.range || null,
                };
            } else {
                state.tunnel = {
                    min: state.tunnel.min || 0,
                    max: state.tunnel.max || 0,
                    reference: action.payload.currentStep.referencePrice || 0,
                };
            }
            state.updated = Date.now();
        },
        subscribeLevel1Failed: (state): void => {
            state.updated = Date.now();
            state.error = true;
        },
        resetTheoricalVariation: (state): void => {
            state.auction = initialState.auction;
            state.error = false;
            state.updated = Date.now();
        },
    },
});

export const subscribeLevel1Actions = subscribeLevel1Slice.actions;
export default subscribeLevel1Slice.reducer;
