import styled from 'styled-components';

export const Main = styled.main`
    padding: 0;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
`;

export const Form = styled.form`
    width: 90%;
    margin: 0 auto;

    display: flex;
    flex-flow: column;
`;
