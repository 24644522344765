import { bee4Theme } from '../../../../../../styles/theme';
import styled from 'styled-components';

interface BarProps {
    width?: string;
    fill: number | string;
}

interface LabelProps {
    status?: number;
    $colorMode?: string;
}

export const Bar = styled.span<BarProps>`
    width: ${({ width }) => (width ? width : '100%')};
    height: 8px;

    background: ${bee4Theme.colors.gray[400]};
    border-radius: 8px;
    border: 0;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';

        // fix size filled
        width: ${({ fill }) => (fill <= 100 ? `${fill}%` : '100%')};
        height: 100%;

        border-radius: 8px;

        position: absolute;
        top: 0;
        left: 0;

        background: linear-gradient(90deg, ${bee4Theme.colors.green[200]} 17.68%, #7cd690 84.69%);
    }
`;

export const Label = styled.span<LabelProps>`
    font-weight: 600;
    font-size: 0.75rem;
    color: ${({ status, $colorMode }) => {
        if ($colorMode === 'dark') {
            return status <= 100 && status > 0 ? bee4Theme.colors.white : bee4Theme.colors.gray[300];
        } else {
            return status <= 100 && status > 0 ? bee4Theme.colors.black : bee4Theme.colors.gray[400];
        }
    }};
`;

export const BlockOverlay = styled.span`
    width: 100%;
    height: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    position: absolute;
    top: 0;
    left: 0;

    z-index: 8;
    display: flex;

    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
`;
