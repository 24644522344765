import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { CurrencyInputField } from './styles';

interface CurrencyInputProps {
    id: string;
    value?: number | string;
    defaultValue?: number | string;
    $colorMode?: string;
    errors?: FieldErrors;
    placeholder?: string;
    disabled?: boolean;
    onValueChange?: (e: unknown) => void;
}

const CurrencyInput = React.forwardRef(
    (
        { id, value, defaultValue, $colorMode, placeholder, disabled, onValueChange, ...rest }: CurrencyInputProps,
        ref: React.Ref<HTMLInputElement>
    ) => (
        <CurrencyInputField
            id={id}
            ref={ref}
            data-testid={id}
            placeholder={placeholder}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            decimalSeparator=","
            decimalScale={2}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            $colorMode={$colorMode}
            onValueChange={onValueChange}
            {...rest}
        />
    )
);
CurrencyInput.displayName = 'CurrencyInput';
export default CurrencyInput;
