import { Fragment, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useColorMode } from '@chakra-ui/react';

import { ColorfulLabel } from '../../../../components/ColorfulLabel';

import { currency } from '../../../../utils/numbers';
import { date, dateShort, onlyDateDBToBR } from '../../../../utils/date';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { getOrdersHistoryActions } from '../../../../store/getOrdersHistory';
import { getTradesHistoryActions } from '../../../../store/getTradesHistory';
import { statusLabel, TGetOrdersHistoryItem } from '../../../../store/getOrdersHistory/types';
import { Loading } from '../../../../components/Loading';
import { DateLabel } from '../../../../components/TradingTable/styles';

export const RecentOrders = () => {
    const infiniteScroll = useRef(null);

    const { colorMode } = useColorMode();
    const dispatch = useAppDispatch();
    const { ordersHistory, user, timeline } = useAppSelector((state) => state);

    let currentDate = '';
    const label = { Buy: 'Compra', Sell: 'Venda' };

    useEffect(() => {
        const div = infiniteScroll.current;
        div.addEventListener('scroll', () => {
            if (div.scrollTop + div.clientHeight + 1 >= div.scrollHeight) {
                dispatch(getOrdersHistoryActions.paginateOrdersHistory());
                dispatch(getTradesHistoryActions.getTradesHistory());
            }
        });

        return () => {
            dispatch(getOrdersHistoryActions.resetOrdersHistory());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.userSession.alphapoint.token === null || timeline.currentStep?.type === undefined) {
            return;
        }

        dispatch(getOrdersHistoryActions.getOrdersHistory());
        dispatch(getTradesHistoryActions.getTradesHistory());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    const status = (order) => {
        const { OrderState, OrigQuantity, QuantityExecuted } = order;

        if (OrderState === 'Working' && !!QuantityExecuted && OrigQuantity > QuantityExecuted) {
            return 'Exe. parcialmente';
        }

        return statusLabel[OrderState];
    };

    return (
        <>
            <thead>
                <tr className="six-columns">
                    <th>Operação</th>
                    <th>Qtd original</th>
                    <th>Qtd executada</th>
                    <th>Preço</th>
                    <th>Status</th>
                    <th>Data/Hora</th>
                </tr>
            </thead>
            <tbody ref={infiniteScroll}>
                <tr id="mobile-header" className="six-columns">
                    <th>Operação</th>
                    <th>Qtd original</th>
                    <th>Qtd executada</th>
                    <th>Preço</th>
                    <th>Status</th>
                    <th>Data/Hora</th>
                </tr>
                {!!ordersHistory.orders.length &&
                    ordersHistory.orders.map((order: TGetOrdersHistoryItem) => {
                        let showDate = false;

                        if (date(new Date(order.ReceiveTime).toISOString()).format('YYYYMMDD') !== currentDate) {
                            currentDate = date(new Date(order.ReceiveTime).toISOString()).format('YYYYMMDD');
                            showDate = true;
                        }

                        return (
                            <Fragment key={`recent-orders-${uuid()}`}>
                                {showDate && (
                                    <DateLabel $colorMode={colorMode}>
                                        <td>
                                            <label>{onlyDateDBToBR(new Date(order.ReceiveTime).toISOString())}</label>
                                        </td>
                                    </DateLabel>
                                )}
                                <tr className="six-columns">
                                    <td>
                                        <ColorfulLabel type={order.Side.toLocaleLowerCase()}>
                                            {label[order.Side]}
                                        </ColorfulLabel>
                                    </td>
                                    <td>{order.OrigQuantity}</td>
                                    <td>{order.QuantityExecuted}</td>
                                    <td>{currency(order.Price)}</td>
                                    <td>{status(order)}</td>
                                    <td>{dateShort(order.ReceiveTime, 'x')}</td>
                                </tr>
                            </Fragment>
                        );
                    })}
            </tbody>
            <tfoot>
                <tr style={{ position: 'absolute', bottom: 0 }}>
                    <td>
                        <Loading active={ordersHistory.loading} />
                    </td>
                </tr>
            </tfoot>
        </>
    );
};
