import styled, { css } from 'styled-components';
import { bee4Theme } from '../../../styles/theme';

interface BlockedProps {
    width?: string;
    height?: string;
    $colorMode?: string;
    disabled?: boolean;
    padding?: string;
    margin?: string;
    fontSize?: string | number;
    fontWeight?: string | number;
    color?: string;
    maxWidth?: string | number;
    background?: string;
}

const BlockedStyle = css<BlockedProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'auto'};

    max-width: ${(props) => props.maxWidth || 'unset'};

    padding: ${(props) => (props.padding ? props.padding : '12px 0')};
    margin: ${(props) => (props.margin ? props.margin : 0)};

    position: relative;
    cursor: not-allowed;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    border: 0;
    border-radius: 4px;

    color: ${bee4Theme.colors.white};

    text-transform: uppercase;
    transition: all 0.5s ease-in-out;

    font-family: 'Georama', sans-serif;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};

    background-color: ${(props) =>
        props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300]};

    &:focus,
    &:active,
    &:visited,
    &:hover {
        color: ${bee4Theme.colors.white} !important;
        border-color: transparent !important;

        &::before {
            background-position: -1px 100%; //-1px fix bug Chrome
        }
    }
`;

export const BlockedButtonStyled = styled.button`
    ${BlockedStyle}
`;

export const BlockedLinkStyled = styled.a`
    ${BlockedStyle}
`;
