import { useColorMode } from '@chakra-ui/react';
import { GradientButtonProps } from '..';
import { ButtonHover, LinkHover } from './styles';

export const ColorfulLightButton = (props: GradientButtonProps) => {
    const { colorMode } = useColorMode();
    return !!props.link ? (
        <LinkHover data-testid={props.id} $colorMode={colorMode} {...props}>
            {props.label}
        </LinkHover>
    ) : (
        <ButtonHover data-testid={props.id} $colorMode={colorMode} {...props}>
            {props.label}
        </ButtonHover>
    );
};
