import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { bee4Theme } from '../../../styles/theme';

interface CustomBoxProps {
    $colorMode?: string; // '$' signals the transient prop
    $background?: string; // '$' signals the transient prop
    disabled?: boolean;
}

export const Card = styled(Box)<CustomBoxProps>`
    --radius: 10px;

    ${({ disabled }) =>
        disabled && `opacity: 1 !important;pointer-events:none; div, input { opacity: 0.4 !important; } `}

    background: ${({ $background }) => $background || bee4Theme.colors.lead[100]};

    position: relative;
    padding: ${({ padding }) => padding || 0};

    color: ${bee4Theme.colors.white};

    border: 0;
    border-radius: var(--radius);

    &::after {
        content: '';

        position: absolute;
        --align: -2px;

        top: var(--align);
        bottom: var(--align);
        left: var(--align);
        right: var(--align);

        background-size: 250% 100%;
        background-position: 0%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.lead[400]} 50%,
                        ${bee4Theme.colors.lead[200]} 110%
                    )`
                : `linear-gradient(
                        90deg,
                        ${bee4Theme.colors.blue[100]} 3%,
                        ${bee4Theme.colors.blue[400]} 35%,
                        ${bee4Theme.colors.silver[100]} 50%,
                        ${bee4Theme.colors.silver[200]} 110%
                    )`};

        z-index: -1;
        border-radius: var(--radius);
    }
`;
