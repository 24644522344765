import { Text, Container, Image, Flex } from '@chakra-ui/react';
import { FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { CardBox } from '../../../components/CardBox';
import { GradientButton } from '../../../components/GradientButton';
import { MetaTags } from '../../../components/MetaTags';
import { Toast } from '../../../components/Toast';

import Logo from '../../../assets/logos/bee4-white-logo.svg';
import IconSecurity from '../../../assets/icons/icon-security.svg';

import { useChangePass } from '../../../context/rescuePassword';
import { Form } from './styles';

export const RequestSecurityCode = () => {
    const { setStep, setUserData, requestCode } = useChangePass();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm();

    const handleRequest = async (data) => {
        setUserData({ userName: data.userName });

        if (await requestCode(data.userName)) {
            return setStep(2);
        }

        return Toast({
            id: 'CodenSended',
            title: `Erro ao enviar o código`,
            description: 'Falha ao enviar o código, tente novamente mais tarde.',
            status: 'error',
        });
    };

    return (
        <main>
            <MetaTags>
                <title>Esqueci minha senha - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <Flex width="100%" height="100%" flexFlow="column" alignItems="center" justifyContent="center">
                    <Image
                        boxSize="180px"
                        height="60px"
                        margin="0 auto 10px"
                        objectFit="contain"
                        src={Logo}
                        alt="Bee4"
                    />

                    <CardBox
                        width="95%"
                        maxWidth="360px"
                        height="auto"
                        padding="25px 15px"
                        margin="25px auto"
                        variant="back-light"
                    >
                        <Flex width="100%" height="100%" direction="column" alignItems="center">
                            <Flex width="90%" margin="10px 0" alignItems="center">
                                <Image src={IconSecurity} width="40px" />
                                <Text fontSize="0.8rem" fontWeight="400">
                                    Ambiente Seguro
                                </Text>
                            </Flex>

                            <Form noValidate onSubmit={handleSubmit(handleRequest)}>
                                <FormControl isInvalid={errors.userName} marginTop="15px">
                                    <Input
                                        data-testid="userName"
                                        type="text"
                                        placeholder="CPF ou CNPJ"
                                        autoComplete="off"
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        {...register('userName', {
                                            required: true,
                                            // pattern: {
                                            //     value: /\S+@\S+\.\S+/,
                                            //     message: 'E-mail inválido',
                                            // },
                                        })}
                                    />
                                    {errors.userName && errors.userName.message ? (
                                        <FormErrorMessage>{errors.userName.message}</FormErrorMessage>
                                    ) : (
                                        <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl marginTop="25px">
                                    <GradientButton
                                        id="requestCode"
                                        label="Redefinir Senha"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                </FormControl>
                            </Form>
                        </Flex>
                    </CardBox>
                </Flex>
            </Container>
        </main>
    );
};
