import { VStack, Text, Flex, HStack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { bee4Theme } from '../../../../styles/theme';
import { LabelHighLigh } from '../../../../components/LabelHighLigh';
import * as Bee4 from './styles';
import { currency } from '../../../../utils/numbers';
import { DashedLine } from './DashedLine';

interface RangeProps {
    minValue: number;
    maxValue: number;
}

interface PriceRangeProps {
    minValue: number;
    maxValue: number;
    referenceValue: number;
    range?: RangeProps;
}

export const PriceRange = ({ minValue, maxValue, referenceValue, range }: PriceRangeProps) => {
    const { colorMode } = useColorMode();
    const labelRangeColor = useColorModeValue(bee4Theme.colors.lead[400], bee4Theme.colors.gray[300]);

    const [arrRange, setArrRange] = useState([]);
    const [referenceMargin, setReferenceMargin] = useState(0);
    const [rangeValues, setRangeValues] = useState<RangeProps>({
        minValue: 0,
        maxValue: 0,
    });

    useEffect(() => {
        if (range && (range.minValue || range.maxValue) && (range.minValue > 0 || range.maxValue > 0)) {
            configRangeValues();
            return;
        }

        const { margin } = calculateValues(minValue, maxValue, referenceValue);
        setReferenceMargin(margin * 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxValue, minValue, range, referenceValue]);

    const calculateValues = (min, max, reference) => {
        const ref = reference - min;
        const amount = max - min;
        const margin = ref / amount;

        return { ref, amount, margin };
    };

    const configRangeValues = () => {
        // const diffBetweenValues = Math.abs(Math.ceil(maxValue) - Math.ceil(minValue)) || null;
        const diffBetweenValues = Math.abs(Math.floor(maxValue) - Math.floor(minValue)) || null;

        const arrDiffBetweenValues = Array(diffBetweenValues)
            .fill(0)
            .map(() => '');

        setArrRange(arrDiffBetweenValues);

        const quantityBoxRange = arrDiffBetweenValues.map(
            (_, index) => index >= rangeValues.minValue && index <= rangeValues.maxValue
        );

        const min = Math.floor(range.minValue) - Math.floor(minValue);
        const max = Math.ceil(range.maxValue) - Math.floor(minValue);
        const calcMin = min === max ? min - 1 : min;

        // Caso o range minimo esteja muito proximo do valor maximo do tunel, necessario setar o
        // ultimo box como true, para que seja exibido o tunel pintado
        if (max > quantityBoxRange.length) quantityBoxRange[quantityBoxRange.length - 1] = true;

        setRangeValues((prev) => ({
            ...prev,
            minValue: max > quantityBoxRange.length ? calcMin - 1 : calcMin,
            maxValue: max >= quantityBoxRange.filter((i) => i).length ? max - 1 : max,
        }));

        const quantityBoxColorful = quantityBoxRange.filter((item) => !!item);

        calculateMarginArrowWithRangeValues({ quantityBoxRange, quantityBoxColorful });
    };

    const calculateMarginArrowWithRangeValues = ({ quantityBoxRange, quantityBoxColorful }) => {
        const divider =
            quantityBoxColorful.length === quantityBoxRange.length
                ? 100
                : Math.round(
                      100 / (quantityBoxColorful.length <= 1 ? quantityBoxRange.length : quantityBoxColorful.length)
                  );

        const sizeBoxRange = 100 / quantityBoxRange.length;
        const index = quantityBoxRange.findIndex((item) => !!item);
        const marginLeftRange = index <= 0 ? 0 : index * (Math.ceil(sizeBoxRange) + 1);

        const { margin } = calculateValues(range.minValue || minValue, range.maxValue || maxValue, referenceValue);
        setReferenceMargin(margin * divider + marginLeftRange);
    };

    const getBackgroundPriceRange = (index): boolean => index >= rangeValues.minValue && index <= rangeValues.maxValue;

    const rangeBar = (index) => {
        const lastIndex = arrRange.length - 1 === index;
        const rangeDiff = rangeValues.maxValue - rangeValues.minValue;
        const isOdd = rangeDiff % 2 !== 0;
        const rangeCenter = rangeValues.minValue + rangeDiff;

        return (
            <Bee4.RangeBar
                width={100 / arrRange.length}
                rangePrice={getBackgroundPriceRange(index)}
                key={index}
                isColorfulUnique={rangeDiff < 1}
                $extrapolateRange={range.minValue < minValue || range.maxValue > maxValue}
                $maxEqualRange={range.maxValue === maxValue && index === arrRange.length - 1}
                $minEqualRange={range.minValue === minValue && index === 0}
            >
                {rangeDiff === 0
                    ? getBackgroundPriceRange(index) && <DashedLine centered />
                    : isOdd
                    ? rangeCenter - Math.ceil(rangeDiff / 2) === index && <DashedLine />
                    : rangeCenter - Math.ceil(rangeDiff / 2) === index && <DashedLine centered />}

                {index === rangeValues.minValue && range.minValue >= minValue && (
                    <>
                        <Bee4.TextValue>{currency(range.minValue)}</Bee4.TextValue>
                        <Text fontSize=".6rem" color={labelRangeColor} style={{ position: 'absolute', bottom: -17 }}>
                            Mín
                        </Text>
                    </>
                )}
                {((index === rangeValues.maxValue && range.maxValue <= maxValue) ||
                    (lastIndex && index + 1 === rangeValues.maxValue && range.maxValue <= maxValue)) && (
                    <>
                        <Bee4.TextValue right={0}>{currency(range.maxValue)}</Bee4.TextValue>
                        <Text
                            fontSize=".6rem"
                            color={labelRangeColor}
                            style={{ position: 'absolute', bottom: -17, right: 0 }}
                        >
                            Máx
                        </Text>
                    </>
                )}
            </Bee4.RangeBar>
        );
    };

    const renderReferencePrice = () => (
        <Bee4.ArrowTop {...{ referenceMargin }}>
            <Bee4.Container minValue={minValue} value={referenceValue} maxValue={maxValue}>
                <LabelHighLigh value={referenceValue} type="currency" small />
            </Bee4.Container>
        </Bee4.ArrowTop>
    );

    const numberToSize = (diff) =>
        diff < 0.1 ? 'xsm' : diff < 0.25 ? 'sm' : diff < 0.5 ? 'md' : diff < 0.7 ? 'lg' : 'xlg';

    const layoutWithRange = () => (
        <Flex width="100%" flexDirection="row" justifyContent="center">
            {range && range.minValue < minValue && (
                <Bee4.Plus>
                    <Bee4.LabelRange $isValue right $colorMode={colorMode}>
                        {currency(range.minValue)}
                    </Bee4.LabelRange>
                    <Bee4.LabelRange right $colorMode={colorMode}>
                        Mín
                    </Bee4.LabelRange>
                </Bee4.Plus>
            )}

            <Bee4.ContainerRange $extrapolateRange={range.minValue < minValue || range.maxValue > maxValue}>
                {range.minValue > minValue && range.minValue - minValue < 1 && (
                    <Bee4.miniRangeBar size={numberToSize(range.minValue - minValue)} />
                )}
                {arrRange.map((_, index) => rangeBar(index))}
                {maxValue > range.maxValue && maxValue - range.maxValue <= 1 && (
                    <Bee4.miniRangeBar size={numberToSize(maxValue - range.maxValue)} />
                )}
                {renderReferencePrice()}
            </Bee4.ContainerRange>

            {range && range.maxValue > maxValue && (
                <Bee4.Plus right>
                    <Bee4.LabelRange $isValue $colorMode={colorMode}>
                        {currency(range.maxValue)}
                    </Bee4.LabelRange>
                    <Bee4.LabelRange $colorMode={colorMode}>Máx</Bee4.LabelRange>
                </Bee4.Plus>
            )}
        </Flex>
    );

    return (
        <VStack flex={1} width="100%" marginTop={20}>
            <Text fontWeight="500" fontSize="1rem" lineHeight="1.8" marginBottom={1} position="absolute" top="-15px">
                Preço de referência
            </Text>

            {range && range.maxValue && range.minValue ? (
                layoutWithRange()
            ) : (
                <Bee4.Bar fill="100%">{renderReferencePrice()}</Bee4.Bar>
            )}

            <Bee4.ContainerValues
                $hasRange={!!range && !!range.minValue}
                style={{ marginTop: range && range.minValue > 0 ? '1rem' : '0.4rem' }}
            >
                <HStack>
                    {range && range.minValue > 0 && range.minValue < minValue && <Bee4.Spacing />}
                    <Bee4.LabelValue $colorMode={colorMode} $hasRange={!!range && !!range.minValue}>
                        Mín: {currency(minValue)}
                    </Bee4.LabelValue>
                </HStack>

                <HStack>
                    <Bee4.LabelValue $colorMode={colorMode} $hasRange={!!range && !!range.maxValue}>
                        Máx: {currency(maxValue)}
                    </Bee4.LabelValue>
                    {range && range.maxValue > 0 && range.maxValue > maxValue && <Bee4.Spacing />}
                </HStack>
            </Bee4.ContainerValues>
        </VStack>
    );
};
