import { bee4Theme } from './../../styles/theme';
import styled from 'styled-components';

type TPageProps = {
    active?: boolean;
    dots?: boolean;
    colorMode: string;
};

export const Pages = styled.ul`
    width: 100%;
    margin: 30px auto 0;

    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    list-style: none;
`;

export const Page = styled.li<TPageProps>`
    --size: 32px;

    width: var(--size);
    height: var(--size);

    display: inline-flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    border: 1px solid ${bee4Theme.colors.lead[100]};
    background-color: ${({ active, colorMode }) =>
        colorMode === 'dark'
            ? active
                ? bee4Theme.colors.blue[300]
                : bee4Theme.colors.lead[200]
            : active
            ? bee4Theme.colors.blue[300]
            : bee4Theme.colors.gray[200]};

    cursor: ${({ dots }) => (!dots ? 'pointer' : 'default')};
    margin: 0 2.5px;

    text-align: center;
    border-radius: 6px;

    transition: all 0.5s ease-in-out;

    &:hover {
        background-color: ${({ dots }) => (dots ? 'none' : bee4Theme.colors.blue[300])};
        color: ${({ dots }) => (dots ? 'none' : bee4Theme.colors.white)};
    }
    &.active {
        background-color: ${bee4Theme.colors.blue[300]};
        color: ${bee4Theme.colors.white} !important;

        &:hover {
            background-color: ${bee4Theme.colors.blue[300]};
        }
    }
`;
