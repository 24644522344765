import { Flex, Modal as ModalChakra, ModalBody, ModalCloseButton, ModalOverlay, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { PageTitle } from '../../components/PageTitle';

import { ModalBox, TopLine } from './styles';

interface ModalProps {
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    motionPreset: 'slideInBottom' | 'slideInRight' | 'scale' | 'none';
    size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs' | 'full' | '4xl' | '3xl' | '5xl' | '6xl' | string;
    metaTagTitle?: string;
    modalTitle?: string;
    topLine?: boolean;
    isCentered?: boolean;
}

export const Modal = ({
    children,
    isOpen,
    onClose,
    motionPreset,
    size,
    modalTitle,
    topLine,
    isCentered = true,
}: ModalProps) => {
    const { colorMode } = useColorMode();

    return (
        <ModalChakra onClose={onClose} isOpen={isOpen} isCentered={isCentered} motionPreset={motionPreset} size={size}>
            <ModalOverlay opacity="0.9 !important" />
            <ModalBox $colorMode={colorMode} $isSmall={size === 'sm' || size === 'md'}>
                {topLine && <TopLine />}
                {modalTitle && (
                    <Flex p={4}>
                        <PageTitle width="fit-content" $fontSize="1.4rem">
                            {modalTitle}
                        </PageTitle>
                    </Flex>
                )}

                <ModalCloseButton onClick={onClose} />
                <ModalBody px={4}>{children}</ModalBody>
            </ModalBox>
        </ModalChakra>
    );
};
