import { Flex, Image, Text, VStack } from '@chakra-ui/react';

import { Button } from '../../../../../components/Button';
import { Link } from '../../../../../components/Link';

import IconCancelOrder from '../../../../../assets/icons/icon-cancel-order.svg';
import { TGetOpenOrdersItem } from '../../../../../store/getOpenOrders/types';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { cancelOrderActions } from '../../../../../store/cancelOrder';

interface OrderCancelProps {
    data: TGetOpenOrdersItem;
    onClose: () => void;
}

export const OrderCancel = ({ data, onClose }: OrderCancelProps) => {
    const { user } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        const orderToCancel = {
            OMSId: data.OMSId,
            AccountId: user.userSession?.alphapoint?.accountId,
            ClOrderId: 0,
            OrderId: data.OrderId,
            Side: data.Side,
        };

        dispatch(cancelOrderActions.sendCancelOrder(orderToCancel));
        onClose();
    };

    return (
        <Flex direction="column" alignItems="center" justifyContent="space-between">
            <Image src={IconCancelOrder} width="90px" margin="40px auto" />
            <Text fontWeight="700" fontSize="1.25rem" lineHeight="1.4" letterSpacing="0.05em">
                Deseja cancelar esta oferta?
            </Text>
            <VStack spacing={5} marginTop="15px">
                <Button width="200px" type="submit" colorScheme="green" onClick={handleCancel}>
                    Sim, cancelar
                </Button>
                <Link onClick={onClose} fontWeight="700" fontSize=".8rem">
                    Voltar
                </Link>
            </VStack>
        </Flex>
    );
};
