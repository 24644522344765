import { http } from './rest.api';

export const urlBalanceWallet = (taxId) => `balance/user-app/${taxId}`;

export const requestWithdraw = async ({ taxId, value }) => {
    const { data } = await http.post(`balance/money-transfer-app`, {
        fromTaxNumber: taxId,
        value,
    });

    return data;
};
