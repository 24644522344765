import styled from 'styled-components';

export const Label = styled.label`
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.1;
    letter-spacing: 0.05em;
`;

export const Form = styled.form`
    width: 80%;
    margin: 65px auto 0;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-evenly;
`;
