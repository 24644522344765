import styled from 'styled-components';
import { devices } from '../../styles/devices';
import { bee4Theme } from '../../styles/theme';

interface LinkProps {
    $colorMode: string;
    width?: string;
    height?: string;
}

export const SocialLink = styled.a<LinkProps>`
    width: ${(props) => (props.width ? props.width : '30px')};
    height: ${(props) => (props.height ? props.height : '30px')};

    border-radius: 5px;
    position: relative;
    z-index: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    @media ${devices.maxTablet} {
        margin: 0;
    }
    @media ${devices.minLaptop} {
        margin: 10px 0;
    }

    svg {
        width: ${(props) => (props.width ? `calc(${props.width} - 5px)` : '20px')};
        height: ${(props) => (props.height ? `calc(${props.height} - 5px)` : '20px')};

        color: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[400] : bee4Theme.colors.white)};
        position: relative;
        z-index: 2;
    }

    &::before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        transition: all 0.5s ease-in-out;
        border-radius: 4px;

        background-size: 10% 270%;
        background-position: 10% 160%;
        background-image: ${(props) =>
            props.$colorMode === 'dark'
                ? `linear-gradient(
                        180deg,
                        ${bee4Theme.colors.white} 3%,
                        ${bee4Theme.colors.white} 35%,
                        ${bee4Theme.colors.blue[100]} 60%,
                        ${bee4Theme.colors.blue[400]} 110%
                    )`
                : `linear-gradient(
                        180deg,
                        ${bee4Theme.colors.gray[700]} 3%,
                        ${bee4Theme.colors.gray[700]} 35%,
                        ${bee4Theme.colors.blue[100]} 60%,
                        ${bee4Theme.colors.blue[400]} 110%
                    )`};
    }

    &:hover {
        &::before {
            background-position: 10% 250%;
        }
    }
`;
