import { useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Label } from './styles';

type TColorfulLabelProps = {
    type: string | number;
    children: ReactNode;
};

export const ColorfulLabel = ({ type, children }: TColorfulLabelProps) => {
    const { colorMode } = useColorMode();
    return (
        <Label type={type} $colorMode={colorMode}>
            {children}
        </Label>
    );
};
