import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial';

export * from './types';

const cancelOrder = createSlice({
    name: 'CancelOrder',
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sendCancelOrder: (state, action) => {
            state.error = false;
            state.busy = true;
        },
        cancelOrder: (state) => {
            state.updated = Date.now();
            state.error = false;
            state.busy = false;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cancelFailed: (state, action): void => {
            state.error = true;
            state.updated = Date.now();
            state.busy = false;
        },
        clockFailed: (state): void => {
            state.error = false;
        },
    },
});

export const cancelOrderActions = cancelOrder.actions;
export default cancelOrder.reducer;
