/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { HStack, useColorModeValue, Image, VStack, Text, Flex, useColorMode } from '@chakra-ui/react';

import { TCompany } from '../../../../store/companies/types';
import { useAppDispatch } from '../../../../hooks/redux';
import { companiesActions } from '../../../../store/companies';
import { bee4Theme } from '../../../../styles/theme';
import { CardBox } from '../../../../components/CardBox';

import {
    Button,
    ButtonsBox,
    CompanyBox,
    CompanyData,
    CompanyExcerpt,
    Cell,
    BoxLoading,
    CompanyLastDealing,
    Label,
    Line,
    Logo,
} from './styles';

import { useEffect, useState } from 'react';
import { GradientButton } from '../../../../components/GradientButton';
import { requestCompaniesDealingData, TCompanyDealingData } from '../../../../services/companies.api';

import { date } from '../../../../utils/date';
import { currency } from '../../../../utils/numbers';
import { Loading } from '../../../../components/Loading';
import { Percent } from '../Percent';

type TCompanyCardProps = {
    data: TCompany;
};

export const CompanyCard = ({ data }: TCompanyCardProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [lastDealing, setLastDealing] = useState<TCompanyDealingData>({} as TCompanyDealingData);
    const [loading, setLoading] = useState(false);

    const { colorMode } = useColorMode();

    useEffect(() => {
        const get = async () => {
            setLoading(true);
            const { date, referencePrice, dealingVariation, lastTradePrice } = await requestCompaniesDealingData(
                data.assets.ticker
            );
            setLastDealing({
                date,
                referencePrice,
                dealingVariation,
                lastTradePrice,
            });
            setLoading(false);
        };
        get();
        return () => {
            setLoading(false);
            setLastDealing({} as TCompanyDealingData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const buttonBoxColor = useColorModeValue(bee4Theme.colors.silver[200], bee4Theme.colors.lead[200]);
    const setCompany = (company) => dispatch(companiesActions.setCompany(company));

    return (
        <CompanyBox>
            <CardBox $topLine={true}>
                <CompanyData>
                    <HStack spacing={3} padding="10px">
                        <Logo>
                            <Image src={data.fullLogo} />
                        </Logo>
                        <VStack
                            spacing={[2, 0]}
                            margin={['15px 0', '15px 20px']}
                            alignItems={['cetnter', 'start']}
                            maxWidth={['unset', 'unset', 'unset', 160]}
                        >
                            <Text fontWeight="600" fontSize="1.2rem" textTransform="uppercase" textAlign="left">
                                {data.assets?.ticker}
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize=".75rem"
                                textTransform="uppercase"
                                textAlign="left"
                                color={useColorModeValue(bee4Theme.colors.gray[700], bee4Theme.colors.gray[300])}
                            >
                                {data.socialName}
                            </Text>
                        </VStack>
                    </HStack>
                    <Flex
                        width="100%"
                        padding="0 0 10px"
                        flexFlow="row wrap"
                        alignItems="center"
                        justifyContent="start"
                    >
                        {!!data.categories?.length &&
                            data.categories?.map((category, index) => (
                                <Button key={`category-item-${index}`}>{category}</Button>
                            ))}
                    </Flex>
                </CompanyData>

                {/* <CompanyLastDealing>
                    <Line />
                    {loading && (
                        <BoxLoading $colorMode={colorMode}>
                            <Loading active={loading} />
                        </BoxLoading>
                    )}
                    <Cell width="100%">
                        <Label>Últ. Pregão:</Label> {date(lastDealing?.date).format('DD/MM/YYYY')}
                    </Cell>
                    <Cell width="50%">
                        <Label>Preço de referência:</Label> {currency(lastDealing?.referencePrice || 0)}
                    </Cell>
                    <Cell width="50%">
                        <Label>Variação do pregão: </Label>
                        <Percent value={lastDealing?.dealingVariation} />
                    </Cell>
                    <Cell width="100%">
                        <Label>Preço do último negócio: </Label>
                        <br /> {!!lastDealing?.lastTradePrice ? currency(lastDealing?.lastTradePrice) : 'N/A'}
                    </Cell>
                </CompanyLastDealing> */}

                <CompanyExcerpt>
                    <Line />
                    {data.excerpt}
                </CompanyExcerpt>

                <ButtonsBox background={buttonBoxColor}>
                    <GradientButton
                        width="47%"
                        label="Ver empresa"
                        type="button"
                        fontSize=".8rem"
                        background={buttonBoxColor}
                        onClick={() => {
                            setCompany(data);
                            return navigate(`/empresas/${data.slug}`);
                        }}
                    />
                    <GradientButton
                        width="47%"
                        label="Negociar"
                        type="button"
                        variant="colorful"
                        fontSize=".8rem"
                        onClick={() => {
                            setCompany(data);
                            navigate(`/negociacoes/${data.slug}`);
                        }}
                    />
                </ButtonsBox>
            </CardBox>
        </CompanyBox>
    );
};
