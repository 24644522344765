import { Text, Image, VStack, HStack, useColorModeValue, Flex } from '@chakra-ui/react';

import { Modal } from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import { Link } from '../../../../components/Link';

import IconInformation from '../../../../assets/icons/icon-alert.svg';
import IconWithdraw from '../../../../assets/icons/icon-withdraw-modal.svg';

interface TModalProps {
    isOpen: boolean;
    isSubmiting: boolean;
    onClose: () => void;
    action: () => void;
}

export const ModalConfirm = ({ isOpen, onClose, action, isSubmiting }: TModalProps) => {
    const inportantColor = useColorModeValue('lead.500', 'gray.200');

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            size="lg"
            modalTitle="Confirmação"
            topLine={true}
        >
            <Image src={IconWithdraw} width="110px" margin="40px auto 50px" />

            <Text fontWeight="700" fontSize="1.5rem" lineHeight="1.4" letterSpacing="0.05em">
                Você confirma sua solicitação de retirada financeira?
            </Text>

            <Flex width="100%" position="relative" margin="10px 0 30px" direction="column">
                <HStack spacing={0} alignItems="baseline">
                    <Image
                        src={IconInformation}
                        width="32px"
                        margin="10px"
                        filter={useColorModeValue('invert(0%)', 'invert(100%)')}
                    />
                    <Text fontWeight="700" fontSize=".9rem" paddingBottom={1.4}>
                        IMPORTANTE
                    </Text>
                </HStack>
                <Text fontWeight="400" fontSize=".85rem" color={inportantColor}>
                    Ao solicitar uma retirada o valor solicitado será debitado de seu{' '}
                    <strong>&#34;Saldo livre&#34;</strong> e transferido para <strong>&#34;Saldo bloqueado&#34;</strong>
                    . O mesmo estará <strong>disponível em sua conta em até 2 dias úteis</strong>.
                </Text>
            </Flex>

            <VStack spacing={3} marginTop="15px">
                <Button width="200px" type="submit" colorScheme="green" onClick={action} disabled={isSubmiting}>
                    {isSubmiting ? 'Aguarde...' : 'Sim, confirmo'}
                </Button>
                <Link onClick={onClose} fontWeight="700" fontSize=".8rem" $textTransform="uppercase">
                    cancelar
                </Link>
            </VStack>
        </Modal>
    );
};
