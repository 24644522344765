export default {
    /** APP **/
    REGISTER_PER_PAGE: 25,
    INVOICES_PER_PAGE: 12,
    NOTIFICATION_BELL_LIMIT: 35,
    DATE_START_LIST_ORDERS: process.env.REACT_APP_DATE_START_LIST_ORDERS,
    CLOSED_TIME: '20:00:00',

    _DEFAULT_OMS_ID: 1, // 1 - Finchain
    _DEFAULT_FIAT_ID: 4, // 4 - BRL
    _DEFAULT_BUFFER_TIME_: 1800,
    _DEFAULT_THROTTLE_TIME_: 800,

    /** Maintenance **/
    MAINTENANCE: process.env.REACT_APP_MAINTENANCE == 'true' ? true : false,
    MAINTENANCE_MESSAGE: process.env.REACT_APP_MAINTENANCE_MESSAGE,

    /** Tools **/
    SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
    SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,

    /** APIs **/
    ALPHAPOINT_URI: process.env.REACT_APP_ALPHAPOINT_URI,
    API_URL: process.env.REACT_APP_API_URL,

    /** Google */
    GTM_ID: process.env.REACT_APP_GTM_ID,

    /** Cognito **/
    AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION,
    AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    AWS_COGNITO_USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    AWS_COGNITO_USER_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_IDENTITY_POOL_ID,
};
