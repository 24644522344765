import {
    Container,
    Flex,
    Text,
    VStack,
    Image,
    // TableContainer,
    // Table,
    // Thead,
    // Tr,
    // Th,
    // Tbody,
    // Td,
    useColorModeValue,
} from '@chakra-ui/react';

import { PageTitle } from '../../components/PageTitle';
import { CardBox } from '../../components/CardBox';
// import { Status } from '../../components/Status';
import { PageDescript } from '../../components/PageDescription';
import { MetaTags } from '../../components/MetaTags';

import IconArrowRight from '../../assets/icons/icon-arrow-right.svg';
import IconInformation from '../../assets/icons/icon-information.svg';
import IconCalendar from '../../assets/icons/icon-calendar.svg';
import IconClockCircle from '../../assets/icons/icon-clock-circle.svg';
// import IconNoDeposit from '../../assets/icons/icon-no-deposit.svg';

import { hideString, hideName, taxId } from '../../utils/strings';

import { useAppSelector } from '../../hooks/redux';
import { getInstituteName } from '../../utils/finalcialInstitution';
import { Breadcrumb } from '../../components/Breadcrumb';

import { FlexStyled, ImageStyled } from './styles';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';

// import { currency } from '../../utils/numbers';
// import { onlyDateDBToBR } from '../../utils/date';
// import { useState } from 'react';

export const Deposit = () => {
    // const [deposits, setDeposits] = useState([]);
    // const tableContainerBackground = useColorModeValue('gray.300', 'lead.200');
    const inportantColor = useColorModeValue('lead.500', 'gray.200');
    const disclaimerFitBnk = useColorModeValue('lead.400', 'white');
    const { user } = useAppSelector((state) => state);

    return (
        <main>
            <MetaTags>
                <title>Depósitos - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%" paddingBottom="40px">
                <Breadcrumb />
                <PageTitle>Depósito Financeiro</PageTitle>
                <PageDescript>Você precisa ter saldo em conta com o valor que deseja operar</PageDescript>

                <UserBlockedDisclaimer />

                <Flex width="100%" flexFlow="row wrap" position="relative" margin="50px 0 0" alignItems="start">
                    <CardBox
                        width="100%"
                        height="100%"
                        maxWidth="750px"
                        margin={['0', '0', '0', '0 15px 0 0']}
                        $topLine={true}
                        padding="24px 24px 36px"
                    >
                        <Text fontWeight="600" fontSize="1.5rem" lineHeight="1.5rem" mb="1.5rem">
                            Como adicionar saldo:
                        </Text>
                        <FlexStyled justifyContent="space-between">
                            <VStack spacing={1} align="stretch" flex={1}>
                                <Text fontWeight="700" fontSize="1rem" lineHeight="1rem">
                                    Passo 1:
                                </Text>
                                <Text fontWeight="400" fontSize=".875rem" lineHeight="1.1rem">
                                    Faça uma
                                    <strong> TED ou um PIX de sua conta bancária para sua conta Fitbank </strong>
                                    com o valor a operar. Lembre-se que deve
                                    <strong> utilizar os dados de sua conta Fitbank </strong>
                                    como favorecido para realizar o depósito via TED ou PIX.
                                </Text>
                            </VStack>

                            <ImageStyled
                                src={IconArrowRight}
                                width="26px"
                                filter={useColorModeValue('invert(100%)', 'invert(0)')}
                                margin="0 1.5rem"
                            />

                            <VStack spacing={1} align="stretch" flex={1}>
                                <Text fontWeight="700" fontSize="1rem" lineHeight="1rem">
                                    Passo 2:
                                </Text>
                                <Text fontWeight="400" fontSize=".875rem" lineHeight="1.1rem">
                                    O saldo vai estar <strong>disponível em até 4 horas</strong> após processamento do
                                    seu depósito por TED ou PIX. Em caso de TED realizada após as 15h30min,
                                    <strong> o processamento ocorre no dia útil seguinte</strong>.
                                </Text>
                            </VStack>
                        </FlexStyled>
                    </CardBox>

                    <CardBox
                        width="100%"
                        minHeight="218px"
                        maxWidth="450px"
                        margin={['15px 0', '15px 0', '15px 0', '0 0 0 0']}
                        $topLine={true}
                        padding="20px 25px"
                    >
                        <Text fontWeight="600" fontSize="1.5rem" lineHeight="1.5rem" mb="1.5rem">
                            Faça uma TED ou PIX para:
                        </Text>
                        <VStack spacing={1} align="stretch" width="100%" marginTop="20px">
                            <Text fontSize=".85rem">
                                <strong>Favorecido: </strong>
                                <span style={{ fontWeight: 500, fontSize: '.9rem' }}>
                                    {hideName(user.userData.name || user.userData.companyName)}
                                </span>
                            </Text>
                            <Text fontWeight="400" fontSize=".85rem">
                                <strong>{user.userData?.taxId?.length > 11 ? 'CNPJ: ' : 'CPF: '}</strong>
                                <span style={{ fontWeight: 500, fontSize: '.9rem' }}>
                                    {hideString(taxId(user.userData?.taxId || ''))}
                                </span>
                            </Text>
                            <Text fontWeight="400" fontSize=".85rem">
                                <strong>Banco: </strong>
                                {`${user.userData.paymentAccountBankInfo?.bankCode} - ${getInstituteName(
                                    user.userData.paymentAccountBankInfo?.bankCode
                                )}`}
                            </Text>
                            <Text fontWeight="400" fontSize=".85rem">
                                <strong>Agência: </strong>
                                {user.userData.paymentAccountBankInfo?.bankBranch}
                            </Text>
                            <Text fontWeight="400" fontSize=".85rem">
                                <strong>Conta: </strong>
                                {`${user.userData.paymentAccountBankInfo?.bankAccount}-${user.userData.paymentAccountBankInfo?.bankAccountDigit}`}
                            </Text>
                        </VStack>
                    </CardBox>
                </Flex>

                <Flex width="100%" position="relative" margin="10px 0 30px" alignItems="center">
                    <Image
                        src={IconInformation}
                        width="32px"
                        margin="10px"
                        filter={useColorModeValue('invert(100%)', 'invert(0)')}
                    />
                    <VStack spacing={1} align="stretch" width="100%" maxWidth="540px" marginTop="20px">
                        <Text fontWeight="700" fontSize=".75rem">
                            IMPORTANTE
                        </Text>

                        <Text fontFamily="Inter, sans-serif" fontWeight="400" fontSize=".75rem" color={inportantColor}>
                            Para que o depósito seja processado, você deve fazer a TED a partir de uma conta com o mesmo
                            CPF cadastrado aqui com a gente.
                        </Text>

                        <Flex>
                            <Image
                                src={IconCalendar}
                                width="16px"
                                marginRight="5px"
                                filter={useColorModeValue('invert(100%)', 'invert(0)')}
                            />
                            <Text
                                fontFamily="Inter, sans-serif"
                                fontWeight="400"
                                fontSize=".75rem"
                                letterSpacing="1px"
                                color={inportantColor}
                            >
                                Dias: dias úteis (em São Paulo/SP)
                            </Text>
                        </Flex>

                        <Flex>
                            <Image
                                src={IconClockCircle}
                                width="16px"
                                marginRight="5px"
                                filter={useColorModeValue('invert(100%)', 'invert(0)')}
                            />
                            <Text
                                fontFamily="Inter, sans-serif"
                                fontWeight="400"
                                fontSize=".75rem"
                                letterSpacing="1px"
                                color={inportantColor}
                            >
                                Horário: 9h às 15h30min
                            </Text>
                        </Flex>

                        <Text
                            padding="15px 0"
                            fontFamily="Inter, sans-serif"
                            fontWeight="700"
                            fontSize=".75rem"
                            color={disclaimerFitBnk}
                        >
                            A conta do Fitbank (CNPJ: 13.203.354/0001-85) é de uso exclusivo para as liquidações das
                            operações realizadas na BEE4 e dos investimentos realizados na Beegin.
                        </Text>
                    </VStack>
                </Flex>
            </Container>
        </main>
    );
};
