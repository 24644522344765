import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Text, Container, Image, Flex, InputRightElement, InputGroup, useColorMode, Checkbox } from '@chakra-ui/react';
import { FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import { CardBox } from '../../../components/CardBox';
import { GradientButton } from '../../../components/GradientButton';
import { FeedbackPassword } from '../../../components/FeedbackPassword';
import { Toast } from '../../../components/Toast';
import { MetaTags } from '../../../components/MetaTags';
import { PageDescript } from '../../../components/PageDescription';

import Logo from '../../../assets/logos/bee4-white-logo.svg';
import IconSecurity from '../../../assets/icons/icon-security.svg';

import { Form, Main } from './styles';
import { useAppSelector } from '../../../hooks/redux';
import { Link } from '../../../components/Link';

interface SetPasswordProps {
    setStep: (step: number) => void;
}

export const SetPassword = ({ setStep }: SetPasswordProps) => {
    const { setColorMode } = useColorMode();
    const user = useAppSelector(({ user }) => user);

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Campo obrigatório'),
        confirm: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
        acceptTerms: Yup.boolean().required('').oneOf([true], ''),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm(formOptions);

    const initialValidation = {
        special: false,
        number: false,
        uppercase: false,
        lowercase: false,
        length: false,
    };

    const [showPass, setShowPass] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [validation, setValidation] = useState(initialValidation);
    const [cognitoUser, setCognitoUser] = useState({});
    const [password, setPassword] = useState('');

    useEffect(() => {
        setColorMode('dark');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCognitoUser(() => user.object);

        if (!user.updated) {
            return;
        }

        if (user.error) {
            Toast({
                id: 'passFeedback',
                title: `Falha ao atualizar sua senha`,
                description: 'Infelizmente houve um problema ao definir sua senha, tente novamente mais tarde.',
                status: 'error',
            });
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.updated]);

    const definePassword = async (cognitoUser, data) => {
        try {
            await Auth.completeNewPassword(cognitoUser, data.password);
            await Auth.updateUserAttributes(cognitoUser, {
                'custom:accept_emails': `${+data.acceptEmails}`,
                'custom:accept_terms': `${+data.acceptTerms}`,
            });
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleValidate = (event) => {
        const _password = event.target.value;

        const regexSpecialChars = `\`!@#$%^&*()_+-=[]{};':"\\|,.<>/?~`;
        const _validation = initialValidation;

        /** Special Character Check **/
        regexSpecialChars.split('').some((specialChar) => {
            if (_password.includes(specialChar)) {
                _validation.special = true;
            }
        });

        /** Number Check **/
        const decimal = /\d/;
        _validation.number = decimal.test(_password);

        /** Uppercase Check **/
        const uppercase = /[A-Z]/;
        _validation.uppercase = uppercase.test(_password);

        /** Lowercase Check **/
        const lowercase = /[a-z]/;
        _validation.lowercase = lowercase.test(_password);

        /** Length String **/
        _validation.length = _password.length >= 8;

        setValidation(_validation);
        setPassword(() => _password);
    };

    const handleSubmitNewPassword = async (data) => {
        if (Object.values(validation).some((value) => value === false)) {
            return Toast({
                id: 'passFeedback',
                title: `Falha de validação`,
                description: 'A senha não corresponde aos critérios.',
                status: 'error',
            });
        }

        /** Envia os dados para o Cognito **/
        if (await definePassword(cognitoUser, data)) {
            setStep(2);
            return true;
        }

        return Toast({
            id: 'passFeedback',
            title: `Falha ao atualizar sua senha`,
            description: 'Infelizmente houve um problema ao definir sua senha, tente novamente mais tarde.',
            status: 'error',
        });
    };

    return (
        <Main>
            <MetaTags>
                <title>Primeiro Acesso - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%">
                <Image width="100px" height="50px" objectFit="contain" margin="10px 0" src={Logo} alt="Bee4" />
                <PageDescript>Defina sua senha para seu acesso à plataforma.</PageDescript>
                <Flex
                    width="100%"
                    height="80%"
                    flexFlow="column"
                    padding="0"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CardBox
                        width="95%"
                        maxWidth="400px"
                        height="auto"
                        padding="25px 15px"
                        margin="130px auto 30px"
                        variant="back-light"
                    >
                        <Flex width="100%" height="100%" direction="column" alignItems="center">
                            <Flex width="90%" margin="10px 0" alignItems="center">
                                <Image src={IconSecurity} width="40px" />
                                <Text fontSize="0.8rem" fontWeight="400">
                                    Ambiente Seguro
                                </Text>
                            </Flex>

                            <Form noValidate onSubmit={handleSubmit(handleSubmitNewPassword)}>
                                <FormControl isInvalid={errors.password} marginTop="15px">
                                    <InputGroup>
                                        <Input
                                            data-testid="password"
                                            type={showPass ? 'text' : 'password'}
                                            placeholder="Senha..."
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            {...register('password')}
                                            onChange={handleValidate}
                                        />
                                        <InputRightElement
                                            onClick={() => setShowPass(!showPass)}
                                            style={{ cursor: 'pointer' }}
                                            color="gray.700"
                                        >
                                            {showPass ? <ViewOffIcon /> : <ViewIcon />}
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors.password && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={errors.confirm} marginTop="15px">
                                    <InputGroup>
                                        <Input
                                            data-testid="confirm"
                                            type={showConfirm ? 'text' : 'password'}
                                            placeholder="Confirmar senha..."
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            {...register('confirm')}
                                        />
                                        <InputRightElement
                                            onClick={() => setShowConfirm(!showConfirm)}
                                            style={{ cursor: 'pointer' }}
                                            color="gray.700"
                                        >
                                            {showConfirm ? <ViewOffIcon /> : <ViewIcon />}
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors.confirm && <FormErrorMessage>{errors.confirm?.message}</FormErrorMessage>}
                                </FormControl>

                                <Flex direction="column" padding="15px 0 0">
                                    <FeedbackPassword
                                        status={validation.length}
                                        value={password}
                                        message="No mínimo 8 caracteres"
                                    />
                                    <FeedbackPassword
                                        status={validation.special}
                                        value={password}
                                        message="Caractere especial (ex: #*@)"
                                    />
                                    <FeedbackPassword
                                        status={validation.number}
                                        value={password}
                                        message="Pelo menos um número"
                                    />
                                    <FeedbackPassword
                                        status={validation.uppercase}
                                        value={password}
                                        message="Letra maiúscula"
                                    />
                                    <FeedbackPassword
                                        status={validation.lowercase}
                                        value={password}
                                        message="Letra minúscula"
                                    />
                                </Flex>

                                <FormControl isInvalid={errors.acceptTerms} marginTop="25px">
                                    <Checkbox
                                        alignItems="top"
                                        data-testid="acceptTerms"
                                        {...register('acceptTerms')}
                                        value="1"
                                    >
                                        <Text fontSize="0.75rem" fontWeight="300">
                                            Você aceita os{' '}
                                            <Link
                                                to="https://bee4.com.br/termos-de-uso"
                                                target="_blank"
                                                fontSize=".75rem"
                                            >
                                                termos e condições
                                            </Link>{' '}
                                            e nossas{' '}
                                            <Link
                                                to="https://bee4.com.br/politica-de-privacidade"
                                                target="_blank"
                                                fontSize=".75rem"
                                            >
                                                políticas de privacidade
                                            </Link>
                                            ?
                                        </Text>
                                    </Checkbox>
                                </FormControl>

                                <FormControl marginTop="0px">
                                    <Checkbox alignItems="top" value={1} {...register('acceptEmails')}>
                                        <Text fontSize="0.75rem" fontWeight="300">
                                            Você deseja receber atualizações, eventos e ofertas?
                                        </Text>
                                    </Checkbox>
                                </FormControl>

                                <FormControl marginTop="25px">
                                    <GradientButton
                                        id="signin"
                                        label="Definir Senha"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                </FormControl>
                            </Form>
                        </Flex>
                    </CardBox>
                </Flex>
            </Container>
        </Main>
    );
};
