import appConfig from '../config/appConfig';
import { http } from './rest.api';
import companiesData from '../data/Companies/list.json';

export type TCompaniesJSON = typeof companiesData;

export type TCompanyDealingData = {
    date: string;
    referencePrice: number;
    dealingVariation: number;
    lastTradePrice: number;
};

export const requestCompaniesDealingData = async (ticker: string): Promise<TCompanyDealingData> => {
    const { data } = await http.get(`${appConfig.API_URL}business/companies/${ticker}`);
    return data;
};
