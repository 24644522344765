import styled from 'styled-components';
import { bee4Theme } from '../../styles/theme';

type TColorfulLabelProps = {
    type: string | number;
    $colorMode?: string;
};

export const Label = styled.span<TColorfulLabelProps>`
    font-weight: 500;
    color: ${({ type, $colorMode }) => {
        return $colorMode === 'dark'
            ? type === 'buy' || type === 0
                ? bee4Theme.colors.green[100]
                : bee4Theme.colors.red[100]
            : type === 'buy' || type === 0
            ? bee4Theme.colors.green[300]
            : bee4Theme.colors.red[300];
    }};
`;
