import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../config/appConfig';
import { initialState } from './initial';

export const getWalletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        getWalletData: (state): void => {
            state.error = false;
            state.loading = true;
            state.positions = initialState.positions;
        },
        setWalletData: (state, action): void => {
            state.updated = Date.now();
            state.error = false;

            const { buy, sell } = action.payload;

            state.positions.buy = {
                coin: buy.ProductSymbol || '',
                amount: buy.Amount || 0,
                hold: buy.Hold || 0,
                operatingLimit: buy.Amount || 0,
                blockedOperatingLimit: buy.Hold || 0,
                pendingDeposits: buy.PendingDeposits,
            };

            sell.map((item) => {
                state.positions.sell.push({
                    coin: item.ProductSymbol || '',
                    tokens: parseInt(item.Amount) || 0,
                    hold: parseInt(item.Hold) || 0,
                });
            });

            state.loading = false;
        },
        updatePositions: (state, action): void => {
            if (action.payload.filter((item) => item.ProductId === appConfig._DEFAULT_FIAT_ID).length > 0) {
                const buy = action.payload.find((item) => item.ProductId === appConfig._DEFAULT_FIAT_ID);
                state.positions.buy = {
                    amount: buy.Amount || 0,
                    hold: buy.Hold || 0,
                    operatingLimit: buy.Amount || 0,
                    blockedOperatingLimit: buy.Hold || 0,
                    pendingDeposits: buy.pendingDeposits || 0,
                };
            }
        },
        getWalletFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
        },
        resetWalletData: (state): void => {
            state.positions = initialState.positions;
            state.error = false;
            state.loading = false;
            state.updated = Date.now();
        },

        setTradingInvoicesFilter: (state, action): void => {
            state.invoices.dateRange = action.payload;
        },

        setInvoicesLoading: (state, action): void => {
            state.invoices.loading = action.payload;
        },
        getTradingInvoices: (state): void => {
            state.invoices.modal.loading = true;
        },
        setTradingInvoices: (state, action): void => {
            if (action.payload.length !== state.invoices.data.length) {
                state.invoices.data = action.payload.data;
                state.invoices.pagination.currentPage = Number(action.payload.currentPage);
                state.invoices.pagination.totalItems = Number(action.payload.totalItems);

                if (!state.invoices.lastInvoices.length) {
                    state.invoices.lastInvoices = action.payload.data.filter((_, index) => index < 5);
                }
            }

            state.invoices.loading = false;
            state.invoices.modal.loading = false;
            state.updated = Date.now();
        },

        changePage: (state, action): void => {
            state.invoices.pagination.currentPage = Number(action.payload.page);
            state.invoices.data = [];
            state.invoices.modal.loading = true;
        },
        openModal: (state, action): void => {
            state.invoices.modal.open = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filterTradingInvoices: (state, action): void => {
            state.invoices.data = [];
            state.invoices.modal.loading = true;
            state.error = false;
        },
        setTradingInvoicesFiltered: (state, action): void => {
            state.invoices.data = action.payload.data;
            state.invoices.pagination.currentPage = Number(action.payload.currentPage);
            state.invoices.pagination.totalItems = Number(action.payload.totalItems);
            state.invoices.modal.loading = false;
            state.invoices.loading = false;
        },
        reloadTradingInvoicesFilter: (state): void => {
            state.invoices.dateRange = null;
            state.invoices.data = initialState.invoices.data;
            state.invoices.pagination = initialState.invoices.pagination;
            state.invoices.modal.loading = true;
        },

        resetTradingInvoices: (state): void => {
            state.invoices.data = initialState.invoices.data;
            state.invoices.pagination = initialState.invoices.pagination;
            state.invoices.loading = false;
            state.invoices.modal.loading = false;
        },
        resetTradingInvoicesFilter: (state): void => {
            state.invoices.dateRange = null;
            state.invoices.data = [];
            state.invoices.modal.loading = false;
        },
    },
});

export const walletActions = getWalletSlice.actions;
export default getWalletSlice.reducer;
