import { combineEpics } from 'redux-observable';
import * as userEpics from './user/epics';
import * as cancelOrderEpics from './cancelOrder/epics';
import * as cancelReplaceOrderEpics from './cancelReplaceOrder/epics';
import * as timelineEpics from './timeline/epics';
import * as companiesEpics from './companies/epics';
import * as walletEpic from './wallet/epics';
import * as tradesHistoryEpics from './getTradesHistory/epics';
import * as getAccountPositionsEpics from './getAccountPositions/epics';
import * as getOpenOrdersEpics from './getOpenOrders/epics';
import * as getOrdersHistoryEpics from './getOrdersHistory/epics';
import * as sendOrderEpics from './sendOrder/epics';
import * as subscribeAccountEventsEpics from './subscribeAccountEvents/epics';
import * as subscribeLevel1Epics from './subscribeLevel1/epics';
import * as subscribeLevel2Epics from './subscribeLevel2/epics';
import * as subscribeTradesEpics from './subscribeTrades/epics';

export const combinedEpics = combineEpics(
    userEpics.userSignInEpic,
    userEpics.signinAlphapointEpic,
    userEpics.userSignOutEpic,
    userEpics.signOutAlhapointEpic,
    userEpics.checkSessionAuthEpic,
    userEpics.createUserDataSessionEpic,
    timelineEpics.subscribeTimelineEpic,
    timelineEpics.getNowTimelineEpic,
    companiesEpics.getCompaniesEpic,
    cancelReplaceOrderEpics.cancelReplaceOrderEpics,
    subscribeLevel1Epics.subscribeLevel1Epic,
    subscribeLevel1Epics.getAuctionDataEpic,
    subscribeLevel1Epics.getTunnelDataEpic,
    subscribeLevel2Epics.subscribeLevel2Epic,
    subscribeTradesEpics.subscribeTradesEpic,
    getOpenOrdersEpics.getOpenOrdersEpic,
    getOrdersHistoryEpics.getOrdersHistoryEpic,
    getOrdersHistoryEpics.getAllOrdersHistoryEpic,
    getOrdersHistoryEpics.paginateOrdersHistoryEpic,
    tradesHistoryEpics.getTradesHistoryEpics,
    sendOrderEpics.sendOrderEpic,
    cancelOrderEpics.cancelOrderEpic,
    getAccountPositionsEpics.getAccountPositionsEpic,
    walletEpic.getPositionsToWalletEpic,
    walletEpic.getTradingInvoicesEpic,
    walletEpic.filterTradingInvoicesEpic,
    walletEpic.filterPaginatedTradingInvoicesEpic,
    walletEpic.resetFilterTradingInvoicesEpic,
    subscribeAccountEventsEpics.subscribeAccountEventsEpic,
    subscribeAccountEventsEpics.subscribeAccountValueEventsEpic
);
