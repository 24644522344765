import { Text } from '@chakra-ui/react';
import { Icon } from '../../../../components/Icon';
import { FlexContainer, WarningCard } from '../../styles';

export const MaintenanceMessage = () => {
    return (
        <FlexContainer justifyContent="center">
            <WarningCard margin="0 0 80px" alignItems="center">
                <Icon type="warning" height="77px" width="88px" mr={4} mb={4} />
                <Text fontSize="1.5rem" fontWeight={600} lineHeight={1.2}>
                    Nosso ambiente de negociação está temporariamente indisponível. Fique tranquilo, pois em breve você
                    poderá continuar com suas operações por aqui.
                </Text>
            </WarningCard>
        </FlexContainer>
    );
};
