import { Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { delay, switchMap, throttleTime, catchError, concatMap } from 'rxjs/operators';

import { getOpenOrdersActions } from '../getOpenOrders';
import { getOrdersHistoryActions } from '../getOrdersHistory';
import { getAccountPositionsActions, getAccountPositionsSlice } from '.';

export const getAccountPositionsEpic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(getAccountPositionsActions.getAccountPositions.match),
        filter(() => !!state$.value.companies.current.assets.defaultInstrumentId),
        delay(800),
        throttleTime(defaultThrottleTime),
        switchMap(({ payload }) => {
            /** Alphapoint **/
            const body = {
                AccountId: state$.value.user.userSession.alphapoint.accountId,
                OMSId: state$.value.companies.current.assets.defaultOMSId,
                ...payload,
            };

            return Alphapoint.subscribe({ action: getAccountPositionsSlice.name, ...body }).pipe(
                concatMap(({ data }) => {
                    return of(
                        getAccountPositionsActions.getAccountPositionsSuccess({
                            company: state$.value.companies.current,
                            data,
                        }),
                        getOpenOrdersActions.resetOpenOrders(),
                        getOpenOrdersActions.getOpenOrders(),
                        getOrdersHistoryActions.getOrdersHistory()
                    );
                }),
                catchError(() => of(getAccountPositionsActions.getAccountPositionsFailed()))
            );
        })
    );
