import { useState } from 'react';
import { bee4Theme } from '../../styles/theme';

import { Background, BackgroundTransparent, ResponsiveVideoContainer, StyledPlayIcon, ThumbNail } from './style';

interface YoutubeEmbedProps {
    url: string;
    align?: 'left' | 'right';
    title?: string;
    id?: string;
}

const YoutubeEmbed = ({ url, align = 'right', title = 'Embedded youtube', id = 'embed-video' }: YoutubeEmbedProps) => {
    const [started, setStarted] = useState(false);
    let params = null;

    if (!!url) {
        const arr = url.split('?');
        params = JSON.parse(`{"${decodeURI(arr[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
    }

    const embedId = params?.v;
    return (
        <ResponsiveVideoContainer align={align}>
            <Background align={align} />

            {!started ? (
                <ThumbNail embedId={embedId} align={align} onClick={() => setStarted(true)}>
                    <BackgroundTransparent />
                    <StyledPlayIcon type="play" height="80px" width="80px" mr="auto" color={bee4Theme.colors.white} />
                </ThumbNail>
            ) : (
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={title}
                    id={id}
                />
            )}
        </ResponsiveVideoContainer>
    );
};

export default YoutubeEmbed;
