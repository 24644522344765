import { Container, HStack, VStack, useColorModeValue, Text, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MetaTags } from '../../components/MetaTags';
import { PageTitle } from '../../components/PageTitle';
import { PageDescript } from '../../components/PageDescription';

import { CardBox } from '../../components/CardBox';
import { GradientButton } from '../../components/GradientButton';
import { bee4Theme } from '../../styles/theme';
import { Card, MainValues, SubtitleCard, TitleCard, ValueCard, Wrapper, WrapperButtons } from './styles';

import IconEye from '../../assets/icons/icon-eye.svg';
import IconEyeOff from '../../assets/icons/icon-eye-off.svg';
import IconEyeLight from '../../assets/icons/icon-eye-light.svg';
import IconEyeOffLight from '../../assets/icons/icon-eye-off-light.svg';

import { useEffect, useState } from 'react';
import { currency } from '../../utils/numbers';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { subscribeAccountEventsActions } from '../../store/subscribeAccountEvents';

import { date, onlyDateDBToBR } from '../../utils/date';

import { walletActions } from '../../store/wallet';
import { TokensBalance } from './Components/TokensBalance';
import { MessageBox } from '../../components/MessageBox';

import { notificationBellActions } from '../../store/notificationBell';
import { companiesActions } from '../../store/companies';
import { UserBlockedDisclaimer } from '../../components/UserBlockedDisclaimer';
import { TBlockedResponse, useRoles } from '../../hooks/useRules';
import { TradingIncoices } from './Components/TradingInvoices';

export const Wallet = () => {
    const navigate = useNavigate();
    const shouldIBlocked = useRoles();
    const dispatch = useAppDispatch();

    const iconEye = useColorModeValue(IconEye, IconEyeLight);
    const iconEyeOff = useColorModeValue(IconEyeOff, IconEyeOffLight);
    const buttonBoxColor = useColorModeValue(bee4Theme.colors[200], bee4Theme.colors[200]);
    const descriptColor = useColorModeValue(bee4Theme.colors.lead[300], bee4Theme.colors.gray[200]);

    const [roles, setRoles] = useState<TBlockedResponse>({} as TBlockedResponse);
    const [state, setState] = useState({
        showBalanceWallet: false,
        showTokensWallet: false,
    });

    const { companies, user, wallet } = useAppSelector((state) => state);
    const { buy, sell } = wallet.positions;

    const currentDate = onlyDateDBToBR(new Date().toISOString());

    useEffect(() => {
        if (user.userSession.alphapoint.token === null) return;
        !companies.list.length && dispatch(companiesActions.getCompanies());

        return () => {
            dispatch(walletActions.resetWalletData());
            setRoles({} as TBlockedResponse);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!companies.list.length) {
            dispatch(walletActions.getWalletData());
            dispatch(subscribeAccountEventsActions.subscribeAccountEvents());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies.list]);

    useEffect(() => {
        setRoles(shouldIBlocked('withdraw'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userSession]);

    useEffect(() => {
        if (!!buy.pendingDeposits) {
            dispatch(
                notificationBellActions.pushNotificationBellList({
                    id: `${buy.pendingDeposits}-WalletPendingDeposit`,
                    title: `Depósito em processamento`,
                    description: `Seu depósito foi reconhecido e em breve o saldo será exibido em Minha Carteira.`,
                    status: 'success',
                    date: date().format('DD/MM/YYYY HH:mm:ss'),
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buy]);

    const updateShowBalance = (label) => {
        setState((prev) => ({
            ...prev,
            [label]: !prev[label],
        }));
    };

    const balance = () =>
        roles.blocked
            ? { amount: currency(buy.amount), free: 0, hold: currency(buy.amount) }
            : { amount: currency(buy.amount), free: currency(buy.amount - buy.hold), hold: currency(buy.hold) };

    return (
        <main>
            <MetaTags>
                <title>Minha Carteira - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%" height="100%" paddingBottom="60px">
                <PageTitle>Minha Carteira</PageTitle>
                <PageDescript>Consulte seu extrato de movimentação e a evolução dos seus investimentos.</PageDescript>

                <UserBlockedDisclaimer />

                <MessageBox
                    messages={
                        buy.pendingDeposits && [
                            {
                                message: `**Seu depósito foi reconhecido e está sendo processado.** Em breve o saldo aparecerá em Minha Carteira.`,
                            },
                        ]
                    }
                    messageId="walletDepositMessages"
                    theme="info"
                />

                <CardBox $topLine={true} width={['100%', '100%', '76%']} marginTop="50px">
                    <Wrapper>
                        <VStack flex={1.4} alignItems="flex-start" paddingLeft="20px">
                            <HStack>
                                <Text fontWeight="500" fontSize="1.35rem" lineHeight="1.8">
                                    Saldo em conta
                                </Text>
                                <Image
                                    src={!state.showBalanceWallet ? iconEyeOff : iconEye}
                                    width="20px"
                                    cursor="pointer"
                                    onClick={() => updateShowBalance('showBalanceWallet')}
                                />
                            </HStack>
                            <MainValues>
                                <Card spacing={0}>
                                    <TitleCard>Saldo total</TitleCard>
                                    <SubtitleCard color={descriptColor}>
                                        Saldo total na conta {currentDate}
                                    </SubtitleCard>
                                    <ValueCard>
                                        {!state.showBalanceWallet ? 'R$ **********' : balance().amount}
                                    </ValueCard>
                                </Card>
                                <Card spacing={0}>
                                    <TitleCard>Saldo livre</TitleCard>
                                    <SubtitleCard color={descriptColor}>
                                        Disponível para retirada ou negociação
                                    </SubtitleCard>
                                    <ValueCard>{!state.showBalanceWallet ? 'R$ **********' : balance().free}</ValueCard>
                                </Card>
                                <Card spacing={0}>
                                    <TitleCard>Saldo bloqueado</TitleCard>
                                    <SubtitleCard color={descriptColor}>
                                        Alocado em ofertas, retiradas ou bloqueios
                                    </SubtitleCard>
                                    <ValueCard>{!state.showBalanceWallet ? 'R$ **********' : balance().hold}</ValueCard>
                                </Card>
                            </MainValues>
                        </VStack>

                        <VStack flex={0.8} padding="20px 0" justifyContent="center">
                            <WrapperButtons width={['100%', '100%', '80%', '60%']}>
                                <GradientButton
                                    label="FAZER NOVO DEPÓSITO"
                                    type="button"
                                    variant="colorful"
                                    fontSize=".8rem"
                                    margin="0"
                                    onClick={() => navigate(`/minha-carteira/depositos`)}
                                />
                                <GradientButton
                                    label="SOLICITAR RETIRADA"
                                    type="button"
                                    fontSize=".8rem"
                                    background={buttonBoxColor}
                                    onClick={() => !roles.blocked && navigate(`/minha-carteira/retirada`)}
                                    isBlocked={roles.blocked}
                                    tip={roles.blocked && roles.messages.withdraw}
                                />
                            </WrapperButtons>
                        </VStack>
                    </Wrapper>
                </CardBox>

                <HStack spacing={6} marginTop="20px">
                    {/* <BalanceHoldByToken
                        tokens={sell}
                        show={state.showBalanceWallet}
                        title="Saldo bloqueado por ativo"
                    /> */}
                    <TokensBalance
                        width={['100%', '100%', '100%', '76%']}
                        tokens={sell}
                        show={state.showBalanceWallet}
                        loading={!sell.length}
                        title="Saldo em tokens"
                    />
                </HStack>

                <TradingIncoices />
            </Container>
        </main>
    );
};
