import styled from 'styled-components';
import { bee4Theme } from '../../../../styles/theme';

export const Line = styled.span`
    position: absolute;

    top: -4px;
    left: -1px;

    width: 55%;
    height: 4px;

    border-radius: 0 10px 10px 0;
    background: linear-gradient(90deg, ${bee4Theme.colors.blue[100]}, ${bee4Theme.colors.blue[400]});
`;
