import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial';

export const sendOrderSlice = createSlice({
    name: 'SendOrder',
    initialState,
    reducers: {
        sendOrder: (state, action): void => {
            state.error = false;
            state.loading = true;
            state.side = action.payload.Side === 1 ? 'sell' : 'buy';
        },
        sendOrderSuccess: (state, action): void => {
            state.updated = Date.now();
            state.error = false;
            state = Object.assign(state, action.payload);
            state.loading = false;
            state.side = null;
        },
        sendOrderFailed: (state): void => {
            state.updated = Date.now();
            state.error = true;
            state.loading = false;
            state.side = null;
        },
    },
});

export const sendOrderActions = sendOrderSlice.actions;
export default sendOrderSlice.reducer;
