import { useState } from 'react';
import { Button, Checkbox, Flex, HStack, Text } from '@chakra-ui/react';

import { Icon } from '../../../../components/Icon';
import { Modal } from '../../../../components/Modal';

import { setLSOneDay } from '../../../../utils/storage';

import { __KEY_MODAL_ORDER } from '../FormTrading/constants';

interface ModalProps {
    isOpen: boolean;
    onClose: (isChecked: boolean) => void;
    id: string;
}

export const ModalOrderConfirm = ({ isOpen, onClose, id }: ModalProps) => {
    const [checked, setChecked] = useState(!!localStorage.getItem(__KEY_MODAL_ORDER));

    const handleConfirm = () => {
        if (checked) {
            setLSOneDay(__KEY_MODAL_ORDER);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(checked)}
            motionPreset="slideInBottom"
            size="sm"
            modalTitle="CONFIRMAR OFERTA"
            topLine={true}
        >
            <Flex justifyContent="center" alignItems="center" m={2}>
                <Flex
                    w="104px"
                    h="104px"
                    bg="yellow.100"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Icon type="warning" width="60px" height="53px" color="black" />
                </Flex>
            </Flex>

            <Text textAlign="center" mb={6} fontSize=".875rem">
                Durante o Período de{' '}
                <strong>
                    Ofertas
                    <br /> Irrevogáveis não é possível cancelar
                    <br /> ofertas
                </strong>
                , apenas editá-las para melhor. Confirmar o envio da oferta?
            </Text>

            <HStack mb={2}>
                <Button variant="outline" width="40%" height="44px" fontWeight="light" onClick={() => onClose(checked)}>
                    NÃO
                </Button>
                <Button
                    colorScheme="green"
                    color="white"
                    width="60%"
                    height="44px"
                    onClick={handleConfirm}
                    type="submit"
                    form={id}
                >
                    CONFIRMAR
                </Button>
            </HStack>

            <Checkbox
                size="sm"
                colorScheme="gray"
                spacing="6px"
                onChange={(e) => setChecked(e.target.checked)}
                isChecked={checked}
            >
                <Text fontSize=".75rem">Silenciar confirmação durante ofertas irrevogáveis</Text>
            </Checkbox>
        </Modal>
    );
};
