import { ReactNode } from 'react';
import styled from 'styled-components';

import { bee4Theme } from './../../styles/theme';
import background from '../../assets/backgrounds/background-button.svg';

export interface BoxType {
    width?: string;
    height?: string;
    background?: string;
    children: ReactNode;
    $colorMode: string;
}

export interface ArrowType {
    side: 'left' | 'right';
}

export const Box = styled.div<BoxType>`
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '100%')};

    min-height: 343px;

    position: relative;
    overflow: hidden;

    padding: 40px 50px;
    border-radius: 10px;
    background: ${({ $colorMode, background }) => {
        if (background) {
            return background;
        }

        return $colorMode === 'dark'
            ? bee4Theme.colors.lead[100]
            : `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]})`;
    }};

    &::after {
        content: '';

        width: 150px;
        height: 150px;

        position: absolute;
        bottom: 0;
        right: -10px;

        background-image: url(${background});
        background-size: 100% 100%;

        background-repeat: no-repeat;
        background-position: center;
    }

    .pagination-wrapper {
        width: fit-content;

        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            background-color: ${bee4Theme.colors.white};
        }
    }
`;

export const Arrow = styled.span<ArrowType>`
    cursor: pointer;

    position: absolute;
    z-index: 4;
    top: 50%;

    ${({ side }) => (side === 'left' ? 'left: 10px;' : 'right: 10px;')}
    transition: all .3s ease-in-out;

    svg {
        width: 35px;
        height: 35px;

        transform: ${({ side }) => (side === 'left' ? 'rotate(180deg)' : 'unset')};

        path {
            transition: all 0.3s ease-in-out;
            fill: ${bee4Theme.colors.gray[300]};
        }
    }

    &:hover {
        svg {
            path {
                fill: ${bee4Theme.colors.white};
            }
        }
    }
`;
