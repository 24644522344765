import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Text, Image, Flex, useColorMode, HStack } from '@chakra-ui/react';
import { InputGroup, FormControl, Input, InputRightElement, FormErrorMessage } from '@chakra-ui/react';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';

import { CardBox } from '../../components/CardBox';
import { GradientButton } from '../../components/GradientButton';
import { Link } from '../../components/Link';

import Bee4LightLogo from '../../assets/logos/bee4-white-logo.svg';
import Bee4DarkLogo from '../../assets/logos/bee4-black-logo.svg';
import IconSecurity from '../../assets/icons/icon-lock-signin.svg';

import { Form, WrapperBox, WrapperImage, Container, MessageWrapper } from './styles';
import { MetaTags } from '../../components/MetaTags';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { userActions } from '../../store/user';
import { Checkbox } from '../../components/Checkbox';
import appConfig from '../../config/appConfig';
import { MessageBox } from '../../components/MessageBox';

export const SignIn = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(({ user }) => user);
    const { colorMode, setColorMode } = useColorMode();

    useEffect(() => {
        setColorMode('dark');
        if (appConfig.MAINTENANCE) {
            dispatch(userActions.signOut());
            console.log({ logout: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logo = colorMode === 'dark' ? Bee4LightLogo : Bee4DarkLogo;
    const [show, setShow] = useState(false);
    const [remember, setRemember] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleLogin = (data): void => {
        const { email, password } = data;
        dispatch(userActions.signIn({ email, password, remember }));
    };

    const message = () => (
        <MessageWrapper>
            <MessageBox
                close={false}
                messages={[{ message: appConfig.MAINTENANCE_MESSAGE }]}
                theme="warning"
                html={true}
            />
        </MessageWrapper>
    );

    const form = () => {
        return (
            <WrapperBox>
                <CardBox width="95%" maxWidth="360px" height="auto" padding="25px 15px" variant="back-light">
                    <Flex width="100%" height="100%" direction="column" alignItems="center">
                        <Flex width="90%" margin="10px 0" alignItems="center">
                            <Image
                                src={IconSecurity}
                                width="25px"
                                style={{
                                    filter: colorMode === 'light' ? 'invert(1)' : 'invert(0)',
                                    marginRight: '5px',
                                }}
                            />
                            <Text fontSize="0.8rem" fontWeight="400">
                                Ambiente Seguro
                            </Text>
                        </Flex>
                        <Form noValidate onSubmit={handleSubmit(handleLogin)}>
                            <FormControl isInvalid={errors.email} marginTop="15px">
                                <Input
                                    data-testid="email"
                                    type="Text"
                                    placeholder="CPF ou CNPJ"
                                    autoComplete="off"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    {...register('email', {
                                        required: true,
                                    })}
                                />
                                {errors.email && errors.email.message ? (
                                    <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                                ) : (
                                    <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={errors.password} marginTop="15px">
                                <InputGroup>
                                    <Input
                                        data-testid="password"
                                        type={show ? 'text' : 'password'}
                                        placeholder="Senha..."
                                        autoComplete="off"
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        {...register('password', { required: true })}
                                    />
                                    <InputRightElement
                                        onClick={() => setShow(!show)}
                                        style={{ cursor: 'pointer' }}
                                        color="gray.700"
                                    >
                                        {show ? <ViewOffIcon /> : <ViewIcon />}
                                    </InputRightElement>
                                </InputGroup>
                                {errors.password && <FormErrorMessage>Campo obrigatório</FormErrorMessage>}
                            </FormControl>

                            <HStack marginTop="10px" alignItems="center" justifyContent="space-between">
                                <Checkbox label="Manter conectado" onChange={() => setRemember(!remember)} />

                                <FormControl textAlign="right">
                                    <Link to="/esqueci-minha-senha" fontSize="0.75rem" fontWeight="700">
                                        Esqueci a senha
                                    </Link>
                                </FormControl>
                            </HStack>

                            <FormControl margin="30px 0 8px">
                                <GradientButton
                                    id="signin"
                                    label={user.loading ? 'Aguarde...' : 'Entrar na minha conta'}
                                    type="submit"
                                    disabled={user.loading}
                                    variant="colorful"
                                    height="44px"
                                    fontWeight="bold"
                                    fontSize="0.8rem"
                                />
                            </FormControl>

                            <FormControl margin="8px 0 15px">
                                <GradientButton
                                    id="sinup"
                                    data-testid="sinup"
                                    label="Criar uma conta"
                                    variant="gray"
                                    link={true}
                                    href="https://cadastro.beegin.com.br/"
                                    target="_blank"
                                    height="44px"
                                    fontWeight="400"
                                    fontSize="0.8rem"
                                />
                            </FormControl>
                        </Form>
                    </Flex>
                </CardBox>
            </WrapperBox>
        );
    };

    return (
        <main style={{ paddingTop: 0, paddingBottom: 0 }}>
            <MetaTags>
                <title>Entre no principal mercado de acesso do Brasil - BEE4</title>
            </MetaTags>
            <Container maxW="container.xl" width="100%">
                <Flex width="100%" height="100%" flexFlow="column" alignItems="center" justifyContent="center">
                    <WrapperImage>
                        <Image boxSize="180px" height="60px" objectFit="contain" src={logo} alt="Bee4" />
                    </WrapperImage>

                    {appConfig.MAINTENANCE ? message() : form()}
                </Flex>
            </Container>
        </main>
    );
};
