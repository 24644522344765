export const currency = (value: number) => {
    const numberFormat = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    return numberFormat.format(value);
};

export const formatNumber = (value: number) => {
    const numberFormat = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return numberFormat.format(value);
};

export const formatNumberWithoutFraction = (value: number) => {
    const numberFormat = new Intl.NumberFormat('pt-BR');
    return numberFormat.format(value);
};

export const convertStringCurrencyToNumber = (value: string): number => {
    if (!value) return 0;

    const _value = value
        .replace(/[^\d,]/gi, '')
        .replace(/[,]/gi, '.')
        .split('.');

    return _value.length > 1 ? Number(`${_value[0]}.${_value[1].substring(0, 2)}`) : Number(_value[0]);
};
