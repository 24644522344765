import styled from 'styled-components';

export const Main = styled.main`
    padding-top: 0;
`;

export const Form = styled.form`
    width: 90%;
    margin: 0 auto;

    display: flex;
    flex-flow: column;
`;
