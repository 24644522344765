import { useEffect, useState } from 'react';
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';

import { LabelHighLigh } from '../../../../components/LabelHighLigh';
import { Link } from '../../../../components/Link';
import { PriceRange } from '../PriceRange';
import { ModalPriceRange } from '../ModalPriceRange';

import { ContainerPriceRange, CaptalizeSentence } from './styles';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { Loading } from '../../../../components/Loading';
import { Information } from '../../../../components/Information';
import { TSubscribeLevel1Tunnel } from '../../../../store/subscribeLevel1/types';
import { subscribeLevel1Actions } from '../../../../store/subscribeLevel1';
import { getTradePriceFromLastDealing } from '../../../../utils/trades';
import { date } from '../../../../utils/date';

type TInformationBoxData = {
    lastTrade: number;
    theoreticalVariation: number;
    theoreticalPrice: number;
    tunnel?: TSubscribeLevel1Tunnel;
    tooltip?: { price: string; variation: string };
};

export const InformationBox = () => {
    const [priceModalOpen, setPriceModalOpen] = useState(false);
    const labelColor = useColorModeValue('gray.600', 'gray.300');

    const { subscribeLevel1, timeline, subscribeTrades, companies } = useAppSelector((state) => state);
    const lastLeadingDate = !!timeline.lastDealing ? date(timeline.lastDealing).utc().format('DD/MM') : '--/--';

    const dispatch = useAppDispatch();
    const sendGTM = useGTMDataLayer();

    const [informations, setInformations] = useState<TInformationBoxData>({
        lastTrade: 0,
        theoreticalVariation: 0,
        theoreticalPrice: 0,
        tunnel: {
            min: 0,
            max: 100,
            reference: 0,
        },
    });

    // useEffect(() => console.log({ info: informations.tunnel }), [informations]);

    const handleOpenPriceModal = () => {
        setPriceModalOpen(true);
        sendGTM({
            title: 'Negociação - Entenda Faixa de Preço - BEE4',
            page: `${location.pathname}/entenda-faixa-preco`,
        });
    };

    const handleClosePriceModal = () => {
        setPriceModalOpen(false);
        sendGTM({
            title: `Negociação - ${companies.current.name} - BEE4`,
            page: `${location.pathname}`,
        });
    };

    useEffect(() => {
        dispatch(subscribeLevel1Actions.subscribeLevel1());
        return () => {
            dispatch(subscribeLevel1Actions.unsubscribeLevel1());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    useEffect(() => {
        let _infos = {
            lastTrade: subscribeLevel1.LastTradedPx,
            tunnel: subscribeLevel1.tunnel,
            theoreticalVariation: 0,
            theoreticalPrice: 0,
        } as TInformationBoxData;

        /**
         * Cálculo das variações:
         **
         * Em primeiros pregões e Leilões:
         * Pregão: Variação do pregão = (Preço do último negócio do pregão atual/ preço do último negócio do pregão anterior - 1) * 100 => conforme o card BEE-6646
         * Leilão: Variação teórica = ((Preço Teórico - Último Negócio) / Último Negócio) * 100 => conforme o card BEE-5760
         */
        if (timeline.currentStep?.type === 'dealing') {
            let lastDealingTradePrice = 0;

            if (!!Object.keys(subscribeTrades.trades).length) {
                lastDealingTradePrice = getTradePriceFromLastDealing(subscribeTrades.trades);
            }
            if (!lastDealingTradePrice) {
                lastDealingTradePrice = subscribeLevel1.tunnel.reference;
            }

            _infos = {
                ..._infos,
                theoreticalVariation: !!lastDealingTradePrice
                    ? (subscribeLevel1.LastTradedPx / lastDealingTradePrice - 1) * 100
                    : 0,
                theoreticalPrice: subscribeLevel1.CurrentDayNotional,
                tooltip: {
                    variation:
                        'Indica a variação entre o preço do último negócio e o preço do último negócio do pregão anterior \n\nCaso em algum momento a "variação do pregão" esteja como "N/A" significa que o sistema está processando os valores fornecidos para gerar a visualização do novo valor de "variação do pregão".',
                    price: 'Valor total dos negócios realizados neste Leilão e Pregão',
                },
            };
        } else {
            let _theoreticalPrice = 0;
            _theoreticalPrice =
                !subscribeLevel1.auction.quantity && !!subscribeLevel1.auction.price
                    ? subscribeLevel1.tunnel.reference
                    : subscribeLevel1.auction.price;

            _infos = {
                ..._infos,
                theoreticalVariation:
                    !!subscribeLevel1.LastTradedPx && subscribeLevel1.auction.price !== null
                        ? ((_theoreticalPrice - subscribeLevel1.LastTradedPx) / subscribeLevel1.LastTradedPx) * 100
                        : 0,
                theoreticalPrice: _theoreticalPrice,
                tooltip: {
                    variation:
                        'Indica a variação entre o “Preço teórico” e o preço do último negócio realizado no pregão anterior \n\nCaso em algum momento a "Variação teórica" esteja como "N/A" significa que o sistema está processando os valores fornecidos para gerar a visualização do novo valor de "Variação teórica".',
                    price: 'Preço indicativo se o leilão fosse realizado com as atuais ofertas de compra e venda. Este preço pode variar a medida que novas ofertas são feitas pelos investidores. \n\nCaso em algum momento o "Preço teórico" esteja como "N/A" significa que o sistema está processando os valores fornecidos para gerar a visualização do novo valor de "Preço teórico".',
                },
            };
        }
        setInformations(() => _infos);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeLevel1, subscribeTrades.trades]);

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            padding={['0', '0', '0', '0 20px']}
            direction={['column', 'column', 'row']}
            position="relative"
        >
            <Flex direction="column" alignItems="center" marginBottom={[5, 5, 0]}>
                <ContainerPriceRange>
                    <PriceRange
                        minValue={informations.tunnel.min}
                        maxValue={informations.tunnel.max}
                        referenceValue={informations.tunnel.reference}
                        range={{
                            minValue: informations.tunnel.range?.min,
                            maxValue: informations.tunnel.range?.max,
                        }}
                    />
                    <Link fontWeight="500" fontSize=".85rem" onClick={handleOpenPriceModal}>
                        Entenda a faixa de preço
                    </Link>
                    <ModalPriceRange isOpen={priceModalOpen} onClose={handleClosePriceModal} />
                </ContainerPriceRange>
            </Flex>

            <Flex
                width={['100%', '100%', '100%', '60%']}
                direction={['column', 'column', 'column', 'row']}
                alignItems={['center', 'center', 'center', 'start']}
                justifyContent="space-between"
            >
                <Flex
                    direction="column"
                    alignItems={['center', 'center', 'center', 'start']}
                    justifyContent="center"
                    marginBottom={[5, 5, 0]}
                >
                    <CaptalizeSentence fontWeight="500" fontSize="1rem" lineHeight="1.8">
                        {timeline.currentStep?.informationBox?.lastTrade}
                    </CaptalizeSentence>

                    {informations.lastTrade ? (
                        <LabelHighLigh value={informations.lastTrade} type="currency" />
                    ) : (
                        <Text fontWeight="600" fontSize="2rem" lineHeight="1">
                            N/A
                        </Text>
                    )}

                    {timeline.currentStep.type !== 'dealing' && (
                        <Text fontWeight="400" fontSize=".75rem" lineHeight="1.8" color={labelColor} paddingTop="5px">
                            Ref. Último pregão {lastLeadingDate}
                        </Text>
                    )}
                </Flex>

                <Flex
                    direction="column"
                    alignItems={['center', 'center', 'center', 'start']}
                    justifyContent="center"
                    marginBottom={[5, 5, 0]}
                >
                    <HStack spacing={1}>
                        <CaptalizeSentence fontWeight="500" fontSize="1rem" lineHeight="1.8" width="fit-content">
                            {timeline.currentStep?.informationBox?.theoreticalVariation}
                        </CaptalizeSentence>
                        {!!informations.tooltip?.variation && <Information label={informations.tooltip.variation} />}
                    </HStack>

                    {!!informations.theoreticalVariation && !subscribeLevel1.loading ? (
                        <LabelHighLigh
                            value={parseFloat(informations.theoreticalVariation.toFixed(2))}
                            type="percent"
                        />
                    ) : (
                        <Text fontWeight="600" fontSize="2rem" lineHeight="1">
                            N/A
                        </Text>
                    )}

                    {timeline.currentStep.type !== 'dealing' && (
                        <Text fontWeight="400" fontSize=".75rem" lineHeight="1.8" color={labelColor} paddingTop="5px">
                            Ref. Último pregão {lastLeadingDate}
                        </Text>
                    )}
                </Flex>

                <Flex
                    direction="column"
                    alignItems={['center', 'center', 'center', 'start']}
                    justifyContent="center"
                    marginBottom={[5, 5, 0]}
                >
                    <HStack spacing={1}>
                        <CaptalizeSentence fontWeight="500" fontSize="1rem" lineHeight="1.8" width="fit-content">
                            {timeline.currentStep?.informationBox?.theoreticalPrice}
                        </CaptalizeSentence>
                        {!!informations.tooltip?.price && <Information label={informations.tooltip.price} />}
                    </HStack>

                    {informations.theoreticalPrice > 0 || timeline.currentStep.type === 'dealing' ? (
                        <LabelHighLigh value={informations.theoreticalPrice} type="currency" />
                    ) : (
                        <Text fontWeight="600" fontSize="2rem" lineHeight="1">
                            N/A
                        </Text>
                    )}
                </Flex>
            </Flex>

            <Box position="absolute" bottom="-30px" left="50%">
                <Loading active={subscribeLevel1.loading} />
            </Box>
        </Flex>
    );
};
