import { createIcon } from '@chakra-ui/icons';

export const IconWarning = createIcon({
    displayName: 'IconWarning',
    viewBox: '0 0 88 78',
    path: (
        <path
            d="M39.2882 59.4286C39.2882 60.6789 39.7849 61.878 40.669 62.7621C41.5531 63.6462 42.7522 64.1429 44.0025 64.1429C45.2528 64.1429 46.4519 63.6462 47.336 62.7621C48.2201 61.878 48.7168 60.6789 48.7168 59.4286C48.7168 58.1783 48.2201 56.9792 47.336 56.0951C46.4519 55.211 45.2528 54.7143 44.0025 54.7143C42.7522 54.7143 41.5531 55.211 40.669 56.0951C39.7849 56.9792 39.2882 58.1783 39.2882 59.4286V59.4286ZM40.8596 29.5714V47.6429C40.8596 48.075 41.2132 48.4286 41.6453 48.4286H46.3596C46.7918 48.4286 47.1453 48.075 47.1453 47.6429V29.5714C47.1453 29.1393 46.7918 28.7857 46.3596 28.7857H41.6453C41.2132 28.7857 40.8596 29.1393 40.8596 29.5714ZM87.5802 72.7857L46.723 2.07143C46.1141 1.02054 45.0632 0.5 44.0025 0.5C42.9418 0.5 41.8811 1.02054 41.282 2.07143L0.42481 72.7857C-0.783226 74.8875 0.729274 77.5 3.14535 77.5H84.8596C87.2757 77.5 88.7882 74.8875 87.5802 72.7857ZM10.6293 70.0455L44.0025 12.2759L77.3757 70.0455H10.6293V70.0455Z"
            fill="currentColor"
        />
    ),
});
