import { createIcon } from '@chakra-ui/icons';

export const IconTwitter = createIcon({
    displayName: 'IconTwitter',
    viewBox: '0 0 30 25',
    path: (
        <path
            d="M29.7692 3.57983C28.6829 4.05448 27.5008 4.39608 26.2832 4.52913C27.5474 3.76838 28.494 2.56653 28.9456 1.14907C27.7594 1.86387 26.4598 2.36511 25.1046 2.63054C24.5382 2.01719 23.8532 1.52857 23.0922 1.19512C22.3313 0.861671 21.5106 0.690543 20.6815 0.692398C17.3269 0.692398 14.629 3.44679 14.629 6.82685C14.629 7.3015 14.6858 7.77614 14.7781 8.23281C9.75501 7.96672 5.27508 5.53595 2.29674 1.81429C1.75405 2.75321 1.46967 3.82228 1.47317 4.91028C1.47317 7.039 2.54168 8.91602 4.17107 10.0199C3.21084 9.98162 2.2731 9.71428 1.43412 9.23964V9.31515C1.43412 12.2961 3.51434 14.7664 6.28679 15.3345C5.76623 15.4715 5.23073 15.5416 4.6929 15.5431C4.29886 15.5431 3.92613 15.5035 3.54984 15.4496C4.31661 17.8804 6.54948 19.6459 9.20833 19.7034C7.12811 21.3539 4.5225 22.3248 1.69326 22.3248C1.18563 22.3248 0.717045 22.3068 0.230713 22.2493C2.91441 23.9932 6.09865 25.0001 9.52782 25.0001C20.6602 25.0001 26.7518 15.6582 26.7518 7.54961C26.7518 7.28352 26.7518 7.01743 26.734 6.75134C27.9126 5.87756 28.9456 4.79522 29.7692 3.57983Z"
            fill="currentColor"
        />
    ),
});
