import styled from 'styled-components';
import { CardBox } from '../../../../components/CardBox';
import { devices } from '../../../../styles/devices';

interface FormProps {
    type: 'buy' | 'sell';
}

export const Form = styled.form<FormProps>`
    width: 100%;
    padding: 5px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const Label = styled.label`
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 2;
    letter-spacing: 0.05em;
`;

export const BoxContainer = styled(CardBox)`
    margin-top: 10px;
    margin-bottom: 10px;
    @media ${devices.minLaptop} {
        max-width: 260px;
    }
    @media ${devices.maxLaptop} {
        width: 49%;
    }
    @media ${devices.maxMobileL} {
        max-width: unset;
        width: 100%;
    }
`;

export const ButtonGroup = styled.section`
    width: 100%;
`;
