import { Epic } from 'redux-observable';
import { interval, of } from 'rxjs';
import { switchMap, throttleTime, map, mergeMap, catchError, takeUntil, delay, filter } from 'rxjs/operators';

import { subscribeLevel1Actions, subscribeLevel1Slice } from '.';
import { ajax } from 'rxjs/ajax';
import appConfig from '../../config/appConfig';
import { getToken } from '../../utils/token';

export const subscribeLevel1Epic: Epic = (action$, state$, { Alphapoint, defaultThrottleTime }) =>
    action$.pipe(
        filter(subscribeLevel1Actions.subscribeLevel1.match),
        throttleTime(defaultThrottleTime),
        switchMap(() => {
            const body = {
                OMSId: state$.value.companies.current.assets.defaultOMSId,
                InstrumentId: state$.value.companies.current.assets.defaultInstrumentId,
            };
            return Alphapoint.subscribe(
                { action: subscribeLevel1Slice.name, ...body },
                { action: 'UnsubscribeLevel1', ...body },
                (msg) => msg.n === 'Level1UpdateEvent'
            ).pipe(
                map(({ data }) => subscribeLevel1Actions.subscribeLevel1Success(data)),
                catchError(() => of(subscribeLevel1Actions.subscribeLevel1Failed())),
                takeUntil(action$.pipe(filter(subscribeLevel1Actions.unsubscribeLevel1.match)))
            );
        })
    );

export const getTunnelDataEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(subscribeLevel1Actions.subscribeLevel1Success.match),
        filter(() => !!state$.value.companies.current.assets.defaultInstrumentId),
        delay(1000),
        throttleTime(defaultThrottleTime),
        switchMap(() =>
            ajax({
                url: `https://api.flowbtc.com.br:8443/ap/GetInstrument?OMSId=1&InstrumentId=${state$.value.companies.current.assets.defaultInstrumentId}`,
                crossDomain: true,
                method: 'GET',
            }).pipe(
                map(({ response }) =>
                    subscribeLevel1Actions.subscribeLevel1TunnelData({
                        currentStep: state$.value.timeline.currentStep.type,
                        response,
                    })
                ),
                catchError(() => of(subscribeLevel1Actions.subscribeLevel1Failed()))
            )
        )
    );

export const getAuctionDataEpic: Epic = (action$, state$, { defaultThrottleTime }) =>
    action$.pipe(
        filter(subscribeLevel1Actions.subscribeLevel1.match),
        filter(
            () =>
                state$.value.timeline.currentStep?.type !== 'dealing' &&
                state$.value.timeline.currentStep?.type !== 'closed'
        ),
        throttleTime(defaultThrottleTime),
        switchMap(() =>
            interval(5000).pipe(
                switchMap(() => {
                    subscribeLevel1Actions.resetTheoricalVariation();
                    return ajax({
                        url: `${appConfig.API_URL}config/platform/auction/${state$.value.companies.current.assets.ticker}`, //'https://bee4-auction.onrender.com/',
                        crossDomain: true,
                        method: 'GET',
                        headers: { Authorization: getToken() },
                    }).pipe(
                        mergeMap(({ response }) => of(subscribeLevel1Actions.subscribeLevel1AuctionData(response))),
                        catchError(() => of(subscribeLevel1Actions.subscribeLevel1Failed()))
                    );
                }),
                takeUntil(action$.pipe(filter(subscribeLevel1Actions.unsubscribeLevel1.match)))
            )
        )
    );
