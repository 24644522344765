import { Tooltip, useColorMode } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Loading } from '../../../../../../components/Loading';
import { useAppSelector } from '../../../../../../hooks/redux';
import { date, getDayShort } from '../../../../../../utils/date';
import { isMobile } from '../../../../../../utils/mobile';

import appConfig from '../../../../../../config/appConfig';
import schedule from '../../../../../../data/Timeline/weekly-schedules.json';

import { DayBox, Descript, Label, Wrapper, Step } from './styles';

export const WeeklySchedules = () => {
    const dayBox = useRef([]);
    const weeklySchedyles = useRef<HTMLDivElement>(null);

    const { colorMode } = useColorMode();
    const { timeline, clock } = useAppSelector((state) => state);

    const steps = { irrevocable: 0, processing: 1, dealing: 2 };
    const weekDays = [0, 1, 2, 3, 4, 5, 6];

    const dayTradeSteps = [
        {
            descript: '10h às 10h59min Ofertas irrevogáveis',
            status: 'irrevocable',
            tip: 'Registro de ofertas irrevogáveis. De 10h às 20h você pode alterar ofertas para melhor. Ou seja, aumentar as quantidades na compra ou venda; comprar por um preço maior ou vender por um preço menor. Não é permitido cancelar ofertas.',
        },
        {
            descript: '11h às 11h59min Execução do leilão',
            status: 'processing',
            tip: 'De 11h às 12h ocorre a execução do leilão.',
        },
        {
            descript: '12h às 19h59min Pregão',
            status: 'dealing',
            tip: 'De 12h às 20h ocorre negociação em tempo real. Ofertas são executadas automaticamente, de acordo com demanda, ordem de preço e cronologia.',
        },
    ];
    const closed = [
        {
            descript: 'Fechado devido ao feriado',
            status: 'closed',
            tip: 'Devido ao feriado, leilão e o pregão não serão realizados hoje.',
        },
        {
            descript: 'Fechado devido ao feriado',
            status: 'closed',
            tip: 'Devido ao feriado, a plataforma estará indisponível.',
        },
    ];

    const [activesDays, setActivesDays] = useState<number[]>([]);
    const [activesSteps, setActivesSteps] = useState<number[]>([]);
    const [todayNumber, setTodayNumber] = useState<number>();
    const [dayTrade, setDayTrade] = useState<number>(2);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        setActives();

        if (!scrolled && !!dayBox.current[todayNumber] && isMobile()) {
            setScrolled(true);
            dayBox.current[todayNumber]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clock.time]);

    useEffect(() => {
        if ([5, 6].includes(todayNumber)) {
            return;
        }

        !!timeline.allWeekEvents?.length &&
            timeline.allWeekEvents.map((event) => {
                const _weekDayName = getDayShort(date(event.date, 'DD/MM').format('YYYY-MM-DD')).toLocaleLowerCase();

                const eventDate = date(event.date, 'DD/MM');
                const _eventDayNumber = eventDate.weekday() - 1;

                const _date = date(event.date, 'DD/MM').add(1, 'day').format('YYYY-MM-DD');
                const _nextDayTrade = getDayShort(_date).toLocaleLowerCase();
                const _nextDayTradeNumber = date(event.date, 'DD/MM').add(1, 'day').weekday() - 1;

                weekDays.forEach((day) => {
                    if (_eventDayNumber === dayTrade) {
                        schedule[_weekDayName].steps = [closed[0]];
                        schedule[_nextDayTrade].steps = dayTradeSteps;
                        setDayTrade(() => _nextDayTradeNumber);
                    } else if (_eventDayNumber === day) {
                        schedule[_weekDayName].steps = [closed[0]];
                    }
                });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.allWeekEvents]);

    const setActives = () => {
        const day = getDayShort(timeline.time).toLocaleLowerCase();
        const _todayNumber = schedule[day].sorter;

        const currentStep = timeline.currentStep.type;
        const step = steps[currentStep];

        const _activesDays = [];
        const _activesSteps = [];

        weekDays.forEach((index) => index <= _todayNumber && _activesDays.push(index));
        Object.values(steps).forEach((value) => value <= step && _activesSteps.push(value));

        setTodayNumber(() => _todayNumber);
        setActivesDays(() => _activesDays);
        setActivesSteps(() => _activesSteps);
    };

    return (
        <Wrapper id="weekly-schedules" ref={weeklySchedyles}>
            {Object.values(schedule).map((day, index) => (
                <DayBox key={`day-${index}`} ref={(element) => dayBox.current.push(element)} overlay={timeline.loading}>
                    <Label
                        active={activesDays.includes(index)}
                        off={
                            dayTrade === index &&
                            todayNumber === index &&
                            date(date(clock.time).format('HH:mm:ss'), 'HH:mm:ss').isBefore(
                                date(appConfig.CLOSED_TIME, 'HH:mm:ss')
                            )
                        }
                        $colorMode={colorMode}
                    >
                        {day.label}
                    </Label>
                    <Descript>
                        {day.steps?.map((step, i) => (
                            <Tooltip
                                key={`step-${i}`}
                                hasArrow
                                maxWidth="200px"
                                background="lead.100"
                                placement="bottom"
                                borderRadius="5px"
                                color="white"
                                fontSize=".7rem"
                                textAlign="center"
                                padding="10px"
                                whiteSpace="pre-wrap"
                                label={step.tip}
                                isDisabled={timeline.loading}
                            >
                                <Step
                                    $colorMode={colorMode}
                                    numItems={day.steps.length}
                                    active={
                                        (day.steps.length === 1 && activesDays.includes(index)) ||
                                        (dayTrade === todayNumber && dayTrade === index && activesSteps.includes(i)) ||
                                        index < activesDays[activesDays.length - 1] ||
                                        (dayTrade === index &&
                                            todayNumber === index &&
                                            timeline.currentStep.type === 'closed' &&
                                            date(
                                                `${date().format('DD/MM/YYYY')} ${date(clock.time).format('HH:mm:ss')}`,
                                                'DD/MM/YYYY HH:mm:ss'
                                            ).isSameOrAfter(
                                                date(
                                                    `${date().format('DD/MM/YYYY')} ${appConfig.CLOSED_TIME}`,
                                                    'DD/MM/YYYY HH:mm:ss'
                                                )
                                            ))
                                    }
                                    halfOrFull={
                                        dayTrade === index &&
                                        i === activesSteps.length - 1 &&
                                        index === activesDays[activesDays.length - 1]
                                            ? 'half'
                                            : 'full'
                                    }
                                >
                                    {step.descript}
                                </Step>
                            </Tooltip>
                        ))}
                    </Descript>
                </DayBox>
            ))}
            <Loading active={timeline.loading} />
        </Wrapper>
    );
};
