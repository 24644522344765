import { Fragment, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { currency } from '../../../../utils/numbers';
import { date, onlyDateDBToBR, onlyTime } from '../../../../utils/date';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';

import { ColorfulLabel } from '../../../../components/ColorfulLabel';
import { Loading } from '../../../../components/Loading';
import { subscribeTradesActions } from '../../../../store/subscribeTrades';
import { TSubscribeTradesItem } from '../../../../store/subscribeTrades/types';
import { DateLabel } from './style';
import { useColorMode } from '@chakra-ui/react';

interface LastTradesProps {
    height?: number | string;
}

export const LastTrades = ({ height }: LastTradesProps) => {
    const dispatch = useAppDispatch();
    const { subscribeTrades, timeline } = useAppSelector((state) => state);
    const [trades, setTrades] = useState([]);

    const { colorMode } = useColorMode();
    let currentDate = '';

    useEffect(() => {
        if (timeline.currentStep?.type === undefined) {
            return;
        }

        dispatch(subscribeTradesActions.subscribeTrades());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline.currentStep]);

    useEffect(() => {
        if (!subscribeTrades.updated) {
            return;
        }

        setTrades(Object.values(subscribeTrades.trades));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeTrades.updated]);

    return (
        <>
            <thead>
                <tr className="small-row">
                    <th>Preço</th>
                    <th>Qtd</th>
                    <th>Horário</th>
                </tr>
            </thead>
            <tbody style={{ height: height }}>
                <tr id="mobile-header">
                    <th>Preço</th>
                    <th>Qtd</th>
                    <th>Horário</th>
                </tr>
                {!!trades.length &&
                    trades.map((item: TSubscribeTradesItem) => {
                        let showDate = false;

                        if (date(new Date(item.Tradetime).toISOString()).format('YYYYMMDD') !== currentDate) {
                            currentDate = date(new Date(item.Tradetime).toISOString()).format('YYYYMMDD');
                            showDate = true;
                        }

                        return (
                            <Fragment key={`recent-orders-${uuid()}`}>
                                {showDate && (
                                    <DateLabel $colorMode={colorMode}>
                                        <td>
                                            <label>
                                                Data: {onlyDateDBToBR(new Date(item.Tradetime).toISOString())}
                                            </label>
                                        </td>
                                    </DateLabel>
                                )}
                                <tr key={`last-trade-${uuid()}`} className="small-row">
                                    <td>
                                        <ColorfulLabel type={item.TakerSide}>{currency(item.Price)}</ColorfulLabel>
                                    </td>
                                    <td>{item.Quantity.toFixed(0)}</td>
                                    <td>{onlyTime(item.Tradetime, 'x')}</td>
                                </tr>
                            </Fragment>
                        );
                    })}
            </tbody>
            <tfoot>
                <tr style={{ position: 'absolute', bottom: 0 }}>
                    <td>
                        <Loading active={subscribeTrades.loading} />
                    </td>
                </tr>
            </tfoot>
        </>
    );
};
