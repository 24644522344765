import appConfig from '../../config/appConfig';
import { TCompany } from './types';

export const initialState = {
    list: [] as TCompany[],
    current: {
        assets: {
            defaultFiatId: appConfig._DEFAULT_FIAT_ID, // 4 - BRL
            defaultOMSId: appConfig._DEFAULT_OMS_ID, // 1 - Finchain
        },
    } as TCompany,
    loading: true,
    assets: {},
};
