import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

import { MessageCard, MessageContainer, StyledIcon } from './styles';
import { Icon } from '../Icon';
import { EventsMessages } from '../../store/timeline/types';

type TMessageBoxProps = {
    messageId?: string;
    messages?: EventsMessages[];
    theme: string;
    close?: boolean;
    html?: boolean;
};

export const MessageBox = ({
    messageId = 'disclaimer-box',
    messages,
    theme,
    close = true,
    html = false,
}: TMessageBoxProps) => {
    const [warningIsHidden, setWarningIsHidden] = useState<boolean>(true);

    useEffect(() => {
        setWarningIsHidden(() => !messages?.length);

        return () => {
            setWarningIsHidden(true);
        };
    }, [messages]);

    const closeAndSetReadMessage = () => {
        setWarningIsHidden(() => true);
    };

    return (
        <MessageContainer id={messageId} flexDirection="column" style={{ display: warningIsHidden ? 'none' : 'flex' }}>
            <MessageCard marginBottom="4px" $theme={!!theme ? theme : 'yellow'}>
                <Icon type="warning" height="28px" width="32px" mr={4} />
                <Flex flex={1} className="disclaimer-text" direction="column">
                    {!!messages?.length &&
                        messages.map((events, idx) =>
                            html ? (
                                <span dangerouslySetInnerHTML={{ __html: events.message }} />
                            ) : (
                                <ReactMarkdown key={`disclaimer-${idx}`}>{events.message}</ReactMarkdown>
                            )
                        )}
                </Flex>
                {close && (
                    <StyledIcon
                        id="disclainer-close"
                        type="close"
                        height="12px"
                        width="12px"
                        ml={4}
                        onClick={closeAndSetReadMessage}
                    />
                )}
            </MessageCard>
        </MessageContainer>
    );
};
