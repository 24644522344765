import { bee4Theme } from './../../styles/theme';
import styled from 'styled-components';
import { Input } from '@chakra-ui/react';

interface FormProps {
    $colorMode: string;
}

export const Form = styled.form<FormProps>`
    width: 100%;
    max-width: 500px;
    margin: 30px 0;
    padding: 20px 40px;

    display: flex;
    flex-flow: column;

    border: 1px solid ${bee4Theme.colors.lead[100]};
    border-radius: 10px;

    box-sizing: border-box;
    background: ${(props) =>
        props.$colorMode === 'light'
            ? `linear-gradient(90deg, ${bee4Theme.colors.silver[100]}, ${bee4Theme.colors.silver[200]});`
            : bee4Theme.colors.lead[400]};
`;

export const DataList = styled.datalist`
    width: 100%;
    background: ${bee4Theme.colors.gray['000']};
`;

export const InputDataList = styled(Input)`
    &[List]::-webkit-calendar-picker-indicator {
        display: none !important;
    }
`;
