import { Text, Flex } from '@chakra-ui/react';
import { bee4Theme } from './../../../../styles/theme';
import styled, { css } from 'styled-components';
import { devices } from './../../../../styles/devices';

interface BarProps {
    width?: string;
    fill: number | string;
}

interface ArrowProps {
    referenceMargin: number;
}

interface RangeBarProps {
    width: number;
    rangePrice: boolean;
    isColorfulUnique: boolean;
    $extrapolateRange: boolean;
    $maxEqualRange: boolean;
    $minEqualRange: boolean;
}

interface MiniRangeBarProps {
    size: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
}

interface ContainerProps {
    minValue: number;
    maxValue: number;
    value: number;
}
interface ContainerRangeProps {
    $extrapolateRange: boolean;
}
interface PlusProps {
    right?: boolean;
}

interface LabelProps {
    $hasRange?: boolean;
    $colorMode?: string;
}
interface LabelRangeProps {
    right?: boolean;
    $isValue?: boolean;
    $colorMode?: string;
}

interface ContainerValuesProps {
    $hasRange?: boolean;
}

export const Bar = styled.div<BarProps>`
    height: 8px;
    width: ${({ width }) => (width ? width : '100%')};
    background: ${bee4Theme.colors.lead[100]};

    border-radius: 8px;
    border: 0;

    position: relative;

    &::before {
        content: '';

        // fix size filled
        width: ${({ fill }) => (fill <= 100 ? `${fill}%` : '100%')};
        height: 100%;

        border-radius: 8px;

        position: absolute;
        top: 0;
        left: 0;

        background: linear-gradient(
            90deg,
            ${bee4Theme.colors.yellow[200]} 17.68%,
            ${bee4Theme.colors.yellow[500]} 84.69%
        );
    }
`;

export const ArrowTop = styled.span<ArrowProps>`
    position: absolute;
    top: -40px;
    width: 0;
    height: 0;
    display: block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid ${bee4Theme.colors.yellow[100]};
    left: ${({ referenceMargin }) => `${referenceMargin < 0 ? 0 : referenceMargin > 95 ? 95 : referenceMargin}%`};
`;

export const miniRangeBar = styled.span<MiniRangeBarProps>`
    background: transparent;
    width: ${({ size }) => (size === 'xsm' ? 5 : size === 'sm' ? 15 : size === 'md' ? 25 : size === 'lg' ? 35 : 45)}px;
    height: 8px;
    position: relative;
`;

export const RangeBar = styled.span<RangeBarProps>`
    background: ${({ rangePrice }) => (rangePrice ? bee4Theme.colors.yellow[200] : 'transparent')};
    width: ${({ width }) => `${width}%`};
    height: 8px;
    position: relative;

    ${({ rangePrice, isColorfulUnique }) =>
        rangePrice &&
        css`
            min-width: ${isColorfulUnique ? '115' : '57.5'}px;

            @media ${devices.maxTablet} {
                min-width: ${isColorfulUnique ? '115' : '57.5'}px;
            }
        `}

    ${({ rangePrice, $extrapolateRange }) =>
        rangePrice &&
        !$extrapolateRange &&
        css`
            @media ${devices.maxTablet} {
                min-width: 115px;
            }
        `}

    ${({ $maxEqualRange }) =>
        $maxEqualRange &&
        css`
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        `}

    ${({ $minEqualRange }) =>
        $minEqualRange &&
        css`
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        `}
`;
// margin-right: 5px;

export const Container = styled.span<ContainerProps>`
    position: absolute;
    top: -30px;
    width: 230px;

    /* Calculo para evitar que posicionamento do valor
    de referencia saia da area dele */
    left: ${({ minValue, maxValue, value }) => (value <= minValue ? '-95px' : value > maxValue ? '-135px' : '-120px')};

    @media ${devices.maxTablet} {
        left: ${({ minValue, maxValue, value }) =>
            value < minValue ? '-65px' : value > maxValue ? '-155px' : '-120px'};
    }
`;

export const TextValue = styled(Text)`
    position: absolute;
    top: -20px;
    font-size: 0.65rem;
`;

export const Plus = styled(Flex)<PlusProps>`
    background: ${bee4Theme.colors.yellow[300]};
    width: 30px;
    height: 8px;
    position: relative;

    &::before {
        content: '';
        ${({ right }) => (right ? `left: -3px;` : `right: -1px`)};
        height: 24px;
        position: absolute;
        width: 3px;
        top: -7px;
        border-right: 1px dashed;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

export const Spacing = styled.div`
    height: 100%;
    width: 30px;
`;

export const ContainerRange = styled(Flex)<ContainerRangeProps>`
    justify-content: space-around;
    width: 100%;
    border-radius: 8px;
    background: ${bee4Theme.colors.lead[100]};
    position: relative;

    @media ${devices.maxTablet} {
        width: ${({ $extrapolateRange }) => ($extrapolateRange ? '80%' : '100%')};
    }
`;

export const Label = styled(Text)<LabelProps>`
    font-weight: 500;
    font-size: 0.8rem;
    color: ${bee4Theme.colors.lead[100]};
    margin-top: -8px;
`;

export const LabelValue = styled(Text)<LabelProps>`
    font-weight: 500;
    font-size: 0.7rem;
    color: ${({ $hasRange, $colorMode }) => {
        if ($colorMode === 'dark') {
            return $hasRange ? bee4Theme.colors.white : bee4Theme.colors.gray[300];
        } else {
            return $hasRange ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[500];
        }
    }};
`;

export const LabelRange = styled(Text)<LabelRangeProps>`
    font-size: 0.6rem;
    color: ${({ $isValue, $colorMode }) => {
        if ($colorMode === 'dark') {
            return $isValue ? bee4Theme.colors.white : bee4Theme.colors.gray[300];
        } else {
            return $isValue ? bee4Theme.colors.lead[400] : bee4Theme.colors.gray[500];
        }
    }};

    position: absolute;
    ${({ $isValue }) => ($isValue ? `top: -25px;` : `top: 11px`)};
    ${({ right }) => (right ? `left: 0;` : `right: -1px`)};
`;
// bottom: ${({ isValue }) => (isValue ? '9px' : '-17px')};

export const ContainerValues = styled(Flex)<ContainerValuesProps>`
    width: 100%;
    justify-content: space-between;
    margin-bottom: -1px;

    ${({ $hasRange }) =>
        !$hasRange &&
        css`
            position: absolute;
            bottom: 44px;
        `};
`;
