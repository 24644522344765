import { createIcon } from '@chakra-ui/icons';

export const IconArrow = createIcon({
    displayName: 'IconArrow',
    viewBox: '0 0 104 104',
    path: (
        <path
            d="M81.4473 49.0777L29.1223 8.20897C28.9856 8.10133 28.8213 8.03443 28.6483 8.01597C28.4752 7.9975 28.3005 8.02821 28.1441 8.10458C27.9878 8.18094 27.8561 8.29986 27.7643 8.44767C27.6725 8.59549 27.6242 8.76621 27.625 8.94022V17.9125C27.625 18.4813 27.892 19.0268 28.333 19.375L70.1188 52.0027L28.333 84.6304C27.8804 84.9786 27.625 85.5242 27.625 86.0929V95.0652C27.625 95.8429 28.5188 96.2724 29.1223 95.7965L81.4473 54.9277C81.8921 54.5808 82.2518 54.1371 82.4993 53.6302C82.7467 53.1234 82.8754 52.5668 82.8754 52.0027C82.8754 51.4387 82.7467 50.8821 82.4993 50.3752C82.2518 49.8684 81.8921 49.4246 81.4473 49.0777Z"
            fill="currentColor"
        />
    ),
});
