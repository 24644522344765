import { TSubscribeTradesInitialState, TSubscribeTradesItem } from './types';

const subscribeTradesMap = new Map();

subscribeTradesMap.set(0, 'TradeId');
subscribeTradesMap.set(1, 'ProductPairCode');
subscribeTradesMap.set(2, 'Quantity');
subscribeTradesMap.set(3, 'Price');
subscribeTradesMap.set(4, 'Order1');
subscribeTradesMap.set(5, 'Order2');
subscribeTradesMap.set(6, 'Tradetime');
subscribeTradesMap.set(7, 'Direction');
subscribeTradesMap.set(8, 'TakerSide');
subscribeTradesMap.set(9, 'BlockTrade');
subscribeTradesMap.set(10, 'OrderClientId');

export { subscribeTradesMap };

export const initialState: TSubscribeTradesInitialState = {
    error: false,
    loading: true,
    trades: {},
};

export const parseSubscribeTradesPayload = (
    data: Array<Array<string | number>>
): Record<TSubscribeTradesItem['TradeId'], TSubscribeTradesItem> => {
    const parsedPayload: Record<TSubscribeTradesItem['TradeId'], TSubscribeTradesItem> = {};

    data?.forEach((orders) => {
        let key = undefined;
        const obj = {};

        orders?.forEach((i, idx) => {
            if (subscribeTradesMap.get(idx) === 'TradeId') {
                key = i;
            }
            obj[subscribeTradesMap.get(idx)] = i;
        });

        parsedPayload[key] = obj;
    });

    return parsedPayload;
};
