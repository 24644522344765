import styled, { css } from 'styled-components';
import { bee4Theme } from '../../styles/theme';

interface ActionButtonProps {
    colorScheme: 'green' | 'red';
    width?: string;
    padding?: string;
    onClick?: () => void;
    disabled?: boolean;
}

interface BlockedButtonProps {
    $colorMode?: string;
}

export const ActionButton = styled.button<ActionButtonProps>`
    width: ${(props) => props.width || '100%'};
    height: auto;
    padding: ${(props) => props.padding || '15px'};
    margin: 15px 0;
    border-radius: 5px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    background: transparent;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 0;
    color: ${bee4Theme.colors.white};

    ${({ disabled }) =>
        !disabled &&
        css`
            &::before {
                content: '';

                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;

                transition: all 0.5s ease-in-out;
                border-radius: 4px;

                background-size: 250% 100%;
                background-position: 100%;
                background-image: ${(props: any) =>
                    props.colorScheme === 'green'
                        ? `linear-gradient(90deg, ${bee4Theme.colors.green[300]} 1%, ${bee4Theme.colors.green[300]} 30%, ${bee4Theme.colors.green[200]} 75%, ${bee4Theme.colors.green[100]} 100%)`
                        : `linear-gradient(90deg, ${bee4Theme.colors.red[300]} 1%, ${bee4Theme.colors.red[300]} 30%, ${bee4Theme.colors.red[200]} 75%, ${bee4Theme.colors.red[200]} 100%)`};
            }
        `}

    &:hover {
        &::before {
            background-position: -1px 100%; //-1px fix bug Chrome
        }
    }

    &:disabled {
        background-color: ${bee4Theme.colors.lead[200]};
    }
`;

export const BlockedButton = styled.button<BlockedButtonProps>`
    width: 100%;
    height: auto;
    padding: 15px;
    margin: 15px 0;
    border-radius: 5px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    background: ${(props) => (props.$colorMode === 'dark' ? bee4Theme.colors.lead[200] : bee4Theme.colors.gray[300])};
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 0;
    color: ${bee4Theme.colors.white};
`;
