import { useChangePass } from '../../context/rescuePassword';

import { PasswordChangeSuccessful } from './PasswordChangeSuccessful';
import { RequestSecurityCode } from './RequestSecurityCode';
import { SetNewPassword } from './SetNewPassword';

export const RescuePassword = () => {
    const { step } = useChangePass();

    switch (step) {
        case 2: // Insert the code received by e-mail and password
            return <SetNewPassword />;
        case 4: // Success screen
            return <PasswordChangeSuccessful />;
        default:
            // any or 1 - Request Code
            return <RequestSecurityCode />;
    }
};
