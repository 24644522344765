import { VStack, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { shallowEqual } from 'react-redux';
import remarkGfm from 'remark-gfm';

import { CardBox } from '../../../../components/CardBox';
import { useAppSelector } from '../../../../hooks/redux';
import { date } from '../../../../utils/date';

export const ProcessingMessage = () => {
    const timeline = useAppSelector((state) => state.timeline, shallowEqual);
    return (
        <CardBox variant="colorful-border" padding="50px 15px" marginTop="25px">
            <VStack>
                <Text
                    width="50%"
                    margin="10px 0 20px"
                    fontWeight="600"
                    fontSize="1.2rem"
                    textAlign="center"
                    lineHeight="1.4"
                >
                    {timeline.lockBox
                        ? timeline.lockBox.message
                        : 'Estamos executando as ofertas do leilão e calculando o novo preço de referência para a sessão de pregão.'}
                </Text>

                {!timeline.lockBox ? (
                    <>
                        <Text width="50%" fontWeight="400" fontSize="1rem" textAlign="center" lineHeight="1.4">
                            O pregão ocorre das
                        </Text>
                        <Text width="50%" fontWeight="400" fontSize="1.2rem" textAlign="center" lineHeight="1.4">
                            {`${date(timeline.currentStep?.finish, 'HH:mm:ss')
                                .add(1, 'minute')
                                .format('HH[h]mm[min]')} às ${date(
                                timeline.schedule?.slice(-1).pop()['finish'],
                                'HH:mm:ss'
                            ).format('HH[h]mm[min]')}`}
                        </Text>
                    </>
                ) : (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{timeline.lockBox.nextDealingTime}</ReactMarkdown>
                )}
            </VStack>
        </CardBox>
    );
};
