import { TSubscribeLevel1InitialState } from './types';

export const initialState: TSubscribeLevel1InitialState = {
    error: false,
    loading: false,
    auction: {
        price: null,
        quantity: 0,
    },
    tunnel: {
        min: 0,
        max: 0,
        reference: 0,
        range: {
            min: 0,
            max: 0,
        },
    },
};
