import { Flex, VStack } from '@chakra-ui/react';
import { CardBox } from '../../../../components/CardBox';
import { TradingTable } from '../../../../components/TradingTable';
import { useAppSelector } from '../../../../hooks/redux';
import { isMobile } from '../../../../utils/mobile';
import { FlexContainer } from '../../styles';
import { FormTrading } from '../FormTrading';
import { InformationBox } from '../InformationBox';
import { LastTrades } from '../TableLastTrades';
import { TableOffers } from '../TableOffers';
import { OpenOrders } from '../TableOpenOrders';
import { RecentOrders } from '../TableRecentOrders';

export const Trade = () => {
    const { current } = useAppSelector((state) => state.companies);

    const recentOffers = {
        time: 'dealing' as const,
        message:
            'Quando suas ofertas são executadas no leilão, gerando negócios, os registros de compra e venda feitos entre quinta da semana anterior e quarta até às 11h são cancelados. Portanto, caso após o leilão visualize uma mensagem de cancelamento, confira se você também recebeu a mensagem de oferta executada antes de fazer um novo registro.',
    };

    return (
        <>
            <CardBox $topLine={true} padding="30px" $hasBorder={true}>
                <InformationBox />
            </CardBox>

            <FlexContainer width="100%" justifyContent="space-between">
                <TableOffers type="buy" title="Ofertas de compra" tablePosition="left" />
                <TableOffers type="sell" title="Ofertas de venda" tablePosition="left" />
                <FormTrading type="buy" title="Ofertas de compra" modalId="order-buy" />
                <FormTrading type="sell" title="Ofertas de venda" modalId="order-sell" />
            </FlexContainer>

            <Flex
                margin={['10px 0 0', '10px 0 0', '10px 0 0', '25px 0 0']}
                width="100%"
                direction={['column', 'column', 'row']}
                justifyContent="space-between"
            >
                <VStack width="100%" maxWidth={['unset', 'unset', '350px']}>
                    <TradingTable title="Últimos negócios">
                        <LastTrades height={isMobile() ? null : 702} />
                    </TradingTable>
                </VStack>
                <VStack width="100%" spacing={3} maxWidth={['unset', 'unset', 'calc(100% - 360px)']}>
                    <TradingTable title={`Minhas ofertas abertas em ${current.assets.ticker}`}>
                        <OpenOrders companyName={current.name} />
                    </TradingTable>
                    <TradingTable
                        title={`Minhas ofertas recentes em ${current.assets.ticker}`}
                        information={recentOffers}
                        hasLink={{
                            label: 'Ver todas as ofertas',
                            link: '/minhas-ofertas',
                            target: '_self',
                        }}
                    >
                        <RecentOrders />
                    </TradingTable>
                </VStack>
            </Flex>
        </>
    );
};
